import React from "react";
import "./PointsRemember.css";
import Skeleton from "react-loading-skeleton";

const InclusionExclusionContent = ({ singlePackageData, loader }) => {
  return (
    <>
      {loader ? (
        <div className="package-update-content-containers">
          <div className="package-update-cards">
            <div className="package-card-body">
              {Array.from({ length: 3 }).map((_, index) => (
                <div className="package-update-card" key={index}>
                  <div className="content-consider">
                    <div>
                      <Skeleton width={100} />
                      <div className="agent">
                        <div className="singlePackageTabShowBullets">
                          <ul>
                            <li>
                              <Skeleton width={720} />
                            </li>
                            <li>
                              <Skeleton width={400} />
                            </li>
                            <li>
                              <Skeleton width={300} />
                            </li>
                            <li>
                              <Skeleton width={200} />
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="package-update-card p_10">
            {/* Content for accommodation */}
            <div className="content-consider">
              <div>
                <div className="agent">
                  <div>
                    {" "}
                    <span style={{ fontWeight: "bold" }}>Inclusions</span>
                  </div>
                  {/* <button className="change_btn">Change</button> */}
                </div>

                {singlePackageData?.packagePolicies?.length > 0 ? (
                  <>
                    {singlePackageData?.packagePolicies?.map((items, index) => {
                      const inclusion = items;
                      return (
                        <div key={index}>
                          {items.policyType === "inclusion" && (
                            // <li className="mt-10">{items.policyContent}</li>
                            <>
                              <div
                                className="singlePackageTabShowBullets"
                                dangerouslySetInnerHTML={{
                                  __html: items.policyContent,
                                }}
                              />

                              {/* { <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(items.policyContent) }} /> } */}
                            </>
                          )}
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <>No Data Found</>
                )}
              </div>
            </div>
          </div>
          <div className="package-update-card p_10">
            {/* Content for accommodation */}
            <div className="content-consider">
              <div>
                <div className="agent">
                  <div>
                    {" "}
                    <span style={{ fontWeight: "bold" }}>Exclusions</span>
                  </div>
                </div>
                {singlePackageData?.packagePolicies?.length > 0 ? (
                  <>
                    {singlePackageData?.packagePolicies?.map((items, index) => {
                      const exclusion = items;
                      return (
                        <div key={index}>
                          {items.policyType === "exclusion" && (
                            // <li className="mt-10">{items.policyContent}</li>
                            <div
                              className="singlePackageTabShowBullets"
                              dangerouslySetInnerHTML={{
                                __html: items.policyContent,
                              }}
                            ></div>
                          )}
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <>No Data Found</>
                )}
              </div>
            </div>
          </div>
          <div className="package-update-card p_10">
            {/* Content for accommodation */}
            <div className="content-consider">
              <div>
                <div className="agent">
                  <div>
                    {" "}
                    <span style={{ fontWeight: "bold" }}>
                      Terms & Conditions
                    </span>
                  </div>
                </div>
                {singlePackageData?.packagePolicies?.length > 0 ? (
                  <>
                    {singlePackageData?.packagePolicies?.map((items, index) => {
                      const terms_and_conditions = items;
                      return (
                        <div key={index}>
                          {items.policyType === "terms_and_conditions" && (
                            <div
                              className="singlePackageTabShowBullets"
                              dangerouslySetInnerHTML={{
                                __html: items.policyContent,
                              }}
                            ></div>
                          )}
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <>No Data Found</>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default InclusionExclusionContent;
