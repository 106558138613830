import React, { useEffect } from "react";
import OurQuery from "../../CustomComponents/OurQuery/OurQuery";
import Footer from "../../CustomComponents/Footer/Footer";
import PageLinks from "../../CustomComponents/PageLinks/PageLinks";
import ReviewReservationBanner from "../../CustomComponents/ReviewReservationBanner/ReviewReservationBanner";
import Header from "../../CustomComponents/Header/Header";
import PageMetaData from "../../CustomComponents/PageMetaData/PageMetaData";
import { useState } from "react";

function CheckOutHoliday() {
    const [isPopupVisibleId, setIsPopupVisibleId] = useState(null);
    const [modifyDate, setmodifyDate] = useState(false);
    const openPopup = (id) => {
        setIsPopupVisibleId(id);
    };
    const closePopup = (id) => {
        setIsPopupVisibleId(id);
    };
    const openmodifyDate = (id) => {
        setmodifyDate(id);
    };
    const closemodifyDate = (id) => {
        setmodifyDate(id);
    };

    const [travelerDetails, settravelerDetails] = useState(true);

    const toggletravelerDetails = () => {
        settravelerDetails(!travelerDetails);
    };

    useEffect(() => {
        const updateHideToggle = () => {
            if (window.innerWidth <= 768) {
                settravelerDetails(false);
            } else {
                settravelerDetails(true);
            }
        };

        updateHideToggle(); // Set the initial state based on screen size
        window.addEventListener('resize', updateHideToggle);

        return () => window.removeEventListener('resize', updateHideToggle);
    }, []);

    return (
        <div id="Listingholidays">
            <PageMetaData
                title="Mountain Mysteries"
                description="Enhance your hotel capabilities with RevCatalyst's powerful system. Optimize performance, streamline operations, and unleash the full potential of property."
                keywords="hotel management, RevCatalyst, property optimization"
            />
            <Header />
            <ReviewReservationBanner heading="Review Reservation Details" />
            {/* <PageLinks heading="Properties In Shimla" /> */}
            <section>
                <div className="container">
                    <main className="justify_content_center">
                        <div className="reservation-content ">
                            <div className="p_0 common_background ">
                                <div className="flex justify_content_between fixed_color">
                                    <div>
                                        <h3>Ex Delhi: Manali Volvo Package 4N/5D</h3>
                                        <span className="custom-text"> <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.375 2.5H8.875" stroke="#333333" strokeWidth="1.2"
                                                stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M6.875 1.5V3.5" stroke="#333333" strokeWidth="1.2"
                                                stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M6.875 2.5H1.375" stroke="#333333" strokeWidth="1.2"
                                                stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M3.375 6H1.375" stroke="#333333" strokeWidth="1.2"
                                                stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M5.375 5V7" stroke="#333333" strokeWidth="1.2" stroke-linecap="round"
                                                stroke-linejoin="round" />
                                            <path d="M10.875 6H5.375" stroke="#333333" strokeWidth="1.2"
                                                stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M10.375 9.5H8.875" stroke="#333333" strokeWidth="1.2"
                                                stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M6.875 8.5V10.5" stroke="#333333" strokeWidth="1.2"
                                                stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M6.875 9.5H1.375" stroke="#333333" strokeWidth="1.2"
                                                stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                            Customizable</span>
                                        <span className="day-text">3N/4D</span>
                                        <span> <b>Date:</b> Tue, 24 Oct 2023 to 27 Oct 2023</span>
                                        <button className="modify_btn" onClick={openmodifyDate}> Modify </button>
                                        {modifyDate && (
                                            <div className="popup_backdrop">
                                                <div className="common_background" id="dateChangePopup">
                                                    <div className="flex justify_content_between">
                                                        <h4><b>Modify Date</b></h4>
                                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" onClick={() => closemodifyDate(null)} xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M1 13L13 1" stroke="#AAAAAA" strokeWidth="1.2" />
                                                            <path d="M13 13L0.999999 1" stroke="#AAAAAA" strokeWidth="1.2" />
                                                        </svg>
                                                    </div>
                                                    <form action="">
                                                        <input type="text" placeholder="02 Aug, 2024" />
                                                        <input type="text" placeholder="11 Aug, 2024" />
                                                        <div className="buttons">
                                                            <button className="primary_btn">Done</button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div>
                                        <b> 1 Room - 2 Adults</b>
                                    </div>
                                </div>
                                <div className="list_details">
                                    <div className="flex justify_content_between cursor_pointer align_items_center" onClick={toggletravelerDetails}>
                                        <h5 className="text-left">1. Travelers Details</h5>
                                        <svg
                                            width="13"
                                            height="8"
                                            viewBox="0 0 13 8"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            style={{
                                                transition: 'transform 0.4s',
                                                transform: travelerDetails ? 'rotate(180deg)' : 'rotate(0deg)',
                                            }}
                                        >
                                            <path
                                                d="M11.5527 1.34473L5.86308 6.65507L0.552735 1.34473"
                                                stroke="#222222"
                                                strokeWidth="1.5"
                                            />
                                        </svg>
                                    </div>
                                    {travelerDetails && (
                                        <>
                                            <div>
                                                <p className="text-left">2 Travelers - <small>1 Room | 2 Adults</small></p>
                                                <div className="travelr">
                                                    <small><b>Traveler 1</b></small><br />
                                                    <button className="add_btn" onClick={openPopup}>Add Traveler</button>
                                                    {isPopupVisibleId && (
                                                        <div className="popup_backdrop">
                                                            <div className="common_background" id="dateChangePopup">
                                                                <div className="flex justify_content_between">
                                                                    <h4><b>Enter Traveler Details</b></h4>
                                                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" onClick={() => closePopup(null)} xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M1 13L13 1" stroke="#AAAAAA" strokeWidth="1.2" />
                                                                        <path d="M13 13L0.999999 1" stroke="#AAAAAA" strokeWidth="1.2" />
                                                                    </svg>
                                                                </div>
                                                                <form action="">
                                                                    <input type="text" placeholder="Name" />
                                                                    <input type="text" placeholder="D.O.B" />
                                                                    <input type="text" placeholder="Gender" />
                                                                    <div className="buttons">
                                                                        <button className="primary_btn">Done</button>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    )}

                                                </div>
                                                <div className="travelr border-top">
                                                    <small><b>Traveler 1</b></small><br />
                                                    <button className="add_btn" onClick={openPopup}>Add Traveler</button>
                                                </div>
                                            </div>
                                            <form action="" className="guest_details m_0_auto">
                                                <h5 className="text-left">Enter contact details</h5>
                                                <div className="input-flex mt-10">
                                                    <div>
                                                        <label for="Phone">Phone</label>
                                                        <input type="text" placeholder="77788-89990" />
                                                    </div>
                                                    <div>
                                                        <label for="Email">Email</label>
                                                        <input type="text" placeholder="example@email.com" />
                                                    </div>
                                                </div>
                                            </form>
                                        </>
                                    )}
                                </div>
                                <div className="list_details">
                                    <h5 className="text-left">1. Cancellation & Data Changes </h5>

                                    <div className="policy_text">
                                        <p className="text-left">Package Cancellation Policy</p>
                                        <div className="cancel-text">Cancellation not possible after booking</div>
                                    </div>
                                    <div className="policy_text">
                                        <p>Package Date Change Policy</p>
                                        <div className="cancel-text">Date Change not possible after booking</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <aside>
                            <div className="common_background">
                                <h5 className="text-left">Price Breakup</h5>

                                <div>
                                    <div className="flex justify_content_between ">
                                        <p>Total Basic Cost <small>10,000 x 2 Traveler</small></p>
                                        <span className="room_text-right">₹ 20,000.00</span>
                                    </div>
                                    <div className="flex justify_content_between mt-10">
                                        <p>Taxes & Service Fees</p>
                                        <span className="room_text-right">1000</span>
                                    </div>
                                    <div className="flex justify_content_between mt-10">
                                        <h6><b>Total Amount:</b></h6>
                                        <h6><b className="room_text-right">₹ 21,000.00</b></h6>
                                    </div>
                                </div>
                                <div className="buttons">
                                    <button className="common_btn m_0_auto" disabled>Proceed to Payment</button>
                                </div>
                            </div>
                            <OurQuery />
                        </aside>
                    </main>
                </div>
            </section>
            <Footer />
        </div>
    )
}
export default CheckOutHoliday;