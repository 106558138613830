import React, { useEffect, useRef, useState } from "react";
// import "";
import SkeletonLoader from "../SkeletonLoader/SkeletonLoader";
import { useLocation, useParams } from "react-router-dom";

function PackagesStateInput({ onDataChange }) {
  const [SearchState, setSearchState] = useState([]);
  const [selectedState, setSelectedState] = useState("");

  const [resultArray, setResultArray] = useState([]);

  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setResultArray([]);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const fetchDestination = async (destination) => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
 myHeaders.append("key", process.env.REACT_APP_API_KEY);
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_APP_URL}/api/v1/destinations/fetch`,
        requestOptions
      );

      const result = await response.json();

      if (result.success) {
        setSearchState(result?.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      SkeletonLoader(false);
    }
  };

  useEffect(() => {
    fetchDestination();
  }, []);

  const handleOnStateChange = (e) => {
    setSelectedState(e.target.value);
    const { value } = e.target;
    const filteredArray = SearchState.filter((item) =>
      item?.name?.toLowerCase()?.includes(value?.toLowerCase())
    );
    setResultArray(filteredArray);
  };
  onDataChange(selectedState, resultArray);

  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    const data = {
      location: params.get("location"),
    };
    setSelectedState(data.location);

    // console.log(data, "datadata");

    // postBookingData(data);
  }, [location.search]);

  return (
    <>
      <div className="search-location-input relative">
        <input
          className="testform_input pac-target-input"
          value={selectedState}
          onChange={handleOnStateChange}
          required
        />

        {resultArray?.length > 0 && (
          <div className="search_list" ref={dropdownRef}>
            {resultArray?.map((state, index) => (
              <div
                key={index}
                onClick={() => [
                  setSelectedState(state?.name),
                  setResultArray([]),
                ]}
              >
                <span>{state?.name}</span>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
}

export default PackagesStateInput;
