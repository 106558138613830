import React, { useEffect, useState } from "react";
import "../AllAmenities/AllAmenities.css";



const mandatoryAmenities = [
    "Air Conditioning",
    "Laundry",
    "Newspaper",
    "Parking",
    "Room service",
    "Smoke detector",
    "Smoking rooms",
    "Swimming Pool",
    "Wifi",
    "Lounge",
    "Reception",
    "Bar",
    "Restaurant",
    "Luggage assistance",
    "Wheelchair",
    "Gym/ Fitness centre",
    "CCTV",
    "Fire extinguishers",
    "Airport Transfers",
    "First-aid services"
];



function AllAmenities({ showTitle, onSubmitForm }) {
    const amenitiesBtn = [
        {
            button: "Mandatory"
        },
        {
            button: "Basic Facilities"
        },
        {
            button: "General Services"
        },
        {
            button: "Outdoor Activities and Sports"
        },
        {
            button: "Common Area"
        },
        {
            button: "Food and Drink"
        },
        {
            button: "Health and Wellness"
        },
        {
            button: "Business Center and Conferences"
        },
        {
            button: "Beauty and Spa"
        },
        {
            button: "Security"
        },
        {
            button: "Transfers"
        },
        {
            button: "Entertainment"
        },
        {
            button: "Shopping"
        },
        {
            button: "Payment Services"
        },
        {
            button: "Indoor Activities and Sports"
        },
        {
            button: "Family and Kids"
        },
        {
            button: "Pet essentials"
        }
    ]
    const [amenities, setAmmenities] = useState(0);

    const handleAmenitiesClick = (tabIndex) => {
        setAmmenities(tabIndex);
    };

    const [amenitiesTab, setAmenitiesTab] = useState("amenities");
    const handleAmenitiesBtn = (data) => {
        setAmenitiesTab(data);
    }

    return (
        <>
            <section>
                <div className='basic-info-container'>
                    {showTitle &&
                        <>
                            <div>
                                <h3 className='text-left'>All Amenities</h3>
                                <p>Answering the amenities available at your property can significantly influence guest to book! Please answer the Mandatory amenities available below</p>
                            </div>
                            <div className='route-filter-btn mt-10'>
                                <button className={`amenity-btn ${amenitiesTab === "amenities" ? 'active' : ''}`} onClick={() => handleAmenitiesBtn("amenities")}>AMENITIES</button>
                                <button className={`amenity-btn ${amenitiesTab === "food_service" ? 'active' : ''}`} onClick={() => handleAmenitiesBtn("food_service")}>FOOD SERVICE</button>
                            </div>
                        </>
                    }
                    <div className="bg-white-div p_0">
                        {amenitiesTab === "amenities" &&
                            <>
                                <div className="amenities">
                                    <div className="amenities-sidebar">
                                        {amenitiesBtn.map((items, index) => {
                                            return (
                                                <>
                                                    <button className={`amenities-btn ${amenities === index ? 'active' : ''}`} onClick={() => handleAmenitiesClick(index)}>{items.button}</button>
                                                </>
                                            )
                                        })}
                                    </div>
                                    {amenities === 0 &&
                                        // <div className="amenities-data">
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5 ">
                                        //         <span> Air Conditioning</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Laundry</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Newspaper</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Parking</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Room service</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Smoke detector</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Smoking rooms</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Swimming Pool</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Wifi</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Lounge</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Reception</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Bar</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Restaurant</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Luggage assistance</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Wheelchair</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Gym/ Fitness centre</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>CCTV</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Fire extinguishers</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Airport Transfers</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>First-aid services</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        // </div>

                                        <div className="amenities-data">
                                            {['Air Conditioning', 'Laundry', 'Newspaper', 'Parking', 'Room service', 'Smoke detector', 'Smoking rooms', 'Swimming Pool', 'Wifi', 'Lounge', 'Reception', 'Bar', 'Restaurant', 'Luggage assistance', 'Wheelchair', 'Gym/ Fitness centre', 'CCTV', 'Fire extinguishers', 'Airport Transfers', 'First-aid services'].map((amenity, index) => (
                                                <div key={index} className="flex justify_content_between align_items_center border-bottom py-5">
                                                    <span>{amenity}</span>
                                                    <div className="flex flex_gap_5 mt-10">
                                                        <div className='flex border-radius border radio-btn'>
                                                            <input type="radio" name={`amenity_${index}`} value="true" />
                                                            <label htmlFor={`amenity_${index}_yes`} className='m_0'>Yes</label>
                                                        </div>
                                                        <div className='flex border-radius border radio-btn'>
                                                            <input type="radio" name={`amenity_${index}`} value="false" />
                                                            <label htmlFor={`amenity_${index}_no`} className='m_0'>No</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    }
                                    {amenities === 1 &&
                                        // <div className="amenities-data">
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5 ">
                                        //         <span>Elevator/ Lift</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Housekeeping</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Kitchen/Kitchenette</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>LAN</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Power backup</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Refrigerator</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Umbrellas</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Washing Machine</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Wifi</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Laundromat</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>EV Charging Station</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        // </div>
                                        <div className="amenities-data">
                                            {['Elevator/ Lift', 'Housekeeping', 'Kitchen/Kitchenette', 'LAN', 'Power backup', 'Refrigerator', 'Umbrellas', 'Washing Machine', 'Wifi', 'Laundromat', 'EV Charging Station'].map((amenity, index) => (
                                                <div key={index} className="flex justify_content_between align_items_center border-bottom py-5">
                                                    <span>{amenity}</span>
                                                    <div className="flex flex_gap_5 mt-10">
                                                        <div className='flex border-radius border radio-btn'>
                                                            <input type="radio" id={`amenity_${index}_yes`} name={`amenity_${index}`} value="true" />
                                                            <label htmlFor={`amenity_${index}_yes`} className='m_0'>Yes</label>
                                                        </div>
                                                        <div className='flex border-radius border radio-btn'>
                                                            <input type="radio" id={`amenity_${index}_no`} name={`amenity_${index}`} value="false" />
                                                            <label htmlFor={`amenity_${index}_no`} className='m_0'>No</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    }
                                    {amenities === 2 &&
                                        // <div className="amenities-data">
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5 ">
                                        //         <span>Bellboy service</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Caretaker</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Concierge</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Multilingual Staff</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Luggage storage</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Specially abled assistance</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Wake-up Call / Service</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Butler Services</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Doctor on call</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Medical centre</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Pool/ Beach towels</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        // </div>
                                        <div className="amenities-data">
                                            {['Bellboy service', 'Caretaker', 'Concierge', 'Multilingual Staff', 'Luggage storage', 'Specially abled assistance', 'Wake-up Call / Service', 'Butler Services', 'Doctor on call', 'Medical centre', 'Pool/ Beach towels'].map((amenity, index) => (
                                                <div key={index} className="flex justify_content_between align_items_center border-bottom py-5">
                                                    <span>{amenity}</span>
                                                    <div className="flex flex_gap_5 mt-10">
                                                        <div className='flex border-radius border radio-btn'>
                                                            <input type="radio" id={`amenity_${index}_yes`} name={`amenity_${index}`} value="true" />
                                                            <label htmlFor={`amenity_${index}_yes`} className='m_0'>Yes</label>
                                                        </div>
                                                        <div className='flex border-radius border radio-btn'>
                                                            <input type="radio" id={`amenity_${index}_no`} name={`amenity_${index}`} value="false" />
                                                            <label htmlFor={`amenity_${index}_no`} className='m_0'>No</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    }
                                    {amenities === 3 &&
                                        // <div className="amenities-data">
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5 ">
                                        //         <span>Beach</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Bonfire</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Golf</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Kayaks</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Outdoor sports</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Snorkelling</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Telescope</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Water sports</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Canoeing</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Skiing</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Jungle Safari</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Cycling</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        // </div>
                                        <div className="amenities-data">
                                            {['Beach', 'Bonfire', 'Golf', 'Kayaks', 'Outdoor sports', 'Snorkelling', 'Telescope', 'Water sports', 'Canoeing', 'Skiing', 'Jungle Safari', 'Cycling'].map((amenity, index) => (
                                                <div key={index} className="flex justify_content_between align_items_center border-bottom py-5">
                                                    <span>{amenity}</span>
                                                    <div className="flex flex_gap_5 mt-10">
                                                        <div className='flex border-radius border radio-btn'>
                                                            <input type="radio" id={`amenity_${index}_yes`} name={`amenity_${index}`} value="true" />
                                                            <label htmlFor={`amenity_${index}_yes`} className='m_0'>Yes</label>
                                                        </div>
                                                        <div className='flex border-radius border radio-btn'>
                                                            <input type="radio" id={`amenity_${index}_no`} name={`amenity_${index}`} value="false" />
                                                            <label htmlFor={`amenity_${index}_no`} className='m_0'>No</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    }
                                    {amenities === 4 &&
                                        // <div className="amenities-data">
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5 ">
                                        //         <span>Balcony/ Terrace</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Fireplace</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Lawn</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Multilingual Staff</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Library</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Seating Area</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Sun Deck</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Verandah</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Jacuzzi</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Prayer Room</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Living Room</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Outdoor Furniture</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Picnic area</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Game Room</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Sitout Area</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Bonfire Pit</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        // </div>
                                        <div className="amenities-data">
                                            {['Balcony/ Terrace', 'Fireplace', 'Lawn', 'Multilingual Staff', 'Library', 'Seating Area', 'Sun Deck', 'Verandah', 'Jacuzzi', 'Prayer Room', 'Living Room', 'Outdoor Furniture', 'Picnic area', 'Game Room', 'Sitout Area', 'Bonfire Pit'].map((amenity, index) => (
                                                <div key={index} className="flex justify_content_between align_items_center border-bottom py-5">
                                                    <span>{amenity}</span>
                                                    <div className="flex flex_gap_5 mt-10">
                                                        <div className='flex border-radius border radio-btn'>
                                                            <input type="radio" id={`amenity_${index}_yes`} name={`amenity_${index}`} value="true" />
                                                            <label htmlFor={`amenity_${index}_yes`} className='m_0'>Yes</label>
                                                        </div>
                                                        <div className='flex border-radius border radio-btn'>
                                                            <input type="radio" id={`amenity_${index}_no`} name={`amenity_${index}`} value="false" />
                                                            <label htmlFor={`amenity_${index}_no`} className='m_0'>No</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    }
                                    {amenities === 5 &&
                                        // <div className="amenities-data">
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5 ">
                                        //         <span>Barbeque</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Cafe</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Coffee shop</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Dining Area</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Kid's Menu</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Bakery</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Breakfast</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        // </div>
                                        <div className="amenities-data">
                                            {['Barbeque', 'Cafe', 'Coffee shop', 'Dining Area', 'Kid\'s Menu', 'Bakery', 'Breakfast'].map((amenity, index) => (
                                                <div key={index} className="flex justify_content_between align_items_center border-bottom py-5">
                                                    <span>{amenity}</span>
                                                    <div className="flex flex_gap_5 mt-10">
                                                        <div className='flex border-radius border radio-btn'>
                                                            <input type="radio" id={`amenity_${index}_yes`} name={`amenity_${index}`} value="true" />
                                                            <label htmlFor={`amenity_${index}_yes`} className='m_0'>Yes</label>
                                                        </div>
                                                        <div className='flex border-radius border radio-btn'>
                                                            <input type="radio" id={`amenity_${index}_no`} name={`amenity_${index}`} value="false" />
                                                            <label htmlFor={`amenity_${index}_no`} className='m_0'>No</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    }
                                    {amenities === 6 &&
                                        // <div className="amenities-data">
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5 ">
                                        //         <span>Activity Centre</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Reflexology</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Yoga</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Meditation Room</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        // </div>
                                        <div className="amenities-data">
                                            {['Activity Centre', 'Reflexology', 'Yoga', 'Meditation Room'].map((amenity, index) => (
                                                <div key={index} className="flex justify_content_between align_items_center border-bottom py-5">
                                                    <span>{amenity}</span>
                                                    <div className="flex flex_gap_5 mt-10">
                                                        <div className='flex border-radius border radio-btn'>
                                                            <input type="radio" id={`amenity_${index}_yes`} name={`amenity_${index}`} value="true" />
                                                            <label htmlFor={`amenity_${index}_yes`} className='m_0'>Yes</label>
                                                        </div>
                                                        <div className='flex border-radius border radio-btn'>
                                                            <input type="radio" id={`amenity_${index}_no`} name={`amenity_${index}`} value="false" />
                                                            <label htmlFor={`amenity_${index}_no`} className='m_0'>No</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    }
                                    {amenities === 7 &&
                                        // <div className="amenities-data">
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5 ">
                                        //         <span>Banquet</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Business Center</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Conference room</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Photocopying</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Fax service</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Printer</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        // </div>
                                        <div className="amenities-data">
                                            {['Banquet', 'Business Center', 'Conference room', 'Photocopying', 'Fax service', 'Printer'].map((amenity, index) => (
                                                <div key={index} className="flex justify_content_between align_items_center border-bottom py-5">
                                                    <span>{amenity}</span>
                                                    <div className="flex flex_gap_5 mt-10">
                                                        <div className='flex border-radius border radio-btn'>
                                                            <input type="radio" id={`amenity_${index}_yes`} name={`amenity_${index}`} value="true" />
                                                            <label htmlFor={`amenity_${index}_yes`} className='m_0'>Yes</label>
                                                        </div>
                                                        <div className='flex border-radius border radio-btn'>
                                                            <input type="radio" id={`amenity_${index}_no`} name={`amenity_${index}`} value="false" />
                                                            <label htmlFor={`amenity_${index}_no`} className='m_0'>No</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    }
                                    {amenities === 8 &&
                                        // <div className="amenities-data">
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5 ">
                                        //         <span>Massage</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Salon</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Spa</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Steam and Sauna</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Open air bath</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Hammam</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        // </div>
                                        <div className="amenities-data">
                                            {['Massage', 'Salon', 'Spa', 'Steam and Sauna', 'Open air bath', 'Hammam'].map((amenity, index) => (
                                                <div key={index} className="flex justify_content_between align_items_center border-bottom py-5">
                                                    <span>{amenity}</span>
                                                    <div className="flex flex_gap_5 mt-10">
                                                        <div className='flex border-radius border radio-btn'>
                                                            <input type="radio" id={`amenity_${index}_yes`} name={`amenity_${index}`} value="true" />
                                                            <label htmlFor={`amenity_${index}_yes`} className='m_0'>Yes</label>
                                                        </div>
                                                        <div className='flex border-radius border radio-btn'>
                                                            <input type="radio" id={`amenity_${index}_no`} name={`amenity_${index}`} value="false" />
                                                            <label htmlFor={`amenity_${index}_no`} className='m_0'>No</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    }
                                    {amenities === 9 &&
                                        // <div className="amenities-data">
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5 ">
                                        //         <span>Security alarms</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Security Guard</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Carbon Monoxide Detector</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        // </div>
                                        <div className="amenities-data">
                                            {['Security alarms', 'Security Guard', 'Carbon Monoxide Detector'].map((amenity, index) => (
                                                <div key={index} className="flex justify_content_between align_items_center border-bottom py-5">
                                                    <span>{amenity}</span>
                                                    <div className="flex flex_gap_5 mt-10">
                                                        <div className='flex border-radius border radio-btn'>
                                                            <input type="radio" id={`amenity_${index}_yes`} name={`amenity_${index}`} value="true" />
                                                            <label htmlFor={`amenity_${index}_yes`} className='m_0'>Yes</label>
                                                        </div>
                                                        <div className='flex border-radius border radio-btn'>
                                                            <input type="radio" id={`amenity_${index}_no`} name={`amenity_${index}`} value="false" />
                                                            <label htmlFor={`amenity_${index}_no`} className='m_0'>No</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>

                                    }
                                    {amenities === 10 &&
                                        <div className="amenities-data">
                                            <div className="flex justify_content_between align_items_center border-bottom py-5 ">
                                                <span>Shuttle Service</span>
                                                <div className="flex flex_gap_5 mt-10">
                                                    <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                                    <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {amenities === 11 &&
                                        // <div className="amenities-data">
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5 ">
                                        //         <span>Events</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Pub</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Professional Photography</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Night Club</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Beach club</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Movie Room</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Music System</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Water Park</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        // </div>
                                        <div className="amenities-data">
                                            {['Events', 'Pub', 'Professional Photography', 'Night Club', 'Beach club', 'Movie Room', 'Music System', 'Water Park'].map((amenity, index) => (
                                                <div key={index} className="flex justify_content_between align_items_center border-bottom py-5">
                                                    <span>{amenity}</span>
                                                    <div className="flex flex_gap_5 mt-10">
                                                        <div className='flex border-radius border radio-btn'>
                                                            <input type="radio" id={`amenity_${index}_yes`} name={`amenity_${index}`} value="true" />
                                                            <label htmlFor={`amenity_${index}_yes`} className='m_0'>Yes</label>
                                                        </div>
                                                        <div className='flex border-radius border radio-btn'>
                                                            <input type="radio" id={`amenity_${index}_no`} name={`amenity_${index}`} value="false" />
                                                            <label htmlFor={`amenity_${index}_no`} className='m_0'>No</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    }
                                    {amenities === 12 &&
                                        // <div className="amenities-data">
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5 ">
                                        //         <span>Book shop</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Souvenir shop</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Jewellery Shop</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        // </div>
                                        <div className="amenities-data">
                                            {['Book shop', 'Souvenir shop', 'Jewellery Shop'].map((amenity, index) => (
                                                <div key={index} className="flex justify_content_between align_items_center border-bottom py-5">
                                                    <span>{amenity}</span>
                                                    <div className="flex flex_gap_5 mt-10">
                                                        <div className='flex border-radius border radio-btn'>
                                                            <input type="radio" id={`amenity_${index}_yes`} name={`amenity_${index}`} value="true" />
                                                            <label htmlFor={`amenity_${index}_yes`} className='m_0'>Yes</label>
                                                        </div>
                                                        <div className='flex border-radius border radio-btn'>
                                                            <input type="radio" id={`amenity_${index}_no`} name={`amenity_${index}`} value="false" />
                                                            <label htmlFor={`amenity_${index}_no`} className='m_0'>No</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    }
                                    {amenities === 13 &&
                                        // <div className="amenities-data">
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5 ">
                                        //         <span>ATM</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Currency Exchange</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        // </div>
                                        <div className="amenities-data">
                                            {['ATM', 'Currency Exchange'].map((amenity, index) => (
                                                <div key={index} className="flex justify_content_between align_items_center border-bottom py-5">
                                                    <span>{amenity}</span>
                                                    <div className="flex flex_gap_5 mt-10">
                                                        <div className='flex border-radius border radio-btn'>
                                                            <input type="radio" id={`amenity_${index}_yes`} name={`amenity_${index}`} value="true" />
                                                            <label htmlFor={`amenity_${index}_yes`} className='m_0'>Yes</label>
                                                        </div>
                                                        <div className='flex border-radius border radio-btn'>
                                                            <input type="radio" id={`amenity_${index}_no`} name={`amenity_${index}`} value="false" />
                                                            <label htmlFor={`amenity_${index}_no`} className='m_0'>No</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    }
                                    {amenities === 14 &&
                                        // <div className="amenities-data">
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5 ">
                                        //         <span>Casino</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Indoor games</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        // </div>
                                        <div className="amenities-data">
                                            {['Casino', 'Indoor games'].map((amenity, index) => (
                                                <div key={index} className="flex justify_content_between align_items_center border-bottom py-5">
                                                    <span>{amenity}</span>
                                                    <div className="flex flex_gap_5 mt-10">
                                                        <div className='flex border-radius border radio-btn'>
                                                            <input type="radio" id={`amenity_${index}_yes`} name={`amenity_${index}`} value="true" />
                                                            <label htmlFor={`amenity_${index}_yes`} className='m_0'>Yes</label>
                                                        </div>
                                                        <div className='flex border-radius border radio-btn'>
                                                            <input type="radio" id={`amenity_${index}_no`} name={`amenity_${index}`} value="false" />
                                                            <label htmlFor={`amenity_${index}_no`} className='m_0'>No</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    }
                                    {amenities === 15 &&
                                        // <div className="amenities-data">
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5 ">
                                        //         <span>Childcare service</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Children's play area</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5 ">
                                        //         <span>Kids' Club</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Strollers</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        // </div>
                                        <div className="amenities-data">
                                            {["Childcare service", "Children's play area", "Kid's Club", "Strollers"].map((amenity, index) => (
                                                <div key={index} className="flex justify_content_between align_items_center border-bottom py-5">
                                                    <span>{amenity}</span>
                                                    <div className="flex flex_gap_5 mt-10">
                                                        <div className='flex border-radius border radio-btn'>
                                                            <input type="radio" id={`amenity_${index}_yes`} name={`amenity_${index}`} value="true" />
                                                            <label htmlFor={`amenity_${index}_yes`} className='m_0'>Yes</label>
                                                        </div>
                                                        <div className='flex border-radius border radio-btn'>
                                                            <input type="radio" id={`amenity_${index}_no`} name={`amenity_${index}`} value="false" />
                                                            <label htmlFor={`amenity_${index}_no`} className='m_0'>No</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>

                                    }
                                    {amenities === 16 &&
                                        // <div className="amenities-data">
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5 ">
                                        //         <span>Pet bowls</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        //     <div className="flex justify_content_between align_items_center border-bottom py-5">
                                        //         <span>Pet baskets</span>
                                        //         <div className="flex flex_gap_5 mt-10">
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                        //             <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        //         </div>
                                        //     </div>
                                        // </div>
                                        <div className="amenities-data">
                                            {['Pet bowls','Pet baskets'].map((amenity, index) => (
                                                <div key={index} className="flex justify_content_between align_items_center border-bottom py-5">
                                                    <span>{amenity}</span>
                                                    <div className="flex flex_gap_5 mt-10">
                                                        <div className='flex border-radius border radio-btn'>
                                                            <input type="radio" id={`amenity_${index}_yes`} name={`amenity_${index}`} value="true" />
                                                            <label htmlFor={`amenity_${index}_yes`} className='m_0'>Yes</label>
                                                        </div>
                                                        <div className='flex border-radius border radio-btn'>
                                                            <input type="radio" id={`amenity_${index}_no`} name={`amenity_${index}`} value="false" />
                                                            <label htmlFor={`amenity_${index}_no`} className='m_0'>No</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>

                                    }
                                </div>
                                <div className="flex justify_content_end p_10">
                                    <button className="primary_btn" style={{ width: "120px" }} onClick={onSubmitForm}>Save & Continue</button>
                                </div>
                            </>
                        }

                        {amenitiesTab === "food_service" &&
                            <>
                                <div className="p_20">
                                    <div className="border-bottom py-5">
                                        <p>Add information below to tell your guests more about your property and attract more bookings.</p>
                                    </div>
                                    <div className='route-flex mt-20 border p_20 rounded'>
                                        <h6>Is restaurant available at your property?</h6>
                                        <div className="flex flex_gap_5 mt-10">
                                            <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                            <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        </div>
                                    </div>
                                    <h6 className="mb-20 mt-20">Food Policies</h6>
                                    <div className='route-flex border p_20 rounded'>
                                        <h6>Is Non-Veg Food allowed?</h6>
                                        <div className="flex flex_gap_5 mt-10">
                                            <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                            <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        </div>
                                    </div>
                                    <div className='route-flex mt-20 border p_20 rounded'>
                                        <h6>Is outside food allowed?</h6>
                                        <div className="flex flex_gap_5 mt-10">
                                            <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                            <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        </div>
                                    </div>
                                    <div className='route-flex mt-20 border p_20 rounded'>
                                        <h6>Is Food Delivery Available?</h6>
                                        <div className="flex flex_gap_5 mt-10">
                                            <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                            <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        </div>
                                    </div>
                                    <div className='route-flex mt-20 border p_20 rounded'>
                                        <h6>Is alcohol consumption allowed at the property?</h6>
                                        <div className="flex flex_gap_5 mt-10">
                                            <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' value="true" /> <label htmlFor="" className='m_0'>Yes</label></div>
                                            <div className='flex border-radius border radio-btn'><input type="radio" name='channel_manager' className='radio-btn' value="false" /> <label htmlFor="" className='m_0'>No</label></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex justify_content_end p_10">
                                    <button className="primary_btn" style={{ width: "70px" }}>Save</button>
                                </div>
                            </>
                        }

                    </div>
                </div>
            </section>
        </>
    )
}

export default AllAmenities;
