import React, { useCallback, useEffect, useState } from "react";
// import "../Dashboard/Dashboard.css";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../../CustomComponents/Loader/Loader";
import { formatIndianCurrency } from "../../../utils/utils";
import SliderComponent from "../../../CustomComponents/SliderComponent/SliderComponent";
import NoDataFound from "../../../CustomComponents/NoDataFound/NoDataFound";
import SeeBookingSlider from "../../../CustomComponents/SeeBookingsSlider/SeeBookingsSlider";
import { showErrorToast } from "../../../assets/toastUtils";

const VendorPromotionsTable = ({ onClose }) => {
  const [discounts, setDiscounts] = useState([]);
  const [deletePopup, setDeletePopup] = useState(false);
  const [addPopup, setAddPopup] = useState(false);
  const { id: propertyId } = useParams();
  const [selectedId, setSelectedId] = useState();
  const [roomDetails, setRoomDetails] = useState({});
  const [loader, setLoader] = useState(false);
  const [isSliderVisible, setIsSliderVisible] = useState(false);
  const [promotionData, setPromotionData] = useState({});
  const [formErrors, setFormErrors] = useState({});

  const validate = () => {
    const errors = {};
    if (!addRates?.name.trim()) {
      errors.name = "required";
    }
    if (!addRates?.type.trim()) {
      errors.type = "required";
    }
    if (!addRates?.room.trim()) {
      errors.room = "required";
    }
    setFormErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const handleCloseSlider = () => {
    setIsSliderVisible(false);
  };

  // console.log(selectedId, "id room");

  const [addRates, setAddRates] = useState({
    name: "",
    type: "",
    room: "",
    tax_included: false,
  });

  const [editRates, setEditRates] = useState({
    name: "",
    type: "",
    room: "",
    tax_included: false,
  });

  const handleInputChange = (e, isEdit = false) => {
    const { name, value } = e.target;
    if (isEdit) {
      setEditRates((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    } else {
      setAddRates((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const openAddPopup = (id) => {
    setSelectedId(id);
    setAddPopup(true);
  };
  const closeAddPopup = () => {
    setAddPopup(false);
  };

  const openDeletePopup = (id) => {
    setSelectedId(id);
    setDeletePopup(true);
  };

  const closeDeletePopup = () => {
    setDeletePopup(false);
  };

  const createPlan = (e) => {
    e.preventDefault();
    const valid = validate();
    setTimeout(() => {
      setFormErrors({});
    }, 1000);
    if (!valid) {
      showErrorToast("Please fill the required details");
      return;
    }

    // const myHeaders = new Headers();
    // myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      name: addRates?.name,
      type: addRates?.type,
      room: parseInt(addRates.room),
      tax_included: addRates?.tax_included === "true",
    });
    // console.log("Data being sent:", raw);

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        key: "D4X50uU40Vz4pweFLEmsoiJy107iPfTD4ompprop1",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_APP_URL}/api/v1/vendor/accommodation-meal-plan-create/${addRates.room}/${propertyId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        // if(result.success){
        // showSuccessToast(result.message);
        // closeAddPopup(); // Close the popup after successful submission
        // fetchRatePlan(propertyId); // Refresh the rate plans
        setAddRates({
          name: "",
          type: "",
          room: "",
          tax_included: "",
        });
        // }
      })
      .catch((error) => console.error(error));
  };

  const fetchRatePlan = (id) => {
    setLoader(true);
    const requestOptions = {
      // myHeaders.append("key", "D4X50uU40Vz4pweFLEmsoiJy107iPfTD4ompprop1");
      headers: {
        "Content-Type": "application/json",
        key: "D4X50uU40Vz4pweFLEmsoiJy107iPfTD4ompprop1",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_APP_URL}/api/v1/vendor/promotion/list/${propertyId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log("result", result);

        if (result.success) {
          setDiscounts(result?.discounts);
          setLoader(false);
        } else {
          setLoader(false);
          setDiscounts([]);
        }
      })
      .catch((error) => setLoader(false));
  };
  // console.log(discounts, "discounts");

  useEffect(() => {
    if (propertyId) {
      fetchRatePlan(propertyId);
    }
  }, [propertyId]);

  const deleteRatePlan = () => {
    const myHeaders = new Headers();
    myHeaders.append("key", process.env.REACT_APP_API_KEY);
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_APP_URL}/api/v1/vendor/promotion/remove/${selectedId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          setDeletePopup(false);
          fetchRatePlan(propertyId);
          toast.success(result.message);
        } else {
        }
      })
      .catch((error) => console.error(error));
  };

  // Get Room api
  const getRooms = (id) => {
    const requestOptions = {
      // myHeaders.append("key", "D4X50uU40Vz4pweFLEmsoiJy107iPfTD4ompprop1");
      headers: {
        "Content-Type": "application/json",
        key: "D4X50uU40Vz4pweFLEmsoiJy107iPfTD4ompprop1",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_APP_URL}/api/v1/vendor/get-rooms/${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log("result", result);

        if (result.success) {
          setRoomDetails(result?.rooms);
        } else {
          setRoomDetails([]);
        }
      })
      .catch((error) => console.error(error));
  };

  // useEffect(() => {
  //   if (propertyId) {
  //     getRooms(propertyId);
  //   }
  // }, [propertyId]);

  // console.log(roomDetails, "details");

  // Single Rate Api
  const [singleRate, setSingleRate] = useState({});

  const fetchSingleRate = (roomId) => {
    // const roomId = discounts?.room_id
    const requestOptions = {
      headers: {
        "Content-Type": "application/json",
        key: "D4X50uU40Vz4pweFLEmsoiJy107iPfTD4ompprop1",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_APP_URL}/api/v1/vendor/get-single-rate-plan/${roomId}/${propertyId}`,
      requestOptions
    )
      .then((response) => response.json()) // Parse the response as JSON
      .then((result) => {
        if (result.success) {
          setSingleRate(result.data); // Set the fetched data to state
          setEditRates({
            name: result.data.name,
            type: result.data.type,
            room: result.data.room.id,
            tax_included: result.data.tax_included ? true : false,
          });
          setEditPopup(true);
        }
      })
      .catch((error) => console.error("Error fetching single rate:", error));
  };
  // console.log(singelRate,"enw")

  // Edit Rate
  const [editPopup, setEditPopup] = useState(false);
  const [editItem, setEditItem] = useState(null);

  const handleEditDetail = (id, item) => {
    // console.log(id, item);
    setSelectedId(id);
    setEditItem(item); // Set the item to be edited
    setEditPopup(true); // Open the edit popup
  };

  // console.log(editRates);

  const openEditPopup = (item) => {
    setSelectedId(item.id);
    fetchSingleRate(item.id);
  };
  const closeEditPopup = () => {
    setEditPopup(false);
    setEditItem(null); // Clear the item when closing the popup
  };

  const handleUpdate = async (e, roomId) => {
    e.preventDefault();
    const raw = JSON.stringify({
      name: editRates.name, // **CHANGE: Update rate plan data from editRates state**
      type: editRates.type,
      room: parseInt(editRates.room),
      tax_included: editRates.tax_included === true,
    });

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        key: "D4X50uU40Vz4pweFLEmsoiJy107iPfTD4ompprop1",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_APP_URL}/api/v1/vendor/promotion/list/${propertyId}`,
        requestOptions
      );
      const result = await response.json();

      if (result.success) {
        toast.success("Update successful");
        closeEditPopup();
        fetchRatePlan(propertyId);
      } else {
        toast.error("Failed to update rate plan");
      }
    } catch (error) {
      toast.error("Failed to update rate plan");
    }
  };

  // const beddingFinalValue = useCallback((data, id) => {
  //   console.log(data, id);
  //   // fetchEnableDisable(id, data)

  //   // setBedding(data);
  //   // setAddRoomTypeData((prevState) => {
  //   //   return {
  //   //     ...prevState,
  //   //     extra_bedding: data,
  //   //   };
  //   // });
  // }, []);

  const beddingFinalValue = (data, id) => {
    // fetchEnableDisable(id, data)
  };
  const [enableDisableValue, setEnableDisableValue] = useState("");

  const fetchEnableDisable = (discountId, status) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      status: status,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_APP_URL}/api/v1/vendor/promotion/update-status/${discountId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        fetchRatePlan(propertyId);
      })
      .catch((error) => console.error(error));
  };

  const getPromotionById = (id) => {
    const promotionDetails = discounts?.find((data) => data.id == id);
    setPromotionData(promotionDetails);
    setIsSliderVisible(true);
  };

  // console.log(promotionData);
  const handleClose = () => {
    setIsSliderVisible(false);
  };

  const stayDays = ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"];

  return (
    <section className="w-full table-data">
      <div className="flex justify_content_between align_items_center">
        <h4>Promotion List</h4>
        <div className="flex flex_gap_10">
          <Link
            to={`/vendor/promotion/add/${propertyId}`}
            className="primary_btn fit-width"
          >
            Add
          </Link>
        </div>
      </div>
      {loader ? (
        <Loader size="4em" color="#016937" />
      ) : (
        <div className="overflow_x_scroll_y_hidden">
          {discounts?.length > 0 ? (
            <>
              <table className="inventory_table">
                <tr style={{ borderBottom: "1px solid #cccccc96" }}>
                  <th> NAME</th>
                  <th> TYPE</th>
                  <th>Method</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Status</th>
                  <th></th>
                  <th></th>
                </tr>
                {discounts?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td style={{ textTransform: "capitalize" }}>
                        {" "}
                        {item?.name.replace("-", " ")}
                      </td>
                      <td style={{ textTransform: "capitalize" }}>
                        {item?.type === "fixed" ? (
                          <>Fixed ({formatIndianCurrency(item?.amount)})</>
                        ) : (
                          <>Percentage ({item?.amount}%)</>
                        )}
                      </td>
                      <td style={{ textTransform: "capitalize" }}>
                        {" "}
                        {item?.method === "code" ? (
                          <>Code ({item?.discount_code})</>
                        ) : (
                          <>Automatic</>
                        )}
                      </td>
                      <td style={{ textTransform: "capitalize" }}>
                        {" "}
                        {item?.start_date}
                      </td>
                      <td style={{ textTransform: "capitalize" }}>
                        {" "}
                        {item?.end_date}
                      </td>

                      <td>
                        <SliderComponent
                          positive={"enabled"}
                          negative={"disabled"}
                          func={beddingFinalValue}
                          title={"Bedding"}
                          byDefaultEnable={
                            item?.status === "enable" ? true : false
                          }
                          id={item?.id}
                          functionToRun={fetchEnableDisable}
                        />

                        {/* <span
                        onClick={() => {
                          fetchSingleRate(item?.id);
                          handleEditDetail(item?.id, item);
                        }}
                      >
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <mask
                            id="mask0_2797_7470"
                            style={{ maskType: "alpha" }}
                            maskUnits="userSpaceOnUse"
                            x="0"
                            y="0"
                            width="20"
                            height="20"
                          >
                            <rect width="20" height="20" fill="#D9D9D9" />
                          </mask>
                          <g mask="url(#mask0_2797_7470)">
                            <path
                              d="M4.16667 15.8333H5.35417L13.5 7.6875L12.3125 6.5L4.16667 14.6458V15.8333ZM2.5 17.5V13.9583L13.5 2.97917C13.6667 2.82639 13.8507 2.70833 14.0521 2.625C14.2535 2.54167 14.4653 2.5 14.6875 2.5C14.9097 2.5 15.125 2.54167 15.3333 2.625C15.5417 2.70833 15.7222 2.83333 15.875 3L17.0208 4.16667C17.1875 4.31944 17.309 4.5 17.3854 4.70833C17.4618 4.91667 17.5 5.125 17.5 5.33333C17.5 5.55556 17.4618 5.76736 17.3854 5.96875C17.309 6.17014 17.1875 6.35417 17.0208 6.52083L6.04167 17.5H2.5ZM12.8958 7.10417L12.3125 6.5L13.5 7.6875L12.8958 7.10417Z"
                              fill="#333333"
                            />
                          </g>
                        </svg>
                      </span>  */}
                      </td>
                      <td>
                        {" "}
                        <svg
                          onClick={() => getPromotionById(item?.id)}
                          className="cursor_pointer"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 14 10"
                          fill="none"
                        >
                          <g clipPath="url(#clip0_1_298)">
                            <path
                              d="M7.00001 3.25C6.53588 3.25 6.09076 3.43437 5.76257 3.76256C5.43438 4.09075 5.25001 4.53587 5.25001 5C5.25001 5.46413 5.43438 5.90925 5.76257 6.23744C6.09076 6.56563 6.53588 6.75 7.00001 6.75C7.46414 6.75 7.90926 6.56563 8.23745 6.23744C8.56564 5.90925 8.75001 5.46413 8.75001 5C8.75001 4.53587 8.56564 4.09075 8.23745 3.76256C7.90926 3.43437 7.46414 3.25 7.00001 3.25ZM7.00001 7.91667C6.22646 7.91667 5.4846 7.60938 4.93762 7.06239C4.39063 6.51541 4.08334 5.77355 4.08334 5C4.08334 4.22645 4.39063 3.48459 4.93762 2.93761C5.4846 2.39062 6.22646 2.08333 7.00001 2.08333C7.77356 2.08333 8.51542 2.39062 9.0624 2.93761C9.60939 3.48459 9.91668 4.22645 9.91668 5C9.91668 5.77355 9.60939 6.51541 9.0624 7.06239C8.51542 7.60938 7.77356 7.91667 7.00001 7.91667ZM7.00001 0.625C4.08334 0.625 1.59251 2.43917 0.583344 5C1.59251 7.56083 4.08334 9.375 7.00001 9.375C9.91668 9.375 12.4075 7.56083 13.4167 5C12.4075 2.43917 9.91668 0.625 7.00001 0.625Z"
                              fill="#888888"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_1_298">
                              <rect width="14" height="10" fill="white"></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </td>
                      <td>
                        <span onClick={() => openDeletePopup(item?.id)}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 32 32"
                            width="20px"
                            height="20px"
                          >
                            <path d="M 15 4 C 14.476563 4 13.941406 4.183594 13.5625 4.5625 C 13.183594 4.941406 13 5.476563 13 6 L 13 7 L 7 7 L 7 9 L 8 9 L 8 25 C 8 26.644531 9.355469 28 11 28 L 23 28 C 24.644531 28 26 26.644531 26 25 L 26 9 L 27 9 L 27 7 L 21 7 L 21 6 C 21 5.476563 20.816406 4.941406 20.4375 4.5625 C 20.058594 4.183594 19.523438 4 19 4 Z M 15 6 L 19 6 L 19 7 L 15 7 Z M 10 9 L 24 9 L 24 25 C 24 25.554688 23.554688 26 23 26 L 11 26 C 10.445313 26 10 25.554688 10 25 Z M 12 12 L 12 23 L 14 23 L 14 12 Z M 16 12 L 16 23 L 18 23 L 18 12 Z M 20 12 L 20 23 L 22 23 L 22 12 Z" />
                          </svg>
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </table>
              {isSliderVisible && (
                <>
                  <div className="bg-backdrop">
                    <div id="booking_details_slider_container_new">
                      <div className="popup" id="popup">
                        <div
                          className="d_flex booking_detail_popup_head fixed_header"
                          style={{ flexDirection: "column" }}
                        >
                          <div className="flex justify_content_between">
                            <div onClick={handleClose} className="close_popup">
                              <svg
                                width="14"
                                height="13"
                                viewBox="0 0 14 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M1 12.5L13 0.5"
                                  stroke="#AAAAAA"
                                  strokeWidth="1.2"
                                />
                                <path
                                  d="M13 12.5L1 0.5"
                                  stroke="#AAAAAA"
                                  strokeWidth="1.2"
                                />
                              </svg>
                            </div>
                            <div className="d_flex booking_id">
                              <b>Promotion Detail</b>
                            </div>
                          </div>
                        </div>
                        <div className="flex justify_content_between p_20 p_top_0 flex_wrap">
                          <div>
                            <div className="mt-10">
                              <h6>Promotion Name</h6>
                              <span>
                                {promotionData?.all_conditions?.promotion_name}
                              </span>
                            </div>{" "}
                            <div className="mt-10">
                              <h6>Name</h6>
                              <span>{promotionData?.name}</span>
                            </div>
                            <div className="mt-10">
                              <h6>Rate Plan</h6>
                              <span>
                                {promotionData?.all_conditions?.rate_plans}
                              </span>
                            </div>
                            <div className="mt-10">
                              <h6>Rooms</h6>
                              <span>
                                {promotionData?.all_conditions?.rooms}
                              </span>
                            </div>
                            <div className="mt-10">
                              <h6>Start Date</h6>
                              <span>{promotionData?.start_date}</span>
                            </div>
                          </div>
                          <div>
                            <div className="mt-10">
                              <h6>Min LOS </h6>
                              <span>
                                {promotionData?.all_conditions?.minLos}
                              </span>
                            </div>
                            <div className="mt-10">
                              <h6>Amount</h6>
                              <span>
                                {promotionData?.all_conditions?.type ===
                                "fixed" ? (
                                  <>
                                    (
                                    {formatIndianCurrency(
                                      promotionData?.amount
                                    )}
                                    )
                                  </>
                                ) : (
                                  <>%</>
                                )}
                              </span>
                            </div>
                            <div className="mt-10">
                              <h6>Stay Dates</h6>
                              <span>
                                {/* {stayDays[promotionData?.all_conditions?.stay_dates]} */}
                                {promotionData?.all_conditions?.stay_dates.map(
                                  (date, index) => (
                                    <React.Fragment key={index}>
                                      {stayDays[date]}{" "}
                                    </React.Fragment>
                                  )
                                )}
                              </span>
                            </div>
                            <div className="mt-10">
                              <h6>End Date</h6>
                              <span>{promotionData?.end_date}</span>
                            </div>
                            <div className="mt-10">
                              <h6>Method</h6>
                              <span>{promotionData?.method}</span>
                            </div>
                          </div>

                          <div>
                            <div className="mt-10">
                              <h6>Booking Period Value</h6>
                              <span>
                                {promotionData?.all_conditions
                                  ?.booking_period_value ? (
                                  <>
                                    {" "}
                                    {
                                      promotionData?.all_conditions
                                        ?.booking_period_value
                                    }{" "}
                                    (
                                    {
                                      promotionData?.all_conditions
                                        ?.booking_period
                                    }
                                    )
                                  </>
                                ) : (
                                  <>nill</>
                                )}
                              </span>
                            </div>
                            <div className="mt-10">
                              <h6>Selecte Rate Plan </h6>
                              <span>
                                {promotionData?.all_conditions
                                  ?.selected_rate_plans.length > 0 ? (
                                  <>
                                    <span>
                                      {
                                        promotionData?.all_conditions
                                          ?.selected_rate_plans
                                      }
                                    </span>
                                  </>
                                ) : (
                                  <>nill</>
                                )}
                              </span>
                            </div>
                            <div className="mt-10">
                              <h6>Selecte Rooms </h6>
                              <span>
                                {promotionData?.all_conditions?.selected_rooms
                                  .length > 0 ? (
                                  <>
                                    <span>
                                      {
                                        promotionData?.all_conditions
                                          ?.selected_rooms
                                      }
                                    </span>
                                  </>
                                ) : (
                                  <>nill</>
                                )}
                              </span>
                            </div>{" "}
                            <div className="mt-10">
                              <h6>Status</h6>
                              <span>{promotionData?.status}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <NoDataFound />
            </>
          )}
        </div>
      )}
      {addPopup && (
        <div className="popup_backdrop">
          <div className="popup_section p_20">
            <div>
              <div className="flex justify_content_between">
                <h4>Add Room Rates</h4>
                <svg
                  onClick={() => closeAddPopup(false)}
                  width="14"
                  height="13"
                  viewBox="0 0 14 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M1 12.5L13 0.5" stroke="#AAAAAA" strokeWidth="1.2" />
                  <path d="M13 12.5L1 0.5" stroke="#AAAAAA" strokeWidth="1.2" />
                </svg>
              </div>

              <form onSubmit={createPlan} action="">
                <div>
                  <div className="mt-10">
                    <label htmlFor="">Room Type</label>
                    <input
                      required
                      className="w-100"
                      name="name"
                      value={addRates?.name}
                      onChange={handleInputChange}
                      type="text"
                      style={
                        formErrors.name && {
                          backgroundColor: "#ff000040",
                          border: "1px solid red",
                        }
                      }
                    />
                  </div>
                  <div className="mt-5">
                    <label htmlFor="">Plan</label>
                    <select
                      className="py-5 w-100 border rounded mt-5"
                      name="type"
                      value={addRates?.type}
                      onChange={handleInputChange}
                      style={
                        formErrors.type && {
                          backgroundColor: "#ff000040",
                          border: "1px solid red",
                        }
                      }
                    >
                      <option value="">Select Option</option>
                      <option value="MAP">MAP</option>
                      <option value="CP">CP</option>
                      <option value="AP">AP</option>
                      <option value="EP">EP</option>
                    </select>
                  </div>
                  <div className="mt-5">
                    <label htmlFor="">Room Category</label>
                    <select
                      className="py-5 w-100 border rounded mt-5"
                      name="room"
                      value={addRates?.room}
                      onChange={handleInputChange}
                      style={
                        formErrors.room && {
                          backgroundColor: "#ff000040",
                          border: "1px solid red",
                        }
                      }
                    >
                      <option value="">Select Option</option>
                      {roomDetails.map((room, index) => (
                        <option key={index} value={room.id}>
                          {room.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="mt-5">
                    <label htmlFor="">Tax Included</label>

                    <div className="flex border-radius border radio-btn mt-5">
                      <input
                        type="radio"
                        id="tax_included_yes"
                        name="tax_included"
                        value="true"
                        checked={addRates?.tax_included === "true"}
                        onChange={handleInputChange}
                      />
                      <label htmlFor="tax_included_yes" className="m_0">
                        Yes
                      </label>
                    </div>
                    <div className="flex border-radius border radio-btn mt-5">
                      <input
                        type="radio"
                        id="tax_included_no"
                        name="tax_included"
                        value="false"
                        checked={addRates?.tax_included === "false"}
                        onChange={handleInputChange}
                      />
                      <label htmlFor="tax_included_no" className="m_0">
                        No
                      </label>
                    </div>
                    {/* <input name="tax_included" value={addRates?.tax_included} onChange={handleInputChange} type="text" /> */}
                  </div>

                  <button className="primary_btn mt-10" type="submit">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      {editPopup && (
        <div className="popup_backdrop">
          <div className="popup_section p_20">
            <div className={`modal ${editPopup ? "show" : ""}`}>
              <div className="flex justify_content_between">
                <h4>Edit Room Rates</h4>
                <svg
                  onClick={closeEditPopup}
                  width="14"
                  height="13"
                  viewBox="0 0 14 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M1 12.5L13 0.5" stroke="#AAAAAA" strokeWidth="1.2" />
                  <path d="M13 12.5L1 0.5" stroke="#AAAAAA" strokeWidth="1.2" />
                </svg>
              </div>

              <form onSubmit={handleUpdate}>
                <div>
                  <div>
                    <label htmlFor="">Rate Name</label>
                    <input
                      type="text"
                      name="name"
                      value={editRates.name}
                      onChange={(e) => handleInputChange(e, true)}
                      placeholder="Name"
                    />
                  </div>
                  <div>
                    <label htmlFor=""></label>
                    <select
                      type="text"
                      name="type"
                      value={editRates.type}
                      onChange={(e) => handleInputChange(e, true)}
                      placeholder="Type"
                    >
                      <option value="AP">AP</option>
                      <option value="MAP">MAP</option>
                      <option value="CP">CP</option>
                      <option value="EP">EP</option>
                    </select>
                  </div>
                  <div>
                    <label htmlFor=""></label>
                    <select
                      name="room"
                      value={editRates.room}
                      onChange={(e) => handleInputChange(e, true)}
                    >
                      {/* **Populate room dropdown with roomDetails** */}
                      {roomDetails.map((room, index) => (
                        <option key={index} value={room.id}>
                          {room.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div>
                    <label htmlFor="">Tax Included</label>

                    <div className="flex border-radius border radio-btn">
                      <input
                        type="radio"
                        id="tax_included_yes"
                        name="tax_included"
                        value={editRates.tax_included}
                        onChange={(e) =>
                          setEditRates({
                            ...editRates,
                            tax_included: true,
                          })
                        }
                        checked={editRates?.tax_included === true}
                      />
                      <label htmlFor="tax_included_yes" className="m_0">
                        Yes
                      </label>
                    </div>
                    <div className="flex border-radius border radio-btn">
                      <input
                        type="radio"
                        id="tax_included_no"
                        name="tax_included"
                        value={editRates.tax_included}
                        onChange={(e) =>
                          setEditRates({
                            ...editRates,
                            tax_included: false,
                          })
                        }
                        checked={editRates?.tax_included === false}
                      />
                      <label htmlFor="tax_included_no" className="m_0">
                        No
                      </label>
                    </div>
                    {/* <input name="tax_included" value={addRates?.tax_included} onChange={handleInputChange} type="text" /> */}
                  </div>
                  <button type="submit">Update Plan</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      {deletePopup && (
        <div className="popup_backdrop">
          <div className="popup_section p_20">
            <div>
              <div className="flex justify_content_between">
                <h4>Are you sure to Delete.</h4>
                <svg
                  onClick={() => closeDeletePopup(false)}
                  width="14"
                  height="13"
                  viewBox="0 0 14 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M1 12.5L13 0.5" stroke="#AAAAAA" strokeWidth="1.2" />
                  <path d="M13 12.5L1 0.5" stroke="#AAAAAA" strokeWidth="1.2" />
                </svg>
              </div>

              <button
                className="primary_btn mt-10"
                type="button"
                onClick={() => deleteRatePlan()}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default VendorPromotionsTable;
