import React, { useEffect, useState } from "react";
import VendorHeader from "../../CustomComponents/Property/VendorHeader/VendorHeader";
import SideBar from "../../CustomComponents/Property/SideBar/SideBar";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import { useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Loader from "../../CustomComponents/Loader/Loader";

const Rules = () => {
  const [selectedRule, setSelectedRule] = useState("cancellation-policy");
  const [editPolicy, setEditPolicy] = useState(false);
  const [loader, setloader] = useState(false);
  const rules = [
    "cancellation-policy",
    "pets-related",
    "restrictions",
    "guest-profile",
    "guest-profile-hourly",
    "id-proof-related",
    "food-arrangement",
    "smoking-alcohol-consumption-rules",
    "property-accessibility",
    "child-and-extra-bed-policy",
    "other-rules",
  ];

  const [ruleDetails, setRuleDetails] = useState({
    name: "",
    content: "",
    rule_type: "cancellation-policy",
  });

  const [allrules, setAllRules] = useState([]);
  // console.log(allrules);

  const handleRuleClick = (ruleName) => {
    const labelMapping = {
      "cancellation-policy": "Cancellation Policy",
      "pets-related": "Pets Related",
      restrictions: "Restrictions",
      "guest-profile": "Guest Profile",
      "guest-profile-hourly": "Guest Profile Hourly",
      "id-proof-related": "ID Proof Related",
      "food-arrangement": "Food Arrangement",
      "smoking-alcohol-consumption-rules": "Smoking Alcohol Consumption Rules",
      "property-accessibility": "Property Accessibility",
      "child-and-extra-bed-policy": "Child And Extra Bed Policy",
      "other-rules": "Other Rules",
    };

    const findedObj = allrules.find((rule) => rule.rule_type === ruleName);

    setRuleDetails((prev) => {
      return {
        ...prev,
        name: labelMapping[ruleName], // Set the label text in ruleDetails.name
        rule_type: ruleName,
        content: findedObj ? findedObj.content : "",
      };
    });

    setSelectedRule(ruleName);
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    setRuleDetails((prev) => {
      return { ...prev, content: value };
    });
  };

  const renderForm = () => {
    switch (ruleDetails?.rule_type) {
      case "cancellation-policy":
        return (
          <form onSubmit={handleFormData}>
            {editPolicy ? (
              <>
                <div className="flex justify_content_between">
                  <label htmlFor="cancellation_description">
                    Cancellation Policy
                  </label>
                  {editPolicy ? (
                    <>
                      <div
                        className="primary_btn fit-width"
                        onClick={() => setEditPolicy(!editPolicy)}
                      >
                        Close
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="primary_btn fit-width"
                        onClick={() => setEditPolicy(!editPolicy)}
                      >
                        Edit
                      </div>
                    </>
                  )}
                </div>
                <ReactQuill
                  className="mt-10"
                  value={ruleDetails.content}
                  onChange={handleEditorChange}
                  modules={modules}
                  formats={formats}
                />
              </>
            ) : (
              <>
                {" "}
                <div className="flex justify_content_between">
                  <label htmlFor="cancellation_description">
                    Cancellation Policy
                  </label>
                  {editPolicy ? (
                    <>
                      <div
                        className="primary_btn fit-width"
                        onClick={() => setEditPolicy(!editPolicy)}
                      >
                        Close
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="primary_btn fit-width"
                        onClick={() => setEditPolicy(!editPolicy)}
                      >
                        Edit
                      </div>
                    </>
                  )}
                </div>
                <div className="border p_10 rounded mt-10">
                  <p
                    dangerouslySetInnerHTML={{ __html: ruleDetails?.content }}
                  ></p>
                </div>
              </>
            )}
            <button className="primary_btn fit-width mt-10" type="submit">
              Update
            </button>
          </form>
        );
      case "restrictions":
        return (
          <form onSubmit={handleFormData}>
            {editPolicy ? (
              <>
                <div className="flex justify_content_between">
                  <label htmlFor="restrictions_description">Restrictions</label>
                  {editPolicy ? (
                    <>
                      <div
                        className="primary_btn fit-width"
                        onClick={() => setEditPolicy(!editPolicy)}
                      >
                        Close
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="primary_btn fit-width"
                        onClick={() => setEditPolicy(!editPolicy)}
                      >
                        Edit
                      </div>
                    </>
                  )}
                </div>
                <ReactQuill
                  className="mt-10"
                  value={ruleDetails.content}
                  onChange={handleEditorChange}
                  modules={modules}
                  formats={formats}
                />
              </>
            ) : (
              <>
                <div className="flex justify_content_between">
                  <label htmlFor="restrictions_description">Restrictions</label>
                  {editPolicy ? (
                    <>
                      <div
                        className="primary_btn fit-width"
                        onClick={() => setEditPolicy(!editPolicy)}
                      >
                        Close
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="primary_btn fit-width"
                        onClick={() => setEditPolicy(!editPolicy)}
                      >
                        Edit
                      </div>
                    </>
                  )}
                </div>
                <div className="border p_10 rounded mt-10">
                  <p
                    dangerouslySetInnerHTML={{ __html: ruleDetails?.content }}
                  ></p>
                </div>
              </>
            )}
            <button className="primary_btn fit-width mt-10" type="submit">
              Update
            </button>
          </form>
        );
      case "property-accessibility":
        return (
          <form onSubmit={handleFormData}>
            {editPolicy ? (
              <>
                {" "}
                <div className="flex justify_content_between">
                  {" "}
                  <label htmlFor="accessibility_description">
                    Property Accessibility
                  </label>
                  {editPolicy ? (
                    <>
                      <div
                        className="primary_btn fit-width"
                        onClick={() => setEditPolicy(!editPolicy)}
                      >
                        Close
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="primary_btn fit-width"
                        onClick={() => setEditPolicy(!editPolicy)}
                      >
                        Edit
                      </div>
                    </>
                  )}
                </div>
                <ReactQuill
                  className="mt-10"
                  value={ruleDetails.content}
                  onChange={handleEditorChange}
                  modules={modules}
                  formats={formats}
                />
              </>
            ) : (
              <>
                <div className="flex justify_content_between">
                  {" "}
                  <label htmlFor="accessibility_description">
                    Property Accessibility
                  </label>
                  {editPolicy ? (
                    <>
                      <div
                        className="primary_btn fit-width"
                        onClick={() => setEditPolicy(!editPolicy)}
                      >
                        Close
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="primary_btn fit-width"
                        onClick={() => setEditPolicy(!editPolicy)}
                      >
                        Edit
                      </div>
                    </>
                  )}
                </div>
                <div className="border p_10 rounded mt-10">
                  <p
                    dangerouslySetInnerHTML={{ __html: ruleDetails?.content }}
                  ></p>
                </div>
              </>
            )}
            <button className="primary_btn fit-width mt-10" type="submit">
              Update
            </button>
          </form>
        );
      case "child-and-extra-bed-policy":
        return (
          <form onSubmit={handleFormData}>
            {editPolicy ? (
              <>
                {" "}
                <div className="flex justify_content_between">
                  {" "}
                  <label htmlFor="accessibility_description">
                    Child And Extra Bed Policy
                  </label>
                  {editPolicy ? (
                    <>
                      <div
                        className="primary_btn fit-width"
                        onClick={() => setEditPolicy(!editPolicy)}
                      >
                        Close
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="primary_btn fit-width"
                        onClick={() => setEditPolicy(!editPolicy)}
                      >
                        Edit
                      </div>
                    </>
                  )}
                </div>
                <ReactQuill
                  className="mt-10"
                  value={ruleDetails.content}
                  onChange={handleEditorChange}
                  modules={modules}
                  formats={formats}
                />
              </>
            ) : (
              <>
                {" "}
                <div className="flex justify_content_between">
                  {" "}
                  <label htmlFor="accessibility_description">
                    Child And Extra Bed Policy
                  </label>
                  {editPolicy ? (
                    <>
                      <div
                        className="primary_btn fit-width"
                        onClick={() => setEditPolicy(!editPolicy)}
                      >
                        Close
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="primary_btn fit-width"
                        onClick={() => setEditPolicy(!editPolicy)}
                      >
                        Edit
                      </div>
                    </>
                  )}
                </div>
                <div className="border p_10 rounded mt-10">
                  <p
                    dangerouslySetInnerHTML={{ __html: ruleDetails?.content }}
                  ></p>
                </div>
              </>
            )}
            <button className="primary_btn fit-width mt-10" type="submit">
              Update
            </button>
          </form>
        );
      case "smoking-alcohol-consumption-rules":
        return (
          <form onSubmit={handleFormData}>
            {editPolicy ? (
              <>
                {" "}
                <div className="flex justify_content_between">
                  {" "}
                  <label htmlFor="accessibility_description">
                    Smoking Alcohol Consumption Rules
                  </label>
                  {editPolicy ? (
                    <>
                      <div
                        className="primary_btn fit-width"
                        onClick={() => setEditPolicy(!editPolicy)}
                      >
                        Close
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="primary_btn fit-width"
                        onClick={() => setEditPolicy(!editPolicy)}
                      >
                        Edit
                      </div>
                    </>
                  )}
                </div>
                <ReactQuill
                  className="mt-10"
                  value={ruleDetails.content}
                  onChange={handleEditorChange}
                  modules={modules}
                  formats={formats}
                />
              </>
            ) : (
              <>
                {" "}
                <div className="flex justify_content_between">
                  {" "}
                  <label htmlFor="accessibility_description">
                    Smoking Alcohol Consumption Rules
                  </label>
                  {editPolicy ? (
                    <>
                      <div
                        className="primary_btn fit-width"
                        onClick={() => setEditPolicy(!editPolicy)}
                      >
                        Close
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="primary_btn fit-width"
                        onClick={() => setEditPolicy(!editPolicy)}
                      >
                        Edit
                      </div>
                    </>
                  )}
                </div>
                <div className="border p_10 rounded mt-10">
                  <p
                    dangerouslySetInnerHTML={{ __html: ruleDetails?.content }}
                  ></p>
                </div>
              </>
            )}
            <button className="primary_btn fit-width mt-10" type="submit">
              Update
            </button>
          </form>
        );
      case "food-arrangement":
        return (
          <form onSubmit={handleFormData}>
            {editPolicy ? (
              <>
                {" "}
                <div className="flex justify_content_between">
                  {" "}
                  <label htmlFor="accessibility_description">
                    Food Arrangement
                  </label>
                  {editPolicy ? (
                    <>
                      <div
                        className="primary_btn fit-width"
                        onClick={() => setEditPolicy(!editPolicy)}
                      >
                        Close
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="primary_btn fit-width"
                        onClick={() => setEditPolicy(!editPolicy)}
                      >
                        Edit
                      </div>
                    </>
                  )}
                </div>{" "}
                <ReactQuill
                  className="mt-10"
                  value={ruleDetails.content}
                  onChange={handleEditorChange}
                  modules={modules}
                  formats={formats}
                />
              </>
            ) : (
              <>
                {" "}
                <div className="flex justify_content_between">
                  {" "}
                  <label htmlFor="accessibility_description">
                    Food Arrangement
                  </label>
                  {editPolicy ? (
                    <>
                      <div
                        className="primary_btn fit-width"
                        onClick={() => setEditPolicy(!editPolicy)}
                      >
                        Close
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="primary_btn fit-width"
                        onClick={() => setEditPolicy(!editPolicy)}
                      >
                        Edit
                      </div>
                    </>
                  )}
                </div>
                <div className="border p_10 rounded mt-10">
                  <p
                    dangerouslySetInnerHTML={{ __html: ruleDetails?.content }}
                  ></p>
                </div>
              </>
            )}
            <button className="primary_btn fit-width mt-10" type="submit">
              Update
            </button>
          </form>
        );
      case "id-proof-related":
        return (
          <form onSubmit={handleFormData}>
            {editPolicy ? (
              <>
                <div className="flex justify_content_between">
                  {" "}
                  <label htmlFor="accessibility_description">
                    Id Proof Related
                  </label>
                  {editPolicy ? (
                    <>
                      <div
                        className="primary_btn fit-width"
                        onClick={() => setEditPolicy(!editPolicy)}
                      >
                        Close
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="primary_btn fit-width"
                        onClick={() => setEditPolicy(!editPolicy)}
                      >
                        Edit
                      </div>
                    </>
                  )}
                </div>{" "}
                <ReactQuill
                  className="mt-10"
                  value={ruleDetails.content}
                  onChange={handleEditorChange}
                  modules={modules}
                  formats={formats}
                />
              </>
            ) : (
              <>
                <div className="flex justify_content_between">
                  {" "}
                  <label htmlFor="accessibility_description">
                    Id Proof Related
                  </label>
                  {editPolicy ? (
                    <>
                      <div
                        className="primary_btn fit-width"
                        onClick={() => setEditPolicy(!editPolicy)}
                      >
                        Close
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="primary_btn fit-width"
                        onClick={() => setEditPolicy(!editPolicy)}
                      >
                        Edit
                      </div>
                    </>
                  )}
                </div>
                <div className="border p_10 rounded mt-10">
                  <p
                    dangerouslySetInnerHTML={{ __html: ruleDetails?.content }}
                  ></p>
                </div>
              </>
            )}
            <button className="primary_btn fit-width mt-10" type="submit">
              Update
            </button>
          </form>
        );
      case "guest-profile":
        return (
          <form onSubmit={handleFormData}>
            {editPolicy ? (
              <>
                {" "}
                <div className="flex justify_content_between">
                  {" "}
                  <label htmlFor="accessibility_description">
                    Guest Profile
                  </label>
                  {editPolicy ? (
                    <>
                      <div
                        className="primary_btn fit-width"
                        onClick={() => setEditPolicy(!editPolicy)}
                      >
                        Close
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="primary_btn fit-width"
                        onClick={() => setEditPolicy(!editPolicy)}
                      >
                        Edit
                      </div>
                    </>
                  )}
                </div>{" "}
                <ReactQuill
                  className="mt-10"
                  value={ruleDetails.content}
                  onChange={handleEditorChange}
                  modules={modules}
                  formats={formats}
                />
              </>
            ) : (
              <>
                {" "}
                <div className="flex justify_content_between">
                  {" "}
                  <label htmlFor="accessibility_description">
                    Guest Profile
                  </label>
                  {editPolicy ? (
                    <>
                      <div
                        className="primary_btn fit-width"
                        onClick={() => setEditPolicy(!editPolicy)}
                      >
                        Close
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="primary_btn fit-width"
                        onClick={() => setEditPolicy(!editPolicy)}
                      >
                        Edit
                      </div>
                    </>
                  )}
                </div>
                <div className="border p_10 rounded mt-10">
                  <p
                    dangerouslySetInnerHTML={{ __html: ruleDetails?.content }}
                  ></p>
                </div>
              </>
            )}
            <button className="primary_btn fit-width mt-10" type="submit">
              Update
            </button>
          </form>
        );
      case "guest-profile-hourly":
        return (
          <form onSubmit={handleFormData}>
            {editPolicy ? (
              <>
                <div className="flex justify_content_between">
                  {" "}
                  <label htmlFor="accessibility_description">
                    Guest Profile Hourly
                  </label>
                  {editPolicy ? (
                    <>
                      <div
                        className="primary_btn fit-width"
                        onClick={() => setEditPolicy(!editPolicy)}
                      >
                        Close
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="primary_btn fit-width"
                        onClick={() => setEditPolicy(!editPolicy)}
                      >
                        Edit
                      </div>
                    </>
                  )}
                </div>{" "}
                <ReactQuill
                  className="mt-10"
                  value={ruleDetails.content}
                  onChange={handleEditorChange}
                  modules={modules}
                  formats={formats}
                />
              </>
            ) : (
              <>
                <div className="flex justify_content_between">
                  {" "}
                  <label htmlFor="accessibility_description">
                    Guest Profile Hourly
                  </label>
                  {editPolicy ? (
                    <>
                      <div
                        className="primary_btn fit-width"
                        onClick={() => setEditPolicy(!editPolicy)}
                      >
                        Close
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="primary_btn fit-width"
                        onClick={() => setEditPolicy(!editPolicy)}
                      >
                        Edit
                      </div>
                    </>
                  )}
                </div>
                <div className="border p_10 rounded mt-10">
                  <p
                    dangerouslySetInnerHTML={{ __html: ruleDetails?.content }}
                  ></p>
                </div>
              </>
            )}
            <button className="primary_btn fit-width mt-10" type="submit">
              Update
            </button>
          </form>
        );
      case "pets-related":
        return (
          <form onSubmit={handleFormData}>
            {editPolicy ? (
              <>
                {" "}
                <div className="flex justify_content_between">
                  {" "}
                  <label htmlFor="accessibility_description">
                    Pets Related
                  </label>
                  {editPolicy ? (
                    <>
                      <div
                        className="primary_btn fit-width"
                        onClick={() => setEditPolicy(!editPolicy)}
                      >
                        Close
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="primary_btn fit-width"
                        onClick={() => setEditPolicy(!editPolicy)}
                      >
                        Edit
                      </div>
                    </>
                  )}
                </div>
                <ReactQuill
                  className="mt-10"
                  value={ruleDetails.content}
                  onChange={handleEditorChange}
                  modules={modules}
                  formats={formats}
                />
              </>
            ) : (
              <>
                {" "}
                <div className="flex justify_content_between">
                  {" "}
                  <label htmlFor="accessibility_description">
                    Pets Related
                  </label>
                  {editPolicy ? (
                    <>
                      <div
                        className="primary_btn fit-width"
                        onClick={() => setEditPolicy(!editPolicy)}
                      >
                        Close
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="primary_btn fit-width"
                        onClick={() => setEditPolicy(!editPolicy)}
                      >
                        Edit
                      </div>
                    </>
                  )}
                </div>
                <div className="border p_10 rounded mt-10">
                  <p
                    dangerouslySetInnerHTML={{ __html: ruleDetails?.content }}
                  ></p>
                </div>{" "}
              </>
            )}
            <button className="primary_btn fit-width mt-10" type="submit">
              Update
            </button>
          </form>
        );
      case "other-rules":
        return (
          <form onSubmit={handleFormData}>
            {editPolicy ? (
              <>
                {" "}
                <div className="flex justify_content_between">
                  {" "}
                  <label htmlFor="accessibility_description">Other Rules</label>
                  {editPolicy ? (
                    <>
                      <div
                        className="primary_btn fit-width"
                        onClick={() => setEditPolicy(!editPolicy)}
                      >
                        Close
                      </div>
                    </>
                  ) : (
                    <>
                      {editPolicy ? (
                        <>
                          <div
                            className="primary_btn fit-width"
                            onClick={() => setEditPolicy(!editPolicy)}
                          >
                            Close
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            className="primary_btn fit-width"
                            onClick={() => setEditPolicy(!editPolicy)}
                          >
                            Edit
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>{" "}
                <ReactQuill
                  className="mt-10"
                  value={ruleDetails.content}
                  onChange={handleEditorChange}
                  modules={modules}
                  formats={formats}
                />
              </>
            ) : (
              <>
                {" "}
                <div className="flex justify_content_between">
                  {" "}
                  <label htmlFor="accessibility_description">Other Rules</label>
                  {editPolicy ? (
                    <>
                      <div
                        className="primary_btn fit-width"
                        onClick={() => setEditPolicy(!editPolicy)}
                      >
                        Close
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="primary_btn fit-width"
                        onClick={() => setEditPolicy(!editPolicy)}
                      >
                        Edit
                      </div>
                    </>
                  )}
                </div>
                <div className="border p_10 rounded mt-10">
                  <p
                    dangerouslySetInnerHTML={{ __html: ruleDetails?.content }}
                  ></p>
                </div>
              </>
            )}
            <button className="primary_btn fit-width mt-10" type="submit">
              Update
            </button>
          </form>
        );
      default:
        return <p>Please select a rule to view and edit its details.</p>;
    }
  };

  const { id: propertyId } = useParams();

  useEffect(() => {
    if (propertyId) {
      roomDetails(propertyId);
    }
  }, [propertyId]);

  const handleFormData = (e, id) => {
    e.preventDefault();
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("key", process.env.REACT_APP_API_KEY);
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: JSON.stringify({ ...ruleDetails }),
    };

    fetch(
      `${process.env.REACT_APP_APP_URL}/api/v1/vendor/add-rules/${propertyId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        if (result.success) {
          showSuccessToast("Rule Updated Successfully");
          roomDetails(propertyId);
          setEditPolicy(false);
        } else {
          showErrorToast(result.message);
        }
      })
      .catch((error) => console.error(error));
  };

  const roomDetails = () => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("key", process.env.REACT_APP_API_KEY);
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    const requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: myHeaders,
    };

    fetch(
      `${process.env.REACT_APP_APP_URL}/api/v1/vendor/get-rules/${propertyId}`,

      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          // console.log(result, "rules");
          setAllRules(result?.rules);
          setSelectedRule("cancellation-policy");
          const findedObj = result?.rules?.find(
            (rule) => rule.rule_type === "cancellation-policy"
          );
          // console.log(findedObj);

          setRuleDetails((prev) => {
            return {
              ...prev,
              rule_type: "cancellation-policy",
              content: findedObj ? findedObj.content : "",
            };
          });
        } else {
          setAllRules([]);
        }
      })
      .catch((error) => console.error(error));
  };

  // New
  const [editorContent, setEditorContent] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      setloader(true);
      const myHeaders = new Headers();
      myHeaders.append("key", process.env.REACT_APP_API_KEY);

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      try {
        const response = await fetch(
          `${process.env.REACT_APP_APP_URL}/api/v1/vendor/get-rules/${propertyId}`,
          requestOptions
        );
        const result = await response.json();

        if (result) {
          // Ensure result.htmlContent exists
          setEditorContent(result?.rules[result.rules.length - 1].content);
        } else {
          console.error("No htmlContent in response:", result);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setloader(false);
      }
    };

    if (propertyId) {
      fetchData();
    }
  }, []);
  // console.log(editorContent, "edit");

  const handleEditorChange = (content) => {
    setEditorContent(content);
    setRuleDetails((prev) => ({
      ...prev,
      content: content,
    }));
  };

  const handleSubmit = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("key", process.env.REACT_APP_API_KEY);
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: JSON.stringify({ ...ruleDetails }),
      };

      const response = await fetch(
        `${process.env.REACT_APP_APP_URL}/api/v1/vendor/add-rules/${propertyId}`,
        requestOptions
      );

      const result = await response.json();
      if (result.success) {
        showSuccessToast("Rule Updated Successfully");
        roomDetails(propertyId);
      } else {
        showErrorToast(result.message);
      }
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };

  // Ensure modules and formats are defined correctly within the component
  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
  ];

  return (
    <>
      <VendorHeader showSelectProperty={true} />
      <div className="sidebar_main_div">
        <SideBar allPropertiesPage={false} />
        <section className="w-full p_20">
          <div className="flex justify_content_between">
            <h4>Rules</h4>
          </div>
          <div className="rules_tab">
            {rules.map((rule, index) => (
              <button
                key={index}
                className={`secondary_btn fit-width ${
                  selectedRule === rule ? "active-btn" : ""
                }`}
                style={{ textTransform: "capitalize" }}
                onClick={() => handleRuleClick(rule)}
              >
                {rule.replace(/-/g, " ")}
              </button>
            ))}
          </div>
          <div className="bg-white-div">{renderForm()}</div>
          {/* {loader ? (<Loader />) : (
            <div>
              <ReactQuill
                value={editorContent}
                onChange={handleEditorChange}
                modules={modules}
                formats={formats}
              />
              <button className="primary_btn fit-width mt-10" onClick={handleSubmit}>Submit</button>

             
              <div dangerouslySetInnerHTML={{ __html: editorContent }}></div>
            </div>
          )} */}
        </section>
      </div>
    </>
  );
};

export default Rules;
