import { useEffect } from 'react';

const NoIndexMeta = () => {
  useEffect(() => {
    // Create the meta element
    const metaTag = document.createElement('meta');
    metaTag.name = 'robots';
    metaTag.content = 'noindex';

    // Append the meta tag to the head
    document.head.appendChild(metaTag);

    // Cleanup function to remove the meta tag when the component is unmounted
    return () => {
      document.head.removeChild(metaTag);
    };
  }, []);

  return null; // No UI elements to render
};

export default NoIndexMeta;
