import React, { useState } from "react";
import AgentHeader from "../AgentHeader/AgentHeader";
import AgentSideBar from "../AgentSideBar/AgentSideBar";
import Footer from "../../../CustomComponents/Footer/Footer";
import "./AgentPromotionAdd.css";
import { Link, useParams } from "react-router-dom";

const AgentPromotionAddNext = () => {
  const { promotionName } = useParams();

  const [promotionData, setPromotionData] = useState({
    type: "",

    // promotion

    // Getaway Deal  Last Escape Deal
    rate_plans: "all", // selected
    selected_rate_plans: [],
    rooms: "all", //selected
    selected_rooms: [],

    discount: "20",
    stay_dates: [1, 2, 3, 4, 5, 6, 7],
    promotion_name: "",

    // last-minute deal
    booking_period: "days", //days hours
    booking_period_value: "30",

    //early-booker-deal
    certain_hours: "",
  });

  const [newPromotionData, setNewPromotionData] = useState({
    name: "Special Promotion",
    type: "percentage",
    method: "code",
    discount_code: "PROMO2024",
    amount: 20,
    start_date: "2024-09-01",
    end_date: "2024-09-30",
    start_time: "00:00:00",
    end_time: "23:59:59",
    status: "enable",
    all_conditons: {
      type: "stay",
      rate_plans: "all",
      selected_rate_plans: [],
      rooms: "all",
      selected_rooms: [],
      discount: "20",
      stay_dates: [],
      promotion_name: "Autumn Special",
      booking_period: "days",
      booking_period_value: "30",
      certain_hours: "",
    },
  });

  


  return (
    <>
      <AgentHeader showSelectProperty={true} />
      <div className="sidebar_main_div">
        <AgentSideBar />
        <section className="w-full table-data">
          {(promotionName === "getaway-deal" ||
            promotionName === "last-escape-deal") && (
            <div>
              <div className="">
                <h4 style={{ textTransform: "capitalize" }}>{promotionName}</h4>

                <div className="mt-5 promotionNextAddInnerParent">
                  <div>
                    <h4>Promotion Details</h4>

                    <div className="promotionNextAddInnerChildFlex">
                      <div>
                        <h6 htmlFor="">
                          Which rate plans will this promotion apply to?
                        </h6>
                        <div className="flex flex_gap_5">
                          <input type="radio" />
                          <label htmlFor="">All rate plans</label>
                        </div>
                        <div className="flex flex_gap_5">
                          <input type="radio" />
                          <label htmlFor="">Select rate plans</label>
                        </div>
                      </div>
                      <div>
                        <h6 htmlFor="">Which rooms?</h6>
                        <div className="flex flex_gap_5">
                          <input type="radio" />
                          <label htmlFor="">
                            All rooms within all selected rate plans
                          </label>
                        </div>
                        <div className="flex flex_gap_5">
                          <input type="radio" />
                          <label htmlFor="">Select rooms</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 promotionNextAddInnerParent">
                  <div>
                    <h4>How much of a discount do you want to give?</h4>

                    <div>
                    <div>
                        <h6 htmlFor="">Method </h6>
                        <div className="flex flex_gap_5">
                          <input type="radio" />
                          <label htmlFor="">
                            Code
                          </label>
                        </div>
                        <div className="flex flex_gap_5">
                          <input type="radio" />
                          <label htmlFor="">Direct</label>
                        </div>
                      </div>

                      <div>
                        <div className="flex flex_gap_5">
                          <input type="input" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 promotionNextAddInnerParent">
                  <div>
                    <h4>Stay dates</h4>
                    <h5>When can guests stay using this promotion?</h5>

                    <div>
                      <div className="promotionNextAddInnerChildFlex">
                        <div>
                          Which day(s) of the week would you like to include in
                          this promotion?
                        </div>

                        <div className="flex flex_gap_5">
                          <div>
                            <input type="checkbox" />
                            <label htmlFor="">Su</label>
                          </div>
                          <div>
                            <input type="checkbox" />
                            <label htmlFor="">Mo</label>
                          </div>
                          <div>
                            <input type="checkbox" />
                            <label htmlFor="">Tu</label>
                          </div>

                          <div>
                            <input type="checkbox" />
                            <label htmlFor="">We</label>
                          </div>

                          <div>
                            <input type="checkbox" />
                            <label htmlFor="">Th</label>
                          </div>

                          <div>
                            <input type="checkbox" />
                            <label htmlFor="">Fr</label>
                          </div>

                          <div>
                            <input type="checkbox" />
                            <label htmlFor="">Sa</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-5 promotionNextAddInnerParent">
                  <div>
                    <h4>Promotion name</h4>

                    <div>
                      <div className="promotionNextAddInnerChildFlex">
                        <div>
                          <h5>What do you want to name this promotion?</h5>
                          <p>
                            This name is just for you. We won’t show it to
                            customers on Booking.com.
                          </p>
                        </div>
                        <div className="flex flex_gap_5">
                          <input type="input" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <button className="common_btn">Create</button>
              </div>
            </div>
          )}
          {promotionName === "basic-deal" && (
            <div>
              <div className="">
                <h4 style={{ textTransform: "capitalize" }}>{promotionName}</h4>

                <div className="mt-5 promotionNextAddInnerParent">
                  <div>
                    <h4>Promotion Details</h4>

                    <div className="promotionNextAddInnerChildFlex">
                      <div>
                        <h6 htmlFor="">
                          Who will be able to see this promotion?
                        </h6>
                        <div className="flex flex_gap_5">
                          <input type="radio" />
                          <label htmlFor="">Everyone</label>
                        </div>
                        <div className="flex flex_gap_5">
                          <input type="radio" />
                          <label htmlFor="">
                            Members and newsletter subscribers only
                          </label>
                        </div>
                      </div>
                      <div>
                        <h6 htmlFor="">
                          Which rate plans will this promotion apply to?
                        </h6>
                        <div className="flex flex_gap_5">
                          <input type="radio" />
                          <label htmlFor="">All rate plans</label>
                        </div>
                        <div className="flex flex_gap_5">
                          <input type="radio" />
                          <label htmlFor="">Select rate plans</label>
                        </div>
                      </div>
                      <div>
                        <h6 htmlFor="">Which rooms?</h6>
                        <div className="flex flex_gap_5">
                          <input type="radio" />
                          <label htmlFor="">
                            All rooms within all selected rate plans
                          </label>
                        </div>
                        <div className="flex flex_gap_5">
                          <input type="radio" />
                          <label htmlFor="">Select rooms</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 promotionNextAddInnerParent">
                  <div>
                    <h4>How much of a discount do you want to give?</h4>

                    <div>
                      <div>
                        <div className="flex flex_gap_5">
                          <input type="input" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 promotionNextAddInnerParent">
                  <div>
                    <h4>Stay dates</h4>
                    <h5>When can guests stay using this promotion?</h5>

                    <div>
                      <div className="promotionNextAddInnerChildFlex">
                        <div>
                          Which day(s) of the week would you like to include in
                          this promotion?
                        </div>

                        <div className="flex flex_gap_5">
                          <div>
                            <input type="checkbox" />
                            <label htmlFor="">Su</label>
                          </div>
                          <div>
                            <input type="checkbox" />
                            <label htmlFor="">Mo</label>
                          </div>
                          <div>
                            <input type="checkbox" />
                            <label htmlFor="">Tu</label>
                          </div>

                          <div>
                            <input type="checkbox" />
                            <label htmlFor="">We</label>
                          </div>

                          <div>
                            <input type="checkbox" />
                            <label htmlFor="">Th</label>
                          </div>

                          <div>
                            <input type="checkbox" />
                            <label htmlFor="">Fr</label>
                          </div>

                          <div>
                            <input type="checkbox" />
                            <label htmlFor="">Sa</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-5 promotionNextAddInnerParent">
                  <div>
                    <h4>Promotion name</h4>

                    <div>
                      <div className="promotionNextAddInnerChildFlex">
                        <div>
                          <h5>What do you want to name this promotion?</h5>
                          <p>
                            This name is just for you. We won’t show it to
                            customers on Booking.com.
                          </p>
                        </div>
                        <div className="flex flex_gap_5">
                          <input type="input" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {promotionName === "last-minute-deal" && (
            <div>
              <div className="">
                <h4 style={{ textTransform: "capitalize" }}>{promotionName}</h4>

                <div className="mt-5 promotionNextAddInnerParent">
                  <div>
                    <h4>Bookable period</h4>

                    <div className="promotionNextAddInnerChildFlex">
                      <div>
                        <h6 htmlFor="">
                          How far in advance do customers need to book this
                          promotion?
                        </h6>
                        <div className="flex flex_gap_5">
                          <input type="radio" />
                          <label htmlFor="">
                            days or fewer before check-in
                          </label>
                        </div>
                        <div className="flex flex_gap_5">
                          <input type="radio" />
                          <label htmlFor="">
                            hours or fewer before check-in
                          </label>
                        </div>
                      </div>
                      <div>
                        <h6 htmlFor="">Which rooms?</h6>
                        <div className="flex flex_gap_5">
                          <input type="input" />
                          {/* <label htmlFor="">
                            All rooms within all selected rate plans
                          </label> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-5 promotionNextAddInnerParent">
                  <div>
                    <h4>Promotion Details</h4>

                    <div className="promotionNextAddInnerChildFlex">
                      <div>
                        <h6 htmlFor="">
                          Who will be able to see this promotion?
                        </h6>
                        <div className="flex flex_gap_5">
                          <input type="radio" />
                          <label htmlFor="">Everyone</label>
                        </div>
                        <div className="flex flex_gap_5">
                          <input type="radio" />
                          <label htmlFor="">
                            Members and newsletter subscribers only
                          </label>
                        </div>
                      </div>
                      <div>
                        <h6 htmlFor="">
                          Which rate plans will this promotion apply to?
                        </h6>
                        <div className="flex flex_gap_5">
                          <input type="radio" />
                          <label htmlFor="">All rate plans</label>
                        </div>
                        <div className="flex flex_gap_5">
                          <input type="radio" />
                          <label htmlFor="">Select rate plans</label>
                        </div>
                      </div>
                      <div>
                        <h6 htmlFor="">Which rooms?</h6>
                        <div className="flex flex_gap_5">
                          <input type="radio" />
                          <label htmlFor="">
                            All rooms within all selected rate plans
                          </label>
                        </div>
                        <div className="flex flex_gap_5">
                          <input type="radio" />
                          <label htmlFor="">Select rooms</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 promotionNextAddInnerParent">
                  <div>
                    <h4>How much of a discount do you want to give?</h4>

                    <div>
                      <div>
                        <div className="flex flex_gap_5">
                          <input type="input" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 promotionNextAddInnerParent">
                  <div>
                    <h4>Stay dates</h4>
                    <h5>When can guests stay using this promotion?</h5>

                    <div>
                      <div className="promotionNextAddInnerChildFlex">
                        <div>
                          Which day(s) of the week would you like to include in
                          this promotion?
                        </div>

                        <div className="flex flex_gap_5">
                          <div>
                            <input type="checkbox" />
                            <label htmlFor="">Su</label>
                          </div>
                          <div>
                            <input type="checkbox" />
                            <label htmlFor="">Mo</label>
                          </div>
                          <div>
                            <input type="checkbox" />
                            <label htmlFor="">Tu</label>
                          </div>

                          <div>
                            <input type="checkbox" />
                            <label htmlFor="">We</label>
                          </div>

                          <div>
                            <input type="checkbox" />
                            <label htmlFor="">Th</label>
                          </div>

                          <div>
                            <input type="checkbox" />
                            <label htmlFor="">Fr</label>
                          </div>

                          <div>
                            <input type="checkbox" />
                            <label htmlFor="">Sa</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-5 promotionNextAddInnerParent">
                  <div>
                    <h4>Promotion name</h4>

                    <div>
                      <div className="promotionNextAddInnerChildFlex">
                        <div>
                          <h5>What do you want to name this promotion?</h5>
                          <p>
                            This name is just for you. We won’t show it to
                            customers on Booking.com.
                          </p>
                        </div>
                        <div className="flex flex_gap_5">
                          <input type="input" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {promotionName === "early-booker-deal" && (
            <div>
              <div className="">
                <h4 style={{ textTransform: "capitalize" }}>{promotionName}</h4>

                <div className="mt-5 promotionNextAddInnerParent">
                  <div>
                    <h4>Bookable period</h4>

                    <div className="promotionNextAddInnerChildFlex">
                      <div>
                        <h6 htmlFor="">
                          How far in advance do customers need to book this
                          promotion?
                        </h6>
                        <small>
                          Select the minimum number of days before the check-in
                          date
                        </small>
                        <div className="flex flex_gap_5 align_items_center">
                          <input type="input" />
                          <label htmlFor="">days or more</label>
                        </div>

                        <h6 htmlFor="">
                          Do you want to limit this promotion to certain hours?
                        </h6>
                        <div className="flex flex_gap_5">
                          <input type="radio" />
                          <label htmlFor="">Yes</label>
                        </div>
                        <div className="flex flex_gap_5">
                          <input type="radio" />
                          <label htmlFor="">No</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-5 promotionNextAddInnerParent">
                  <div>
                    <h4>Promotion Details</h4>

                    <div className="promotionNextAddInnerChildFlex">
                      <div>
                        <h6 htmlFor="">
                          Who will be able to see this promotion?
                        </h6>
                        <div className="flex flex_gap_5">
                          <input type="radio" />
                          <label htmlFor="">Everyone</label>
                        </div>
                        <div className="flex flex_gap_5">
                          <input type="radio" />
                          <label htmlFor="">
                            Members and newsletter subscribers only
                          </label>
                        </div>
                      </div>
                      <div>
                        <h6 htmlFor="">
                          Which rate plans will this promotion apply to?
                        </h6>
                        <div className="flex flex_gap_5">
                          <input type="radio" />
                          <label htmlFor="">All rate plans</label>
                        </div>
                        <div className="flex flex_gap_5">
                          <input type="radio" />
                          <label htmlFor="">Select rate plans</label>
                        </div>
                      </div>
                      <div>
                        <h6 htmlFor="">Which rooms?</h6>
                        <div className="flex flex_gap_5">
                          <input type="radio" />
                          <label htmlFor="">
                            All rooms within all selected rate plans
                          </label>
                        </div>
                        <div className="flex flex_gap_5">
                          <input type="radio" />
                          <label htmlFor="">Select rooms</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 promotionNextAddInnerParent">
                  <div>
                    <h4>How much of a discount do you want to give?</h4>
                    <small>Select a discount amount between 1% and 99%</small>
                    <div>
                      <div>
                        <div className="flex flex_gap_5">
                          <input type="input" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 promotionNextAddInnerParent">
                  <div>
                    <h4>Stay dates</h4>
                    <h5>When can guests stay using this promotion?</h5>

                    <div>
                      <div className="promotionNextAddInnerChildFlex">
                        <div>
                          Which day(s) of the week would you like to include in
                          this promotion?
                        </div>

                        <div className="flex flex_gap_5">
                          <div>
                            <input type="checkbox" />
                            <label htmlFor="">Su</label>
                          </div>
                          <div>
                            <input type="checkbox" />
                            <label htmlFor="">Mo</label>
                          </div>
                          <div>
                            <input type="checkbox" />
                            <label htmlFor="">Tu</label>
                          </div>

                          <div>
                            <input type="checkbox" />
                            <label htmlFor="">We</label>
                          </div>

                          <div>
                            <input type="checkbox" />
                            <label htmlFor="">Th</label>
                          </div>

                          <div>
                            <input type="checkbox" />
                            <label htmlFor="">Fr</label>
                          </div>

                          <div>
                            <input type="checkbox" />
                            <label htmlFor="">Sa</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-5 promotionNextAddInnerParent">
                  <div>
                    <h4>Promotion name</h4>

                    <div>
                      <div className="promotionNextAddInnerChildFlex">
                        <div>
                          <h5>What do you want to name this promotion?</h5>
                          <p>
                            This name is just for you. We won’t show it to
                            customers on Booking.com.
                          </p>
                        </div>
                        <div className="flex flex_gap_5">
                          <input type="input" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </section>
      </div>
      <Footer />
    </>
  );
};

export default AgentPromotionAddNext;
