import React from "react";
import html2pdf from "html2pdf.js";
import printIcon from "../../assets/images/print.png";

const PrintButtonClickPackage = ({
  itemToDownloadID,
  ignoreFromPrint,
  pdfOrientation,
}) => {
  const handleDownload = (printId, ignoreIds) => {
    const printContent = document.getElementById(printId).innerHTML;
    const originalContent = document.body.innerHTML;
    document.body.innerHTML = printContent;
    const packageDetails = document.getElementById("package-details");

    window.print();
    document.body.innerHTML = originalContent;
  };

  // const handleDownload = (printId, ignoreClass) => {
  //   const printElement = document.getElementById(printId);
  //   if (!printElement) {
  //     console.error(`Element with id ${printId} not found`);
  //     return;
  //   }

  //   const printContent = printElement.cloneNode(true);

  //   if (ignoreClass) {
  //     const ignoreElements = printContent.getElementsByClassName(ignoreClass);
  //     Array.from(ignoreElements).forEach((element) => {
  //       element.classList.add("ignore-print");
  //     });
  //   }

  //   const originalContent = document.body.innerHTML;
  //   const tempDiv = document.createElement('div');
  //   tempDiv.appendChild(printContent);
  //   document.body.innerHTML = tempDiv.innerHTML;

  //   const packageDetails = document.getElementById("package-details");
  //   // if (packageDetails) {
  //   //   packageDetails.style.display = "none";
  //   // }

  //   window.print();
  //   document.body.innerHTML = originalContent;
  // };

  return (
    <div
      onClick={() => handleDownload(itemToDownloadID, ignoreFromPrint)}
      className="list_details cursor_pointer"
    >
      <img src={printIcon} alt="print-icon" className="icon-img" />
      <span  className="itineary_send_txt">
        Print <br /> Itinerary
      </span>
    </div>
  );
};

export default PrintButtonClickPackage;
