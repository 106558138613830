import CryptoJS from 'crypto-js';
 
 
const secretKey = process.env.REACT_APP_SECRET_KEY;  
 
 
// export const saveEncryptedData = ( keyName , data) => {
//     const encryptedToken = CryptoJS.AES.encrypt(data, secretKey).toString();
//     localStorage.setItem( keyName , encryptedToken);
// };

export const saveEncryptedData = (keyName, data) => {
    // Convert non-string data (e.g., objects, arrays) to JSON string
    const stringData = typeof data === 'string' ? data : JSON.stringify(data);

    // Encrypt the data
    const encryptedToken = CryptoJS.AES.encrypt(stringData, secretKey).toString();

    // Save the encrypted data to localStorage
    localStorage.setItem(keyName, encryptedToken);
};
 
export const getDecryptedData = (keyName) => {
    const encryptedData = localStorage.getItem(keyName);
    
    if (!encryptedData) {
        return null;
    }
 
    try {
        const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
        const decryptedData = decryptedBytes.toString(CryptoJS.enc.Utf8);
        
        return decryptedData;  
    } catch (error) {
        console.error('Error decrypting data:', error);
        return null;  
    }
};