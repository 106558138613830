import React, { useEffect, useState } from "react";
import "./PkgItinerary.css";
import logo from "../../assets/images/Mountain-mystries-logo.png"
import kasol from "../../assets/images/kasolitineary.jpg"
import trith from "../../assets/images/tirthnathitineary.jpg"
import { useParams } from "react-router-dom";
import { textDateFormatter } from "../../utils/utils";

function PkgItinerary({style, idToAdd,packageId }) {

    const [pkgDetail, setPkgDetail] = useState({});
    const { id } = useParams();
    // console.log(packageId, "pkg")

    useEffect(() => {
        if (id || packageId) {
            fetchData(id);
        }
    }, [id, packageId]);

    const fetchData = (id) => {
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                key: "D4X50uU40Vz4pweFLEmsoiJy107iPfTD4ompprop1",
            },
            redirect: "follow",
        };

        fetch(
            `${process.env.REACT_APP_APP_URL}/api/v1/holidays/fetch/single/${id ? id : packageId}`,
            requestOptions
        )
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                return response.json();
            })
            .then((data) => {
                setPkgDetail(data.data);
            })
            .catch((error) =>
                console.error(
                    "There has been a problem with your fetch operation:",
                    error
                )
            );

    };



    // const startDateTime = pkgDetail?.validFrom;
    // const endDateTime = pkgDetail?.validTo;

    // const getDateOnly = (dateTime) => {
    //     const date = new Date(dateTime);
    //     // Get date in YYYY-MM-DD format
    //     return date.toISOString().split('T')[0];
    // };

    // const startDate = getDateOnly(startDateTime);
    // const endDate = getDateOnly(endDateTime);


    return (
        <div  id={idToAdd}>
            <table className="PkgItinerary_table">
                <thead>
                    <tr>
                        <td>
                            <div className="PkgItinerary_logo">
                                <img src={logo} alt="" />
                            </div>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <div className="PkgItinerary_content">
                                <div className="PkgItinerary_details">
                                    <h3 className="PkgItinerary_details_heading">{pkgDetail?.packageName}</h3>
                                    <p className="PkgItinerary_details_validity">Validity : {textDateFormatter(pkgDetail.validFrom)} / {textDateFormatter(pkgDetail.validTo)} </p>
                                    <p className="PkgItinerary_details_tourDuration">Duration : 4 Nights / 5 Days</p>
                                </div>
                            </div>
                            <div className="PkgItinerary_wrapBox">
                                <div className="PkgItinerary_titleHead">Destination</div>
                                <div className="PkgItinerary_destinationWrap">
                                    {pkgDetail?.packageDestination?.map((items) => {
                                        return (
                                            <div className="PkgItinerary_imgWrapDest">
                                                <img src={items?.image} alt="" />
                                                <div className="PkgItinerary_destTitle">{items?.title}</div>
                                            </div>
                                        )
                                    }
                                    )}

                                    {/* <div className="PkgItinerary_imgWrapDest">
                                        <img src={trith} alt="" />
                                        <div className="PkgItinerary_destTitle">Tirthan Valley ( 2 Nights )</div>
                                    </div>
                                    <div className="PkgItinerary_imgWrapDest">
                                        <img src={kasol} alt="" />
                                        <div className="PkgItinerary_destTitle">Kasol (1 Night)</div>
                                    </div>
                                    <div className="PkgItinerary_imgWrapDest">
                                        <img src={kasol} alt="" />
                                        <div className="PkgItinerary_destTitle">Kasol (1 Night)</div>
                                    </div> */}


                                </div>
                            </div>
                            <div className="PkgItinerary_wrapBox">
                                <div className="PkgItinerary_titleHead">Day Wise Details</div>
                                <table cellSpacing={0} className="w-full PkgItinerary_itinearyTable">
                                    <tbody>
                                        {pkgDetail?.packageItinerary?.map((items) => {
                                            return (
                                                <tr>
                                                    <td>
                                                        <span className="PkgItinerary_dayBox">
                                                            <span className="daytxt">Day </span>
                                                            <span className="dayNumber"> {items?.dayCount} </span>
                                                        </span>
                                                        <div className="PkgItinerary_itinearyContent">
                                                            <p>{items?.shortDescription}</p>
                                                            <div className="PkgItinerary_summaryCol">
                                                                <p><b>Starts :</b> {items.startPoint}</p>
                                                                <p><b>End :</b> {items.endPoint}</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        )}
                                        {/* <tr>
                                            <td>
                                                <span className="PkgItinerary_dayBox">
                                                    <span className="daytxt">Day </span>
                                                    <span className="dayNumber"> 1 </span>
                                                </span>
                                                <div className="PkgItinerary_itinearyContent">
                                                    <p>Delhi - Aut (500 KMS, 10-11 Hours) Board our evening Volvo from Delhi and start your journey towards the Kullu Valley. Overnight journey on board. Reach in Aut By next morning.</p>
                                                    <div className="PkgItinerary_summaryCol">
                                                        <p><b>Starts :</b> Delhi</p>
                                                        <p><b>End :</b> Aut</p>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span className="PkgItinerary_dayBox">
                                                    <span className="daytxt">Day </span>
                                                    <span className="dayNumber"> 2 </span>
                                                </span>
                                                <div className="PkgItinerary_itinearyContent">
                                                    <p>Delhi - Aut (500 KMS, 10-11 Hours) Board our evening Volvo from Delhi and start your journey towards the Kullu Valley. Overnight journey on board. Reach in Aut By next morning.</p>
                                                    <div className="PkgItinerary_summaryCol">
                                                        <p><b>Starts :</b> Delhi</p>
                                                        <p><b>End :</b> Aut</p>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span className="PkgItinerary_dayBox">
                                                    <span className="daytxt">Day </span>
                                                    <span className="dayNumber"> 3 </span>
                                                </span>
                                                <div className="PkgItinerary_itinearyContent">
                                                    <p>Delhi - Aut (500 KMS, 10-11 Hours) Board our evening Volvo from Delhi and start your journey towards the Kullu Valley. Overnight journey on board. Reach in Aut By next morning.</p>
                                                    <div className="PkgItinerary_summaryCol">
                                                        <p><b>Starts :</b> Delhi</p>
                                                        <p><b>End :</b> Aut</p>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span className="PkgItinerary_dayBox">
                                                    <span className="daytxt">Day </span>
                                                    <span className="dayNumber"> 4 </span>
                                                </span>
                                                <div className="PkgItinerary_itinearyContent">
                                                    <p>Delhi - Aut (500 KMS, 10-11 Hours) Board our evening Volvo from Delhi and start your journey towards the Kullu Valley. Overnight journey on board. Reach in Aut By next morning.</p>
                                                    <div className="PkgItinerary_summaryCol">
                                                        <p><b>Starts :</b> Delhi</p>
                                                        <p><b>End :</b> Aut</p>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr> */}
                                    </tbody>
                                </table>
                            </div>
                            <div className="PkgItinerary_wrapBox">
                                <div className="PkgItinerary_titleHead">Proposed Hotel</div>
                                <table cellSpacing={0} className="PkgItinerary_itineary_details" style={{ width: "100%", padding: "10px 0px" }}>
                                    <tbody>
                                        <tr className="headerRow">
                                            <td><b>Destination</b></td>
                                            <td><b>Hotel</b></td>
                                            <td><b>Category</b></td>
                                        </tr>
                                        <tr className="contentRow">
                                            <td>Jibhi</td>
                                            <td>Jibhi Cottage</td>
                                            <td>delux</td>
                                        </tr>
                                        <tr className="contentRow">
                                            <td>Kasol</td>
                                            <td>Trishla Resort</td>
                                            <td>delux</td>
                                        </tr>
                                        <tr className="contentRow">
                                            <td>Jibhi</td>
                                            <td>Adventure Camp</td>
                                            <td>delux</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            {/* <div className="PkgItinerary_wrapBox">
                                <div className="PkgItinerary_titleHead">Cost per person for the proposed tour</div>
                                <table cellSpacing={0} className="PkgItinerary_itineary_details" style={{ width: "100%", padding: "10px 0px" }}>
                                    <tbody>
                                        <tr className="headerRow">
                                            <td><b>No of Pax</b></td>
                                            <td><b>Hotel Category</b></td>
                                            <td><b>Price</b></td>
                                            <td><b>Extra Adult/Child with bed</b></td>
                                            <td><b>Child without Bed</b></td>
                                            <td><b>Meal Plan</b></td>
                                        </tr>
                                        {pkgDetail?.packageRates?.map((items) => {
                                            return (
                                                <tr className="contentRow">
                                                    <td>{items?.no_pax}</td>
                                                    <td>Standard</td>
                                                    <td>18288.00</td>
                                                    <td>9017.00</td>
                                                    <td>N/A</td>
                                                    <td>MAP</td>
                                                </tr>
                                            )
                                        })}

                                        <tr className="contentRow">
                                            <td>4 Pax</td>
                                            <td>Standard</td>
                                            <td>18288.00</td>
                                            <td>9017.00</td>
                                            <td>N/A</td>
                                            <td>MAP</td>
                                        </tr>
                                        <tr className="contentRow">
                                            <td>6 Pax</td>
                                            <td>Standard</td>
                                            <td>18288.00</td>
                                            <td>9017.00</td>
                                            <td>N/A</td>
                                            <td>MAP</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div> */}
                            <div className="PkgItinerary_legalWrap">
                                <div className="PkgItinerary_inclusion">
                                    <div>
                                        <div className="PkgItinerary_titleHead">Inclusions</div>
                                        <div className="PkgItinerary_legalContent">
                                            <ul style={{ listStyleType: "disc" }}>
                                                <li>Volvo Ticket from Delhi to  Aut  & Kasol to Delhi</li>
                                                <li>Transportation by A/C Alto / D’zire / Etios (2Pax) / Innova / Similar for 4 to 6 Pax   for the entire tour [Ac will not work in the hills]</li>
                                                <li>All sightseeing and excursions as per the itinerary</li>
                                                <li>GST @5%</li>
                                                <li>Mentioned or similar hotels on as per the meal Plan</li>
                                                <li>Child of the age of below 5 Years of complimentary without extra bed</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="PkgItinerary_exclusion">
                                    <div>
                                        <div className="PkgItinerary_titleHead">Enclusions</div>
                                        <div className="PkgItinerary_legalContent">
                                            <ul style={{ listStyleType: "disc" }}>
                                                <li>Personal expenses of any nature.</li>
                                                <li>Adventure activities.</li>
                                                <li>Train/Air fare</li>
                                                <li>Guide</li>
                                                <li>Entry Charges</li>
                                                <li>Heater Charges</li>
                                                <li>Unforeseen Expenses because of the weather conditions.</li>
                                                <li>Any up gradation in Airline class or hotel room category.</li>
                                                <li>Cost of Insurance.</li>
                                                <li>Any extra expense such as route change, Date change, Accommodation facilities, etc incurred due to the unforeseen, unavoidable forced majeure circumstances during the tour.</li>
                                                <li>Any services or activity charges other than those included in the tour itinerary/ Holiday Package Offer</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="PkgItinerary_term">
                                    <div>
                                        <div className="PkgItinerary_titleHead">Terms and Conditions</div>
                                        <div className="PkgItinerary_legalContent">
                                            <ul>
                                                <li>Prices valid from 15th Apr to 15th July 2024</li>
                                                <li>50% advance to be deposited at the time of booking confirmation.</li>
                                                <li>Balance 50% before 15 days from the Tour Starts</li>
                                                <li>Check In / Check out time at all the properties would at 1200 hrs (Noon).</li>
                                                <li>All the above mentioned rates are per person rates on twin sharing.</li>
                                                <li>For Cancellation please refer to our Terms and Conditions available at our booking office</li>
                                                <li>Rooms holding shall be for a period of 72 hours only.</li>
                                                <li>The above rates are applicable strictly for resident Indian citizens only.</li>
                                                <li>Any amendment and cancellation in the booking before 15 days RS.500 Per Person are chargeable as communication charges.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="PkgItinerary_cancellation">
                                    <div>
                                        <div className="PkgItinerary_titleHead">Cancellation Policy</div>
                                        <div className="PkgItinerary_legalContent">
                                            <ul>
                                                <li>Prior to 30 days or more – 100% amount of the tour cost will be Refunded </li>
                                                <li>Between 29 and 15 days of departure - 75% amount of tour cost will be refunded </li>
                                                <li>Between 14 days and 7  prior to date of departure - 50% amount of tour cost will be refunded </li>
                                                <li>Between  6 days and In case of no show -  No refund- 100% retention shall be charged</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="PkgItinerary_remember">
                                    <div>
                                        <div className="PkgItinerary_titleHead">Points to remember</div>
                                        <div className="PkgItinerary_legalContent">
                                            <h4>Hotels :</h4>
                                            <ul>
                                                <li>Kindly note that names of hotels mentioned in the proposed hotels only indicate that our rates have been based on usage of these hotels and it is not to be construed that accommodation is confirmed at these hotels until and unless we convey such confirmation to you. We reserve the right to change the same due to availability issues with the hotel, or unavoidable circumstances. In that case, we may provide an alternative, similar accommodation and shall pass on the difference of rates (supplement/reduction whatever applicable) to you.</li>
                                                <li>Our offer is based on usage of base category rooms at the mentioned hotels (unless specified otherwise) and if this category of rooms is not available we shall try to confirm accommodation in next available higher category of rooms and shall advise supplementary cost involved while conveying the status.</li>
                                            </ul>
                                            <h4>Meals :</h4>
                                            <ul>
                                                <li>The menus are pre-set menus provided for breakfast/lunch/dinner on the tour as mentioned under each Tour itinerary and inclusions and exclusions as committed at the time of confirmation. In the standard/Budget category of hotels, the meals preferences are veg meals, unless specified and in the Deluxe/Super Deluxe and Luxury category of hotels/resorts, the meals are a mixture of veg and non-veg preferences.</li>
                                                <li>Mostly buffet meals are served during the tour, but depending upon the less occupancy and group size Ala Carte meals may be served by the hotels. No claim can be made for the meal which the guest has missed and not utilized.</li>
                                            </ul>
                                            <h4>TINERARY CHANGES :</h4>
                                            <ul>
                                                <li>For the comfort and convenience of guests, there could be some changes or amendments in the itinerary. We will try to advise you of these amendments, before the start of the tour or on tour. In the event, that the guest misses on any part of the sightseeing tour or any such tour due to delay on his part, he will not be entitled to claim a refund of the same.</li>
                                                <li>The timings mentioned in the Daily schedule in the itinerary may vary depending upon the road conditions, particular destination visits, En-route meals, climatic conditions, particular health conditions of the guests, and timings of sightseeing places and monuments.</li>
                                            </ul>
                                            <h4>CONDITION OF TRAVEL :</h4>
                                            <ul>
                                                <li>Please note the readymade packages are made based on destinations to be visited , sightseeings/excursions and activities to be done during the tour, keeping in view the time, cost and other factors while desiging the package. The guest have to strictly follow the Tour itinerary. Any additional sightseeings and excursions apart from the suggested itinerary will be on supplement cost. Any additional transfers/ pickups/drops apart from the proposed itinerary shall be subject to additional charges payable apart from the package quote.</li>
                                                <li>In case where a guest along with his family is unable to join or continue the tour due to any reason whatsoever including illness, death or loss of any travel documents, flight/train delays or cancellation no claim shall be entertained for refund of unutilized services, his booking shall be treated as "no show" on the tour and 100% cancellation charges will be levied.</li>
                                            </ul>
                                            <h4>Transport :</h4>
                                            <ul>
                                                <li>We normally use A/C Sedan such as Etios/Desire, In nova/Crysta/Tempo Travellers/2X2 A/C Coaches for our tours depending upon the group size and preferences of the travel. The drivers of the vehicles are bound by specific rules like maximum driving hours within a day, rest period per day etc. Clients will have to strictly adhere to the prescribed timetable for the day so that the driver can complete the travel. In case, any of the sightseeing schedules is missed due to delays caused by the client, the same will not be refunded to the client under any circumstances. Please note that AC will not work in Hills and no claims to this regard shall be entertained.</li>
                                            </ul>
                                            <h4>IDENTITY PROOFS :</h4>
                                            <ul>
                                                <li>It is mandatory to carry the Photo identity Proof during travel and have to be presented at the time of Check Inn in all hotels for security reasons.</li>
                                            </ul>
                                            <h4>ROHTANG PASS EXCURSION : (CLOSED ON TUESDAY) OPENS FROM JUNE 15 TO OCTOBER 15</h4>
                                            <ul>
                                                <li>Excursion to Rohtang is not included in the itinerary because NGT has restricted the number of the vehicle to this place and, It entirely depends upon the permission on a particular day. The client can take the local State Transport Bus (IN R 7000 to 7500 per person return) and visit Rohtang. The guest has to get the tickets from the Bus Depot at Mall Road. The local cabs charge somewhere between INR 5000 to INR 73000 depending on the demand</li>
                                            </ul>
                                            <h4>MONUMENTS AND ENTRY CHARGES :</h4>
                                            <ul>
                                                <li>All monuments are closed on Monday and the Taj Mahal is closed on Friday. Rohtang Pass is closed on Tuesday for maintenance. Kindly check your itinerary at your end as well.</li>
                                                <li>Entry Charges are payable extra at all destinations</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ marginTop: "15px" }} className="PkgItinerary_titleHead">Get in touch</div>
                            <div className="PkgItinerary_contactInfo">
                               
                                <div className="PkgItinerary_c_details">
                                    <div><b>Phone : </b>+91-98050-21966 , 0177-2627995</div>
                                    <div><b>Email : </b>info@mountainmysteries.com</div>
                                </div>
                            </div>

                            <div className="PkgItinerary_notice">
                                This is one of our most popular holiday package to your chosen destination. The pricing is indicative and can change depending on hotels etc. chosen. Once your Travel Expert gets in touch with you, feel free to ask for any customisation in this itinerary or have a fresh itinerary designed, as per your preference. When you are making the booking, please do check that all the selected package inclusions are showing on the package review page and confirm that all elements including transportation, hotels and sightseeing are exactly as finalised by you.
                            </div>

                        </td>
                    </tr>
                </tbody>
            </table>





            {/* <table className="PkgItinerary_table">
                <thead>
                    <tr>
                        <td>
                            <div className="PkgItinerary_logo">
                                <img src={logo} alt="" />
                            </div>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <div className="PkgItinerary_content">
                                <div className="PkgItinerary_details">
                                    <h3 className="PkgItinerary_details_heading">Weekend Getaways Special Tirthan Valley + Kasol 2024</h3>
                                    <p className="PkgItinerary_details_validity">Validity : 15 Apr, 2024 - 15 Jul, 2024</p>
                                    <p className="PkgItinerary_details_tourDuration">Duration : 4 Nights / 5 Days</p>
                                </div>
                            </div>
                            <div className="PkgItinerary_wrapBox">
                                <div className="PkgItinerary_titleHead">Destination</div>
                                <div className="PkgItinerary_destinationWrap">
                                    <div className="PkgItinerary_imgWrapDest">
                                        <img src={kasol} alt="" />
                                        <div className="PkgItinerary_destTitle">Kasol (1 Night)</div>
                                    </div>
                                    <div className="PkgItinerary_imgWrapDest">
                                        <img src={trith} alt="" />
                                        <div className="PkgItinerary_destTitle">Tirthan Valley ( 2 Nights )</div>
                                    </div>
                                    <div className="PkgItinerary_imgWrapDest">
                                        <img src={kasol} alt="" />
                                        <div className="PkgItinerary_destTitle">Kasol (1 Night)</div>
                                    </div>
                                    <div className="PkgItinerary_imgWrapDest">
                                        <img src={kasol} alt="" />
                                        <div className="PkgItinerary_destTitle">Kasol (1 Night)</div>
                                    </div>


                                </div>
                            </div>
                            <div className="PkgItinerary_wrapBox">
                                <div className="PkgItinerary_titleHead">Day Wise Details</div>
                                <table cellSpacing={0} className="w-full PkgItinerary_itinearyTable">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <span className="PkgItinerary_dayBox">
                                                    <span className="daytxt">Day </span>
                                                    <span className="dayNumber"> 1 </span>
                                                </span>
                                                <div className="PkgItinerary_itinearyContent">
                                                    <p>Delhi - Aut (500 KMS, 10-11 Hours) Board our evening Volvo from Delhi and start your journey towards the Kullu Valley. Overnight journey on board. Reach in Aut By next morning.</p>
                                                    <div className="PkgItinerary_summaryCol">
                                                        <p><b>Starts :</b> Delhi</p>
                                                        <p><b>End :</b> Aut</p>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span className="PkgItinerary_dayBox">
                                                    <span className="daytxt">Day </span>
                                                    <span className="dayNumber"> 2 </span>
                                                </span>
                                                <div className="PkgItinerary_itinearyContent">
                                                    <p>Delhi - Aut (500 KMS, 10-11 Hours) Board our evening Volvo from Delhi and start your journey towards the Kullu Valley. Overnight journey on board. Reach in Aut By next morning.</p>
                                                    <div className="PkgItinerary_summaryCol">
                                                        <p><b>Starts :</b> Delhi</p>
                                                        <p><b>End :</b> Aut</p>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span className="PkgItinerary_dayBox">
                                                    <span className="daytxt">Day </span>
                                                    <span className="dayNumber"> 3 </span>
                                                </span>
                                                <div className="PkgItinerary_itinearyContent">
                                                    <p>Delhi - Aut (500 KMS, 10-11 Hours) Board our evening Volvo from Delhi and start your journey towards the Kullu Valley. Overnight journey on board. Reach in Aut By next morning.</p>
                                                    <div className="PkgItinerary_summaryCol">
                                                        <p><b>Starts :</b> Delhi</p>
                                                        <p><b>End :</b> Aut</p>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span className="PkgItinerary_dayBox">
                                                    <span className="daytxt">Day </span>
                                                    <span className="dayNumber"> 4 </span>
                                                </span>
                                                <div className="PkgItinerary_itinearyContent">
                                                    <p>Delhi - Aut (500 KMS, 10-11 Hours) Board our evening Volvo from Delhi and start your journey towards the Kullu Valley. Overnight journey on board. Reach in Aut By next morning.</p>
                                                    <div className="PkgItinerary_summaryCol">
                                                        <p><b>Starts :</b> Delhi</p>
                                                        <p><b>End :</b> Aut</p>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="PkgItinerary_wrapBox">
                                <div className="PkgItinerary_titleHead">Proposed Hotel</div>
                                <table cellSpacing={0} className="PkgItinerary_itineary_details" style={{ width: "100%", padding: "10px 0px" }}>
                                    <tbody>
                                        <tr className="headerRow">
                                            <td><b>Destination</b></td>
                                            <td><b>Hotel</b></td>
                                            <td><b>Category</b></td>
                                        </tr>
                                        <tr className="contentRow">
                                            <td>Jibhi</td>
                                            <td>Jibhi Cottage</td>
                                            <td>delux</td>
                                        </tr>
                                        <tr className="contentRow">
                                            <td>Kasol</td>
                                            <td>Trishla Resort</td>
                                            <td>delux</td>
                                        </tr>
                                        <tr className="contentRow">
                                            <td>Jibhi</td>
                                            <td>Adventure Camp</td>
                                            <td>delux</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="PkgItinerary_wrapBox">
                                <div className="PkgItinerary_titleHead">Cost per person for the proposed tour</div>
                                <table cellSpacing={0} className="PkgItinerary_itineary_details" style={{ width: "100%", padding: "10px 0px" }}>
                                    <tbody>
                                        <tr className="headerRow">
                                            <td><b>No of Pax</b></td>
                                            <td><b>Hotel Category</b></td>
                                            <td><b>Price</b></td>
                                            <td><b>Extra Adult/Child with bed</b></td>
                                            <td><b>Child without Bed</b></td>
                                            <td><b>Meal Plan</b></td>
                                        </tr>
                                        <tr className="contentRow">
                                            <td>2 Pax</td>
                                            <td>Standard</td>
                                            <td>18288.00</td>
                                            <td>9017.00</td>
                                            <td>N/A</td>
                                            <td>MAP</td>
                                        </tr>
                                        <tr className="contentRow">
                                            <td>4 Pax</td>
                                            <td>Standard</td>
                                            <td>18288.00</td>
                                            <td>9017.00</td>
                                            <td>N/A</td>
                                            <td>MAP</td>
                                        </tr>
                                        <tr className="contentRow">
                                            <td>6 Pax</td>
                                            <td>Standard</td>
                                            <td>18288.00</td>
                                            <td>9017.00</td>
                                            <td>N/A</td>
                                            <td>MAP</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="PkgItinerary_legalWrap">
                                <div className="PkgItinerary_inclusion">
                                    <div>
                                        <div className="PkgItinerary_titleHead">Inclusions</div>
                                        <div className="PkgItinerary_legalContent">
                                            <ul style={{ listStyleType: "disc" }}>
                                                <li>Volvo Ticket from Delhi to  Aut  & Kasol to Delhi</li>
                                                <li>Transportation by A/C Alto / D’zire / Etios (2Pax) / Innova / Similar for 4 to 6 Pax   for the entire tour [Ac will not work in the hills]</li>
                                                <li>All sightseeing and excursions as per the itinerary</li>
                                                <li>GST @5%</li>
                                                <li>Mentioned or similar hotels on as per the meal Plan</li>
                                                <li>Child of the age of below 5 Years of complimentary without extra bed</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="PkgItinerary_exclusion">
                                    <div>
                                        <div className="PkgItinerary_titleHead">Enclusions</div>
                                        <div className="PkgItinerary_legalContent">
                                            <ul style={{ listStyleType: "disc" }}>
                                                <li>Personal expenses of any nature.</li>
                                                <li>Adventure activities.</li>
                                                <li>Train/Air fare</li>
                                                <li>Guide</li>
                                                <li>Entry Charges</li>
                                                <li>Heater Charges</li>
                                                <li>Unforeseen Expenses because of the weather conditions.</li>
                                                <li>Any up gradation in Airline class or hotel room category.</li>
                                                <li>Cost of Insurance.</li>
                                                <li>Any extra expense such as route change, Date change, Accommodation facilities, etc incurred due to the unforeseen, unavoidable forced majeure circumstances during the tour.</li>
                                                <li>Any services or activity charges other than those included in the tour itinerary/ Holiday Package Offer</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="PkgItinerary_term">
                                    <div>
                                        <div className="PkgItinerary_titleHead">Terms and Conditions</div>
                                        <div className="PkgItinerary_legalContent">
                                            <ul>
                                                <li>Prices valid from 15th Apr to 15th July 2024</li>
                                                <li>50% advance to be deposited at the time of booking confirmation.</li>
                                                <li>Balance 50% before 15 days from the Tour Starts</li>
                                                <li>Check In / Check out time at all the properties would at 1200 hrs (Noon).</li>
                                                <li>All the above mentioned rates are per person rates on twin sharing.</li>
                                                <li>For Cancellation please refer to our Terms and Conditions available at our booking office</li>
                                                <li>Rooms holding shall be for a period of 72 hours only.</li>
                                                <li>The above rates are applicable strictly for resident Indian citizens only.</li>
                                                <li>Any amendment and cancellation in the booking before 15 days RS.500 Per Person are chargeable as communication charges.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="PkgItinerary_cancellation">
                                    <div>
                                        <div className="PkgItinerary_titleHead">Cancellation Policy</div>
                                        <div className="PkgItinerary_legalContent">
                                            <ul>
                                                <li>Prior to 30 days or more – 100% amount of the tour cost will be Refunded </li>
                                                <li>Between 29 and 15 days of departure - 75% amount of tour cost will be refunded </li>
                                                <li>Between 14 days and 7  prior to date of departure - 50% amount of tour cost will be refunded </li>
                                                <li>Between  6 days and In case of no show -  No refund- 100% retention shall be charged</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="PkgItinerary_remember">
                                    <div>
                                        <div className="PkgItinerary_titleHead">Points to remember</div>
                                        <div className="PkgItinerary_legalContent">
                                            <h4>Hotels :</h4>
                                            <ul>
                                                <li>Kindly note that names of hotels mentioned in the proposed hotels only indicate that our rates have been based on usage of these hotels and it is not to be construed that accommodation is confirmed at these hotels until and unless we convey such confirmation to you. We reserve the right to change the same due to availability issues with the hotel, or unavoidable circumstances. In that case, we may provide an alternative, similar accommodation and shall pass on the difference of rates (supplement/reduction whatever applicable) to you.</li>
                                                <li>Our offer is based on usage of base category rooms at the mentioned hotels (unless specified otherwise) and if this category of rooms is not available we shall try to confirm accommodation in next available higher category of rooms and shall advise supplementary cost involved while conveying the status.</li>
                                            </ul>
                                            <h4>Meals :</h4>
                                            <ul>
                                                <li>The menus are pre-set menus provided for breakfast/lunch/dinner on the tour as mentioned under each Tour itinerary and inclusions and exclusions as committed at the time of confirmation. In the standard/Budget category of hotels, the meals preferences are veg meals, unless specified and in the Deluxe/Super Deluxe and Luxury category of hotels/resorts, the meals are a mixture of veg and non-veg preferences.</li>
                                                <li>Mostly buffet meals are served during the tour, but depending upon the less occupancy and group size Ala Carte meals may be served by the hotels. No claim can be made for the meal which the guest has missed and not utilized.</li>
                                            </ul>
                                            <h4>TINERARY CHANGES :</h4>
                                            <ul>
                                                <li>For the comfort and convenience of guests, there could be some changes or amendments in the itinerary. We will try to advise you of these amendments, before the start of the tour or on tour. In the event, that the guest misses on any part of the sightseeing tour or any such tour due to delay on his part, he will not be entitled to claim a refund of the same.</li>
                                                <li>The timings mentioned in the Daily schedule in the itinerary may vary depending upon the road conditions, particular destination visits, En-route meals, climatic conditions, particular health conditions of the guests, and timings of sightseeing places and monuments.</li>
                                            </ul>
                                            <h4>CONDITION OF TRAVEL :</h4>
                                            <ul>
                                                <li>Please note the readymade packages are made based on destinations to be visited , sightseeings/excursions and activities to be done during the tour, keeping in view the time, cost and other factors while desiging the package. The guest have to strictly follow the Tour itinerary. Any additional sightseeings and excursions apart from the suggested itinerary will be on supplement cost. Any additional transfers/ pickups/drops apart from the proposed itinerary shall be subject to additional charges payable apart from the package quote.</li>
                                                <li>In case where a guest along with his family is unable to join or continue the tour due to any reason whatsoever including illness, death or loss of any travel documents, flight/train delays or cancellation no claim shall be entertained for refund of unutilized services, his booking shall be treated as "no show" on the tour and 100% cancellation charges will be levied.</li>
                                            </ul>
                                            <h4>Transport :</h4>
                                            <ul>
                                                <li>We normally use A/C Sedan such as Etios/Desire, In nova/Crysta/Tempo Travellers/2X2 A/C Coaches for our tours depending upon the group size and preferences of the travel. The drivers of the vehicles are bound by specific rules like maximum driving hours within a day, rest period per day etc. Clients will have to strictly adhere to the prescribed timetable for the day so that the driver can complete the travel. In case, any of the sightseeing schedules is missed due to delays caused by the client, the same will not be refunded to the client under any circumstances. Please note that AC will not work in Hills and no claims to this regard shall be entertained.</li>
                                            </ul>
                                            <h4>IDENTITY PROOFS :</h4>
                                            <ul>
                                                <li>It is mandatory to carry the Photo identity Proof during travel and have to be presented at the time of Check Inn in all hotels for security reasons.</li>
                                            </ul>
                                            <h4>ROHTANG PASS EXCURSION : (CLOSED ON TUESDAY) OPENS FROM JUNE 15 TO OCTOBER 15</h4>
                                            <ul>
                                                <li>Excursion to Rohtang is not included in the itinerary because NGT has restricted the number of the vehicle to this place and, It entirely depends upon the permission on a particular day. The client can take the local State Transport Bus (IN R 7000 to 7500 per person return) and visit Rohtang. The guest has to get the tickets from the Bus Depot at Mall Road. The local cabs charge somewhere between INR 5000 to INR 73000 depending on the demand</li>
                                            </ul>
                                            <h4>MONUMENTS AND ENTRY CHARGES :</h4>
                                            <ul>
                                                <li>All monuments are closed on Monday and the Taj Mahal is closed on Friday. Rohtang Pass is closed on Tuesday for maintenance. Kindly check your itinerary at your end as well.</li>
                                                <li>Entry Charges are payable extra at all destinations</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ marginTop: "15px" }} className="PkgItinerary_titleHead">Get in touch</div>
                            <div className="PkgItinerary_contactInfo">
                                <div className="PkgItinerary_name">
                                    <span>Crafted By</span>
                                    <h4>Rajeev</h4>
                                </div>
                                <div className="PkgItinerary_c_details">
                                    <div><b>Phone : </b>+91-98050-21966 , 0177-2627995</div>
                                    <div><b>Email : </b>info@mountainmysteries.com</div>
                                </div>
                            </div>

                            <div className="PkgItinerary_notice">
                                This is one of our most popular holiday package to your chosen destination. The pricing is indicative and can change depending on hotels etc. chosen. Once your Travel Expert gets in touch with you, feel free to ask for any customisation in this itinerary or have a fresh itinerary designed, as per your preference. When you are making the booking, please do check that all the selected package inclusions are showing on the package review page and confirm that all elements including transportation, hotels and sightseeing are exactly as finalised by you.
                            </div>

                        </td>
                    </tr>
                </tbody>
            </table> */}

           




        </div>
    )
}

                export default PkgItinerary;