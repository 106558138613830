import React from "react";
import { useEffect } from "react";

const PageMetaData = ({ title, description, keywords, canonicalUrl }) => {
    useEffect(() => {

        document.title = title;


        let metaDescription = document.querySelector('meta[name="description"]');
        if (!metaDescription) {
            metaDescription = document.createElement("meta");
            metaDescription.name = "description";
            document.head.appendChild(metaDescription);
        }
        metaDescription.content = description;


        let metaKeywords = document.querySelector('meta[name="keywords"]');
        if (!metaKeywords) {
            metaKeywords = document.createElement("meta");
            metaKeywords.name = "keywords";
            document.head.appendChild(metaKeywords);
        }
        metaKeywords.content = keywords;


        let canonicalLink = document.querySelector('link[rel="canonical"]');
        if (!canonicalLink && canonicalUrl) {
            canonicalLink = document.createElement("link");
            canonicalLink.rel = "canonical";
            document.head.appendChild(canonicalLink);
        }
        if (canonicalLink) {
            canonicalLink.href = canonicalUrl;
        }


        return () => {
            if (metaDescription) {
                document.head.removeChild(metaDescription);
            }
            if (metaKeywords) {
                document.head.removeChild(metaKeywords);
            }
            if (canonicalLink) {
                document.head.removeChild(canonicalLink);
            }
        };
    }, [title, description, keywords, canonicalUrl]);

    return null;
};
export default PageMetaData;