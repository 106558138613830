import React, { useEffect, useState } from "react";
import AgentHeader from "../AgentHeader/AgentHeader";
import AgentSideBar from "../AgentSideBar/AgentSideBar";
import Footer from "../../../CustomComponents/Footer/Footer";
// import "./AgentPromotionAdd.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import VendorHeader from "../../../CustomComponents/Property/VendorHeader/VendorHeader";
import SideBar from "../../../CustomComponents/Property/SideBar/SideBar";
import DatePicker from "react-flatpickr";
import Flatpickr from "react-flatpickr";
import { formatDateToHHMM, formatDateToYYYYMMDD } from "../../../utils/utils";
import { toast } from "react-toastify";

const VendorPromotionAddNext = () => {
  const { promotionName } = useParams();
  const navigate = useNavigate();

  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  // console.log("userDetails", userDetails);/

  const [rooms, setRooms] = useState([]);
  const [loader, setLoader] = useState(false);

  const [promotionData, setPromotionData] = useState({
    type: "",

    // promotion

    // Getaway Deal  Last Escape Deal
    rate_plans: "all", // selected
    selected_rate_plans: [],
    rooms: "all", //selected
    selected_rooms: [],

    discount: "20",
    stay_dates: [1, 2, 3, 4, 5, 6, 7],
    promotion_name: "",

    // last-minute deal
    booking_period: "days", //days hours
    booking_period_value: "30",

    //early-booker-deal
    certain_hours: "",
  });

  const [newPromotionData, setNewPromotionData] = useState({
    name: promotionName,
    type: "percentage", // fixed
    method: "code", // automatic
    discount_code: "",
    amount: "",
    start_date: "",
    end_date: "",
    start_time: "00:00:00",
    end_time: "00:00:00",
    status: "enable",
    all_conditions: {
      type: "percentage",
      rate_plans: "all",
      selected_rate_plans: [],
      rooms: "all",
      selected_rooms: [],
      discount: "",
      stay_dates: [],
      promotion_name: "",
      booking_period: "days",
      booking_period_value: "",
      certain_hours: "",
    },
  });

  // console.log("newPromotionData", newPromotionData);

  // onChange={() =>
  // setNewPromotionData({
  //   ...newPromotionData,
  //   all_conditions: {
  //     ...newPromotionData.all_conditions,
  //     selected_rate_plans: [
  //       ...newPromotionData
  //         .all_conditions.selected_rate_plans,
  //       "ep",
  //     ],
  //   },
  // })
  // }
  const handleRatePlanChange = (ratePlan) => {
    const updatedSelectedRatePlans =
      newPromotionData.all_conditions.selected_rate_plans.includes(ratePlan)
        ? newPromotionData.all_conditions.selected_rate_plans.filter(
            (plan) => plan !== ratePlan
          )
        : [...newPromotionData.all_conditions.selected_rate_plans, ratePlan];

    setNewPromotionData({
      ...newPromotionData,
      all_conditions: {
        ...newPromotionData.all_conditions,
        selected_rate_plans: updatedSelectedRatePlans,
      },
    });
  };
  const handleRoomChange = (ratePlan) => {
    // console.log("handleRoomChange", ratePlan);

    const updatedSelectedRatePlans =
      newPromotionData.all_conditions.selected_rooms.includes(ratePlan)
        ? newPromotionData.all_conditions.selected_rooms.filter(
            (plan) => plan !== ratePlan
          )
        : [...newPromotionData.all_conditions.selected_rooms, ratePlan];

    setNewPromotionData({
      ...newPromotionData,
      all_conditions: {
        ...newPromotionData.all_conditions,
        selected_rooms: updatedSelectedRatePlans,
      },
    });
  };
  const handleStayDates = (ratePlan) => {
    const updatedSelectedRatePlans =
      newPromotionData.all_conditions.stay_dates.includes(ratePlan)
        ? newPromotionData.all_conditions.stay_dates.filter(
            (plan) => plan !== ratePlan
          )
        : [...newPromotionData.all_conditions.stay_dates, ratePlan];

    setNewPromotionData({
      ...newPromotionData,
      all_conditions: {
        ...newPromotionData.all_conditions,
        stay_dates: updatedSelectedRatePlans,
      },
    });
  };

  const { id: propertyId } = useParams();

  const getRooms = (id) => {
    setLoader(true);
    const requestOptions = {
      // myHeaders.append("key", "D4X50uU40Vz4pweFLEmsoiJy107iPfTD4ompprop1");
      headers: {
        "Content-Type": "application/json",
        key: "D4X50uU40Vz4pweFLEmsoiJy107iPfTD4ompprop1",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_APP_URL}/api/v1/vendor/get-rooms/${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log("result", result);

        if (result.success) {
          setRooms(result?.rooms);
          setLoader(false);
        } else {
          setRooms([]);
          setLoader(false);
        }
      })
      .catch((error) => console.error(error));
  };
  useEffect(() => {
    if (propertyId) {
      getRooms(propertyId);
    }
  }, [propertyId]);

  const postData = () => {
    const myHeaders = new Headers();
 myHeaders.append("key", process.env.REACT_APP_API_KEY);
    myHeaders.append("Content-Type", "application/json");

    const data = {
      name: promotionName,
      type: "percentage",
      vendor_id: 2,
      accommodation_id: 2,
      method: "code",
      discount_code: "PROMO2024",
      amount: 20,
      start_date: "2024-09-01",
      end_date: "2024-09-30",
      start_time: "00:00:00",
      end_time: "23:59:59",
      status: "enable",
      all_conditons: {
        type: "stay",
        rate_plans: "all",
        selected_rate_plans: [],
        rooms: "all",
        selected_rooms: [],
        discount: "20",
        stay_dates: [],
        promotion_name: "Autumn Special",
        booking_period: "days",
        booking_period_value: "30",
        certain_hours: "",
        minLos: "",
      },
    };

    if(newPromotionData?.amount === 0 || newPromotionData?.amount === "" || !newPromotionData?.amount) {
      return toast.error("Please enter valid discount Amount / Percentage");
    }

    if(!newPromotionData.start_date || !newPromotionData.end_date) {
      return toast.error("Please enter valid date");
    }

    if(!newPromotionData.start_time || !newPromotionData.end_time) {
      return toast.error("Please enter valid time");
    }
    if(newPromotionData?.method === "code" && !newPromotionData?.discount_code) {
      return toast.error("Please enter discount code");
    }
    if(newPromotionData?.all_conditions?.stay_dates.length === 0) {
      return toast.error("Please select stay dates");
    }
    
    if( newPromotionData?.all_conditions?.rate_plans === "selected" && newPromotionData?.all_conditions?.selected_rate_plans.length === 0) {
      return toast.error("Please select rooms");
    }
    if( newPromotionData?.all_conditions?.rooms === "selected" && newPromotionData?.all_conditions?.selected_rooms.length === 0) {
      return toast.error("Please select rooms");
    }

    if(!newPromotionData?.all_conditions?.promotion_name) {
      return toast.error("Please enter promotion name");
    }


    const raw = JSON.stringify({
      ...newPromotionData,
      accommodation_id: propertyId,
      vendor_id: userDetails.id,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_APP_URL}/api/v1/vendor/promotion/save`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          toast.success(result.message);
          navigate(`/partner/promotion/${propertyId}`);
        }
      })
      .catch((error) => console.error(error));
  };

  return (
    <>
      <VendorHeader showSelectProperty={true} />
      <div className="sidebar_main_div">
        <SideBar />
        <section className="w-full table-data">
          {(promotionName === "getaway-deal" ||
            promotionName === "last-escape-deal") && (
            <div>
              <div className="">
                <h4 style={{ textTransform: "capitalize" }}>{promotionName}</h4>

                <div className="mt-5 promotionNextAddInnerParent">
                  <div>
                    <h4>Promotion Details</h4>

                    <div className="promotionNextAddInnerChildFlex">
                      <div>
                        <h6 htmlFor="">
                          Which rate plans will this promotion apply to?
                        </h6>
                        <div className="flex flex_gap_5">
                          <input
                            onChange={() =>
                              setNewPromotionData({
                                ...newPromotionData,
                                all_conditions: {
                                  ...newPromotionData.all_conditions,
                                  rate_plans: "all",
                                },
                              })
                            }
                            value={newPromotionData.all_conditions.rate_plans}
                            checked={
                              newPromotionData.all_conditions.rate_plans ===
                              "all"
                            }
                            type="radio"
                          />
                          <label htmlFor="">All rate plans</label>
                        </div>
                        <div className="flex flex_gap_5">
                          <input
                            onChange={() =>
                              setNewPromotionData({
                                ...newPromotionData,
                                all_conditions: {
                                  ...newPromotionData.all_conditions,
                                  rate_plans: "selected",
                                },
                              })
                            }
                            value={newPromotionData.all_conditions.rate_plans}
                            checked={
                              newPromotionData.all_conditions.rate_plans ===
                              "selected"
                            }
                            type="radio"
                          />
                          <label htmlFor="">Select rate plans</label>
                        </div>

                        <div>
                          {newPromotionData.all_conditions.rate_plans ===
                            "selected" && (
                            <div className="mt_10">
                              <h6 htmlFor="">Select rate plans</h6>
                              <div className="flex flex_gap_5">
                                <input
                                  onChange={() => handleRatePlanChange("ep")}
                                  checked={newPromotionData.all_conditions.selected_rate_plans.includes(
                                    "ep"
                                  )}
                                  type="checkbox"
                                />
                                <label htmlFor="">EP</label>
                              </div>
                              <div className="flex flex_gap_5">
                                <input
                                  onChange={() => handleRatePlanChange("cp")}
                                  checked={newPromotionData.all_conditions.selected_rate_plans.includes(
                                    "cp"
                                  )}
                                  type="checkbox"
                                />
                                <label htmlFor="">CP</label>
                              </div>
                              <div className="flex flex_gap_5">
                                <input
                                  onChange={() => handleRatePlanChange("map")}
                                  checked={newPromotionData.all_conditions.selected_rate_plans.includes(
                                    "map"
                                  )}
                                  type="checkbox"
                                />
                                <label htmlFor="">MAP</label>
                              </div>
                              <div className="flex flex_gap_5">
                                <input
                                  onChange={() => handleRatePlanChange("ap")}
                                  checked={newPromotionData.all_conditions.selected_rate_plans.includes(
                                    "ap"
                                  )}
                                  type="checkbox"
                                />
                                <label htmlFor="">AP</label>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div>
                        <h6 htmlFor="">Which rooms?</h6>
                        <div className="flex flex_gap_5">
                          <input
                            onChange={() =>
                              setNewPromotionData({
                                ...newPromotionData,
                                all_conditions: {
                                  ...newPromotionData.all_conditions,
                                  rooms: "all",
                                },
                              })
                            }
                            value={newPromotionData.all_conditions.rooms}
                            checked={
                              newPromotionData.all_conditions.rooms === "all"
                            }
                            type="radio"
                          />
                          <label htmlFor="">
                            {" "}
                            All rooms within all selected rate plans
                          </label>
                        </div>
                        {/* <div className="flex flex_gap_5">
                          <input type="radio" />
                          <label htmlFor="">
                            All rooms within all selected rate plans
                          </label>
                        </div> */}
                        <div className="flex flex_gap_5">
                          <input
                            onChange={() =>
                              setNewPromotionData({
                                ...newPromotionData,
                                all_conditions: {
                                  ...newPromotionData.all_conditions,
                                  rooms: "selected",
                                },
                              })
                            }
                            value={newPromotionData.all_conditions.rooms}
                            checked={
                              newPromotionData.all_conditions.rooms ===
                              "selected"
                            }
                            type="radio"
                          />
                          <label htmlFor=""> Select rooms</label>
                        </div>

                        <div>
                          {newPromotionData.all_conditions.rooms ===
                            "selected" && (
                            <div className="mt_10">
                              <h6 htmlFor="">Select rate plans</h6>

                              {rooms.map((room) => {
                                return (
                                  <div className="flex flex_gap_5">
                                    <input
                                      onChange={() =>
                                        handleRoomChange(room?.id)
                                      }
                                      checked={newPromotionData.all_conditions.selected_rooms.includes(
                                        room?.id
                                      )}
                                      type="checkbox"
                                    />
                                    <label htmlFor="">{room.name}</label>
                                  </div>
                                );
                              })}

                              {/* <div className="flex flex_gap_5">
                                <input
                                  onChange={() => handleRatePlanChange("ep")}
                                  checked={newPromotionData.all_conditions.selected_rate_plans.includes(
                                    "ep"
                                  )}
                                  type="checkbox"
                                />
                                <label htmlFor="">EP</label>
                              </div>
                              <div className="flex flex_gap_5">
                                <input
                                  onChange={() => handleRatePlanChange("cp")}
                                  checked={newPromotionData.all_conditions.selected_rate_plans.includes(
                                    "cp"
                                  )}
                                  type="checkbox"
                                />
                                <label htmlFor="">CP</label>
                              </div>
                              <div className="flex flex_gap_5">
                                <input
                                  onChange={() => handleRatePlanChange("map")}
                                  checked={newPromotionData.all_conditions.selected_rate_plans.includes(
                                    "map"
                                  )}
                                  type="checkbox"
                                />
                                <label htmlFor="">MAP</label>
                              </div> */}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 promotionNextAddInnerParent">
                  <div>
                    <h4>Select Date Range</h4>

                    <div className="promotionNextAddInnerChildFlex">
                      <div>
                        <Flatpickr
                          className="search_form_input"
                          defaultValue="Select Range"
                          
                          style={{
                            width: "200px",
                            border: "1px solid #ccc",
                            padding: "5px 10px",
                          }}
                          value={newPromotionData?.start_date}
                          onChange={([date1, date2]) => {
                            setNewPromotionData({
                              ...newPromotionData,
                              start_date: formatDateToYYYYMMDD(
                                date1 ? date1 : new Date()
                              ),
                              end_date: formatDateToYYYYMMDD(
                                date2 ? date2 : new Date()
                              ), // Combine both updates here
                            });
                          }}
                          // onChange={([date1, date2]) => {
                          //   setStartDate(date1);
                          //   setEndDate(date2);
                          // }}
                          options={{ mode: "range", minDate: "today" }}
                          required
                        />
                      </div>
                      <div>
                        {/* <Flatpickr
                      className="search_form_input"
                      value={newPromotionData?.end_date}
                      onChange={([date1, date2]) =>{
                        setNewPromotionData({
                          ...newPromotionData,
                          start_date: date1,
                        });
                        setNewPromotionData({
                          ...newPromotionData,
                          end_date: date2,
                        })
                      }
                       
                      }
                      // onChange={([date1, date2]) => {
                      //   setStartDate(date1);
                      //   setEndDate(date2);
                      // }}
                      options={{ mode: "range", minDate: "today" }}
                      required
                    /> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 promotionNextAddInnerParent">
                  <div>
                    <h4>Select Time Slot</h4>

                    <div className="promotionNextAddInnerChildFlex">
                    <div>
  <Flatpickr
    className="search_form_input"
    style={{
      width: "200px",
      border: "1px solid #ccc",
      padding: "5px 10px",
    }}
    value={newPromotionData?.start_time}
    onChange={([startTime]) => {
      setNewPromotionData({
        ...newPromotionData,
        start_time: startTime ? formatDateToHHMM(startTime) : null,
      });
    }}
    options={{
      enableTime: true,
      noCalendar: true,
      dateFormat: "H:i",
    }}
    required
  />
</div>
-
<div>
  <Flatpickr
    className="search_form_input"
    style={{
      width: "200px",
      border: "1px solid #ccc",
      padding: "5px 10px",
    }}
    value={newPromotionData?.end_time}
    onChange={([endTime]) => {
      setNewPromotionData({
        ...newPromotionData,
        end_time: endTime ? formatDateToHHMM(endTime) : null,
      });
    }}
    options={{
      enableTime: true,
      noCalendar: true,
      dateFormat: "H:i",
    }}
    required
  />
</div>

                      <div>
                        {/* <Flatpickr
                      className="search_form_input"
                      value={newPromotionData?.end_date}
                      onChange={([date1, date2]) =>{
                        setNewPromotionData({
                          ...newPromotionData,
                          start_date: date1,
                        });
                        setNewPromotionData({
                          ...newPromotionData,
                          end_date: date2,
                        })
                      }
                       
                      }
                      // onChange={([date1, date2]) => {
                      //   setStartDate(date1);
                      //   setEndDate(date2);
                      // }}
                      options={{ mode: "range", minDate: "today" }}
                      required
                    /> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 promotionNextAddInnerParent">
                  <div>
                    <h4>Min LOS</h4>

                    <div className="promotionNextAddInnerChildFlex">
                      <input
                        onChange={(e) =>
                          setNewPromotionData({
                            ...newPromotionData,
                            type: "percentage",
                            all_conditions: {
                              ...newPromotionData.all_conditions,
                              minLos: e.target.value,
                            },
                          })
                        }
                        value={newPromotionData.minLos}
                        type="number"
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-5 promotionNextAddInnerParent">
                  <div>
                    <h4>How much of a discount do you want to give?</h4>

                    <div className="flex flex_gap_15">
                      <div>
                        <h6 htmlFor="">Discount Method </h6>
                        <div className="flex flex_gap_5">
                          <input
                            checked={newPromotionData.method === "code"}
                            onChange={() =>
                              setNewPromotionData({
                                ...newPromotionData,
                                method: "code",
                              })
                            }
                            type="radio"
                          />
                          <label htmlFor="">Code</label>
                        </div>

                        <div className="flex flex_gap_5">
                          <input
                            checked={newPromotionData.method === "automatic"}
                            onChange={() =>
                              setNewPromotionData({
                                ...newPromotionData,
                                method: "automatic",
                              })
                            }
                            type="radio"
                          />
                          <label htmlFor="">Automatic</label>
                        </div>
                      </div>
                      {newPromotionData.method === "code" && (
                        <div>
                          <h6 htmlFor="">Discount Code</h6>
                          <div className="flex flex_gap_5">
                            <input
                              onChange={(e) =>
                                setNewPromotionData({
                                  ...newPromotionData,
                                  discount_code: e.target.value,
                                })
                              }
                              value={newPromotionData.discount_code}
                              type="input"
                            />
                          </div>
                        </div>
                      )}

                      <div>
                        <h6 htmlFor="">Discount Type </h6>
                        <div className="flex flex_gap_5">
                          <input
                            checked={
                              newPromotionData.all_conditions.type ===
                              "percentage"
                            }
                            onChange={() =>
                              setNewPromotionData({
                                ...newPromotionData,
                                type: "percentage",
                                all_conditions: {
                                  ...newPromotionData.all_conditions,
                                  type: "percentage",
                                },
                              })
                            }
                            type="radio"
                          />
                          <label htmlFor="">Percentage</label>
                        </div>
                        <div className="flex flex_gap_5">
                          <input
                            checked={
                              newPromotionData.all_conditions.type === "fixed"
                            }
                            onChange={() =>
                              setNewPromotionData({
                                ...newPromotionData,
                                type: "fixed",
                                all_conditions: {
                                  ...newPromotionData.all_conditions,
                                  type: "fixed",
                                },
                              })
                            }
                            type="radio"
                          />
                          <label htmlFor="">Fixed</label>
                        </div>
                      </div>
                    </div>
                    <div>
                      <h6 htmlFor="">Discount</h6>
                      <div className="flex flex_gap_5">
                        <input
                          onChange={(e) =>
                            setNewPromotionData({
                              ...newPromotionData,
                              amount: e.target.value,
                            })
                          }
                          value={newPromotionData.amount}
                          type="input"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 promotionNextAddInnerParent">
                  <div>
                    <h4>Stay dates</h4>
                    <h5>When can guests stay using this promotion?</h5>

                    <div>
                      <div className="promotionNextAddInnerChildFlex">
                        <div>
                          Which day(s) of the week would you like to include in
                          this promotion?
                        </div>

                        <div className="flex flex_gap_5">
                          <div>
                            <input
                              checked={newPromotionData.all_conditions.stay_dates.includes(
                                1
                              )}
                              onChange={() => handleStayDates(1)}
                              type="checkbox"
                              id="su"
                            />
                            <label htmlFor="su">Su</label>
                          </div>
                          <div>
                            <input
                              checked={newPromotionData.all_conditions.stay_dates.includes(
                                2
                              )}
                              onChange={() => handleStayDates(2)}
                              type="checkbox"
                              id="Mo"
                            />
                            <label htmlFor="Mo">Mo</label>
                          </div>
                          <div>
                            <input
                              checked={newPromotionData.all_conditions.stay_dates.includes(
                                3
                              )}
                              onChange={() => handleStayDates(3)}
                              type="checkbox"
                              id="Tu"
                            />
                            <label htmlFor="Tu">Tu</label>
                          </div>

                          <div>
                            <input
                              checked={newPromotionData.all_conditions.stay_dates.includes(
                                4
                              )}
                              onChange={() => handleStayDates(4)}
                              type="checkbox"
                              id="We"
                            />
                            <label htmlFor="We">We</label>
                          </div>

                          <div>
                            <input
                              checked={newPromotionData.all_conditions.stay_dates.includes(
                                5
                              )}
                              onChange={() => handleStayDates(5)}
                              type="checkbox"
                              id="Th"
                            />
                            <label htmlFor="Th">Th</label>
                          </div>

                          <div>
                            <input
                              checked={newPromotionData.all_conditions.stay_dates.includes(
                                6
                              )}
                              onChange={() => handleStayDates(6)}
                              type="checkbox"
                              id="Fr"
                            />
                            <label htmlFor="Fr">Fr</label>
                          </div>

                          <div>
                            <input
                              checked={newPromotionData.all_conditions.stay_dates.includes(
                                7
                              )}
                              onChange={() => handleStayDates(7)}
                              type="checkbox"
                              id="Sa"
                            />
                            <label htmlFor="Sa">Sa</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-5 promotionNextAddInnerParent">
                  <div>
                    <h4>Promotion name</h4>

                    <div>
                      <div className="promotionNextAddInnerChildFlex">
                        <div>
                          <h5>What do you want to name this promotion?</h5>
                          <p>
                            This name is just for you. We won’t show it to
                            customers on Booking.com.
                          </p>
                        </div>
                        <div className="flex flex_gap_5">
                          <input
                            value={
                              newPromotionData?.all_conditions?.promotion_name
                            }
                            onChange={(e) =>
                              setNewPromotionData({
                                ...newPromotionData,
                                all_conditions: {
                                  ...newPromotionData.all_conditions,
                                  promotion_name: e.target.value,
                                },
                              })
                            }
                            //   setNewPromotionData({
                            //     ...newPromotionData,
                            //     name: e.target.value,
                            //   })
                            // }
                            type="input"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <button onClick={postData} className="common_btn">
                  Create
                </button>
              </div>
            </div>
          )}
          {promotionName === "basic-deal" && (
            <div>
              <div className="">
                <h4 style={{ textTransform: "capitalize" }}>{promotionName}</h4>

                <div className="mt-5 promotionNextAddInnerParent">
                  <div>
                    <h4>Promotion Details</h4>

                    <div className="promotionNextAddInnerChildFlex">
                      <div>
                        <h6 htmlFor="">
                          Which rate plans will this promotion apply to?
                        </h6>
                        <div className="flex flex_gap_5">
                          <input
                            onChange={() =>
                              setNewPromotionData({
                                ...newPromotionData,
                                all_conditions: {
                                  ...newPromotionData.all_conditions,
                                  rate_plans: "all",
                                },
                              })
                            }
                            value={newPromotionData.all_conditions.rate_plans}
                            checked={
                              newPromotionData.all_conditions.rate_plans ===
                              "all"
                            }
                            type="radio"
                          />
                          <label htmlFor="">All rate plans</label>
                        </div>
                        <div className="flex flex_gap_5">
                          <input
                            onChange={() =>
                              setNewPromotionData({
                                ...newPromotionData,
                                all_conditions: {
                                  ...newPromotionData.all_conditions,
                                  rate_plans: "selected",
                                },
                              })
                            }
                            value={newPromotionData.all_conditions.rate_plans}
                            checked={
                              newPromotionData.all_conditions.rate_plans ===
                              "selected"
                            }
                            type="radio"
                          />
                          <label htmlFor="">Select rate plans</label>
                        </div>

                        <div>
                          {newPromotionData.all_conditions.rate_plans ===
                            "selected" && (
                            <div className="mt_10">
                              <h6 htmlFor="">Select rate plans</h6>
                              <div className="flex flex_gap_5">
                                <input
                                  onChange={() => handleRatePlanChange("ep")}
                                  checked={newPromotionData.all_conditions.selected_rate_plans.includes(
                                    "ep"
                                  )}
                                  type="checkbox"
                                />
                                <label htmlFor="">EP</label>
                              </div>
                              <div className="flex flex_gap_5">
                                <input
                                  onChange={() => handleRatePlanChange("cp")}
                                  checked={newPromotionData.all_conditions.selected_rate_plans.includes(
                                    "cp"
                                  )}
                                  type="checkbox"
                                />
                                <label htmlFor="">CP</label>
                              </div>
                              <div className="flex flex_gap_5">
                                <input
                                  onChange={() => handleRatePlanChange("map")}
                                  checked={newPromotionData.all_conditions.selected_rate_plans.includes(
                                    "map"
                                  )}
                                  type="checkbox"
                                />
                                <label htmlFor="">MAP</label>
                              </div>
                              <div className="flex flex_gap_5">
                                <input
                                  onChange={() => handleRatePlanChange("ap")}
                                  checked={newPromotionData.all_conditions.selected_rate_plans.includes(
                                    "ap"
                                  )}
                                  type="checkbox"
                                />
                                <label htmlFor="">AP</label>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div>
                        <h6 htmlFor="">Which rooms?</h6>
                        <div className="flex flex_gap_5">
                          <input
                            onChange={() =>
                              setNewPromotionData({
                                ...newPromotionData,
                                all_conditions: {
                                  ...newPromotionData.all_conditions,
                                  rooms: "all",
                                },
                              })
                            }
                            value={newPromotionData.all_conditions.rooms}
                            checked={
                              newPromotionData.all_conditions.rooms === "all"
                            }
                            type="radio"
                          />
                          <label htmlFor="">
                            {" "}
                            All rooms within all selected rate plans
                          </label>
                        </div>
                        {/* <div className="flex flex_gap_5">
                        <input type="radio" />
                        <label htmlFor="">
                          All rooms within all selected rate plans
                        </label>
                      </div> */}
                        <div className="flex flex_gap_5">
                          <input
                            onChange={() =>
                              setNewPromotionData({
                                ...newPromotionData,
                                all_conditions: {
                                  ...newPromotionData.all_conditions,
                                  rooms: "selected",
                                },
                              })
                            }
                            value={newPromotionData.all_conditions.rooms}
                            checked={
                              newPromotionData.all_conditions.rooms ===
                              "selected"
                            }
                            type="radio"
                          />
                          <label htmlFor=""> Select rooms</label>
                        </div>

                        <div>
                          {newPromotionData.all_conditions.rooms ===
                            "selected" && (
                            <div className="mt_10">
                              <h6 htmlFor="">Select rate plans</h6>

                              {rooms.map((room) => {
                                return (
                                  <div className="flex flex_gap_5">
                                    <input
                                      onChange={() =>
                                        handleRoomChange(room?.id)
                                      }
                                      checked={newPromotionData.all_conditions.selected_rooms.includes(
                                        room?.id
                                      )}
                                      type="checkbox"
                                    />
                                    <label htmlFor="">{room.name}</label>
                                  </div>
                                );
                              })}

                              {/* <div className="flex flex_gap_5">
                              <input
                                onChange={() => handleRatePlanChange("ep")}
                                checked={newPromotionData.all_conditions.selected_rate_plans.includes(
                                  "ep"
                                )}
                                type="checkbox"
                              />
                              <label htmlFor="">EP</label>
                            </div>
                            <div className="flex flex_gap_5">
                              <input
                                onChange={() => handleRatePlanChange("cp")}
                                checked={newPromotionData.all_conditions.selected_rate_plans.includes(
                                  "cp"
                                )}
                                type="checkbox"
                              />
                              <label htmlFor="">CP</label>
                            </div>
                            <div className="flex flex_gap_5">
                              <input
                                onChange={() => handleRatePlanChange("map")}
                                checked={newPromotionData.all_conditions.selected_rate_plans.includes(
                                  "map"
                                )}
                                type="checkbox"
                              />
                              <label htmlFor="">MAP</label>
                            </div> */}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 promotionNextAddInnerParent">
                  <div>
                    <h4>Select Date Range</h4>

                    <div className="promotionNextAddInnerChildFlex">
                      <div>
                        <Flatpickr
                          className="search_form_input"
                          defaultValue="Select Range"
                          style={{
                            width: "200px",
                            border: "1px solid #ccc",
                            padding: "5px 10px",
                          }}
                          value={newPromotionData?.start_date}
                          onChange={([date1, date2]) => {
                            setNewPromotionData({
                              ...newPromotionData,
                              start_date: formatDateToYYYYMMDD(
                                date1 ? date1 : new Date()
                              ),
                              end_date: formatDateToYYYYMMDD(
                                date2 ? date2 : new Date()
                              ), // Combine both updates here
                            });
                          }}
                          // onChange={([date1, date2]) => {
                          //   setStartDate(date1);
                          //   setEndDate(date2);
                          // }}
                          options={{ mode: "range", minDate: "today" }}
                          required
                        />
                      </div>
                      <div>
                        {/* <Flatpickr
                      className="search_form_input"
                      value={newPromotionData?.end_date}
                      onChange={([date1, date2]) =>{
                        setNewPromotionData({
                          ...newPromotionData,
                          start_date: date1,
                        });
                        setNewPromotionData({
                          ...newPromotionData,
                          end_date: date2,
                        })
                      }
                       
                      }
                      // onChange={([date1, date2]) => {
                      //   setStartDate(date1);
                      //   setEndDate(date2);
                      // }}
                      options={{ mode: "range", minDate: "today" }}
                      required
                    /> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 promotionNextAddInnerParent">
                  <div>
                    <h4>Min LOS</h4>

                    <div className="promotionNextAddInnerChildFlex">
                      <input
                        onChange={(e) =>
                          setNewPromotionData({
                            ...newPromotionData,
                            type: "percentage",
                            all_conditions: {
                              ...newPromotionData.all_conditions,
                              minLos: e.target.value,
                            },
                          })
                        }
                        value={newPromotionData.minLos}
                        type="input"
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-5 promotionNextAddInnerParent">
                  <div>
                    <h4>How much of a discount do you want to give?</h4>

                    <div className="flex flex_gap_15">
                      <div>
                        <h6 htmlFor="">Discount Method </h6>
                        <div className="flex flex_gap_5">
                          <input
                            checked={newPromotionData.method === "code"}
                            onChange={() =>
                              setNewPromotionData({
                                ...newPromotionData,
                                method: "code",
                              })
                            }
                            type="radio"
                          />
                          <label htmlFor="">Code</label>
                        </div>

                        <div className="flex flex_gap_5">
                          <input
                            checked={newPromotionData.method === "automatic"}
                            onChange={() =>
                              setNewPromotionData({
                                ...newPromotionData,
                                method: "automatic",
                              })
                            }
                            type="radio"
                          />
                          <label htmlFor="">Automatic</label>
                        </div>
                      </div>
                      {newPromotionData.method === "code" && (
                        <div>
                          <h6 htmlFor="">Discount Code</h6>
                          <div className="flex flex_gap_5">
                            <input
                              onChange={(e) =>
                                setNewPromotionData({
                                  ...newPromotionData,
                                  discount_code: e.target.value,
                                })
                              }
                              value={newPromotionData.discount_code}
                              type="input"
                            />
                          </div>
                        </div>
                      )}

                      <div>
                        <h6 htmlFor="">Discount Type </h6>
                        <div className="flex flex_gap_5">
                          <input
                            checked={newPromotionData.type === "percentage"}
                            onChange={() =>
                              setNewPromotionData({
                                ...newPromotionData,
                                type: "percentage",
                                all_conditions: {
                                  ...newPromotionData.all_conditions,
                                  type: "percentage",
                                },
                              })
                            }
                            type="radio"
                          />
                          <label htmlFor="">Percentage</label>
                        </div>
                        <div className="flex flex_gap_5">
                          <input
                            checked={newPromotionData.type === "fixed"}
                            onChange={() =>
                              setNewPromotionData({
                                ...newPromotionData,
                                type: "fixed",
                                all_conditions: {
                                  ...newPromotionData.all_conditions,
                                  type: "fixed",
                                },
                              })
                            }
                            type="radio"
                          />
                          <label htmlFor="">Fixed</label>
                        </div>
                      </div>
                    </div>
                    <div>
                      <h6 htmlFor="">Discount</h6>
                      <div className="flex flex_gap_5">
                        <input
                          onChange={(e) =>
                            setNewPromotionData({
                              ...newPromotionData,
                              amount: e.target.value,
                            })
                          }
                          value={newPromotionData.amount}
                          type="input"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 promotionNextAddInnerParent">
                  <div>
                    <h4>Stay dates</h4>
                    <h5>When can guests stay using this promotion?</h5>

                    <div>
                      <div className="promotionNextAddInnerChildFlex">
                        <div>
                          Which day(s) of the week would you like to include in
                          this promotion?
                        </div>

                        <div className="flex flex_gap_5">
                          <div>
                            <input
                              checked={newPromotionData.all_conditions.stay_dates.includes(
                                1
                              )}
                              onChange={() => handleStayDates(1)}
                              type="checkbox"
                            />
                            <label htmlFor="">Su</label>
                          </div>
                          <div>
                            <input
                              checked={newPromotionData.all_conditions.stay_dates.includes(
                                2
                              )}
                              onChange={() => handleStayDates(2)}
                              type="checkbox"
                            />
                            <label htmlFor="">Mo</label>
                          </div>
                          <div>
                            <input
                              checked={newPromotionData.all_conditions.stay_dates.includes(
                                3
                              )}
                              onChange={() => handleStayDates(3)}
                              type="checkbox"
                            />
                            <label htmlFor="">Tu</label>
                          </div>

                          <div>
                            <input
                              checked={newPromotionData.all_conditions.stay_dates.includes(
                                4
                              )}
                              onChange={() => handleStayDates(4)}
                              type="checkbox"
                            />
                            <label htmlFor="">We</label>
                          </div>

                          <div>
                            <input
                              checked={newPromotionData.all_conditions.stay_dates.includes(
                                5
                              )}
                              onChange={() => handleStayDates(5)}
                              type="checkbox"
                            />
                            <label htmlFor="">Th</label>
                          </div>

                          <div>
                            <input
                              checked={newPromotionData.all_conditions.stay_dates.includes(
                                6
                              )}
                              onChange={() => handleStayDates(6)}
                              type="checkbox"
                            />
                            <label htmlFor="">Fr</label>
                          </div>

                          <div>
                            <input
                              checked={newPromotionData.all_conditions.stay_dates.includes(
                                7
                              )}
                              onChange={() => handleStayDates(7)}
                              type="checkbox"
                            />
                            <label htmlFor="">Sa</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-5 promotionNextAddInnerParent">
                  <div>
                    <h4>Promotion name</h4>

                    <div>
                      <div className="promotionNextAddInnerChildFlex">
                        <div>
                          <h5>What do you want to name this promotion?</h5>
                          <p>
                            This name is just for you. We won’t show it to
                            customers on Booking.com.
                          </p>
                        </div>
                        <div className="flex flex_gap_5">
                          <input
                            value={
                              newPromotionData?.all_conditions?.promotion_name
                            }
                            onChange={(e) =>
                              setNewPromotionData({
                                ...newPromotionData,
                                all_conditions: {
                                  ...newPromotionData.all_conditions,
                                  promotion_name: e.target.value,
                                },
                              })
                            }
                            //   setNewPromotionData({
                            //     ...newPromotionData,
                            //     name: e.target.value,
                            //   })
                            // }
                            type="input"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <button onClick={postData} className="common_btn">
                  Create
                </button>
              </div>
            </div>
          )}
          {promotionName === "last-minute-deal" && (
            <div>
              <div className="">
                <h4 style={{ textTransform: "capitalize" }}>{promotionName}</h4>

                <div className="mt-5 promotionNextAddInnerParent">
                  <div>
                    <h4>Bookable period</h4>

                    <div className="promotionNextAddInnerChildFlex">
                      <div>
                        <h6 htmlFor="">
                          How far in advance do customers need to book this
                          promotion?
                        </h6>
                        <div className="flex flex_gap_5">
                          <input
                            type="radio"
                            onChange={() =>
                              setNewPromotionData({
                                ...newPromotionData,
                                all_conditions: {
                                  ...newPromotionData.all_conditions,
                                  booking_period: "days",
                                },
                              })
                            }
                            checked={
                              newPromotionData.all_conditions.booking_period ===
                              "days"
                            }
                          />
                          <label htmlFor="">
                            days or fewer before check-in
                          </label>
                        </div>
                        <div className="flex flex_gap_5">
                          <input
                            type="radio"
                            onChange={() =>
                              setNewPromotionData({
                                ...newPromotionData,
                                all_conditions: {
                                  ...newPromotionData.all_conditions,
                                  booking_period: "hours",
                                },
                              })
                            }
                            checked={
                              newPromotionData.all_conditions.booking_period ===
                              "hours"
                            }
                          />
                          <label htmlFor="">
                            hours or fewer before check-in
                          </label>
                        </div>
                      </div>

                      {newPromotionData.all_conditions.booking_period ===
                      "days" ? (
                        <>
                          {" "}
                          <div>
                            <h6 htmlFor="">How many days before?</h6>
                            <div className="flex flex_gap_5">
                              <input
                                onChange={(e) =>
                                  setNewPromotionData({
                                    ...newPromotionData,
                                    all_conditions: {
                                      ...newPromotionData.all_conditions,
                                      booking_period_value: e.target.value,
                                    },
                                  })
                                }
                                type="input"
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          {" "}
                          <div>
                            <h6 htmlFor="">How many hours before?</h6>
                            <div className="flex flex_gap_5">
                              <input
                                onChange={(e) =>
                                  setNewPromotionData({
                                    ...newPromotionData,
                                    all_conditions: {
                                      ...newPromotionData.all_conditions,
                                      booking_period_value: e.target.value,
                                    },
                                  })
                                }
                                type="input"
                              />
                              {/* <label htmlFor="">
                            All rooms within all selected rate plans
                          </label> */}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>

                <div className="mt-5 promotionNextAddInnerParent">
                  <div>
                    <h4>Promotion Details</h4>

                    <div className="promotionNextAddInnerChildFlex">
                      <div>
                        <h6 htmlFor="">
                          Which rate plans will this promotion apply to?
                        </h6>
                        <div className="flex flex_gap_5">
                          <input
                            onChange={() =>
                              setNewPromotionData({
                                ...newPromotionData,
                                all_conditions: {
                                  ...newPromotionData.all_conditions,
                                  rate_plans: "all",
                                },
                              })
                            }
                            value={newPromotionData.all_conditions.rate_plans}
                            checked={
                              newPromotionData.all_conditions.rate_plans ===
                              "all"
                            }
                            type="radio"
                          />
                          <label htmlFor="">All rate plans</label>
                        </div>
                        <div className="flex flex_gap_5">
                          <input
                            onChange={() =>
                              setNewPromotionData({
                                ...newPromotionData,
                                all_conditions: {
                                  ...newPromotionData.all_conditions,
                                  rate_plans: "selected",
                                },
                              })
                            }
                            value={newPromotionData.all_conditions.rate_plans}
                            checked={
                              newPromotionData.all_conditions.rate_plans ===
                              "selected"
                            }
                            type="radio"
                          />
                          <label htmlFor="">Select rate plans</label>
                        </div>

                        <div>
                          {newPromotionData.all_conditions.rate_plans ===
                            "selected" && (
                            <div className="mt_10">
                              <h6 htmlFor="">Select rate plans</h6>
                              <div className="flex flex_gap_5">
                                <input
                                  onChange={() => handleRatePlanChange("ep")}
                                  checked={newPromotionData.all_conditions.selected_rate_plans.includes(
                                    "ep"
                                  )}
                                  type="checkbox"
                                />
                                <label htmlFor="">EP</label>
                              </div>
                              <div className="flex flex_gap_5">
                                <input
                                  onChange={() => handleRatePlanChange("cp")}
                                  checked={newPromotionData.all_conditions.selected_rate_plans.includes(
                                    "cp"
                                  )}
                                  type="checkbox"
                                />
                                <label htmlFor="">CP</label>
                              </div>
                              <div className="flex flex_gap_5">
                                <input
                                  onChange={() => handleRatePlanChange("map")}
                                  checked={newPromotionData.all_conditions.selected_rate_plans.includes(
                                    "map"
                                  )}
                                  type="checkbox"
                                />
                                <label htmlFor="">MAP</label>
                              </div>
                              <div className="flex flex_gap_5">
                                <input
                                  onChange={() => handleRatePlanChange("ap")}
                                  checked={newPromotionData.all_conditions.selected_rate_plans.includes(
                                    "ap"
                                  )}
                                  type="checkbox"
                                />
                                <label htmlFor="">AP</label>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div>
                        <h6 htmlFor="">Which rooms?</h6>
                        <div className="flex flex_gap_5">
                          <input
                            onChange={() =>
                              setNewPromotionData({
                                ...newPromotionData,
                                all_conditions: {
                                  ...newPromotionData.all_conditions,
                                  rooms: "all",
                                },
                              })
                            }
                            value={newPromotionData.all_conditions.rooms}
                            checked={
                              newPromotionData.all_conditions.rooms === "all"
                            }
                            type="radio"
                          />
                          <label htmlFor="">
                            {" "}
                            All rooms within all selected rate plans
                          </label>
                        </div>
                        {/* <div className="flex flex_gap_5">
                        <input type="radio" />
                        <label htmlFor="">
                          All rooms within all selected rate plans
                        </label>
                      </div> */}
                        <div className="flex flex_gap_5">
                          <input
                            onChange={() =>
                              setNewPromotionData({
                                ...newPromotionData,
                                all_conditions: {
                                  ...newPromotionData.all_conditions,
                                  rooms: "selected",
                                },
                              })
                            }
                            value={newPromotionData.all_conditions.rooms}
                            checked={
                              newPromotionData.all_conditions.rooms ===
                              "selected"
                            }
                            type="radio"
                          />
                          <label htmlFor=""> Select rooms</label>
                        </div>

                        <div>
                          {newPromotionData.all_conditions.rooms ===
                            "selected" && (
                            <div className="mt_10">
                              <h6 htmlFor="">Select rate plans</h6>

                              {rooms.map((room) => {
                                return (
                                  <div className="flex flex_gap_5">
                                    <input
                                      onChange={() =>
                                        handleRoomChange(room?.id)
                                      }
                                      checked={newPromotionData.all_conditions.selected_rooms.includes(
                                        room?.id
                                      )}
                                      type="checkbox"
                                    />
                                    <label htmlFor="">{room.name}</label>
                                  </div>
                                );
                              })}

                              {/* <div className="flex flex_gap_5">
                              <input
                                onChange={() => handleRatePlanChange("ep")}
                                checked={newPromotionData.all_conditions.selected_rate_plans.includes(
                                  "ep"
                                )}
                                type="checkbox"
                              />
                              <label htmlFor="">EP</label>
                            </div>
                            <div className="flex flex_gap_5">
                              <input
                                onChange={() => handleRatePlanChange("cp")}
                                checked={newPromotionData.all_conditions.selected_rate_plans.includes(
                                  "cp"
                                )}
                                type="checkbox"
                              />
                              <label htmlFor="">CP</label>
                            </div>
                            <div className="flex flex_gap_5">
                              <input
                                onChange={() => handleRatePlanChange("map")}
                                checked={newPromotionData.all_conditions.selected_rate_plans.includes(
                                  "map"
                                )}
                                type="checkbox"
                              />
                              <label htmlFor="">MAP</label>
                            </div> */}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 promotionNextAddInnerParent">
                  <div>
                    <h4>Select Date Range</h4>

                    <div className="promotionNextAddInnerChildFlex">
                      <div>
                        <Flatpickr
                          className="search_form_input"
                          defaultValue="Select Range"
                          style={{
                            width: "200px",
                            border: "1px solid #ccc",
                            padding: "5px 10px",
                          }}
                          value={newPromotionData?.start_date}
                          onChange={([date1, date2]) => {
                            setNewPromotionData({
                              ...newPromotionData,
                              start_date: formatDateToYYYYMMDD(
                                date1 ? date1 : new Date()
                              ),
                              end_date: formatDateToYYYYMMDD(
                                date2 ? date2 : new Date()
                              ), // Combine both updates here
                            });
                          }}
                          // onChange={([date1, date2]) => {
                          //   setStartDate(date1);
                          //   setEndDate(date2);
                          // }}
                          options={{ mode: "range", minDate: "today" }}
                          required
                        />
                      </div>
                      <div>
                        {/* <Flatpickr
                      className="search_form_input"
                      value={newPromotionData?.end_date}
                      onChange={([date1, date2]) =>{
                        setNewPromotionData({
                          ...newPromotionData,
                          start_date: date1,
                        });
                        setNewPromotionData({
                          ...newPromotionData,
                          end_date: date2,
                        })
                      }
                       
                      }
                      // onChange={([date1, date2]) => {
                      //   setStartDate(date1);
                      //   setEndDate(date2);
                      // }}
                      options={{ mode: "range", minDate: "today" }}
                      required
                    /> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 promotionNextAddInnerParent">
                  <div>
                    <h4>Min LOS</h4>

                    <div className="promotionNextAddInnerChildFlex">
                      <input
                        onChange={(e) =>
                          setNewPromotionData({
                            ...newPromotionData,
                            type: "percentage",
                            all_conditions: {
                              ...newPromotionData.all_conditions,
                              minLos: e.target.value,
                            },
                          })
                        }
                        value={newPromotionData.minLos}
                        type="input"
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-5 promotionNextAddInnerParent">
                  <div>
                    <h4>How much of a discount do you want to give?</h4>

                    <div className="flex flex_gap_15">
                      <div>
                        <h6 htmlFor="">Discount Method </h6>
                        <div className="flex flex_gap_5">
                          <input
                            checked={newPromotionData.method === "code"}
                            onChange={() =>
                              setNewPromotionData({
                                ...newPromotionData,
                                method: "code",
                              })
                            }
                            type="radio"
                          />
                          <label htmlFor="">Code</label>
                        </div>

                        <div className="flex flex_gap_5">
                          <input
                            checked={newPromotionData.method === "automatic"}
                            onChange={() =>
                              setNewPromotionData({
                                ...newPromotionData,
                                method: "automatic",
                              })
                            }
                            type="radio"
                          />
                          <label htmlFor="">Automatic</label>
                        </div>
                      </div>
                      {newPromotionData.method === "code" && (
                        <div>
                          <h6 htmlFor="">Discount Code</h6>
                          <div className="flex flex_gap_5">
                            <input
                              onChange={(e) =>
                                setNewPromotionData({
                                  ...newPromotionData,
                                  discount_code: e.target.value,
                                })
                              }
                              value={newPromotionData.discount_code}
                              type="input"
                            />
                          </div>
                        </div>
                      )}

                      <div>
                        <h6 htmlFor="">Discount Type </h6>
                        <div className="flex flex_gap_5">
                          <input
                            checked={newPromotionData.type === "percentage"}
                            onChange={() =>
                              setNewPromotionData({
                                ...newPromotionData,
                                type: "percentage",
                                all_conditions: {
                                  ...newPromotionData.all_conditions,
                                  type: "percentage",
                                },
                              })
                            }
                            type="radio"
                          />
                          <label htmlFor="">Percentage</label>
                        </div>
                        <div className="flex flex_gap_5">
                          <input
                            checked={newPromotionData.type === "fixed"}
                            onChange={() =>
                              setNewPromotionData({
                                ...newPromotionData,
                                type: "fixed",
                              })
                            }
                            type="radio"
                          />
                          <label htmlFor="">Fixed</label>
                        </div>
                      </div>
                    </div>
                    <div>
                      <h6 htmlFor="">Discount</h6>
                      <div className="flex flex_gap_5">
                        <input
                          onChange={(e) =>
                            setNewPromotionData({
                              ...newPromotionData,
                              amount: e.target.value,
                            })
                          }
                          value={newPromotionData.amount}
                          type="input"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 promotionNextAddInnerParent">
                  <div>
                    <h4>Stay dates</h4>
                    <h5>When can guests stay using this promotion?</h5>

                    <div>
                      <div className="promotionNextAddInnerChildFlex">
                        <div>
                          Which day(s) of the week would you like to include in
                          this promotion?
                        </div>

                        <div className="flex flex_gap_5">
                          <div>
                            <input
                              checked={newPromotionData.all_conditions.stay_dates.includes(
                                1
                              )}
                              onChange={() => handleStayDates(1)}
                              type="checkbox"
                            />
                            <label htmlFor="">Su</label>
                          </div>
                          <div>
                            <input
                              checked={newPromotionData.all_conditions.stay_dates.includes(
                                2
                              )}
                              onChange={() => handleStayDates(2)}
                              type="checkbox"
                            />
                            <label htmlFor="">Mo</label>
                          </div>
                          <div>
                            <input
                              checked={newPromotionData.all_conditions.stay_dates.includes(
                                3
                              )}
                              onChange={() => handleStayDates(3)}
                              type="checkbox"
                            />
                            <label htmlFor="">Tu</label>
                          </div>

                          <div>
                            <input
                              checked={newPromotionData.all_conditions.stay_dates.includes(
                                4
                              )}
                              onChange={() => handleStayDates(4)}
                              type="checkbox"
                            />
                            <label htmlFor="">We</label>
                          </div>

                          <div>
                            <input
                              checked={newPromotionData.all_conditions.stay_dates.includes(
                                5
                              )}
                              onChange={() => handleStayDates(5)}
                              type="checkbox"
                            />
                            <label htmlFor="">Th</label>
                          </div>

                          <div>
                            <input
                              checked={newPromotionData.all_conditions.stay_dates.includes(
                                6
                              )}
                              onChange={() => handleStayDates(6)}
                              type="checkbox"
                            />
                            <label htmlFor="">Fr</label>
                          </div>

                          <div>
                            <input
                              checked={newPromotionData.all_conditions.stay_dates.includes(
                                7
                              )}
                              onChange={() => handleStayDates(7)}
                              type="checkbox"
                            />
                            <label htmlFor="">Sa</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-5 promotionNextAddInnerParent">
                  <div>
                    <h4>Promotion name</h4>

                    <div>
                      <div className="promotionNextAddInnerChildFlex">
                        <div>
                          <h5>What do you want to name this promotion?</h5>
                          <p>
                            This name is just for you. We won’t show it to
                            customers on Booking.com.
                          </p>
                        </div>
                        <div className="flex flex_gap_5">
                          <input
                            value={
                              newPromotionData?.all_conditions?.promotion_name
                            }
                            onChange={(e) =>
                              setNewPromotionData({
                                ...newPromotionData,
                                all_conditions: {
                                  ...newPromotionData.all_conditions,
                                  promotion_name: e.target.value,
                                },
                              })
                            }
                            //   setNewPromotionData({
                            //     ...newPromotionData,
                            //     name: e.target.value,
                            //   })
                            // }
                            type="input"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <button onClick={postData} className="common_btn">
                  Create
                </button>
              </div>
            </div>
          )}
          {promotionName === "early-booker-deal" && (
            <div>
              <div className="">
                <h4 style={{ textTransform: "capitalize" }}>{promotionName}</h4>

                <div className="mt-5 promotionNextAddInnerParent">
                  <div>
                    <h4>Bookable </h4>

                    <div className="promotionNextAddInnerChildFlex">
                      <div>
                        <h6 htmlFor="">
                          How far in advance do customers need to book this
                          promotion?
                        </h6>
                        <small>
                          Select the minimum number of days before the check-in
                          date
                        </small>
                        <div className="flex flex_gap_5 align_items_center">
                          <input type="input" />
                          <label htmlFor="">days or more</label>
                        </div>

                        <h6 htmlFor="">
                          Do you want to limit this promotion to certain hours?
                        </h6>
                        <div className="flex flex_gap_5">
                          <input type="radio" />
                          <label htmlFor="">Yes</label>
                        </div>
                        <div className="flex flex_gap_5">
                          <input type="radio" />
                          <label htmlFor="">No</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-5 promotionNextAddInnerParent">
                  <div>
                    <h4>Promotion Details</h4>

                    <div className="promotionNextAddInnerChildFlex">
                      <div>
                        <h6 htmlFor="">
                          Who will be able to see this promotion?
                        </h6>
                        <div className="flex flex_gap_5">
                          <input type="radio" />
                          <label htmlFor="">Everyone</label>
                        </div>
                        <div className="flex flex_gap_5">
                          <input type="radio" />
                          <label htmlFor="">
                            Members and newsletter subscribers only
                          </label>
                        </div>
                      </div>
                      <div>
                        <h6 htmlFor="">
                          Which rate plans will this promotion apply to?
                        </h6>
                        <div className="flex flex_gap_5">
                          <input type="radio" />
                          <label htmlFor="">All rate plans</label>
                        </div>
                        <div className="flex flex_gap_5">
                          <input type="radio" />
                          <label htmlFor="">Select rate plans</label>
                        </div>
                      </div>
                      <div>
                        <h6 htmlFor="">Which rooms?</h6>
                        <div className="flex flex_gap_5">
                          <input type="radio" />
                          <label htmlFor="">
                            All rooms within all selected rate plans
                          </label>
                        </div>
                        <div className="flex flex_gap_5">
                          <input type="radio" />
                          <label htmlFor="">Select rooms</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-5 promotionNextAddInnerParent">
                  <div>
                    <h4>Select Date Range</h4>

                    <div className="promotionNextAddInnerChildFlex">
                      <div>
                        <Flatpickr
                          className="search_form_input"
                          defaultValue="Select Range"
                          style={{
                            width: "200px",
                            border: "1px solid #ccc",
                            padding: "5px 10px",
                          }}
                          value={newPromotionData?.start_date}
                          onChange={([date1, date2]) => {
                            setNewPromotionData({
                              ...newPromotionData,
                              start_date: formatDateToYYYYMMDD(
                                date1 ? date1 : new Date()
                              ),
                              end_date: formatDateToYYYYMMDD(
                                date2 ? date2 : new Date()
                              ), // Combine both updates here
                            });
                          }}
                          // onChange={([date1, date2]) => {
                          //   setStartDate(date1);
                          //   setEndDate(date2);
                          // }}
                          options={{ mode: "range", minDate: "today" }}
                          required
                        />
                      </div>
                      <div>
                        {/* <Flatpickr
                      className="search_form_input"
                      value={newPromotionData?.end_date}
                      onChange={([date1, date2]) =>{
                        setNewPromotionData({
                          ...newPromotionData,
                          start_date: date1,
                        });
                        setNewPromotionData({
                          ...newPromotionData,
                          end_date: date2,
                        })
                      }
                       
                      }
                      // onChange={([date1, date2]) => {
                      //   setStartDate(date1);
                      //   setEndDate(date2);
                      // }}
                      options={{ mode: "range", minDate: "today" }}
                      required
                    /> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 promotionNextAddInnerParent">
                  <div>
                    <h4>Min LOS</h4>

                    <div className="promotionNextAddInnerChildFlex">
                      <input
                        onChange={(e) =>
                          setNewPromotionData({
                            ...newPromotionData,
                            type: "percentage",
                            all_conditions: {
                              ...newPromotionData.all_conditions,
                              minLos: e.target.value,
                            },
                          })
                        }
                        value={newPromotionData.minLos}
                        type="input"
                      />
                    </div>
                  </div>
                </div>

                <div className="mt-5 promotionNextAddInnerParent">
                  <div>
                    <h4>How much of a discount do you want to give?</h4>

                    <div className="flex flex_gap_15">
                      <div>
                        <h6 htmlFor="">Discount Method </h6>
                        <div className="flex flex_gap_5">
                          <input
                            checked={newPromotionData.method === "code"}
                            onChange={() =>
                              setNewPromotionData({
                                ...newPromotionData,
                                method: "code",
                              })
                            }
                            type="radio"
                          />
                          <label htmlFor="">Code</label>
                        </div>

                        <div className="flex flex_gap_5">
                          <input
                            checked={newPromotionData.method === "automatic"}
                            onChange={() =>
                              setNewPromotionData({
                                ...newPromotionData,
                                method: "automatic",
                              })
                            }
                            type="radio"
                          />
                          <label htmlFor="">Automatic</label>
                        </div>
                      </div>
                      {newPromotionData.method === "code" && (
                        <div>
                          <h6 htmlFor="">Discount Code</h6>
                          <div className="flex flex_gap_5">
                            <input
                              onChange={(e) =>
                                setNewPromotionData({
                                  ...newPromotionData,
                                  discount_code: e.target.value,
                                })
                              }
                              value={newPromotionData.discount_code}
                              type="input"
                            />
                          </div>
                        </div>
                      )}

                      <div>
                        <h6 htmlFor="">Discount Type </h6>
                        <div className="flex flex_gap_5">
                          <input
                            checked={newPromotionData.type === "percentage"}
                            onChange={() =>
                              setNewPromotionData({
                                ...newPromotionData,
                                type: "percentage",
                                all_conditions: {
                                  ...newPromotionData.all_conditions,
                                  type: "percentage",
                                },
                              })
                            }
                            type="radio"
                          />
                          <label htmlFor="">Percentage</label>
                        </div>
                        <div className="flex flex_gap_5">
                          <input
                            checked={newPromotionData.type === "fixed"}
                            onChange={() =>
                              setNewPromotionData({
                                ...newPromotionData,
                                type: "fixed",
                              })
                            }
                            type="radio"
                          />
                          <label htmlFor="">Fixed</label>
                        </div>
                      </div>
                    </div>
                    <div>
                      <h6 htmlFor="">Discount</h6>
                      <div className="flex flex_gap_5">
                        <input
                          onChange={(e) =>
                            setNewPromotionData({
                              ...newPromotionData,
                              amount: e.target.value,
                            })
                          }
                          value={newPromotionData.amount}
                          type="input"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 promotionNextAddInnerParent">
                  <div>
                    <h4>Stay dates</h4>
                    <h5>When can guests stay using this promotion?</h5>

                    <div>
                      <div className="promotionNextAddInnerChildFlex">
                        <div>
                          Which day(s) of the week would you like to include in
                          this promotion?
                        </div>

                        <div className="flex flex_gap_5">
                          <div>
                            <input
                              checked={newPromotionData.all_conditions.stay_dates.includes(
                                1
                              )}
                              onChange={() => handleStayDates(1)}
                              type="checkbox"
                            />
                            <label htmlFor="">Su</label>
                          </div>
                          <div>
                            <input
                              checked={newPromotionData.all_conditions.stay_dates.includes(
                                2
                              )}
                              onChange={() => handleStayDates(2)}
                              type="checkbox"
                            />
                            <label htmlFor="">Mo</label>
                          </div>
                          <div>
                            <input
                              checked={newPromotionData.all_conditions.stay_dates.includes(
                                3
                              )}
                              onChange={() => handleStayDates(3)}
                              type="checkbox"
                            />
                            <label htmlFor="">Tu</label>
                          </div>

                          <div>
                            <input
                              checked={newPromotionData.all_conditions.stay_dates.includes(
                                4
                              )}
                              onChange={() => handleStayDates(4)}
                              type="checkbox"
                            />
                            <label htmlFor="">We</label>
                          </div>

                          <div>
                            <input
                              checked={newPromotionData.all_conditions.stay_dates.includes(
                                5
                              )}
                              onChange={() => handleStayDates(5)}
                              type="checkbox"
                            />
                            <label htmlFor="">Th</label>
                          </div>

                          <div>
                            <input
                              checked={newPromotionData.all_conditions.stay_dates.includes(
                                6
                              )}
                              onChange={() => handleStayDates(6)}
                              type="checkbox"
                            />
                            <label htmlFor="">Fr</label>
                          </div>

                          <div>
                            <input
                              checked={newPromotionData.all_conditions.stay_dates.includes(
                                7
                              )}
                              onChange={() => handleStayDates(7)}
                              type="checkbox"
                            />
                            <label htmlFor="">Sa</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-5 promotionNextAddInnerParent">
                  <div>
                    <h4>Promotion name</h4>

                    <div>
                      <div className="promotionNextAddInnerChildFlex">
                        <div>
                          <h5>What do you want to name this promotion?</h5>
                          <p>
                            This name is just for you. We won’t show it to
                            customers on Booking.com.
                          </p>
                        </div>
                        <div className="flex flex_gap_5">
                          <input
                            value={
                              newPromotionData?.all_conditions?.promotion_name
                            }
                            onChange={(e) =>
                              setNewPromotionData({
                                ...newPromotionData,
                                all_conditions: {
                                  ...newPromotionData.all_conditions,
                                  promotion_name: e.target.value,
                                },
                              })
                            }
                            //   setNewPromotionData({
                            //     ...newPromotionData,
                            //     name: e.target.value,
                            //   })
                            // }
                            type="input"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </section>
      </div>
      <Footer />
    </>
  );
};

export default VendorPromotionAddNext;
