import React from "react";

const MMSignature = () => {
    return (
        <table
            style={{
                maxWidth: "600px",
                fontFamily: "Inter, sans-serif",
                boxShadow: "none",
                borderRadius: "0",
                overflow: "hidden",
                margin: "0",
            }}

        >
            <tbody>
                <tr>
                    <td colSpan={3}>
                        <table
                            style={{
                                boxShadow: "none",
                                borderRadius: "0",
                                overflow: "hidden",
                                margin: "0",
                            }}
                        >
                            <tbody>
                                <tr>
                                    {/* Logo Section */}
                                    <td style={{ padding: "10px 15px 10px 0", verticalAlign: "top" }}>
                                        <img
                                            src="https://www.mountainmysteries.com/emailsig/logo.png"
                                            alt="Mountain Mysteries"
                                            width="80"
                                            height="80"
                                            style={{
                                                display: "block",
                                                width: "80px",
                                                height: "80px",
                                                objectFit: ' contain'
                                            }}
                                        />
                                    </td>

                                    {/* Social Links and Button Section */}
                                    <td style={{ padding: "10px 15px 10px 0", verticalAlign: "top" }}>
                                        <table
                                            style={{
                                                textAlign: "center",
                                                boxShadow: "none",
                                                borderRadius: "0",
                                                overflow: "hidden",
                                                margin: "0",
                                            }}
                                        >
                                            <tbody>
                                                <tr>
                                                    <td style={{ padding: "0 5px" }}>
                                                        <a
                                                            href="https://www.facebook.com/mountainmysteries.in"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            <img
                                                                src="https://www.mountainmysteries.com/emailsig/facebook.png"
                                                                alt="Facebook"
                                                                width="24"
                                                                height="24"
                                                                style={{
                                                                    display: "inline-block",
                                                                    width: "24px",
                                                                    height: "24px",
                                                                }}
                                                            />
                                                        </a>
                                                    </td>
                                                    <td style={{ padding: "0 5px" }}>
                                                        <a
                                                            href="https://www.instagram.com/mountainmysteries/"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            <img
                                                                src="https://www.mountainmysteries.com/emailsig/instagram.png"
                                                                alt="Instagram"
                                                                width="24"
                                                                height="24"
                                                                style={{
                                                                    display: "inline-block",
                                                                    width: "24px",
                                                                    height: "24px",
                                                                }}
                                                            />
                                                        </a>
                                                    </td>
                                                    <td style={{ padding: "0 5px" }}>
                                                        <a
                                                            href="https://www.youtube.com/channel/UCLCvoKy9UnH6f8nvax44R8w"
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            <img
                                                                src="https://www.mountainmysteries.com/emailsig/youtube.png"
                                                                alt="YouTube"
                                                                width="24"
                                                                height="24"
                                                                style={{
                                                                    display: "inline-block",
                                                                    width: "24px",
                                                                    height: "24px",
                                                                }}
                                                            />
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="3" style={{ paddingTop: "5px", textAlign: 'center' }}>
                                                        <a
                                                            href="https://www.mountainmysteries.com/"
                                                            style={{
                                                                display: "inline-block",
                                                            }}
                                                        >
                                                            <img
                                                                src="/emailsig/button.png"
                                                                alt="Tourism icon"
                                                                width="90"
                                                                height="30"
                                                                style={{
                                                                    objectFit: "contain",
                                                                    height: '30px',
                                                                    width: '90px'
                                                                }}
                                                            />

                                                        </a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>

                                    {/* Contact Info Section */}
                                    <td style={{ verticalAlign: "top", padding: "10px 0 10px 10px ", }}>
                                        <table
                                            style={{
                                                lineHeight: "1.5",
                                                boxShadow: "none",
                                                borderRadius: "0",
                                                overflow: "hidden",
                                                margin: "0",
                                            }}
                                        >
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <img
                                                            src="https://www.mountainmysteries.com/emailsig/call.png"
                                                            alt="Phone"
                                                            style={{
                                                                display: "inline-block",
                                                                verticalAlign: "middle",
                                                                width: "14px",
                                                                height: "14px",
                                                            }}
                                                        />
                                                        <a href="tel:+919805021966">
                                                            <span style={{ color: '#333333', background: 'none', borderRadius: '0', border: 'none', padding: '0 0 0 8px' }}>+91-98050-21966</span>
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <img
                                                            src="https://www.mountainmysteries.com/emailsig/email.png"
                                                            alt="Email"
                                                            style={{
                                                                display: "inline-block",
                                                                verticalAlign: "middle",
                                                                width: "14px",
                                                                height: "14px",
                                                            }}
                                                        />
                                                        <a href="mailto:info@mountainmysteries.com" style={{ color: '#333333' }}>
                                                            <span style={{ color: '#333333', background: 'none', borderRadius: '0', border: 'none', padding: '0 0 0 8px' }}>info@mountainmysteries.com</span>
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <img
                                                            src="https://www.mountainmysteries.com/emailsig/location.png"
                                                            alt="Location"
                                                            style={{
                                                                display: "inline-block",
                                                                verticalAlign: "middle",
                                                                width: "14px",
                                                                height: "14px",
                                                            }}
                                                        />
                                                        <a href="https://maps.app.goo.gl/c7xHUPuDy8LqVLPF9">
                                                            <span style={{ color: '#333333', background: 'none', borderRadius: '0', border: 'none', padding: '0 0 0 8px' }}>  Near Petrol Pump, Vikas Nagar,<br /> Shimla, Himachal Pradesh - 171009</span>
                                                        </a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>

                                <tr>
                                    <td
                                        colSpan={3}
                                        style={{
                                            padding: "10px 0",
                                            borderTop: "0.5px solid #CCCCCC",
                                            borderBottom: "0.5px solid #CCCCCC",
                                        }}
                                    >
                                        <table
                                            style={{
                                                fontFamily: "Inter, sans-serif",
                                                boxShadow: "none",
                                                borderRadius: "0",
                                                overflow: "hidden",
                                                margin: "0",
                                            }}
                                        >
                                            <tr>
                                                <td>
                                                    <table
                                                        style={{
                                                            fontFamily: "Inter, sans-serif",
                                                            boxShadow: "none",
                                                            borderRadius: "0",
                                                            overflow: "hidden",
                                                            margin: "0",
                                                        }}
                                                    >
                                                        <tr>
                                                            <td>GST No: 02AZWPS6433N1ZK</td>
                                                        </tr>
                                                        <tr>
                                                            <td>PAN No: AZWPS6433N</td>
                                                        </tr>
                                                    </table>
                                                </td>
                                                <td>
                                                    <table
                                                        style={{
                                                            fontFamily: "Inter, sans-serif",
                                                            boxShadow: "none",
                                                            borderRadius: "0",
                                                            overflow: "hidden",
                                                            margin: "0",
                                                        }}
                                                    >
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    width: "31px",
                                                                    height: "30px",
                                                                }}
                                                            >
                                                                <img
                                                                    src="https://www.mountainmysteries.com/emailsig/tourism.png"
                                                                    alt="Tourism icon"
                                                                    width="31"
                                                                    height="30"
                                                                    style={{
                                                                        objectFit: "contain",
                                                                        height: '31px',
                                                                        width: '30px'
                                                                    }}
                                                                />
                                                            </td>
                                                            <td
                                                                style={{
                                                                    width: "27px",
                                                                    height: "30px",
                                                                }}
                                                            >
                                                                <img
                                                                    src="https://www.mountainmysteries.com/emailsig/tour.png"
                                                                    alt="Tour icon"
                                                                    width="27"
                                                                    height="30"
                                                                    style={{
                                                                        objectFit: "contain",
                                                                        width: "27px",
                                                                        height: "30px",
                                                                    }}
                                                                />
                                                            </td>
                                                            <td
                                                                style={{
                                                                    width: "57px",
                                                                    height: "30px",
                                                                }}
                                                            >
                                                                <img
                                                                    src="https://www.mountainmysteries.com/emailsig/adtoi.png"
                                                                    alt="Adtoi icon"
                                                                    width="57"
                                                                    height="30"
                                                                    style={{
                                                                        objectFit: "contain",
                                                                    }}
                                                                />
                                                            </td>

                                                            <td
                                                                style={{
                                                                    width: "57px",
                                                                    height: "30px",
                                                                }}
                                                            >
                                                                <img
                                                                    src="https://www.mountainmysteries.com/emailsig/dib.png"
                                                                    alt="Tourism icon"
                                                                    width="57"
                                                                    height="30"
                                                                    style={{
                                                                        objectFit: "contain",
                                                                        width: "57px",
                                                                        height: "30px",
                                                                    }}
                                                                />
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>

                                <tr>
                                    <td
                                        colSpan={3}
                                        style={{ textAlign: "center", padding: "10px 0" }}
                                    >
                                        <table
                                            style={{
                                                width: "300px",
                                                margin: "0 auto",
                                                padding: "10px 0",
                                                fontFamily: "Inter, sans-serif",
                                                boxShadow: "none",
                                                borderRadius: "0",
                                                overflow: "hidden",
                                                textAlign: "center",
                                            }}
                                        >
                                            <tbody>
                                                <tr>
                                                    <td style={{ width: "95px", height: "35px" }}>
                                                        <img
                                                            src="https://www.mountainmysteries.com/emailsig/eco-logo.png"
                                                            alt="Eco Hospitality Logo"
                                                            height="35px"
                                                            width="95px"
                                                            style={{ display: "inline", objectFit: "contain" }}
                                                        />
                                                    </td>
                                                    <td
                                                        style={{
                                                            borderRadius: "0",
                                                            cursor: "default",
                                                            transition: "none",
                                                            fontSize: "12px",
                                                            color: "#222",
                                                            fontWeight: "500",
                                                            border: "none",
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                       &nbsp; SHIMLA &nbsp;|
                                                    </td>
                                                    <td
                                                        style={{
                                                            borderRadius: "0",
                                                            cursor: "default",
                                                            transition: "none",
                                                            fontSize: "12px",
                                                            color: "#222",
                                                            fontWeight: "500",
                                                            border: "none",
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                        &nbsp; DALHOUSIE &nbsp; |
                                                    </td>
                                                    <td
                                                        style={{
                                                            borderRadius: "0",
                                                            cursor: "default",
                                                            transition: "none",
                                                            fontSize: "12px",
                                                            color: "#222",
                                                            fontWeight: "500",
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                        SPITI
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>

                                <tr>
                                    <td
                                        colSpan={3}
                                        style={{ textAlign: "center", padding: "0" }}
                                    >
                                        <a
                                            href="https://mmcrm.in/enchanting-himachal"
                                            style={{ textDecoration: "none", width: "600px" }}
                                        >
                                            <img
                                                src="https://www.mountainmysteries.com/emailsig/offer.png"
                                                alt="Plan Explore Experience"
                                                style={{ width: "100%", maxHeight: "250px" }}
                                            />
                                        </a>
                                    </td>
                                </tr>

                                <tr>
                                    <td colSpan={3} style={{ padding: '10px 0' }}>
                                        <table
                                            style={{
                                                width: "600px",
                                                boxShadow: "none",
                                                borderRadius: "0",
                                                margin: "0",
                                            }}
                                        >
                                            <tr>
                                                <td>
                                                    <table
                                                        style={{
                                                            width: "40px",
                                                            fontFamily: "Inter, sans-serif",
                                                            boxShadow: "none",
                                                            borderRadius: "0",
                                                            overflow: "hidden",
                                                            margin: "0",
                                                        }}
                                                    >
                                                        <tr>
                                                            <td>
                                                                <img
                                                                    src="https://www.mountainmysteries.com/emailsig/tree.png"
                                                                    alt="Tree Icon"
                                                                    width='30'
                                                                    height='30'
                                                                    style={{ width: "30px", height: '30px', objectFit: 'contain' }}
                                                                />
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                                <td
                                                    style={{
                                                        fontSize: "12px",
                                                        fontWeight: "500",
                                                        color: "#333",
                                                        fontFamily: "Inter",
                                                        paddingRight: "10px",
                                                    }}
                                                >
                                                    <table
                                                        style={{
                                                            fontFamily: "Inter, sans-serif",
                                                            boxShadow: "none",
                                                            borderRadius: "0",
                                                            overflow: "hidden",
                                                            margin: "0",
                                                        }}
                                                    >
                                                        <tr>
                                                            <td>
                                                                Please consider your environmental
                                                                responsibility. Before printing this e-mail
                                                                message,<br /> ask yourself whether you really need a
                                                                hard copy.
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>

                                <tr>
                                    <td colSpan={3}>
                                        <table
                                            style={{
                                                width: "600px",
                                                boxShadow: "none",
                                                borderRadius: "0",
                                                margin: "0",
                                            }}
                                        >
                                            <tbody>
                                                <tr>
                                                    <td
                                                        style={{
                                                            fontSize: "12px",
                                                            color: "#666",
                                                        }}
                                                    >
                                                        DISCLAIMER: This email is confidential and intended
                                                        solely for the recipient. Unauthorised use,<br />
                                                        distribution, or copying is prohibited. Views
                                                        expressed are of the sender and not necessarily of<br />
                                                        RevCatalyst. If received in error, please notify the
                                                        sender and delete immediately.
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

export default MMSignature;
