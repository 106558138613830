import React from "react";
import "./NoSlug.css";
import Header from "../Header/Header";
import NoIndexMeta from "../../utils/NoIndexMeta";

const NoSlug = () => {
  return (
    <div>
      <NoIndexMeta />
      <Header />
      <p class="zoom-area">
        {/* <b>CSS</b> animations to make a cool 404 page.{" "} */}
      </p>
      <section class="error-container">
        <span class="four">
          <span class="screen-reader-text">4</span>
        </span>
        <span class="zero">
          <span class="screen-reader-text">0</span>
        </span>
        <span class="four">
          <span class="screen-reader-text">4</span>
        </span>
      </section>
      <section class="error-container-mobile">
        <div className="error-text">404</div>
      </section>
      <div class="link-container">
        <a href="/" class="more-link">
          Visit the Home
        </a>
      </div>
    </div>
  );
};

export default NoSlug;
