import React, { useEffect } from 'react'

export default function ReviewReservationBanner(Props) {
    const scrollToSection = () => {
        const element = document.getElementById('checkoutPage');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
        scrollToSection();
    }, []);

    return (
        <section id="checkoutPage" className="checkoutPage">
            <div className="container">
                <h1 className='text-left'>{Props.heading}</h1>
            </div>
        </section>
    )
}
