import React from "react";
import Slider from "react-slick";
import { testimonialReviews } from "../../constants";

function Testimonial() {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 2000,
    centerMode: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          infinite: true,
          // arrows:true
        },
      },
      {
        breakpoint: 565,
        settings: {
          infinite: true,
          slidesToShow: 1,
          // arrows:true
        },
      },
    ],
  };

  return (
    <section className="">
      <div className="common_padding">
        <h3>Happy Customers</h3>
      </div>
      <div className="testimonial-slider">
        <div className="container">
          <div className="testimonial-main-slide">
            <Slider {...settings}>
              {testimonialReviews.map((items, index) => {
                return (
                  <div className="testimonial" key={items.id}>
                    <div className="flex flex_gap_10 flex_direction_col">
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.73898 24.745C7.6312 24.745 6.7235 24.515 6.02077 24.05C5.31316 23.585 4.73731 22.995 4.28346 22.27C3.78081 21.395 3.43921 20.48 3.26352 19.525C3.08784 18.57 3 17.785 3 17.165C3 14.64 3.62953 12.345 4.88859 10.28C6.14765 8.215 8.10945 6.54 10.7789 5.25L11.4572 6.645C9.89556 7.315 8.54866 8.375 7.41648 9.815C6.2843 11.26 5.71821 12.73 5.71821 14.225C5.71821 14.845 5.79141 15.385 5.94269 15.85C6.7479 15.18 7.68 14.845 8.7341 14.845C10.042 14.845 11.1741 15.285 12.1306 16.16C13.0871 17.035 13.5654 18.25 13.5654 19.795C13.5654 21.24 13.0871 22.425 12.1306 23.355C11.1741 24.285 10.042 24.75 8.7341 24.75L8.73898 24.745ZM21.5736 24.745C20.4658 24.745 19.5581 24.515 18.8554 24.05C18.1478 23.585 17.5719 22.995 17.1181 22.27C16.6154 21.395 16.2738 20.48 16.0981 19.525C15.9225 18.57 15.8346 17.785 15.8346 17.165C15.8346 14.64 16.4642 12.345 17.7232 10.28C18.9823 8.22 20.9441 6.54 23.6135 5.25L24.2918 6.645C22.7302 7.315 21.3833 8.375 20.2511 9.815C19.1189 11.26 18.5528 12.73 18.5528 14.225C18.5528 14.845 18.6309 15.385 18.7773 15.85C19.5825 15.18 20.5146 14.845 21.5687 14.845C22.8766 14.845 24.0088 15.285 24.9653 16.16C25.9218 17.035 26.4 18.25 26.4 19.795C26.4 21.24 25.9218 22.425 24.9653 23.355C24.0088 24.285 22.8766 24.75 21.5687 24.75L21.5736 24.745Z"
                          fill="#009A50"
                        />
                      </svg>

                      <i className="text-left font_12">{items.text}</i>
                    </div>
                    <div className="flex flex_gap_5 align_items_center w-100 border-top pt_10">
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <mask
                          id="mask0_5012_6628"
                          style={{ maskType: "alpha" }}
                          maskUnits="userSpaceOnUse"
                          x="0"
                          y="0"
                          width="32"
                          height="32"
                        >
                          <rect
                            width="31.7647"
                            height="31.7647"
                            fill="#D9D9D9"
                          />
                        </mask>
                        <g mask="url(#mask0_5012_6628)">
                          <path
                            d="M7.74256 22.6324C8.86756 21.7721 10.1249 21.0938 11.5146 20.5975C12.9043 20.1011 14.3602 19.853 15.8823 19.853C17.4043 19.853 18.8602 20.1011 20.2499 20.5975C21.6396 21.0938 22.897 21.7721 24.022 22.6324C24.794 21.728 25.3951 20.7022 25.8253 19.5552C26.2554 18.4081 26.4705 17.1839 26.4705 15.8824C26.4705 12.9486 25.4393 10.4504 23.3768 8.3879C21.3143 6.3254 18.8161 5.29415 15.8823 5.29415C12.9484 5.29415 10.4503 6.3254 8.38778 8.3879C6.32528 10.4504 5.29403 12.9486 5.29403 15.8824C5.29403 17.1839 5.5091 18.4081 5.93925 19.5552C6.3694 20.7022 6.9705 21.728 7.74256 22.6324ZM15.8823 17.2059C14.5808 17.2059 13.4834 16.7592 12.59 15.8658C11.6966 14.9725 11.2499 13.875 11.2499 12.5736C11.2499 11.2721 11.6966 10.1747 12.59 9.28129C13.4834 8.3879 14.5808 7.94121 15.8823 7.94121C17.1837 7.94121 18.2812 8.3879 19.1745 9.28129C20.0679 10.1747 20.5146 11.2721 20.5146 12.5736C20.5146 13.875 20.0679 14.9725 19.1745 15.8658C18.2812 16.7592 17.1837 17.2059 15.8823 17.2059ZM15.8823 29.1177C14.0514 29.1177 12.3308 28.7703 10.7205 28.0754C9.11021 27.3806 7.70947 26.4375 6.5183 25.2464C5.32712 24.0552 4.38411 22.6544 3.68925 21.0442C2.9944 19.4339 2.64697 17.7133 2.64697 15.8824C2.64697 14.0515 2.9944 12.3309 3.68925 10.7206C4.38411 9.11033 5.32712 7.7096 6.5183 6.51842C7.70947 5.32724 9.11021 4.38423 10.7205 3.68937C12.3308 2.99452 14.0514 2.64709 15.8823 2.64709C17.7132 2.64709 19.4337 2.99452 21.044 3.68937C22.6543 4.38423 24.0551 5.32724 25.2462 6.51842C26.4374 7.7096 27.3804 9.11033 28.0753 10.7206C28.7701 12.3309 29.1176 14.0515 29.1176 15.8824C29.1176 17.7133 28.7701 19.4339 28.0753 21.0442C27.3804 22.6544 26.4374 24.0552 25.2462 25.2464C24.0551 26.4375 22.6543 27.3806 21.044 28.0754C19.4337 28.7703 17.7132 29.1177 15.8823 29.1177Z"
                            fill="#009A50"
                          />
                        </g>
                      </svg>
                      <h6>{items.name}</h6>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Testimonial;
