import React from "react";

function NoDataFound() {
    return (
        <section className="m_0_auto bg-white">
            <div className="dataFound text_center">
                <h3>No Data Found</h3>
                {/* <p>Try changing the above filters.</p> */}
            </div>
        </section>
    )
}

export default NoDataFound;