import React, { useEffect, useState } from "react";
import sightseeingImg from "../../assets/images/Sightseeing.jpg";
import { Link } from "react-router-dom";
import StarRating from "../StarRating/StarRating";
import carImg from "../../assets/images/car.png";
import { DisabledTextButton } from "../Buttons/Buttons";
import CustomSlider from "../CustomSlider/CustomSlider";
import sightseeingnImg from "../../assets/images/Sightseeing.jpg";
import CustomSliderTaxi from "../CustomSliderTaxi/CustomSliderTaxi";
import {
  calculateToDatePlus,
  textDateFormatter,
  textDateFormatterNoAddInIteration,
} from "../../utils/utils";
import { vehicleDetails as vehicleDetailsData } from "../../constants";
import NewCustomSliderTaxi from "../CustomSliderTaxi/NewCustomSliderTaxi";
import NewCustomSlider from "../CustomSlider/NewCustomSlider";
import "./NewCustomizableAccommodation.css";
import NewCustomSliderActivities from "../CustomSliderTaxi/NewCustomSliderActivities";
import moment from "moment";

const NewCustomizableAccommodation = ({
  singlePackageData,
  setSelectedCategory,
  selectedCategory,
  getHotelDayByDay,
  getAccommodationDetails,
  setIsCustomizedClick,
  setCustomizableRatesLoader,
  setCalculationData,
  packageStartDate,
  bookingData,
  ratesOfPackages,
  fetchTransportCost,
  vehicleDetails,

  isVehicleCustomizedClick,
  setIsVehicleCustomizedClick,
  fetchTransportsArray,

  allVehicles,
  changeSelectedVehicle,

  calculateSingleHotelDetails,
  fetchMelPlanPrice,
  daySelected,
  setDaySelected,
  hotelDayByDay,
  setHotelDayByDay,

  fetchCostOfVehicle,
  setOldVehicleCost,
  setNewVehicleCost,
  setVehicleDetails,
  setMinusTransportationCost,

  setBasePriceOfPackage,
  basePriceOfPackage,
  setRatesOfPackages,

  setRemovedVehicle,
  calculationsOfCustomizedPackage,

  isAddActivityClick,
  setIsAddActivityClick,
  fetchActivitiesArray,
  changeSelectedActivity,
  setAddActivityIdClick,
  addActivityIdClick,
  handleRemoveActivity,
  allActivities,
}) => {
  const [isSliderVisible, setIsSliderVisible] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  // const

  const [loader, setLoader] = useState(false);
  const [expandedCard, setExpandedCard] = useState(0); // Initially expand the first card
  const [selectedCard, setSelectedCard] = useState(0); // Initially select the first card
  const [selectedHotelId, setSelectedHotelId] = useState(
    JSON.parse(localStorage.getItem("selectedHotel")) || null
  );
  const [hotelData, setHotelData] = useState(null);
  const [selectedHotelPrice, setSelectedHotelPrice] = useState(null);
  const [data, setData] = useState([]);

  const storedData = JSON.parse(localStorage.getItem("selected"));
  const selectedHotel = JSON.parse(localStorage.getItem("selectedHotel"));
  const selectedRoomRate = JSON.parse(
    localStorage.getItem("selectedRoomRates")
  );

  // console.log("hotelDayByDay", hotelDayByDay);

  //   useEffect(() => {
  //     if (storedData) {
  //       setData(selectedHotelId);

  //     //   setSelectedHotelId(selectedHotel)
  //     }
  //   }, [localStorage, selectedRoomRate]);

  // const updateHotelNameForSelectedDay = (hotel, roomRates) => {

  //   // Ensure hotelDayByDay and data are defined
  //   if (hotelDayByDay && hotelDayByDay.length > 0 && data) {
  //     const selectedDay = hotelDayByDay.find(
  //       (day) => day.dayCount === daySelected
  //     );

  //     if (selectedDay) {
  //       setHotelDayByDay((prev) => {
  //         const updatedData = [...prev];
  //         const dayIndex = updatedData.findIndex(
  //           (day) => day.dayCount === daySelected
  //         );

  //         if (dayIndex !== -1) {
  //           // Update hotel name for the selected day
  //           updatedData[dayIndex] = {
  //             ...updatedData[dayIndex],
  //             accommodationName: hotel?.hotel_name,
  //             accommodationId: hotel?.hotel_id,
  //             accommodationStarRating: hotel?.hotel_rating,
  //             //   accomodationMealPlan: [hotel?.meal_plan],
  //             accomodationCity: hotel?.hotel_city,
  //             accommodationImage: hotel?.hotel_image[0],
  //           };
  //         }

  //         return updatedData;
  //       });
  //     }
  //   }

  //   getAccommodationDetails(hotelDayByDay).then(accommodationDetails => {

  // });
  // };

  function getDatesInArray(startDate, numberOfDays) {
    const resultArray = [];
    let currentDate = new Date(startDate);

    for (let i = 0; i < numberOfDays; i++) {
      resultArray.push(currentDate.toISOString().split("T")[0]);
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return resultArray;
  }

  // const departureDate =
  const departureDate = `${bookingData.departureYear}-${String(
    bookingData.departureMonth
  ).padStart(2, "0")}-${String(bookingData.departureDay).padStart(2, "0")}`;

  const resultDateArray = getDatesInArray(departureDate, 40);

  const updateHotelNameForSelectedDay = (hotel, roomRates, roomCategory) => {
    // Ensure hotelDayByDay and data are defined
    if (hotelDayByDay && hotelDayByDay?.length > 0 && data) {
      const selectedDay = hotelDayByDay.find(
        (day) => day.dayCount === daySelected
      );

      if (selectedDay) {
        // Create a copy of hotelDayByDay and update the selected day's data
        const updatedHotelDayByDay = hotelDayByDay?.map((day) => {
          if (day.accommodationId === selectedDay.accommodationId) {
            return {
              ...day,
              ...data,
              accommodationName: hotel?.hotel_name,
              accommodationId: hotel?.hotel_id,
              accommodationStarRating: hotel?.hotel_rating,
              accommodationCity: hotel?.hotel_city,
              accommodationImage: hotel?.hotel_image[0],
            };
          }
          return day;
        });

        // Update the state with the modified array
        // setHotelDayByDay(updatedHotelDayByDay);

        calculateSingleHotelDetails(
          hotel,
          roomRates,
          updatedHotelDayByDay,
          selectedDay,
          roomCategory
        );
      }
    }
  };

  const calculateDataDayByDay = () => {
    getAccommodationDetails(hotelDayByDay)
      .then((accommodationDetails) => {
        const calculateBookingValues = (
          roomRate,
          bookingDiscount,
          bookingDiscountType,
          agentDiscount,
          agentDiscountType,
          childPricesArray,
          noOfNights,
          noOfRooms,
          extraAdult,
          extraAdultPrice
        ) => {
          let roomRateParsed = parseFloat(roomRate) * +noOfNights * +noOfRooms;
          if (isNaN(roomRateParsed)) {
            roomRateParsed = 0;
          }

          // let taxSlab = +roomRate > 7500 ? 18 : 12;
          let taxSlab = 0;

          let bookingDiscountAmount = 0;
          if (bookingDiscountType === "percent") {
            bookingDiscountAmount = (roomRateParsed * bookingDiscount) / 100;
          } else {
            bookingDiscountAmount = parseFloat(bookingDiscount);
            if (isNaN(bookingDiscountAmount)) {
              bookingDiscountAmount = 0;
            }
          }

          let agentDiscountAmount = 0;
          if (agentDiscountType === "percent") {
            agentDiscountAmount =
              ((roomRateParsed - bookingDiscountAmount) * agentDiscount) / 100;
          } else {
            agentDiscountAmount = parseFloat(agentDiscount);
            if (isNaN(agentDiscountAmount)) {
              agentDiscountAmount = 0;
            }
          }

          let totalDiscountAmount = bookingDiscountAmount + agentDiscountAmount;

          let childPrices = childPricesArray.reduce(
            (accumulator, currentPrice) => {
              const parsedPrice = parseFloat(currentPrice);
              return accumulator + (isNaN(parsedPrice) ? 0 : parsedPrice);
            },
            0
          );

          let extraAdultPrices = +extraAdult * +extraAdultPrice * +noOfNights;
          if (isNaN(extraAdultPrice)) {
            extraAdultPrices = 0;
          }
          const subtotal =
            roomRateParsed -
            totalDiscountAmount +
            +childPrices * +noOfNights +
            extraAdultPrices;
          const taxAmount = (subtotal * taxSlab) / 100;
          const grandTotal = subtotal + taxAmount;

          return {
            taxSlab,
            subtotal,
            taxAmount,
            grandTotal,
            discountAmt: totalDiscountAmount,
            totalChildAdultCost: childPrices * +noOfNights,
            childPrices,
            bookingDiscountAmount,
            agentDiscountAmount,
            roomRate,

            extraAdultCount: extraAdult,
            extraAdultCost: extraAdultPrice,

            childCount: childPricesArray.length,
          };
        };

        const calculateTotalArrayObjectKey = (array, property) => {
          if (!Array.isArray(array)) {
            return 0;
          }
          return array.reduce((total, item) => {
            const value = parseFloat(item[property]) || 0;
            return total + value;
          }, 0);
        };

        // Parameters for the calculation
        const bookingDiscount = 0; // example discount
        const bookingDiscountType = "percent"; // example type
        const agentDiscount = 0; // example agent discount
        const agentDiscountType = "percent"; // example type
        const noOfNights = 1; // example nights
        const extraAdultPrice = 500; // example extra adult price
        // const noOfRooms = 1;

        // const extraAdultCount

        function getRoomsExtraAdults(guests = 0, childAgeArray = []) {
          const allowedAdultsPerRoom = 2;
          let totalAdults = guests;

          childAgeArray.forEach((age) => {
            if (Number(age) > 5) {
              totalAdults++;
            }
          });

          let extraAdults = totalAdults % allowedAdultsPerRoom;
          const noOfRooms = Math.floor(totalAdults / allowedAdultsPerRoom);

          return { extraAdults, noOfRooms };
        }

        const { extraAdults, noOfRooms } = getRoomsExtraAdults(
          bookingData.guest,
          bookingData.child_age
        );

        const rates_array =
          accommodationDetails &&
          accommodationDetails.map((room, index) => {
            const bookingValues = calculateBookingValues(
              room.rate_per_night,
              bookingDiscount,
              bookingDiscountType,
              agentDiscount,
              agentDiscountType,
              room.child_price,
              1,
              noOfRooms,
              extraAdults,
              1000
            );

            return { ...bookingValues, meal_plan_id: room.rate_plan_id };
          });

        const total_rates = {
          subtotal: calculateTotalArrayObjectKey(rates_array, "subtotal"),
          total_discount: calculateTotalArrayObjectKey(
            rates_array,
            "discountAmt"
          ),
          total_grand_total: calculateTotalArrayObjectKey(
            rates_array,
            "grandTotal"
          ),
          total_tax_amount: calculateTotalArrayObjectKey(
            rates_array,
            "taxAmount"
          ),
          totalChildCost: calculateTotalArrayObjectKey(
            rates_array,
            "childPrices"
          ),
        };

        setCalculationData({ total_rates, rates_array });
      })
      .then((data) => {
        setIsCustomizedClick(true);
        setCustomizableRatesLoader(false);
      });
  };

  // calculations

  useEffect(() => {
    // if (selectedHotelId && daySelected && selectedHotel) {
    getHotelDayByDay(hotelDayByDay);
    if (vehicleDetails) {
      // fetchTransportCost(vehicleDetails[0]?.Vehicle);
    }
    // }
  }, [hotelDayByDay]);

  //   useEffect(() => {
  //     if (selectedHotelId && daySelected && selectedHotel) {
  //       updateHotelNameForSelectedDay();
  //     }
  //   }, [selectedHotelId, daySelected,]);

  const handleCloseSlider = () => {
    setIsSliderVisible(false);
    setIsSliderOpen(false);
    setIsVehicleCustomizedClick(false);
    setIsAddActivityClick(false);
  };

  const fetchItineraryData = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("key", process.env.REACT_APP_API_KEY);

    const formdata = new FormData();
    formdata.append("packageId", singlePackageData?.packageId);
    formdata.append("type", "standard");

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_APP_URL}/api/v1/holidays/fetch/hotelbyday`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setHotelDayByDay(result?.data);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    if (singlePackageData?.packageId && selectedCategory) {
      fetchItineraryData(singlePackageData?.packageId, selectedCategory);
    }
  }, [singlePackageData?.packageId, selectedCategory]);

  const fetchHotelData = async (location) => {
    setLoader(true);
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("key", process.env.REACT_APP_API_KEY);

    const data = {
      //   hotelId: 1,
      //   location: "shimla",
      //   room: 1,
      //   adult: 1,
      //   children: 1,
      //   childAge: [7],
      //   checkinDay: 28,
      //   checkinMonth: 7,
      //   checkinYear: 2024,
      //   checkoutDay: 29,
      //   checkoutMonth: 7,
      //   checkoutYear: 2024,
      //   radio: "Hotel",
    };

    const checkInDate = `${data.checkinYear}-${String(
      data.checkinMonth
    ).padStart(2, "0")}-${String(data.checkinDay).padStart(2, "0")}`;
    const checkOutDate = `${data.checkoutYear}-${String(
      data.checkoutMonth
    ).padStart(2, "0")}-${String(data.checkoutDay).padStart(2, "0")}`;

    const formdata = new FormData();
    // formdata.append("checkIn", checkInDate);
    // formdata.append("checkOut", checkOutDate);
    // formdata.append("adults", data.adult);
    // formdata.append("child", data.children);
    formdata.append("location", location);
    // formdata.append("rooms", data.room);
    // formdata.append("PropertyType", "Hotel");

    // Append childAge values dynamically
    if (data.children > 0) {
      for (let i = 1; i <= data.children; i++) {
        formdata.append(`childAge[${i - 1}]`, data.childAge[i - 1]);
      }
    }

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_APP_URL}/api/v1/hotel-engine/check-availability`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      //   localStorage.setItem("token", result.data.token);
      setHotelData(result.data.properties);
      setIsSliderVisible(true);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    } finally {
      setLoader(false);
    }
  };

  const toggleCard = (index) => {
    if (expandedCard === index) {
      setExpandedCard(null); // Collapse the card if it's already open
    } else {
      setExpandedCard(index); // Expand the clicked card
    }
  };

  const toggleSelect = (index) => {
    setSelectedCard(index); // Toggle the selected card
  };

  const groupByCategory = (accommodations) => {
    return accommodations?.reduce((acc, accommodation) => {
      const category = accommodation.accommodationCategory;
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(accommodation);
      return acc;
    }, {});
  };

  const handleChangeClick = async (
    hotelId,
    location,
    dayCount,
    mealPlan,
    index,
    roomId
  ) => {
    setCustomizableRatesLoader(true);

    // fetchHotelData
    const departureDate = `${bookingData.departureYear}-${String(
      bookingData.departureMonth
    ).padStart(2, "0")}-${String(bookingData.departureDay).padStart(2, "0")}`;

    const resultDateArray = getDatesInArray(departureDate, 40);

    setLoader(false);
    setDaySelected(dayCount);
    setSelectedHotelId(hotelId);
    // setSelectedHotelPrice(1000);

    await fetchMelPlanPrice(
      bookingData,
      hotelId,
      mealPlan,
      resultDateArray[dayCount],
      resultDateArray[dayCount + 1],
      dayCount,
      roomId
    );
    await fetchHotelData(location);
  };

  const [isSliderOpen, setIsSliderOpen] = useState(false);
  const handleChangeTaxiClick = async () => {
    setLoader(false);

    fetchTransportsArray();
    fetchCostOfVehicle(vehicle?.Vehicle);

    // setCustomizableRatesLoader(true);

    // setLoader(false);
    // setDaySelected(dayCount);
    // setSelectedHotelId(hotelId);
    // setSelectedHotelPrice(1000);
    // await fetchHotelData(location);
  };
  const handleAddActivityClick = async (index, location) => {
    setLoader(false);
    setAddActivityIdClick(index);
    const lowerCaseLocation = location.toLowerCase();
    fetchActivitiesArray(index, lowerCaseLocation);
    // fetchCostOfVehicle(vehicle?.Vehicle);

    // setCustomizableRatesLoader(true);

    // setLoader(false);
    // setDaySelected(dayCount);
    // setSelectedHotelId(hotelId);
    // setSelectedHotelPrice(1000);
    // await fetchHotelData(location);
  };
  const handleAddTaxi = async () => {
    setLoader(false);

    fetchTransportsArray();
    fetchCostOfVehicle(vehicle?.Vehicle);

    // setCustomizableRatesLoader(true);

    // setLoader(false);
    // setDaySelected(dayCount);
    // setSelectedHotelId(hotelId);
    // setSelectedHotelPrice(1000);
    // await fetchHotelData(location);
  };

  const vehicle = vehicleDetails && vehicleDetails[0];

  const handleRemoveVehicle = (vehicle) => {
    setRemovedVehicle(vehicle);
    setNewVehicleCost(0);
    setOldVehicleCost(0);
    setVehicleDetails([]);

    if (calculationsOfCustomizedPackage?.transportationCost) {
      // setMinusTransportationCost(vehicle.Price  );
    } else {
      setMinusTransportationCost(vehicle.Price);
    }

    // setBasePriceOfPackage(basePriceOfPackage - (vehicle.Price / bookingData?.guest));
    setRatesOfPackages((prev) => {
      return {
        ...prev,
        // Price: prev?.Price - (vehicle.Price / bookingData?.guest),
      };
    });
    // setVehicleDetails([]);
  };

  const handleIncrease = (activityId) => {
    setHotelDayByDay((prevActivities) =>
      prevActivities.map((day) => ({
        ...day,
        activity: day?.activity?.map((activity) =>
          activity.id === activityId
            ? {
                ...activity,
                guest: Math.min(
                  activity.guest + 1,
                  Number(bookingData?.guest) + Number(bookingData?.children)
                ),
              }
            : activity
        ),
      }))
    );
  };

  const handleDecrease = (activityId) => {
    setHotelDayByDay((prevActivities) =>
      prevActivities.map((day) => ({
        ...day,
        activity: day?.activity?.map((activity) =>
          activity.id === activityId
            ? { ...activity, guest: Math.max(activity.guest - 1, 1) }
            : activity
        ),
      }))
    );
  };

  return (
    <div>
      <div className="colored_bg">
        <div style={{ padding: "10px" }}>
          <div className="agent">
            <small>
              Package Destinations -{" "}
              {singlePackageData?.packageDestination?.map((dest, index) => (
                <span key={index}>{dest.title}, </span>
              ))}
              {singlePackageData?.hotelName}
            </small>
            <div className="flex align_items_center flex_gap_10">
              {vehicleDetails && vehicleDetails.length > 0 ? (
                <>
                  {" "}
                  <DisabledTextButton
                    loader={loader}
                    textColor="#016937"
                    onClick={() => handleChangeTaxiClick()}
                  >
                    Change
                  </DisabledTextButton>
                  <DisabledTextButton
                    loader={loader}
                    textColor="#016937"
                    onClick={() => handleRemoveVehicle(vehicle)}
                    style={{ color: "red" }}
                  >
                    Remove
                  </DisabledTextButton>
                </>
              ) : (
                <>
                  {" "}
                  <DisabledTextButton
                    loader={loader}
                    textColor="#016937"
                    onClick={() => handleAddTaxi()}
                  >
                    Add
                  </DisabledTextButton>
                </>
              )}
            </div>
          </div>

          {vehicleDetails && vehicleDetails.length > 0 ? (
            <div className="hotel-data">
              <div className="hotel_img flex align_items_center justify_content_center">
                {
                  vehicleDetailsData.find(
                    (item) => item.name == vehicle?.Vehicle
                  )?.image
                }
              </div>
              <div className="hotel-text">
                <h6>
                  <b>{vehicle?.Vehicle}</b>
                </h6>
                <small>Private Vehicle</small>
                {/* <p>
                <small>Facilities: </small>3 seater | AC | 0 luggage bags | Self
                Drive
              </p> */}
              </div>
            </div>
          ) : (
            <div>No Vehicle Added</div>
          )}
        </div>

        {hotelDayByDay.length > 0 ? (
          hotelDayByDay?.map((item, index) => (
            <div key={index}>
              <div className="fixed_bg">
                <div className="agent">
                  <p>
                    {/* Day {item?.dayCount} -  */}
                    {
                      resultDateArray &&
                        resultDateArray.length > 0 &&
                        resultDateArray[index] &&
                        moment(resultDateArray[index]).format("MMM Do YYYY")

                      // textDateFormatterNoAddInIteration(
                      //   resultDateArray[index]
                      // )
                    }{" "}
                    - {item?.startPoint} To {item?.endPoint}
                  </p>
                  <div className="flex flex_gap_10">
                    <span>Included: 1 Hotel</span>
                    {item?.activity?.length ? (
                      <span className="text-white">
                        Included: {item?.activity?.length} Activity
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="content-consider">
                {item?.accommodationId ? (
                  <>
                    <div className="hotel-data">
                      <div className="hotel_img customise_accommodation_img">
                        <img src={item?.accommodationImage} alt="" />
                      </div>
                      <div className="hotel-text">
                        <h6>
                          <b>{item?.accommodationName}</b>
                        </h6>
                        <StarRating
                          value={
                            item?.accommodationStarRating
                              ? Number(item?.accommodationStarRating)
                              : 0
                          }
                        />
                        <small>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M4.83301 6.66667C4.83301 5.82681 5.16664 5.02136 5.7605 4.4275C6.35437 3.83363 7.15982 3.5 7.99967 3.5C8.83953 3.5 9.64498 3.83363 10.2388 4.4275C10.8327 5.02136 11.1663 5.82681 11.1663 6.66667C11.1663 7.50652 10.8327 8.31197 10.2388 8.90584C9.64498 9.4997 8.83953 9.83333 7.99967 9.83333C7.15982 9.83333 6.35437 9.4997 5.7605 8.90584C5.16664 8.31197 4.83301 7.50652 4.83301 6.66667ZM7.99967 4.5C7.42504 4.5 6.87394 4.72827 6.46761 5.1346C6.06128 5.54093 5.83301 6.09203 5.83301 6.66667C5.83301 7.2413 6.06128 7.7924 6.46761 8.19873C6.87394 8.60506 7.42504 8.83333 7.99967 8.83333C8.57431 8.83333 9.12541 8.60506 9.53174 8.19873C9.93807 7.7924 10.1663 7.2413 10.1663 6.66667C10.1663 6.09203 9.93807 5.54093 9.53174 5.1346C9.12541 4.72827 8.57431 4.5 7.99967 4.5Z"
                              fill="#888888"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M2.34926 5.90471C2.46321 4.52258 3.09274 3.23383 4.11287 2.29436C5.13299 1.35488 6.4691 0.83337 7.85593 0.833374H8.14393C9.53075 0.83337 10.8669 1.35488 11.887 2.29436C12.9071 3.23383 13.5366 4.52258 13.6506 5.90471C13.7774 7.44423 13.3018 8.97288 12.3239 10.1687L9.12859 14.076C8.99185 14.2433 8.81965 14.378 8.62445 14.4705C8.42926 14.5631 8.21594 14.6111 7.99993 14.6111C7.78391 14.6111 7.57059 14.5631 7.3754 14.4705C7.1802 14.378 7.00801 14.2433 6.87126 14.076L3.67593 10.1687C2.698 8.97292 2.22238 7.44424 2.34926 5.90471ZM7.85593 1.83337C6.72025 1.83355 5.62615 2.26073 4.79081 3.03012C3.95546 3.79952 3.43995 4.85487 3.34659 5.98671C3.24099 7.26795 3.63678 8.54016 4.45059 9.53537L7.64593 13.4434C7.68888 13.4959 7.74298 13.5383 7.80432 13.5674C7.86566 13.5965 7.9327 13.6116 8.00059 13.6116C8.06848 13.6116 8.13552 13.5965 8.19686 13.5674C8.2582 13.5383 8.3123 13.4959 8.35526 13.4434L11.5506 9.53537C12.3639 8.53997 12.7592 7.26777 12.6533 5.98671C12.5599 4.85476 12.0443 3.79931 11.2088 3.02989C10.3733 2.26048 9.27905 1.83338 8.14326 1.83337H7.85526H7.85593Z"
                              fill="#888888"
                            />
                          </svg>{" "}
                          {item?.accomodationCity}
                        </small>{" "}
                        <br />
                        {/* <small>
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_2810_11970)">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M10.3744 1.33366H8.91606V0.750326C8.91606 0.428159 8.65489 0.166992 8.33272 0.166992C8.01056 0.166992 7.74939 0.428159 7.74939 0.750326V1.33366H3.66606V0.750326C3.66606 0.428159 3.40489 0.166992 3.08272 0.166992C2.76056 0.166992 2.49939 0.428159 2.49939 0.750326V1.33366H1.62439C1.15772 1.33366 0.74939 1.74199 0.74939 2.20866V10.9587C0.74939 11.4253 1.15772 11.8337 1.62439 11.8337H10.3744C10.8411 11.8337 11.2494 11.4253 11.2494 10.9587V2.20866C11.2494 1.74199 10.8411 1.33366 10.3744 1.33366ZM1.91606 10.667H10.0827V4.83366H1.91606V10.667Z"
                            fill="#888888"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_2810_11970">
                            <rect
                              width="11.6667"
                              height="11.6667"
                              fill="white"
                              transform="translate(0.166016 0.166992)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      Tue, 24 Oct 2023 - Fri, 27 Oct 2023{" "}
                    </small> */}
                        <div>
                          <small>Category : {item?.accomodationRoomName}</small>
                        </div>
                        <div>
                          <small>
                            Meal Plan :{" "}
                            {item?.accomodationMealPlan
                              ?.map((meal) => meal?.toUpperCase())
                              .join(", ")}
                          </small>
                        </div>
                      </div>

                      <DisabledTextButton
                        loader={loader}
                        textColor="#016937"
                        onClick={() =>
                          handleChangeClick(
                            item?.accommodationId,
                            item?.endPoint,
                            item?.dayCount,
                            item?.accomodationMealPlan,
                            index,
                            item.accomodationRoomId
                          )
                        }
                      >
                        Change
                      </DisabledTextButton>
                    </div>

                    {item?.activity?.length > 0 && (
                      <>
                        <hr />
                        <div className="activityDayByDayParent">
                          <div></div>
                          {item?.activity?.map((activity, idx) => {
                            return (
                              <div key={idx}>
                                {/* <h6> */}
                                {/* </h6> */}
                                <small>{activity?.name}</small>
                                <div className="activity-data">
                                  <div className="activity_img">
                                    <img
                                      src={
                                        activity?.image
                                          ? activity?.image
                                          : "https://st4.depositphotos.com/14953852/24787/v/450/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg"
                                      }
                                      alt=""
                                    />
                                  </div>
                                  <div className="hotel-text">
                                    <h6>
                                      <b>{activity?.name}</b>
                                    </h6>
                                    <small>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M4.83301 6.66667C4.83301 5.82681 5.16664 5.02136 5.7605 4.4275C6.35437 3.83363 7.15982 3.5 7.99967 3.5C8.83953 3.5 9.64498 3.83363 10.2388 4.4275C10.8327 5.02136 11.1663 5.82681 11.1663 6.66667C11.1663 7.50652 10.8327 8.31197 10.2388 8.90584C9.64498 9.4997 8.83953 9.83333 7.99967 9.83333C7.15982 9.83333 6.35437 9.4997 5.7605 8.90584C5.16664 8.31197 4.83301 7.50652 4.83301 6.66667ZM7.99967 4.5C7.42504 4.5 6.87394 4.72827 6.46761 5.1346C6.06128 5.54093 5.83301 6.09203 5.83301 6.66667C5.83301 7.2413 6.06128 7.7924 6.46761 8.19873C6.87394 8.60506 7.42504 8.83333 7.99967 8.83333C8.57431 8.83333 9.12541 8.60506 9.53174 8.19873C9.93807 7.7924 10.1663 7.2413 10.1663 6.66667C10.1663 6.09203 9.93807 5.54093 9.53174 5.1346C9.12541 4.72827 8.57431 4.5 7.99967 4.5Z"
                                          fill="#888888"
                                        />
                                        <path
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M2.34926 5.90471C2.46321 4.52258 3.09274 3.23383 4.11287 2.29436C5.13299 1.35488 6.4691 0.83337 7.85593 0.833374H8.14393C9.53075 0.83337 10.8669 1.35488 11.887 2.29436C12.9071 3.23383 13.5366 4.52258 13.6506 5.90471C13.7774 7.44423 13.3018 8.97288 12.3239 10.1687L9.12859 14.076C8.99185 14.2433 8.81965 14.378 8.62445 14.4705C8.42926 14.5631 8.21594 14.6111 7.99993 14.6111C7.78391 14.6111 7.57059 14.5631 7.3754 14.4705C7.1802 14.378 7.00801 14.2433 6.87126 14.076L3.67593 10.1687C2.698 8.97292 2.22238 7.44424 2.34926 5.90471ZM7.85593 1.83337C6.72025 1.83355 5.62615 2.26073 4.79081 3.03012C3.95546 3.79952 3.43995 4.85487 3.34659 5.98671C3.24099 7.26795 3.63678 8.54016 4.45059 9.53537L7.64593 13.4434C7.68888 13.4959 7.74298 13.5383 7.80432 13.5674C7.86566 13.5965 7.9327 13.6116 8.00059 13.6116C8.06848 13.6116 8.13552 13.5965 8.19686 13.5674C8.2582 13.5383 8.3123 13.4959 8.35526 13.4434L11.5506 9.53537C12.3639 8.53997 12.7592 7.26777 12.6533 5.98671C12.5599 4.85476 12.0443 3.79931 11.2088 3.02989C10.3733 2.26048 9.27905 1.83338 8.14326 1.83337H7.85526H7.85593Z"
                                          fill="#888888"
                                        />
                                      </svg>{" "}
                                      {item?.accomodationCity}
                                    </small>{" "}
                                    <br />
                                    <div>
                                      <small> {activity?.content}</small>
                                    </div>
                                    {/* <div>
                          <small>
                            Meal Plan :{" "}
                            {item?.accomodationMealPlan
                              ?.map((meal) => meal?.toUpperCase())
                              .join(", ")}
                          </small>
                        </div> */}
                                  </div>

                                  <div>
                                    <div
                                      style={{ left: "0px" }}
                                      className="activities-option-counter"
                                      id=""
                                    >
                                      <div className="agent">
                                        {/* <span> Children{" "}</span>{"  "} */}
                                        <div className="flex">
                                          <button
                                            type="button"
                                            disabled={activity.guest <= 1}
                                            onClick={() =>
                                              handleDecrease(activity.id)
                                            }
                                            className="optionCounterButton optionCounterButtonWithBorder"
                                          >
                                            <span
                                              className="onclick_btn"
                                              id="adult_minus"
                                            >
                                              -
                                            </span>
                                          </button>

                                          <input
                                            className="optionCounterNumber"
                                            value={activity?.guest}
                                            min={1}
                                          />
                                          <button
                                            type="button"
                                            // disabled={activity.guest >= (bookingData?.guest + bookingData?.children)}
                                            onClick={() =>
                                              handleIncrease(activity.id)
                                            }
                                            style={{
                                              opacity:
                                                activity.guest >=
                                                bookingData?.guest +
                                                  bookingData?.children
                                                  ? "0.2"
                                                  : "1",
                                            }}
                                            className="optionCounterButton optionCounterButtonWithBorder"
                                          >
                                            <span
                                              className="onclick_btn"
                                              id="adult_plus"
                                            >
                                              +
                                            </span>
                                          </button>
                                        </div>
                                      </div>
                                    </div>

                                    <div>
                                      <DisabledTextButton
                                        loader={loader}
                                        textColor="red"
                                        onClick={() =>
                                          handleRemoveActivity(index, idx)
                                        }
                                      >
                                        Remove
                                      </DisabledTextButton>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </>
                    )}

                    {item?.endPoint.toLowerCase() === "shimla" ||
                    item?.endPoint.toLowerCase() === "manali" ||
                    item?.endPoint.toLowerCase() === "dharamshala" ||
                    item?.endPoint.toLowerCase() === "dalhousie" ||
                    item?.endPoint.toLowerCase() === "bir" ? (
                      <div className="packageAddActivityParent">
                        <hr className="packageAddActivityLine" />
                        <button
                          onClick={() =>
                            handleAddActivityClick(index, item?.endPoint)
                          }
                          className="flex justify_content_center align_items_center"
                        >
                          {" "}
                          <span>
                            {" "}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              version="1.1"
                              viewBox="0 0 24 24"
                              width="15"
                              height="15"
                            >
                              <g>
                                <g id="Layer_1">
                                  <g id="SVGRepo_iconCarrier">
                                    <g>
                                      <path d="M20,9.1v-1.4c0-.2-.2-.4-.4-.4s-.4.2-.4.4v1.1c0,0-.1,0-.2,0h-2.1s-2.9-2.4-3.9-2.8c-1.5-.5-3.1.4-3.5,1.9l-2.7,8.7-3.1,4.7c-.5.7-.3,1.7.4,2.2.3.2.6.3.9.3.5,0,1-.2,1.3-.7l3.2-4.9c0-.1.1-.3.2-.4l.6-1.9.9,1.7.9,5.2c.1.8.8,1.3,1.5,1.3s.2,0,.3,0c.9-.1,1.4-1,1.3-1.8l-1-5.5c0-.2,0-.3-.2-.5l-.9-1.7c.1-.2.3-.5.3-.7l.9-3,1.1.8c.3.2.6.3.9.3h2.6c0,0,.1,0,.2,0v10.7c0,.2.2.4.4.4s.4-.2.4-.4v-11.1c.4-.3.6-.7.6-1.2s-.2-.9-.6-1.2Z"></path>
                                      <circle cx="14.8" cy="3" r="3"></circle>
                                      <path d="M5.5,13.6c.2,0,.3,0,.5,0,.7,0,1.3-.4,1.5-1.1l1.6-5.3c.3-.8-.2-1.7-1-2-.8-.3-1.7.2-2,1l-1.6,5.3c-.3.8.2,1.7,1,2Z"></path>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </svg>
                          </span>
                          <span>Add Activity</span>
                        </button>
                      </div>
                    ) : null}
                  </>
                ) : (
                  <>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item?.shortDescription,
                      }}
                    ></div>
                  </>
                )}
              </div>
            </div>
          ))
        ) : (
          <div className="no-data text-center">
            <p>No Data Found</p>
          </div>
        )}
      </div>

      {isSliderVisible && (
        <NewCustomSlider
          onClose={handleCloseSlider}
          setIsSliderVisible={setIsSliderVisible}
          data={hotelData}
          selectedHotelId={selectedHotelId}
          selectedHotelPrice={selectedHotelPrice}
          updateHotelNameForSelectedDay={updateHotelNameForSelectedDay}
          setCustomizableRatesLoader={setCustomizableRatesLoader}
        />
      )}

      {isVehicleCustomizedClick && (
        <NewCustomSliderTaxi
          onClose={handleCloseSlider}
          setIsSliderOpen={setIsSliderOpen}
          setCustomizableRatesLoader={setCustomizableRatesLoader}
          allVehicles={allVehicles}
          changeSelectedVehicle={changeSelectedVehicle}
          setIsVehicleCustomizedClick={setIsVehicleCustomizedClick}
          fetchTransportCost={fetchTransportCost}
          calculateDataDayByDay={calculateDataDayByDay}
          fetchCostOfVehicle={fetchCostOfVehicle}
          vehicleDetails={vehicleDetails}
          changeSelectedActivity={changeSelectedActivity}
          setOldVehicleCost={setOldVehicleCost}
          setNewVehicleCost={setNewVehicleCost}
        />
      )}
      {isAddActivityClick && (
        <NewCustomSliderActivities
          onClose={handleCloseSlider}
          setIsSliderOpen={setIsSliderOpen}
          setCustomizableRatesLoader={setCustomizableRatesLoader}
          allVehicles={allVehicles}
          changeSelectedVehicle={changeSelectedVehicle}
          setIsVehicleCustomizedClick={setIsAddActivityClick}
          fetchTransportCost={fetchTransportCost}
          calculateDataDayByDay={calculateDataDayByDay}
          fetchCostOfVehicle={fetchCostOfVehicle}
          vehicleDetails={vehicleDetails}
          changeSelectedActivity={changeSelectedActivity}
          allActivities={allActivities}
          hotelDayByDay={hotelDayByDay}
          setOldVehicleCost={setOldVehicleCost}
          setNewVehicleCost={setNewVehicleCost}
        />
      )}

      {/* {deletePopup && (
        <div className="popup_backdrop">
          <div className="popup_section p_20">
            <div>
              <div className="flex justify_content_between">
                <h4>Are you sure to Delete.</h4>
                <svg
                  // onClick={() => closeDeletePopup(false)}
                  width="14"
                  height="13"
                  viewBox="0 0 14 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M1 12.5L13 0.5" stroke="#AAAAAA" strokeWidth="1.2" />
                  <path d="M13 12.5L1 0.5" stroke="#AAAAAA" strokeWidth="1.2" />
                </svg>
              </div>

              <button
                className="primary_btn mt-10"
                type="button"
                // onClick={() => deleteRatePlan()}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )} */}
    </div>
  );
};

export default NewCustomizableAccommodation;
