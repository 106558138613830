import React, { useState } from "react";
import "../SingleHoliday/SingleHoliday.css";
import Footer from "../../CustomComponents/Footer/Footer";
import PageLinks from "../../CustomComponents/PageLinks/PageLinks";
import SingleSearchForm from "../../CustomComponents/SingleSearchForm/SingleSearchForm";
import Header from "../../CustomComponents/Header/Header";
import OurQuery from "../../CustomComponents/OurQuery/OurQuery";
import ChooseUs from "../../CustomComponents/ChooseUs/ChooseUs";
import SendItinerary from "../../CustomComponents/SendItinerary/SendItinerary";
import CustomSlider from "../../CustomComponents/CustomSlider/CustomSlider";
import { DisabledTextButton } from "../../CustomComponents/Buttons/Buttons";
import { useEffect } from "react";
import StarRating from "../../CustomComponents/StarRating/StarRating";
import carImg from "../../assets/images/car.png";
import sightseeingnImg from "../../assets/images/Sightseeing.jpg";
import { Link } from "react-router-dom";
import PageMetaData from "../../CustomComponents/PageMetaData/PageMetaData";
import OgMetaTitles from "../../CustomComponents/PageMetaData/OgMetaTitles";

function SingleHoliday() {
  const [isSliderVisible, setIsSliderVisible] = useState(false);
  const [loader, setLoader] = useState(false);
  const [selectedHotelId, setSelectedHotelId] = useState(
    JSON.parse(localStorage.getItem("selected")) || null
  );
  const [hotelData, setHotelData] = useState(null);
  const [selectedHotelPrice, setSelectedHotelPrice] = useState(null);
  const [data, setData] = useState(null);
  // console.log(data, "datass")

  // const

  const storedData = JSON.parse(localStorage.getItem("selected"));
  // console.log("storedData", selectedHotelId);
  useEffect(() => {
    if (storedData) {
      setData(selectedHotelId);
    }
  }, [localStorage]);

  const handleCloseSlider = () => {
    setIsSliderVisible(false);
  };

  const fetchHotelData = async () => {
    setLoader(true);
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
 myHeaders.append("key", process.env.REACT_APP_API_KEY);

    const data = {
      hotelId: 1,
      location: "shimla",
      room: 1,
      adult: 1,
      children: 1,
      childAge: [7],
      checkinDay: 28,
      checkinMonth: 7,
      checkinYear: 2024,
      checkoutDay: 29,
      checkoutMonth: 7,
      checkoutYear: 2024,
      radio: "Hotel",
    };

    const checkInDate = `${data.checkinYear}-${String(
      data.checkinMonth
    ).padStart(2, "0")}-${String(data.checkinDay).padStart(2, "0")}`;
    const checkOutDate = `${data.checkoutYear}-${String(
      data.checkoutMonth
    ).padStart(2, "0")}-${String(data.checkoutDay).padStart(2, "0")}`;

    const formdata = new FormData();
    formdata.append("checkIn", checkInDate);
    formdata.append("checkOut", checkOutDate);
    formdata.append("adults", data.adult);
    formdata.append("child", data.children);
    formdata.append("location", data.location);
    formdata.append("rooms", data.room);
    formdata.append("PropertyType", "Hotel");

    // Append childAge values dynamically
    if (data.children > 0) {
      for (let i = 1; i <= data.children; i++) {
        formdata.append(`childAge[${i - 1}]`, data.childAge[i - 1]);
      }
    }

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_APP_URL}/api/v1/hotel-engine/check-availability`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      localStorage.setItem("token", result.data.token);
      setHotelData(result.data.properties);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    } finally {
      setLoader(false);
    }
  };

  const handleChangeClick = async (hotelId) => {
    setLoader(false);
    setSelectedHotelId(hotelId);
    setSelectedHotelId(hotelId);
    setSelectedHotelPrice(1000);
    await fetchHotelData();
    setIsSliderVisible(true);
  };

  return (
    <>
      {/* <PageMetaData
                title="Mountain Mysteries"
                description="Enhance your hotel capabilities with RevCatalyst's powerful system. Optimize performance, streamline operations, and unleash the full potential of property."
                keywords="hotel management, RevCatalyst, property optimization"
            /> */}
      <PageMetaData
        title={`${
          hotelData?.hotelInfo?.name &&
          hotelData?.hotelInfo?.name !== "" &&
          hotelData?.hotelInfo?.name !== undefined &&
          hotelData?.hotelInfo?.name
        } - Mountain Mysteries`}
        description="Enhance your hotel capabilities with RevCatalyst's powerful system. Optimize performance, streamline operations, and unleash the full potential of property."
        keywords="hotel management, RevCatalyst, property optimization"
      />

      <OgMetaTitles
        title={`${
          hotelData?.hotelInfo?.name &&
          hotelData?.hotelInfo?.name !== "" &&
          hotelData?.hotelInfo?.name !== undefined &&
          hotelData?.hotelInfo?.name
        } - Mountain Mysteries`}
        type="Enhance your hotel capabilities with RevCatalyst's powerful system. Optimize performance, streamline operations, and unleash the full potential of property."
        // url={`https://www.mountainmysteries.com/single-hotel/${bookingData?.hotelId}?hotelId=${bookingData?.hotelId}&location=$${bookingData?.location}&room=${bookingData?.room}&adult=${bookingData?.adult}&children=0&&checkinDay=5&checkinMonth=10&checkinYear=2024&checkoutDay=6&checkoutMonth=10&checkoutYear=2024&hotel_name=Shimla%20Havens%20Resort&hotel_address=Shimla`}
      />

      <Header />
      <SingleSearchForm />
      {/* <PageLinks /> */}
      <section>
        <div className="container">
          <main className="justify_content_center">
            <div className="reservation-content">
              <div className="SingleHotel-Heading">
                <h3 className="text_left">
                  Ex Delhi: Queen of Hills -3 Nights Shimla
                </h3>
                <span className="custom-text">
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.375 2.5H8.875"
                      stroke="#333333"
                      strokeWidth="1.2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6.875 1.5V3.5"
                      stroke="#333333"
                      strokeWidth="1.2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6.875 2.5H1.375"
                      stroke="#333333"
                      strokeWidth="1.2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M3.375 6H1.375"
                      stroke="#333333"
                      strokeWidth="1.2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M5.375 5V7"
                      stroke="#333333"
                      strokeWidth="1.2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10.875 6H5.375"
                      stroke="#333333"
                      strokeWidth="1.2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10.375 9.5H8.875"
                      stroke="#333333"
                      strokeWidth="1.2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6.875 8.5V10.5"
                      stroke="#333333"
                      strokeWidth="1.2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6.875 9.5H1.375"
                      stroke="#333333"
                      strokeWidth="1.2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Customisable
                </span>
                <span className="day-text">3N/4D</span>
                <span className="valid-text">
                  <b>Date:</b> Tue, 24 Oct 2023 to 27 Oct 2023{" "}
                </span>
                <a href="" className="modify_btn">
                  Modify
                </a>
              </div>
              <div className="colored_bg">
                <div className="agent">
                  <button className="border_btn">4 Day Plan</button>
                  <button className="border_btn">1 Hotels</button>
                  <button className="border_btn">4 Transfers</button>
                  <button className="border_btn">2 Activites</button>
                </div>
                <div className="fixed_bg">
                  <div className="agent">
                    <p>Day 1 - New Delhi To Shimla</p>
                    <span>Included: 1 Hotel, 1 Transfer</span>
                  </div>
                </div>
                <div className="content-consider">
                  <div>
                    <div className="agent">
                      <small>Transfer from New Delhi to Shimla</small>
                      <button className="change_btn">Change</button>
                    </div>
                    <div className="hotel-data">
                      <div className="hotel_img">
                        <img src={carImg} alt="car-image" />
                      </div>
                      <div className="hotel-text">
                        <h6>
                          <b>Private Transfer</b>
                        </h6>
                        <small>KUV 100 / Baleno /Glanza or similar</small>
                        <p>
                          <small>Facilities: </small>3 seater | AC | 0 luggage
                          bags | Self Drive
                        </p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="agent">
                      <small>Check-in to {data?.hotel_name}</small>

                      <DisabledTextButton
                        loader={loader}
                        textColor="#016937"
                        onClick={() => handleChangeClick(data?.hotel_id)}
                      >
                        Change
                      </DisabledTextButton>
                    </div>
                    {isSliderVisible && (
                      <CustomSlider
                        onClose={handleCloseSlider}
                        setIsSliderVisible={setIsSliderVisible}
                        data={hotelData}
                        selectedHotelId={selectedHotelId}
                        selectedHotelPrice={selectedHotelPrice}
                      />
                    )}
                  </div>

                  <div className="hotel-data">
                    <div className="hotel_img">
                      <img src={data?.hotel_image[0]} alt="" />
                    </div>
                    <div className="hotel-text">
                      <h6>
                        <b>{data?.hotel_name}</b>
                      </h6>
                      <StarRating
                        value={data?.hotel_rating ? data?.hotel_rating : 0}
                      />
                      <small>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M4.83301 6.66667C4.83301 5.82681 5.16664 5.02136 5.7605 4.4275C6.35437 3.83363 7.15982 3.5 7.99967 3.5C8.83953 3.5 9.64498 3.83363 10.2388 4.4275C10.8327 5.02136 11.1663 5.82681 11.1663 6.66667C11.1663 7.50652 10.8327 8.31197 10.2388 8.90584C9.64498 9.4997 8.83953 9.83333 7.99967 9.83333C7.15982 9.83333 6.35437 9.4997 5.7605 8.90584C5.16664 8.31197 4.83301 7.50652 4.83301 6.66667ZM7.99967 4.5C7.42504 4.5 6.87394 4.72827 6.46761 5.1346C6.06128 5.54093 5.83301 6.09203 5.83301 6.66667C5.83301 7.2413 6.06128 7.7924 6.46761 8.19873C6.87394 8.60506 7.42504 8.83333 7.99967 8.83333C8.57431 8.83333 9.12541 8.60506 9.53174 8.19873C9.93807 7.7924 10.1663 7.2413 10.1663 6.66667C10.1663 6.09203 9.93807 5.54093 9.53174 5.1346C9.12541 4.72827 8.57431 4.5 7.99967 4.5Z"
                            fill="#888888"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M2.34926 5.90471C2.46321 4.52258 3.09274 3.23383 4.11287 2.29436C5.13299 1.35488 6.4691 0.83337 7.85593 0.833374H8.14393C9.53075 0.83337 10.8669 1.35488 11.887 2.29436C12.9071 3.23383 13.5366 4.52258 13.6506 5.90471C13.7774 7.44423 13.3018 8.97288 12.3239 10.1687L9.12859 14.076C8.99185 14.2433 8.81965 14.378 8.62445 14.4705C8.42926 14.5631 8.21594 14.6111 7.99993 14.6111C7.78391 14.6111 7.57059 14.5631 7.3754 14.4705C7.1802 14.378 7.00801 14.2433 6.87126 14.076L3.67593 10.1687C2.698 8.97292 2.22238 7.44424 2.34926 5.90471ZM7.85593 1.83337C6.72025 1.83355 5.62615 2.26073 4.79081 3.03012C3.95546 3.79952 3.43995 4.85487 3.34659 5.98671C3.24099 7.26795 3.63678 8.54016 4.45059 9.53537L7.64593 13.4434C7.68888 13.4959 7.74298 13.5383 7.80432 13.5674C7.86566 13.5965 7.9327 13.6116 8.00059 13.6116C8.06848 13.6116 8.13552 13.5965 8.19686 13.5674C8.2582 13.5383 8.3123 13.4959 8.35526 13.4434L11.5506 9.53537C12.3639 8.53997 12.7592 7.26777 12.6533 5.98671C12.5599 4.85476 12.0443 3.79931 11.2088 3.02989C10.3733 2.26048 9.27905 1.83338 8.14326 1.83337H7.85526H7.85593Z"
                            fill="#888888"
                          />
                        </svg>{" "}
                        {data?.hotel_address}
                      </small>{" "}
                      <br />
                      <small>
                        <svg
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_2810_11970)">
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M10.3744 1.33366H8.91606V0.750326C8.91606 0.428159 8.65489 0.166992 8.33272 0.166992C8.01056 0.166992 7.74939 0.428159 7.74939 0.750326V1.33366H3.66606V0.750326C3.66606 0.428159 3.40489 0.166992 3.08272 0.166992C2.76056 0.166992 2.49939 0.428159 2.49939 0.750326V1.33366H1.62439C1.15772 1.33366 0.74939 1.74199 0.74939 2.20866V10.9587C0.74939 11.4253 1.15772 11.8337 1.62439 11.8337H10.3744C10.8411 11.8337 11.2494 11.4253 11.2494 10.9587V2.20866C11.2494 1.74199 10.8411 1.33366 10.3744 1.33366ZM1.91606 10.667H10.0827V4.83366H1.91606V10.667Z"
                              fill="#888888"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_2810_11970">
                              <rect
                                width="11.6667"
                                height="11.6667"
                                fill="white"
                                transform="translate(0.166016 0.166992)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                        Tue, 24 Oct 2023 - Fri, 27 Oct 2023{" "}
                      </small>
                    </div>
                  </div>
                </div>
                <div className="meal_plan">
                  <div>
                    <h6>
                      <b>Deluxe Room (Forest View)</b>
                    </h6>
                    <small>
                      <b>Meal Plan:</b> Breakfast
                    </small>
                    <br />
                    <small>
                      <b>Room Inclusion:</b> Complimentary Breakfast is
                      available
                    </small>
                  </div>
                  <div>
                    <button className="change_btn">Change Room</button>
                  </div>
                </div>
                <div className="line SingleHotel-activity_btn_style"></div>
                <div className="SingleHotel-act_btn">
                  <button className="activity_btn">Add Activity to Day</button>
                </div>

                <div className="fixed_bg">
                  <div className="agent">
                    <p>Day 2 - Shimla</p>
                    <span>Included: 1 Hotel, 1 Transfer, 1 Activity</span>
                  </div>
                </div>
                <div className="content-consider">
                  <div>
                    <div className="agent">
                      <small>
                        Transfer from Private AC Self Drive - Hatchback for
                        sightseeing in & around Shimla
                      </small>
                      <button className="change_btn">Change</button>
                    </div>
                    <div className="hotel-data">
                      <div className="hotel_img">
                        <img src={carImg} alt="car-image" />
                      </div>
                      <div className="hotel-text">
                        <h6>
                          <b>Private Transfer</b>
                        </h6>
                        <small>KUV 100 / Baleno /Glanza or similar</small>
                        <p>
                          <small>Facilities: </small>3 seater | AC | 0 luggage
                          bags | Self Drive
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="line"></div>
                  <div>
                    <div className="agent">
                      <button className="change_btn">Change</button>
                    </div>
                    <div className="hotel-data">
                      <div className="hotel_img">
                        <img src={sightseeingnImg} alt="sightseeing-image" />
                      </div>
                      <div className="hotel-text">
                        <h6>
                          <b>Sightseeing in Shimla</b>
                        </h6>
                        <small>Shimla, India</small> <br />
                        <p>
                          Enjoy a delicious bowl of Maggi noodles at the top of
                          Jakhoo Hill. Promising a breathtaking view, snack time
                          here will be delightful! Note: Private transfers
                          included.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="line"></div>
                  <div>
                    <h6>
                      <b>Day Meals</b>{" "}
                      <small>
                        <b>Beakfast: </b>Included at Hotel Woodrina , Shimla
                      </small>
                    </h6>
                  </div>
                  <div className="line SingleHotel-activity_btn_style"></div>
                  <div className="SingleHotel-act_btn">
                    <button className="activity_btn">
                      Add Activity to Day
                    </button>
                  </div>
                </div>
                <div className="fixed_bg">
                  <div className="agent">
                    <p>Day 3 - Shimla</p>
                    <span>Included: 1 Hotel, 1 Transfer, 1 Activity</span>
                  </div>
                </div>
                <div className="content-consider">
                  <div>
                    <div className="agent">
                      <small>
                        Transfer from Private AC Self Drive - Hatchback for
                        sightseeing in & around Shimla
                      </small>
                      <button className="change_btn">Change</button>
                    </div>
                    <div className="hotel-data">
                      <div className="hotel_img">
                        <img src={carImg} alt="car-image" />
                      </div>
                      <div className="hotel-text">
                        <h6>
                          <b>Private Transfer</b>
                        </h6>
                        <small>KUV 100 / Baleno /Glanza or similar</small>
                        <p>
                          <small>Facilities: </small>3 seater | AC | 0 luggage
                          bags | Self Drive
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="line"></div>
                  <div>
                    <div className="agent">
                      <button className="change_btn">Change</button>
                    </div>
                    <div className="hotel-data">
                      <div className="hotel_img">
                        <img src={sightseeingnImg} alt="sightseeing-image" />
                      </div>
                      <div className="hotel-text">
                        <h6>
                          <b>Sightseeing in Shimla</b>
                        </h6>
                        <small>Shimla, India</small> <br />
                        <p>
                          Enjoy a delicious bowl of Maggi noodles at the top of
                          Jakhoo Hill. Promising a breathtaking view, snack time
                          here will be delightful! Note: Private transfers
                          included.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="line"></div>
                  <div className="day_meal">
                    <h6>
                      <b>Day Meals</b>{" "}
                      <small>
                        <b>Beakfast:</b>Included at Hotel Woodrina , Shimla
                      </small>
                    </h6>
                  </div>
                  <div className="line  SingleHotel-activity_btn_style"></div>
                  <div className="SingleHotel-act_btn">
                    <button className="activity_btn">
                      Add Activity to Day
                    </button>
                  </div>
                </div>
                <div className="fixed_bg">
                  <div className="agent">
                    <p>Day 4 - Shimla</p>
                    <span>Included: 1 Transfer</span>
                  </div>
                </div>
                <div className="content-consider">
                  <div>
                    <div className="day_meal">
                      <h6>
                        <b>Day Meals</b>{" "}
                        <small>
                          <b>Beakfast:</b>Included at Hotel Woodrina , Shimla
                        </small>
                      </h6>
                      <small>Checkout from Hotel Woodrina in Shimla</small>
                    </div>
                    <div className="agent">
                      <small>Transfer from New Delhi to Shimla</small>
                      <button className="change_btn">Change</button>
                    </div>
                    <div className="hotel-data">
                      <div className="hotel_img">
                        <img src={carImg} alt="car-image" />
                      </div>
                      <div className="hotel-text">
                        <h6>
                          <b>Private Transfer</b>
                        </h6>
                        <small>KUV 100 / Baleno /Glanza or similar</small>
                        <p>
                          <small>Facilities: </small>3 seater | AC | 0 luggage
                          bags | Self Drive
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <aside id="PricsBreak">
              <div className="common_background">
                <h4>
                  <b>&#x20B9 1,749 </b>
                  <small> per Adult</small>
                </h4>
                <div className="buttons flex_direction_col">
                  <button className="common_btn">Book Now</button>
                  <button className="secondary_btn mt-10 border-radius">
                    Send Enquiry
                  </button>
                </div>
              </div>
              <OurQuery />
              <ChooseUs />
              <SendItinerary />
            </aside>
          </main>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default SingleHoliday;
