import React, { useEffect, useState } from "react";
import "../BasicInformation/BasicInformation.css";
import AddressAutocomplete from "../../CustomGooglePlaceAPi/AddressAutocomplete";
import "../Locations/Locations.css";
import hotelImg from "../../../assets/images/golden-fern-img.png";
import { Link } from "react-router-dom";
import NoDataFound from "../../NoDataFound/NoDataFound";

const MyProperties = () => {
  const [formData, setFormData] = useState({
    address: "",
  });

  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  // console.log(userDetails);

  useEffect(() => {
    if (userDetails.id) {
      getProperties(userDetails.id);
    }
  }, [userDetails.id]);

  const [allProperties, setAllProperties] = useState([]);

  const [addressData, setAddressData] = useState({
    country: "",
    state: "",
    city: "",
    address: "",
    latitude: "",
    longitude: "",
    placeId: "",
  });

  const handleAddressData = (data) => {
    // console.log(data, "data");
    setAddressData(data);
  };

  const getProperties = (id) => {
    const requestOptions = {
      // myHeaders.append("key", "D4X50uU40Vz4pweFLEmsoiJy107iPfTD4ompprop1");
      headers: {
        "Content-Type": "application/json",
        key: "D4X50uU40Vz4pweFLEmsoiJy107iPfTD4ompprop1",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_APP_URL}/api/v1/vendor/get-accommodation/${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log("result", result);

        if (result.success) {
          setAllProperties(result?.hotels);
        } else {
          setAllProperties([]);
        }
      })
      .catch((error) => console.error(error));
  };

  // console.log(allProperties, "allProperties");

  return (
    <section className="w-full">
      <div className="basic-info-container">
        <div className="flex mt-10 justify_content_between w-full py-5">
          <h3 className="text-left">My Properties</h3>
          <Link to="/vendor/property/add" className="primary_btn fit-width">
            Add Property
          </Link>
        </div>
        {allProperties?.length > 0 ? (
          allProperties?.map((item, index) => {
            return (
              <div className="bg-white-div mt-10" key={index}>
                <Link to={`/partner/overview/${item?.id}`}>
                  <div className="flex justify_content_between w-full py-5 align_items_center">
                    <div className="flex flex_gap_10 align_items_center property_data">
                      <div className="property_img">
                        <img
                          src={
                            item?.featuredImage ? item?.featuredImage : hotelImg
                          }
                          alt="logo-image"
                          className="hotel-img"
                          style={{
                            objectFit: "cover",
                            borderRadius: "6px",
                          }}
                        />
                      </div>
                      <div className="property_content">
                        <h6>{item?.name}</h6>
                        <span className="font_12">{item?.address}</span>
                      </div>
                    </div>
                    <div>
                      <div className="flex flex_gap_10 align_items_center">
                        {/* <Link className="secondary_btn" to={`/rates/${item?.id}`}>
                        rates
                      </Link> */}

                        <Link
                          to={`/vendor/property/edit/${item?.id}`}
                          style={{ color: "#016937" }}
                        >
                          <svg
                            width="26"
                            height="27"
                            viewBox="0 0 26 27"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <mask
                              id="mask0_4568_4221"
                              style={{ maskType: "alpha" }}
                              maskUnits="userSpaceOnUse"
                              x="0"
                              y="0"
                              width="26"
                              height="27"
                            >
                              <rect
                                y="0.5"
                                width="26"
                                height="26"
                                fill="#D9D9D9"
                              />
                            </mask>
                            <g mask="url(#mask0_4568_4221)">
                              <path
                                d="M5.41667 21.0833H6.96042L17.55 10.4938L16.0063 8.95L5.41667 19.5396V21.0833ZM3.25 23.25V18.6458L17.55 4.37292C17.7667 4.17431 18.0059 4.02083 18.2677 3.9125C18.5295 3.80417 18.8049 3.75 19.0938 3.75C19.3826 3.75 19.6625 3.80417 19.9333 3.9125C20.2042 4.02083 20.4389 4.18333 20.6375 4.4L22.1271 5.91667C22.3438 6.11528 22.5017 6.35 22.601 6.62083C22.7003 6.89167 22.75 7.1625 22.75 7.43333C22.75 7.72222 22.7003 7.99757 22.601 8.25938C22.5017 8.52118 22.3438 8.76042 22.1271 8.97708L7.85417 23.25H3.25ZM16.7646 9.73542L16.0063 8.95L17.55 10.4938L16.7646 9.73542Z"
                                fill="#016937"
                              />
                            </g>
                          </svg>
                        </Link>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            );
          })
        ) : (
          <>
            <NoDataFound />
          </>
        )}
      </div>
    </section>
  );
};

export default MyProperties;
