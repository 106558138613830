import React from "react";
import { Link } from "react-router-dom";
import "../CustomListingCard/CustomListingCard.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { formatIndianCurrency } from "../../utils/utils";
import BgBackdrop from "../BgBackDrop/BgBackDrop";
import { animateScroll as scroll } from "react-scroll";
import SendEnquiryPopup from "../SendEnquiryPopup/SendEnquiryPopup";

function CustomListingCard({
  imgSrc,
  id,
  title,
  packagehandle,
  duration,
  nightDuration,
  packageHub,
  price,
  bookingLink,
  detailsLink,
  packageHotels,
  canBookable,
}) {
  const navigate = useNavigate();
  const [data, setData] = useState({ fromCity: "", toCity: "" });
  const [departureDate, setdepartureDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [date, setDate] = useState(new Date());
  const [hotelData, setHotelData] = useState([]);
  const [guest, setGuest] = useState(2);
  const [children, setChildren] = useState(0);
  const [rooms, setRooms] = useState(1);
  const [childAge, setChildAge] = useState([]);
  const [bookingData, setBookingData] = useState({});
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const checkinDay = queryParams.get("checkinDay") || "";
  const checkinMonth = queryParams.get("checkinMonth") || "";
  const checkinYear = queryParams.get("checkinYear") || "";
  const checkoutDay = queryParams.get("checkoutDay") || "";
  const checkoutMonth = queryParams.get("checkoutMonth") || "";
  const checkoutYear = queryParams.get("checkoutYear") || "";

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    // Get the childAge parameters
    const childAges = [];
    let i = 1;
    while (params.has(`childAge[${i}]`)) {
      childAges.push(params.get(`childAge[${i}]`));
      i++;
    }

    const data = {
      hotelID: params.get("hotelID"),
      location: params.get("location"),
      room: params.get("room"),
      guest: params.get("guest"),
      children: params.get("children"),
      checkinDay: params.get("checkinDay"),
      checkinMonth: params.get("checkinMonth"),
      checkinYear: params.get("checkinYear"),
      checkoutDay: params.get("checkoutDay"),
      checkoutMonth: params.get("checkoutMonth"),
      checkoutYear: params.get("checkoutYear"),
      radio: params.get("radio"),
      childAge: childAges,
    };

    const checkInDate = `${data.checkinYear}-${String(
      data.checkinMonth
    ).padStart(2, "0")}-${String(data.checkinDay).padStart(2, "0")}`;
    const checkOutDate = `${data.checkoutYear}-${String(
      data.checkoutMonth
    ).padStart(2, "0")}-${String(data.checkoutDay).padStart(2, "0")}`;

    setData({
      fromCity: params.get("location"),
      // toCity: params.get("location"),
    });

    setGuest(data.guest ? data.guest : 2);
    setChildren(data?.children ? data?.children : 0);
    setRooms(data.room);
    setChildAge(childAges);
    setdepartureDate(checkInDate);
    setEndDate(checkOutDate);
    setBookingData(data);
  }, [location.search]);

  const handleSelectRoomClickHandler = async (item, hotelData, room_id) => {
    let hasChildAgeError = false;
    const childAge = bookingData?.childAge;
    const sanitizedChildAges = childAge
      .map((age) => (age !== "" ? age : null))
      .filter((age) => age !== null);

    const childAgeParams = sanitizedChildAges
      .map((age, index) => {
        if (age > 12) {
          alert("Child age must be between 0 and 12");
          hasChildAgeError = true;
          return;
        }
        return `&childAge[${index + 1}]=${age}`;
      })
      .join("");

    if (hasChildAgeError) {
      alert("Child age must be between 0 and 12");
      return;
    }

    // console.log(children, 'jjj');

    // Calculate booking values before navigating
    try {
      // http://localhost:3000/package/complete-himachal?location=shimla&guest=2&packagehandle=complete-himachal
      await navigate(
        `/package/${packagehandle}?location=${data.fromCity}&guest=${guest}&children=${children}&${childAgeParams}&packagehandle=${packagehandle}`,
        undefined,
        { shallow: false }
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const [showRatePopup, setshowRatePopup] = useState(false);
  const handleshowRatePopup = (e) => {
    e.stopPropagation();
    setshowRatePopup(!showRatePopup);
  };
  const [formData, setFormData] = useState({
    company_name: "",
    name: "",
    phone: "",
    email: "",
    querry_detail: "",
    country: "",
    state: "",
    city: "",
    address: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  // console.log(showRatePopup);
  return (
    <>
      <div
        className=" card-listing"
        style={{
          background: "none",
          borderRadius: "8px",
          marginBottom: "0px",
          boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          padding: "0px",
        }}
      >
        <img
          onClick={() => {
            handleSelectRoomClickHandler();
            scroll.scrollToTop({ duration: 500, smooth: "easeInOutQuad" });
          }}
          src={imgSrc}
          alt={title}
          style={{ borderRadius: "8px 8px 0px 0px", cursor: "pointer" }}
        />
        <div className="card_content" style={{ marginTop: "0px" }}>
          <h6
            className="cursor_pointer"
            onClick={() => {
              handleSelectRoomClickHandler();
              scroll.scrollToTop({ duration: 500, smooth: "easeInOutQuad" });
            }}
          >
            {title}
          </h6>
          <p className="m_0">{packageHub}</p>
          {duration && (
            <div className="flex justify_content_between align_items_center">
              <div className="flex align_items_center">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.27832 2.3C13.2482 2.3 16.4783 5.5301 16.4783 9.5C16.4783 13.4699 13.2482 16.7 9.27832 16.7C5.30842 16.7 2.07832 13.4699 2.07832 9.5C2.07832 5.5301 5.30842 2.3 9.27832 2.3ZM9.27832 0.5C4.30762 0.5 0.27832 4.5293 0.27832 9.5C0.27832 14.4707 4.30762 18.5 9.27832 18.5C14.249 18.5 18.2783 14.4707 18.2783 9.5C18.2783 4.5293 14.249 0.5 9.27832 0.5ZM12.6983 12.56L10.1783 9.2003V5H8.37832V9.7997L11.2583 13.6391L12.6983 12.56Z"
                    fill="#EB442D"
                  ></path>
                </svg>
                {`${duration}D /${nightDuration}N`}
              </div>
              <h4>{formatIndianCurrency(price)}</h4>
            </div>
          )}

          <div className="buttons">
            {canBookable === 1 ? (
              // <Link onClick={() => scroll.scrollToTop({ duration: 500, smooth: "easeInOutQuad" })} to="/checkout-package" className="primary_btn">
              //   Book now
              // </Link>
              <div
                className="primary_btn cursor_pointer"
                onClick={() => {
                  handleSelectRoomClickHandler();
                  scroll.scrollToTop({
                    duration: 500,
                    smooth: "easeInOutQuad",
                  });
                }}
              >
                Book Now
              </div>
            ) : (
              <button
                onClick={(e) => handleshowRatePopup(e)}
                className="primary_btn"
              >
                Send Enquiry
              </button>
            )}

            <div
              className="secondary_btn cursor_pointer"
              onClick={() => {
                handleSelectRoomClickHandler();
                scroll.scrollToTop({ duration: 500, smooth: "easeInOutQuad" });
              }}
            >
              View Details
            </div>
          </div>
        </div>
      </div>

      {showRatePopup && (
        <>
          <SendEnquiryPopup
            package_id={id}
            setshowRatePopup={setshowRatePopup}
            typeDetail="package"
          />
        </>
      )}
    </>
  );
}

export default CustomListingCard;
