import React from "react";
import { highlightData } from "../../constants";
import "./Highlights.css";

function Highlights() {
  return (
    <section className="">
      <div className="container">
        <div className="common_padding">
          {" "}
          <h3>Why Choose Mountain Mysteries?</h3>
          <p className="Highlight-Exp common_content mt-10 text-center">
            Experience the Enchantment of the Himalayas with Mountain
            Mysteries!. We offer exclusive packages, unforgettable experiences,
            and a commitment to creating magical memories. Let us take you on a
            journey beyond the ordinary!
          </p>
          <div className="flex flex_wrap align_items_center mt-20 highlight-main">
            {highlightData?.map((items, index) => {
              return (
                <div className="highlight" key={items.id}>
                  <img
                    src={items.image}
                    alt={items.alt}
                    style={{ width: "45px" }}
                  />
                  <h6>{items.title}</h6>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Highlights;
