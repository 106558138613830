import React from "react";
import bannerImg from '../../assets/images/packages-banner.png'

function ListingBanner(Props) {
    return (
        <section class="links_section">
            <div class="container">
                <h6 style={{ textTransform: 'capitalize' }}>{Props.heading} </h6>
            </div>
        </section>
    )
}

export default ListingBanner;