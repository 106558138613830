import React from "react";
import "../CustomPagination/CustomPagination.css";
import { animateScroll as scroll } from "react-scroll";


function CustomPagination({
  currentPage,
  itemsPerPage,
  totalItems,
  onPageChange,
  clearSelection,
}) {
  // Calculate total pages based on total items passed
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  // console.log(totalPages);
  // console.log(totalItems);
  // console.log(itemsPerPage);

  const handlePageClick = (pageNumber) => {
    if (typeof clearSelection === "function") {
      clearSelection();
    }
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      onPageChange(pageNumber);
    }
  };

  const handlePrevClick = () => {
    if (typeof clearSelection === "function") {
      clearSelection();
    }
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNextClick = () => {
    if (typeof clearSelection === "function") {
      clearSelection();
    }
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };
  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 3;

    if (totalPages <= maxPagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
      // console.log(pageNumbers, "pageNumbers");

      return pageNumbers.map((page, index) => (
        <button
          key={index}
          onClick={() => {handlePageClick(page); scroll.scrollToTop({ duration: 500, smooth: "easeInOutQuad" })}}
          disabled={page === currentPage}
          className={
            page === currentPage
              ? "custom_items_pagination_item pagination_item_active"
              : "custom_items_pagination_item"
          }
        >
          {page}
        </button>
      ));
    } else {
      const firstPage = Math.max(1, currentPage - 1);
      const lastPage = Math.min(totalPages, firstPage + maxPagesToShow - 1);

      if (firstPage > 1) {
        pageNumbers.push(1);
        if (firstPage > 2) {
          pageNumbers.push("...");
        }
      }

      for (let i = firstPage; i <= lastPage; i++) {
        pageNumbers.push(i);
      }

      if (lastPage < totalPages) {
        if (lastPage < totalPages - 1) {
          pageNumbers.push("...");
        }
        pageNumbers.push(totalPages);
      }
      return pageNumbers.map((page, index) => (
        <button
          key={index}
          onClick={() => {handlePageClick(page); scroll.scrollToTop({ duration: 500, smooth: "easeInOutQuad" })}}
          disabled={page === currentPage}
          className={
            page === currentPage
              ? "custom_items_pagination_item pagination_item_active"
              : "custom_items_pagination_item"
          }
        >
          {page}
        </button>
      ));
    }
  };
  // Ensure pagination UI is always shown, even if there are no items to paginate
  if (totalItems === 0 || totalItems <= itemsPerPage) {
    return null;
  }
  return (
    <>
      <div className="custom_items_pagination_main">
        <div className="custom_items_pagination">
          <button
            onClick={() => { handlePrevClick(); scroll.scrollToTop({ duration: 500, smooth: "easeInOutQuad" }) }}
            disabled={currentPage === 1}
            style={{ opacity: currentPage === 1 ? 0.2 : 1 }}
            className="custom_items_pagination_prev_btn"
          >
            &lt;
          </button>

          {renderPageNumbers()}

          <button
            onClick={() => { handleNextClick(); scroll.scrollToTop({ duration: 500, smooth: "easeInOutQuad" }) }}
            disabled={currentPage === totalPages}
            style={{ opacity: currentPage === totalPages ? 0.2 : 1 }}
            className="custom_items_pagination_next_btn"
          >
            &gt;
          </button>
        </div>
      </div>
    </>
  );
}

export default CustomPagination;
