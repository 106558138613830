import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { destinationImages } from "../../constants";
import { Link } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
import { Dots } from "@fancyapps/ui";
import Skeleton from "react-loading-skeleton";

function Themes({ footerDestinations, heading }) {
  const [loader, setLoader] = useState(true);
  const token = localStorage.getItem("token");
  const [themes, setThemes] = useState([]);

  const fetchThemesDetails = (token) => {
    setLoader(true);
    const myHeaders = new Headers();
 myHeaders.append("key", process.env.REACT_APP_API_KEY);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(`${process.env.REACT_APP_APP_URL}/api/tour-themes`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        // console.log(data, 'themes');
        if (data.success) {
          setThemes(data?.themes);
            setLoader(false);

          // setTimeout(() => {
          //   setLoader(false);
          // }, 2000);
        } else {
          // console.log("Error fetching data");
          setLoader(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching  details:", error);
      });
  };

  useEffect(() => {
    fetchThemesDetails();
  }, []);

  var settings = {
    slidesToShow: 4,
    speed: 500,
    arrows: false,
    dots: true,
    autoplay: true,
    autoplaySpeed: 1500,

    responsive: [
      {
        breakpoint: 768,
        settings: {
          centerPadding: "40px",
          slidesToShow: 3.5,
          arrows: true,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 2.5,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 424,
        settings: {
          slidesToShow: 2,
          arrows: false,
          dots: true,
        },
      },
    ],
  };

  return (
    <section className="common_bg pb-60">
      <div className="container">
        <div className="common_padding pt_20">
          <h3>Explore Holidays By Theme</h3>
        </div>
        <div className="images-flex">
          {loader ? (
            <>
              <Slider {...settings}>
                {Array.from({ length: 5 }).map((_, index) => {
                  return (
                    <Link
                      style={{ width: "33.33%" }}
                      className="destination_link" key={index}
                    >
                      <div>
                        <Skeleton height={300} />
                      </div>
                    </Link>
                  );
                })}
              </Slider>
            </>
          ) : (
            <Slider {...settings}>
              {themes?.map((items, index) => {
                return (
                  <Link
                    onClick={() =>
                      scroll.scrollToTop({
                        duration: 500,
                        smooth: "easeInOutQuad",
                      })
                    }
                    to={`/themes/${items.id}?themename=${items?.slug}`}
                    className="destination_link"
                    key={items.id}
                  >
                    <img
                      src={items.featuredImage}
                      alt={items?.slug}
                      loading="lazy"
                    />
                  </Link>
                );
              })}
            </Slider>
          )}
        </div>
      </div>
    </section>
  );
}

export default Themes;
