import React, { useEffect, useState } from "react";
import VendorHeader from "../../../CustomComponents/Property/VendorHeader/VendorHeader";
import { Link, useParams } from "react-router-dom";
import AgentSideBar from "../AgentSideBar/AgentSideBar";
import AgentHeader from "../AgentHeader/AgentHeader";
import SideBar from "../../../CustomComponents/Property/SideBar/SideBar";
import VendorPromotionsTable from "./VendorPromotionsTable";

const VendorPromotion = () => {
  const { propertyId } = useParams();

  return (
    <>
      <VendorHeader showSelectProperty={true} />
      <div className="sidebar_main_div">
        <SideBar />

            <VendorPromotionsTable />
      </div>
    </>
  );
};

export default VendorPromotion;
