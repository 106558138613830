import React, { useEffect, useState } from "react";
import Dashboard from "../../../CustomComponents/Property/Dashboard/Dashboard";
import CustomerHeader from "../CustomerHeader/CustomerHeader";
import CustomerSideBar from "../CustomerSideBar/CustomerSideBar";
import DashboardCustomer from "../DashboardCustomer/DashboardCustomer";


function CustomerDashboard() {
  return (
    <>
      <CustomerHeader />
      <div className="sidebar_main_div">
        <CustomerSideBar />
        <DashboardCustomer text={false} />
      </div>
    </>
  );
}

export default CustomerDashboard;
