import React from "react";
import "./Loader.css"

function Loader ({size, color}){

    const spinnerStyle = {
        width: size,
        height: size,
        borderColor: `rgba(0, 0, 0, 0.1)`,
        borderLeftColor: color
      };

    return (
        <div className="spinner_parent">
        <div className="spinner" style={spinnerStyle}></div>
      </div>
    )
}

export default Loader;