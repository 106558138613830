import React, { useState } from "react";
import "./ItinearyContent.css";
import NoDataFound from "../NoDataFound/NoDataFound";
import Skeleton from "react-loading-skeleton";
import {
  formatDate,
  formatDateReusable,
  textDateFormatter,
  textDateFormatterNoAdd,
  textDateFormatterNoAddInIteration,
} from "../../utils/utils";
import moment from "moment";

function ItineraryContent({
  singlePackageData,
  loader,
  bookingData,
  customized = false,
}) {
  const [hideContent, setHideContent] = useState(false);
  const [selectedId, setSelectedId] = useState(false);

  // console.log("bookingData", bookingData);

  const departureDate = `${bookingData?.departureYear}-${String(
    bookingData?.departureMonth
  ).padStart(2, "0")}-${String(bookingData?.departureDay).padStart(2, "0")}`;

  // console.log("departureDate", departureDate);

  function getDatesInArray(startDate, numberOfDays) {
    const resultArray = [];

    // Validate that startDate is a valid date
    const parsedStartDate = new Date(startDate);
    if (isNaN(parsedStartDate.getTime())) {
      // throw new Error("Invalid start date provided.");
    }

    // Validate that numberOfDays is a positive integer
    if (
      typeof numberOfDays !== "number" ||
      numberOfDays <= 0 ||
      !Number.isInteger(numberOfDays)
    ) {
      // throw new Error("Number of days must be a positive integer.");
    }

    let currentDate = parsedStartDate;

    if (!customized) return resultArray;
    for (let i = 0; i < numberOfDays; i++) {
      resultArray.push(currentDate.toISOString().split("T")[0]);
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return resultArray;
  }

  const resultDateArray = getDatesInArray(departureDate, 40);
  // console.log(resultDateArray);

  return (
    <>
      {loader ? (
        <div className="package-update-content-containers">
          <div className="package-update-cards">
            <div className="package-card-body">
              {Array.from({ length: 5 }).map((_, index) => (
                <div className="package-update-card" key={index}>
                  <div className="content-consider">
                    <div>
                      <div className="agent">
                        <div className="flex">
                          <span>
                            <Skeleton width={50} />
                          </span>
                          <span style={{ padding: "0 5px" }}>|</span>
                          <strong>
                            <Skeleton width={50} />
                          </strong>
                        </div>
                      </div>
                      <p>
                        <Skeleton />
                      </p>
                      <p>
                        <Skeleton width={700} />
                      </p>
                      <p>
                        <Skeleton width={600} />
                      </p>
                      <p>
                        <Skeleton width={500} />
                      </p>
                      <p>
                        <Skeleton width={400} />
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <>
          {singlePackageData?.packageItinerary?.length > 0 ? (
            <>
              {singlePackageData?.packageItinerary?.map((items, index) => {
                return (
                  <div className="package-update-card" key={index}>
                    <div className="content-consider">
                      <div>
                        <div className="agent flex_wrap">
                          <div>
                            {" "}
                            <span style={{ fontWeight: "bold" }}>
                              {!customized ? (
                                <span> Day {items.dayCount}</span>
                              ) : resultDateArray[items.dayCount - 1] ? (
                                moment(
                                  resultDateArray[items.dayCount - 1]
                                ).format("MMM Do YYYY")
                              ) : (
                                // textDateFormatterNoAddInIteration(
                                //   resultDateArray[items.dayCount - 1]
                                // )
                                `Day ${items.dayCount}`
                              )}
                            </span>
                            <span style={{ padding: "0 5px" }}>|</span>
                            <strong>{items.dayHeading}</strong>
                          </div>

                          <div className="package-view-btn">
                            {items.longDescription && (
                              <button
                                className="view_btn"
                                onClick={() => {
                                  setHideContent(!hideContent); // Toggle hideContent immediately
                                  setSelectedId(items.dayCount); // Set selectedId immediately
                                }}
                              >
                                {hideContent && selectedId === items.dayCount
                                  ? "Hide Details"
                                  : "View Details"}
                              </button>
                            )}
                          </div>
                        </div>

                        {hideContent && selectedId === items.dayCount ? (
                          <p
                            dangerouslySetInnerHTML={{
                              __html: items.longDescription,
                            }}
                          ></p>
                        ) : (
                          <p
                            dangerouslySetInnerHTML={{
                              __html: items.shortDescription,
                            }}
                          ></p>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <NoDataFound />
          )}
        </>
      )}
    </>
  );
}

export default ItineraryContent;
