import React, { useState, useEffect } from "react";
import Footer from "../../CustomComponents/Footer/Footer";
import PageLinks from "../../CustomComponents/PageLinks/PageLinks";
import SingleSearchForm from "../../CustomComponents/SingleSearchForm/SingleSearchForm";
import Header from "../../CustomComponents/Header/Header";
import OurQuery from "../../CustomComponents/OurQuery/OurQuery";
import ChooseUs from "../../CustomComponents/ChooseUs/ChooseUs";
import SendItinerary from "../../CustomComponents/SendItinerary/SendItinerary";
import CustomSlider from "../../CustomComponents/CustomSlider/CustomSlider";
import { DisabledTextButton } from "../../CustomComponents/Buttons/Buttons";
import StarRating from "../../CustomComponents/StarRating/StarRating";
import { Link } from "react-router-dom";
import carImg from "../../assets/images/car.png";
import OverviewContent from "../CustomTabHeader/OverviewContent";
import ItineraryContent from "../CustomTabHeader/ItineraryContent";
import AccommodationContent from "../CustomTabHeader/AccommodationContent";
import InclusionExclusionContent from "../CustomTabHeader/InclusionExclusionContent";

import TabHeader from "../CustomTabHeader/TabHeader";
import SinglePackageHeader from "../SinglePackageHeader/SinglePackageHeader";
import { useNavigate, useLocation } from "react-router-dom";
import { showSuccessToast } from "../../assets/toastUtils";
import Cancellation from "../CustomTabHeader/Cancellation";
import PointRemember from "../CustomTabHeader/PointRemember";
import PackageDetails from "../ButtonToDownload/PackageDetails";
import Gallery from "../CustomTabHeader/Gallery";
import {
  calculateToDatePlus,
  currentDateSingle,
  formatIndianCurrency,
  scrollToTop,
} from "../../utils/utils";
import CustomizableAccommodation from "../CustomTabHeader/CustomizableAccommodation";
import TabHeaderCustomizable from "../CustomTabHeader/TabHeaderCustomizable";
import SendEnquiryPopup from "../SendEnquiryPopup/SendEnquiryPopup";
import Skeleton from "react-loading-skeleton";
import PageMetaData from "../PageMetaData/PageMetaData";
import OgMetaTitles from "../PageMetaData/OgMetaTitles";

function SinglePackage() {
  const [isSliderVisible, setIsSliderVisible] = useState(false);
  const [showRatePopup, setshowRatePopup] = useState(false);
  const [loader, setLoader] = useState(true);
  const [selectedHotelId, setSelectedHotelId] = useState(
    JSON.parse(localStorage.getItem("selected")) || null
  );

  const [selectedMealPlan, setSelectedMealPlan] = useState(null);
  // console.log(selectedMealPlan, "selectedMealPlan");

  const navigate = useNavigate();

  const [selectedCategory, setSelectedCategory] = useState("");
  const [ratesOfPackages, setRatesOfPackages] = useState(null);
  const [selectedHotelPrice, setSelectedHotelPrice] = useState(null);
  const [data, setData] = useState(null);
  const [guest, setGuest] = useState(1);
  const [children, setChildren] = useState(0);
  const [rooms, setRooms] = useState(1);
  const [childAge, setChildAge] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [singlePackageData, setSinglePackageData] = useState({});
  const [activePayment, setActivePayment] = useState("priceSummary");
  const [paymentDetails, setPaymentDetails] = useState(true);
  const [mobilePriceBreakup, setMobilePriceBreakup] = useState(false);
  const [priceBreakupPopup, setPriceBreakupPopup] = useState(false);

  useEffect(() => {
    const updateHideToggle = () => {
      if (window.innerWidth <= 768) {
        setMobilePriceBreakup(true);
      } else {
        setMobilePriceBreakup(false);
      }
    };

    updateHideToggle(); // Set the initial state based on screen size
    window.addEventListener("resize", updateHideToggle);

    return () => window.removeEventListener("resize", updateHideToggle);
  }, []);

  const togglePaymentDetails = () => {
    setPaymentDetails(!paymentDetails);
  };

  const handleButtonClick = () => {
    setActivePayment((prev) =>
      prev === "priceSummary" ? "priceDetails" : "priceSummary"
    );
  };

  const [formData, setFormData] = useState({
    company_name: "",
    name: "",
    phone: "",
    email: "",
    querry_detail: "",
    country: "",
    state: "",
    city: "",
    address: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const storedData = JSON.parse(localStorage.getItem("selected"));
  const agentDetails = JSON.parse(localStorage.getItem("userDetails"));
  // console.log(agentDetails, "agentDetails");
  useEffect(() => {
    if (storedData) {
      setData(selectedHotelId);
    }
  }, [localStorage]);

  const handleCloseSlider = () => {
    setIsSliderVisible(false);
  };
  // console.log(agentDetails, 'agentDetails');
  const [activeTab, setActiveTab] = useState("package-information");

  const renderContent = () => {
    switch (activeTab) {
      case "package-information":
        return (
          <OverviewContent
            singlePackageData={singlePackageData}
            loader={loader}
          />
        );
      case "package-itinerary":
        return (
          <ItineraryContent
            singlePackageData={singlePackageData}
            loader={loader}
          />
        );
      case "package-hotels":
        return (
          <AccommodationContent
            loader={loader}
            singlePackageData={singlePackageData}
            setSelectedCategory={setSelectedCategory}
            selectedCategory={selectedCategory}
            setSelectedMealPlan={setSelectedMealPlan}
            selectedMealPlan={selectedMealPlan}
          />
        );
      case "package-destinations":
        return (
          <InclusionExclusionContent
            singlePackageData={singlePackageData}
            loader={loader}
          />
        );
      case "package-cancellation":
        return (
          <Cancellation singlePackageData={singlePackageData} loader={loader} />
        );
      case "package-remember":
        return (
          <PointRemember
            singlePackageData={singlePackageData}
            loader={loader}
          />
        );
      case "package-gallery":
        return (
          <Gallery singlePackageData={singlePackageData} loader={loader} />
        );
      default:
        return null;
    }
  };

  const groupByCategory = (accommodations) => {
    return accommodations?.reduce((acc, accommodation) => {
      const category = accommodation.accommodationCategory;
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(accommodation);
      return acc;
    }, {});
  };

  useEffect(() => {
    if (selectedCategory) {
      const selectedArray = groupByCategory(singlePackageData?.packageHotels)[
        selectedCategory
      ];

      // console.log(selectedArray, "selectedArray");

      if (selectedArray.length > 0) {
        const findMeaPlan = selectedArray[0]?.accommodationMealPlan;
        setSelectedMealPlan(findMeaPlan);
        // console.log(findMeaPlan, "findMeaPlan");
      }
    }
  }, [selectedCategory]);

  const selectedCategorySend = (category) => {
    // console.log("category", category);
    // setActiveTab(category);
  };

  const handleshowRatePopup = (e) => {
    e.stopPropagation();
    setshowRatePopup(!showRatePopup);
  };

  const postQueryData = () => { };

  // console.log(singlePackageData, "pkg data");

  const [bookingData, setBookingData] = useState({});
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const childAges = [];
    let i = 1;
    while (params.has(`childAge[${i}]`)) {
      childAges.push(params.get(`childAge[${i}]`));
      i++;
    }
    const data = {
      location: params.get("location"),
      room: params.get("room"),
      guest: params.get("guest"),
      children: params.get("children"),
      departureDay: params.get("checkinDay"),
      departureMonth: params.get("checkinMonth"),
      departureYear: params.get("checkinYear"),
      radio: params.get("radio"),
      childAge: childAges,
      packageId: params.get("packageId"),
      packagehandle: params.get("packagehandle"),
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        key: "D4X50uU40Vz4pweFLEmsoiJy107iPfTD4ompprop1",
      },
      // body: raw,

      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_APP_URL}/api/v1/holidays/fetch/single/${data?.packagehandle}`,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setLoader(false);
        setSinglePackageData(data?.data);
        setSelectedCategory(
          data?.data?.packageHotels[0]?.accommodationCategory
        );
        scrollToTop();

        // navigate("/");
        // window.location.href = "/";
      })
      .catch((error) =>
        console.error(
          "There has been a problem with your fetch operation:",
          error
        )
      );

    const checkInDate = `${data.departureYear}-${String(
      data.departureMonth
    ).padStart(2, "0")}-${String(data.departureDay).padStart(2, "0")}`;
    const checkOutDate = `${data.checkoutYear}-${String(
      data.checkoutMonth
    ).padStart(2, "0")}-${String(data.checkoutDay).padStart(2, "0")}`;

    setData({
      fromCity: params.get("location"),
    });

    setGuest(data.guest);
    setChildren(data.children);
    setRooms(data.room);
    setChildAge(childAges);

    if (data.departureDay && data.departureMonth && data.departureYear) {
      setStartDate(checkInDate);
    } else {
      setStartDate(calculateToDatePlus(currentDateSingle, 5));
    }
    // setStartDate(checkInDate);
    setEndDate(checkOutDate);
    setBookingData(data);
  }, [location.search]);

  // console.log("bookingData", startDate);

  const getRatesFromApi = async (
    packageId,
    pax,
    selectedCategory,
    selectedMealPlan
  ) => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("key", process.env.REACT_APP_API_KEY);

    const formdata = new FormData();
    formdata.append("package_id", packageId);
    formdata.append(
      "mealPlan",
      selectedMealPlan && selectedMealPlan.length > 0
        ? selectedMealPlan[0]
        : "ma"
    );
    formdata.append("no_pax", pax == 1 ? 2 : pax);
    formdata.append("hotelCategory", selectedCategory);
    formdata.append("children", bookingData?.children);
    // Append childAge values dynamically
    if (bookingData.children > 0) {
      for (let i = 1; i <= bookingData?.children; i++) {
        formdata.append(`childAge[${i - 1}]`, bookingData?.childAge[i - 1]);
      }
    }

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_APP_URL}/api/v1/holidays/fetch/rate`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setRatesOfPackages(result?.data);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    if (
      singlePackageData?.packageId &&
      bookingData?.guest &&
      selectedCategory &&
      selectedMealPlan
    ) {
      getRatesFromApi(
        singlePackageData?.packageId,
        bookingData?.guest,
        selectedCategory,
        selectedMealPlan
      );
    }
  }, [
    singlePackageData,
    bookingData?.guest,
    selectedCategory,
    selectedMealPlan,
  ]);

  function calculateTotalAmount(
    basePrice = 0,
    discount = 0,
    discountType = "fixed",
    guest = 0,
    ratesOfPackages,
    childAgeArray
  ) {
    const childWithAgeGreaterThanSix = childAgeArray.filter(
      (age) => age > 6
    )?.length;

    const perPaxCount =
      Number(bookingData.guest) +
      childAgeArray?.length +
      ratesOfPackages?.ExtraAdultCount;

    guest = Number(guest);
    basePrice = Number(basePrice);
    discount = Number(discount);

    let startingPrice = basePrice;

    basePrice = basePrice * guest;
    const onlyAdultCost = startingPrice * guest;

    let adultCosts = 0;
    if (ratesOfPackages?.ExtraAdultCount) {
      adultCosts =
        Number(ratesOfPackages?.ExtraAdultCount) *
        Number(ratesOfPackages?.ExtraAdult);
      // console.log(adultCosts);
      const price = basePrice + adultCosts;
      basePrice = basePrice + adultCosts;
    }

    let childCosts = 0;
    let childCostWithBed = 0;
    let childCostWithOutBed = 0;


    if (childAgeArray?.length > 0) {
      childAgeArray.forEach((age) => {
        if (Number(age) >= 6) {
          childCostWithBed += Number(ratesOfPackages?.ChildWithBedCost);
        } else {
          childCostWithOutBed += Number(ratesOfPackages?.ChildWithOutBedCost);
        }
      });
    }

    // if (childCosts) {
    //   basePrice += childCosts;
    // }

    let totalChildCostPrice = childCostWithBed + childCostWithOutBed;

    if (totalChildCostPrice) {
      basePrice += totalChildCostPrice;
    }

    let discountAmount;
    if (discountType === "percentage") {
      discountAmount = (basePrice * discount) / 100;
    } else {
      discountAmount = discount;
    }

    const priceAfterDiscount = basePrice - discountAmount;

    const gst = priceAfterDiscount * 0.05;

    const totalAmount = priceAfterDiscount + gst;

    const discountPercentage = (discountAmount / basePrice) * 100;

    return {
      basePrice: basePrice.toFixed(2),
      discount: discountAmount.toFixed(2),
      priceAfterDiscount: priceAfterDiscount.toFixed(2),
      gst: gst.toFixed(2),
      totalAmount: totalAmount.toFixed(2),
      discountAmount: discountAmount.toFixed(2),
      discountPercentage: discountPercentage,
      taxAmount: gst.toFixed(2),
      ExtraAdultCost: adultCosts,
      childCosts,
      childCostWithBed,
      childCostWithOutBed,
      totalChildCostPrice,
      adultNumber: guest,
      onlyAdultCost: onlyAdultCost,
      extraAdultNumber: ratesOfPackages?.ExtraAdultCount,
      extraAdultCost: adultCosts,
      childNumber: childAgeArray?.length,

      perPaxCount,
      perPaxCost: (totalAmount / perPaxCount).toFixed(2),
    };
  }

  const calculationsOfPackages = calculateTotalAmount(
    ratesOfPackages?.Price,
    agentDetails?.agent_category_discount
      ? agentDetails?.agent_category_discount
      : 0,
    agentDetails?.agent_discount_type
      ? agentDetails?.agent_discount_type
      : "percentage",
    ratesOfPackages?.NoPax,
    ratesOfPackages,
    bookingData?.childAge ? bookingData?.childAge : []
  );

  // console.log(calculationsOfPackages, "calculationsOfPackages");
  // console.log(bookingData, "bookingData");

  const navigateToCheckOutPackage = () => {
    let hasChildAgeError = false;
    const childAge = bookingData?.childAge;
    const sanitizedChildAges = childAge
      .map((age) => (age !== "" ? age : null))
      .filter((age) => age !== null);

    const childAgeParams = sanitizedChildAges
      .map((age, index) => {
        if (age > 12) {
          alert("Child age must be between 0 and 12");
          hasChildAgeError = true;
          return;
        }
        return `&childAge[${index + 1}]=${age}`;
      })
      .join("");

    navigate(
      `/checkout-package/${bookingData?.packagehandle}?location=${bookingData?.location
      }&guest=${bookingData?.guest}&children=${bookingData?.children
      }${childAgeParams}&checkinDay=${new Date(
        startDate
      ).getDate()}&checkinMonth=${new Date(startDate).getMonth() + 1
      }&checkinYear=${new Date(startDate).getFullYear()}`,
      {
        state: {
          packageData: singlePackageData,
          bookingData: bookingData,
          selectedCategory: selectedCategory,
          calculationsOfPackages: calculationsOfPackages,
          customized: false,
        },
      }
    );
  };

  const navigateToCustomizePackage = () => {
    let hasChildAgeError = false;
    const childAge = bookingData?.childAge;
    const sanitizedChildAges = childAge
      .map((age) => (age !== "" ? age : null))
      .filter((age) => age !== null);

    const childAgeParams = sanitizedChildAges
      .map((age, index) => {
        if (age > 12) {
          alert("Child age must be between 0 and 12");
          hasChildAgeError = true;
          return;
        }
        return `&childAge[${index + 1}]=${age}`;
      })
      .join("");

    navigate(
      `/customize-package/${bookingData?.packagehandle}?packagehandle=${bookingData?.packagehandle
      }&location=${bookingData?.location}&guest=${bookingData?.guest
      }&children=${bookingData?.children ? bookingData?.children : 0
      }${childAgeParams}&checkinDay=${new Date(
        startDate
      ).getDate()}&checkinMonth=${new Date(startDate).getMonth() + 1
      }&checkinYear=${new Date(startDate).getFullYear()}`,
      {
        state: {
          packageData: singlePackageData,
          bookingData: bookingData,
          selectedCategory: selectedCategory,
          calculationsOfPackages: calculationsOfPackages,
        },
      }
    );
  };

  //   const childCostsUnder6 = calculationsOfPackages?.childCostsUnder6;
  // const childCostsOver6 = calculationsOfPackages?.childCostsOver6;
  const under6AgeChildren = bookingData?.childAge?.filter(age => Number(age) < 6);
  const over6AgeChildren = bookingData?.childAge?.filter(age => Number(age) >= 6);

  return (
    <>
      <Header />

      <PageMetaData
        title={`${singlePackageData?.packageName &&
          singlePackageData?.packageName !== "" &&
          singlePackageData?.packageName !== undefined &&
          singlePackageData?.packageName
          } - Mountain Mysteries`}
        description="Enhance your hotel capabilities with RevCatalyst's powerful system. Optimize performance, streamline operations, and unleash the full potential of property."
        keywords="hotel management, RevCatalyst, property optimization"
      />

      <OgMetaTitles
        title={`${singlePackageData?.packageName &&
          singlePackageData?.packageName !== "" &&
          singlePackageData?.packageName !== undefined &&
          singlePackageData?.packageName
          } - Mountain Mysteries`}
        type="Enhance your hotel capabilities with RevCatalyst's powerful system. Optimize performance, streamline operations, and unleash the full potential of property."
        url={`https://www.mountainmysteries.com/package/${singlePackageData?.packagehandle}?location=&guest=2&children=0&packagehandle=${singlePackageData?.packagehandle}#package-information`}
      />
      {/* <SingleSearchForm /> */}
      {/* <PageLinks /> */}

      <section>
        <div className="container">
          {/* <PackageDetails packageData={singlePackageData} /> */}
          <SinglePackageHeader packageData={singlePackageData} />
          <main className="justify_content_center flex  packages_flex_wrap">
            <div className="reservation-content">
              <div
                className="m_0 package-update-profile-tab-form profile_tab_form"
                style={{ padding: "0" }}
              >
                <div className="package-update-content">
                  {/* {singlePackageData?.canCustomise ? (
                    <TabHeaderCustomizable
                      activeTab={activeTab}
                      setActiveTab={setActiveTab}
                    />
                  ) : ( */}
                  <TabHeader
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                  />
                  {/* )} */}

                  <div className="package-update-content-containers">
                    {renderContent()}
                  </div>
                </div>
              </div>
            </div>

            <aside id="Price-Break">
              <div className="common_background common_background_parent_sticky">
                <div className="flex justify_content_between align_items_center">
                  <h4 style={{ fontSize: "14px", padding: "10px 0px" }}>
                    Fare Summary
                  </h4>

                  <div onClick={togglePaymentDetails}>
                    <button
                      className={`${activePayment === "priceSummary" ? "" : ""
                        }`}
                      onClick={handleButtonClick}
                    >
                      <small className="link_btn">
                        {activePayment === "priceSummary"
                          ? "View Breakup"
                          : "Minimize Breakup"}{" "}
                      </small>
                      <svg
                        width="13"
                        height="8"
                        viewBox="0 0 13 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                          transition: "transform 0.3s",
                          transform: paymentDetails
                            ? "rotate(180deg)"
                            : "rotate(0deg)",
                        }}
                      >
                        <path
                          d="M11.5527 1.34473L5.86308 6.65507L0.552735 1.34473"
                          stroke="#222222"
                          strokeWidth="1.5"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <div>
                  {/* {activePayment === "priceSummary" && ( */}
                  <>
                    {activePayment !== "priceSummary" && (
                      <>
                        <div className="flex justify_content_between mt-10">
                          <p>
                            Adult Cost{" "}
                            <small>
                              {" "}
                              (
                              {calculationsOfPackages?.onlyAdultCost /
                                calculationsOfPackages?.adultNumber}{" "}
                              X {calculationsOfPackages?.adultNumber})
                            </small>
                          </p>
                          <span
                            className={`room_text-right ${Number(calculationsOfPackages?.onlyAdultCost) <=
                              0 && "filterBlur3"
                              }`}
                          >
                            {formatIndianCurrency(
                              calculationsOfPackages?.onlyAdultCost
                            )}
                          </span>
                        </div>

                        {calculationsOfPackages?.extraAdultNumber > 0 &&
                          calculationsOfPackages?.ExtraAdultCost ? (
                          <div className="flex justify_content_between mt-10">
                            <p>
                              Extra Adult Cost{" "}
                              <small>
                                {" "}
                                (
                                {calculationsOfPackages?.ExtraAdultCost /
                                  calculationsOfPackages?.extraAdultNumber}{" "}
                                X {calculationsOfPackages?.extraAdultNumber})
                              </small>
                            </p>
                            <span
                              className={`room_text-right ${Number(calculationsOfPackages?.totalAmount) <=
                                0 && "filterBlur3"
                                }`}
                            >
                              {formatIndianCurrency(
                                calculationsOfPackages?.ExtraAdultCost
                              )}
                              {/* {formatIndianCurrency(Number(calculationsOfPackages?.ExtraAdultCost) + Number(calculationsOfPackages?.basePrice))} */}
                            </span>
                          </div>
                        ) : null}

                        {calculationsOfPackages?.extraAdultNumber > 0 && (
                          <div className="flex justify_content_between mt-10">
                            <p>
                              <b>Total Adult Cost</b>
                            </p>
                            <span
                              className={`room_text-right ${Number(calculationsOfPackages?.totalAmount) <=
                                0 && "filterBlur3"
                                }`}
                            >
                              <b>
                                {formatIndianCurrency(
                                  calculationsOfPackages?.ExtraAdultCost +
                                  calculationsOfPackages?.onlyAdultCost
                                )}
                              </b>

                              {/* {formatIndianCurrency(Number(calculationsOfPackages?.ExtraAdultCost) + Number(calculationsOfPackages?.basePrice))} */}
                            </span>
                          </div>
                        )}

                        {/* {calculationsOfPackages?.childNumber > 0 &&
                          calculationsOfPackages?.childCosts ? (
                          <div className="flex justify_content_between mt-10">
                            <p>
                              Child Cost{" "}
                              <small>
                                ̀ (
                                {calculationsOfPackages?.childCosts /
                                  calculationsOfPackages?.childNumber}{" "}
                                X {calculationsOfPackages?.childNumber})
                              </small>
                            </p>
                            <span
                              className={`room_text-right ${Number(calculationsOfPackages?.childCosts) <=
                                0 && "filterBlur3"
                                }`}
                            >
                              {formatIndianCurrency(
                                calculationsOfPackages?.childCosts
                              )}
                            </span>
                          </div>
                        ) : null} */}

                        {(under6AgeChildren.length > 0 && calculationsOfPackages?.childNumber > 0 && calculationsOfPackages?.childCostWithOutBed) ? (
                          <div className="flex justify_content_between mt-10">
                            <p>
                              Child Cost ({`< 6 yrs`}){" "}
                              <small>
                                ̀ ({calculationsOfPackages?.childCostWithOutBed / under6AgeChildren.length} X {under6AgeChildren.length})
                              </small>
                            </p>
                            <span
                              className={`room_text-right ${Number(calculationsOfPackages?.childCostWithOutBed) <= 0 && "filterBlur3"}`}
                            >
                              {formatIndianCurrency(calculationsOfPackages?.childCostWithOutBed)}
                            </span>
                          </div>
                        ) : null}
                        {(over6AgeChildren.length > 0 && calculationsOfPackages?.childNumber > 0 && calculationsOfPackages?.childCostWithBed) ? (
                          <div className="flex justify_content_between mt-10">
                            <p>
                              Child Cost ({`> 6 yrs`}){" "}
                              <small>
                                ̀ ({calculationsOfPackages?.childCostWithBed / over6AgeChildren.length} X {over6AgeChildren.length})
                              </small>
                            </p>
                            <span
                              className={`room_text-right ${Number(calculationsOfPackages?.childCostWithBed) <= 0 && "filterBlur3"}`}
                            >
                              {formatIndianCurrency(calculationsOfPackages?.childCostWithBed)}
                            </span>
                          </div>
                        ): null}
                      </>
                    )}

                    <div className="flex justify_content_between mt-10">
                      <p>
                        <b>Total Package Cost</b>
                      </p>
                      <span
                        className={`room_text-right alert_text ${Number(calculationsOfPackages?.totalAmount) <= 0 &&
                          "filterBlur3"
                          }`}
                      >
                        <b>
                          {" "}
                          {formatIndianCurrency(
                            calculationsOfPackages?.basePrice
                          )}
                        </b>
                      </span>
                    </div>

                    {calculationsOfPackages?.discount > 0 && (
                      <>
                        <div className="flex justify_content_between mt-10">
                          <p>
                            Discount (
                            {calculationsOfPackages?.discountPercentage}
                            %)
                          </p>
                          <span
                            className={`room_text-right ${Number(calculationsOfPackages?.totalAmount) <=
                              0 && "filterBlur3"
                              }`}
                          >
                            {formatIndianCurrency(
                              calculationsOfPackages?.discountAmount
                            )}
                          </span>
                        </div>
                      </>
                    )}

                    {calculationsOfPackages?.discount > 0 && (
                      <div className="flex justify_content_between mt-10 border-bottom-one p_b_10">
                        <p>
                          Package Cost <br />
                          After Discount
                        </p>
                        <span
                          className={`room_text-right ${Number(calculationsOfPackages?.totalAmount) <= 0 &&
                            "filterBlur3"
                            }`}
                        >
                          {formatIndianCurrency(
                            calculationsOfPackages?.priceAfterDiscount
                          )}
                        </span>
                      </div>
                    )}

                    <div className="flex justify_content_between mt-10 border-bottom-one p_b_10">
                      <p>GST(5%) </p>
                      <span
                        className={`room_text-right  ${Number(calculationsOfPackages?.totalAmount) <= 0 &&
                          "filterBlur3"
                          }`}
                      >
                        {formatIndianCurrency(
                          calculationsOfPackages?.taxAmount
                        )}
                      </span>
                    </div>

                    <div className="flex justify_content_between mt-10">
                      <h6>
                        <b>Total Amount to be paid</b>
                      </h6>
                      <h6>
                        <span
                          className={` ${Number(calculationsOfPackages?.totalAmount) <= 0 &&
                            "filterBlur3"
                            }`}
                        >
                          <b>
                            {formatIndianCurrency(
                              calculationsOfPackages?.totalAmount
                            )}
                          </b>
                        </span>
                      </h6>
                    </div>
                    {/* <div className="flex justify_content_between mt-10">
                      <h6>
                        <b>
                          Per Pax Cost{" "}
                          <small>({calculationsOfPackages?.perPaxCount})</small>
                        </b>
                      </h6>
                      <h6>
                        <b>
                          {" "}
                          {formatIndianCurrency(
                            calculationsOfPackages?.perPaxCost
                          )}
                        </b>
                      </h6>
                    </div>{" "} */}
                  </>
                  {/* )} */}
                </div>

                <div className="buttons flex_direction_col">
                  {/* <Link to={"/checkout-package"}> */}
                  {Number(calculationsOfPackages?.totalAmount) > 0 ? (
                    <>
                      {/* {singlePackageData?.canCustomise && (
                        <button
                          onClick={navigateToCustomizePackage}
                          className="common_btn"
                        >
                          Customize & Book
                        </button>
                      )} */}
                      <button
                        style={{ marginTop: "10px", borderRadius: "15px" }}
                        onClick={navigateToCheckOutPackage}
                        className="primary_btn"
                      >
                        Book Now
                      </button>
                    </>
                  ) : (
                    <>
                      {/* {singlePackageData?.canCustomise && (
                        <button
                          onClick={navigateToCustomizePackage}
                          className="common_btn_disabled"
                        >
                          Customize
                        </button>
                      )} */}
                      {/* <button
                        onClick={navigateToCheckOutPackage}
                        className="common_btn_disabled"
                        disabled
                      >
                        Book Now
                      </button> */}
                    </>
                  )}
                  {/* </Link> */}
                  <button
                    onClick={(e) => handleshowRatePopup(e)}
                    className="secondary_btn mt-10 border-radius"
                  >
                    Get Quote
                  </button>
                </div>
              </div>
              <OurQuery />
              <ChooseUs />
              <SendItinerary singlePackageData={singlePackageData} />
            </aside>
            {mobilePriceBreakup && (
              <>
                <div className="flex justify_content_between total_amount align_items_center">
                  <div>
                    <h6 className="font_12">Total Amount to be paid </h6>
                    <h6>
                      <span
                        className={` ${Number(calculationsOfPackages?.totalAmount) <= 0 &&
                          "filterBlur3"
                          }`}
                      >
                        {formatIndianCurrency(
                          calculationsOfPackages?.totalAmount
                        )}{" "}
                        <svg
                          onClick={() => setPriceBreakupPopup(true)}
                          xmlns="http://www.w3.org/2000/svg"
                          // xmlns:xlink="http://www.w3.org/1999/xlink"
                          viewBox="0,0,256,256"
                          width="20px"
                          height="20px"
                          fill-rule="nonzero"
                        >
                          <g
                            fill="#ffffff"
                            fill-rule="nonzero"
                            stroke="none"
                            strokeWidth="1"
                            stroke-linecap="butt"
                            stroke-linejoin="miter"
                            stroke-miterlimit="10"
                            stroke-dasharray=""
                            stroke-dashoffset="0"
                            font-family="none"
                            font-weight="none"
                            font-size="none"
                            text-anchor="none"
                            style={{ mixBlendMode: "normal" }}
                          >
                            <g transform="scale(8,8)">
                              <path d="M16,3c-7.16797,0 -13,5.83203 -13,13c0,7.16797 5.83203,13 13,13c7.16797,0 13,-5.83203 13,-13c0,-7.16797 -5.83203,-13 -13,-13zM16,5c6.08594,0 11,4.91406 11,11c0,6.08594 -4.91406,11 -11,11c-6.08594,0 -11,-4.91406 -11,-11c0,-6.08594 4.91406,-11 11,-11zM15,10v2h2v-2zM15,14v8h2v-8z"></path>
                            </g>
                          </g>
                        </svg>
                      </span>
                    </h6>{" "}
                  </div>
                  <div className="flex flex_gap_10 align_items_center">
                    <div>
                      {/* {singlePackageData?.canCustomise && (
                        <button
                          style={{
                            background: "#fff",
                            color: "#016937",
                            padding: "7px",
                            fontSize: "10px",
                            borderRadius: "4px",
                          }}
                          onClick={navigateToCustomizePackage}
                        >
                          <b> Customize</b>
                        </button>
                      )} */}
                    </div>
                    <div>
                      {" "}
                      <button
                        style={{ background: "white", color: "#016937" }}
                        onClick={navigateToCheckOutPackage}
                        className="primary_btn fit-width"
                      >
                        <b> Book Now</b>
                      </button>
                    </div>
                  </div>
                </div>
                {priceBreakupPopup && (
                  <div className="bg_back_drop_overlay">
                    <div
                      className="common_background common_background_parent_sticky"
                      id="PriceBreak"
                    >
                      <div className="flex justify_content_end p_b_10 ">
                        <svg
                          onClick={() => setPriceBreakupPopup(false)}
                          width="14"
                          height="13"
                          viewBox="0 0 14 13"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 12.5L13 0.5"
                            stroke="#AAAAAA"
                            strokeWidth="1.2"
                          />
                          <path
                            d="M13 12.5L1 0.5"
                            stroke="#AAAAAA"
                            strokeWidth="1.2"
                          />
                        </svg>
                      </div>
                      <div className="flex justify_content_between align_items_center">
                        <h4 style={{ fontSize: "14px" }}>
                          Fare Summary
                        </h4>

                        <div onClick={togglePaymentDetails}>
                          <button
                            className={`${activePayment === "priceSummary" ? "" : ""
                              }`}
                            onClick={handleButtonClick}
                          >
                            <small className="link_btn">
                              {activePayment === "priceSummary"
                                ? "View Breakup"
                                : "Minimize Breakup"}{" "}
                            </small>
                            <svg
                              width="13"
                              height="8"
                              viewBox="0 0 13 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              style={{
                                transition: "transform 0.3s",
                                transform: paymentDetails
                                  ? "rotate(180deg)"
                                  : "rotate(0deg)",
                              }}
                            >
                              <path
                                d="M11.5527 1.34473L5.86308 6.65507L0.552735 1.34473"
                                stroke="#222222"
                                strokeWidth="1.5"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                      <div>
                        {/* {activePayment === "priceSummary" && ( */}
                        <>
                          {activePayment !== "priceSummary" && (
                            <>
                              <div className="flex justify_content_between mt-10">
                                <p>
                                  Adult Cost{" "}
                                  <small>
                                    {" "}
                                    (
                                    {calculationsOfPackages?.onlyAdultCost /
                                      calculationsOfPackages?.adultNumber}{" "}
                                    X {calculationsOfPackages?.adultNumber})
                                  </small>
                                </p>
                                <span
                                  className={`room_text-right ${Number(
                                    calculationsOfPackages?.onlyAdultCost
                                  ) <= 0 && "filterBlur3"
                                    }`}
                                >
                                  {formatIndianCurrency(
                                    calculationsOfPackages?.onlyAdultCost
                                  )}
                                </span>
                              </div>

                              {calculationsOfPackages?.extraAdultNumber > 0 &&
                                calculationsOfPackages?.ExtraAdultCost ? (
                                <div className="flex justify_content_between mt-10">
                                  <p>
                                    Extra Adult Cost{" "}
                                    <small>
                                      {" "}
                                      (
                                      {calculationsOfPackages?.ExtraAdultCost /
                                        calculationsOfPackages?.extraAdultNumber}{" "}
                                      X{" "}
                                      {calculationsOfPackages?.extraAdultNumber}
                                      )
                                    </small>
                                  </p>
                                  <span
                                    className={`room_text-right ${Number(
                                      calculationsOfPackages?.totalAmount
                                    ) <= 0 && "filterBlur3"
                                      }`}
                                  >
                                    {formatIndianCurrency(
                                      calculationsOfPackages?.ExtraAdultCost
                                    )}
                                    {/* {formatIndianCurrency(Number(calculationsOfPackages?.ExtraAdultCost) + Number(calculationsOfPackages?.basePrice))} */}
                                  </span>
                                </div>
                              ) : null}

                              {calculationsOfPackages?.extraAdultNumber > 0 && (
                                <div className="flex justify_content_between mt-10">
                                  <p>
                                    <b>Total Adult Cost</b>
                                  </p>
                                  <span
                                    className={`room_text-right ${Number(
                                      calculationsOfPackages?.totalAmount
                                    ) <= 0 && "filterBlur3"
                                      }`}
                                  >
                                    <b>
                                      {formatIndianCurrency(
                                        calculationsOfPackages?.ExtraAdultCost +
                                        calculationsOfPackages?.onlyAdultCost
                                      )}
                                    </b>

                                    {/* {formatIndianCurrency(Number(calculationsOfPackages?.ExtraAdultCost) + Number(calculationsOfPackages?.basePrice))} */}
                                  </span>
                                </div>
                              )}

                              {/* {calculationsOfPackages?.childNumber > 0 && (
                                <div className="flex justify_content_between mt-10">
                                  <p>
                                    Child Cost{" "}
                                    <small>
                                      {" "}
                                      (
                                      {calculationsOfPackages?.childCosts /
                                        calculationsOfPackages?.childNumber}{" "}
                                      X {calculationsOfPackages?.childNumber})
                                    </small>
                                  </p>
                                  <span
                                    className={`room_text-right ${Number(
                                      calculationsOfPackages?.childCosts
                                    ) <= 0 && "filterBlur3"
                                      }`}
                                  >
                                    {formatIndianCurrency(
                                      calculationsOfPackages?.childCosts
                                    )}
                                  </span>
                                </div>
                              )} */}

                              {under6AgeChildren.length > 0 && calculationsOfPackages?.childNumber > 0 && calculationsOfPackages?.childCostWithOutBed && (
                                <div className="flex justify_content_between mt-10">
                                  <p>
                                    Child Cost ({`< 6 yrs`}){" "}
                                    <small>
                                      ̀ ({calculationsOfPackages?.childCostWithOutBed / under6AgeChildren.length} X {under6AgeChildren.length})
                                    </small>
                                  </p>
                                  <span
                                    className={`room_text-right ${Number(calculationsOfPackages?.childCostWithOutBed) <= 0 && "filterBlur3"}`}
                                  >
                                    {formatIndianCurrency(calculationsOfPackages?.childCostWithOutBed)}
                                  </span>
                                </div>
                              )}

                              {over6AgeChildren.length > 0 && calculationsOfPackages?.childNumber > 0 && calculationsOfPackages?.childCostWithBed && (
                                <div className="flex justify_content_between mt-10">
                                  <p>
                                    Child Cost ({`> 6 yrs`}){" "}
                                    <small>
                                      ̀ ({calculationsOfPackages?.childCostWithBed / over6AgeChildren.length} X {over6AgeChildren.length})
                                    </small>
                                  </p>
                                  <span
                                    className={`room_text-right ${Number(calculationsOfPackages?.childCostWithBed) <= 0 && "filterBlur3"}`}
                                  >
                                    {formatIndianCurrency(calculationsOfPackages?.childCostWithBed)}
                                  </span>
                                </div>
                              )}
                            </>
                          )}
                          <div className="flex justify_content_between mt-10">
                            <p>
                              <b>Total Package Cost</b>
                            </p>
                            <span
                              className={`room_text-right alert_text ${Number(calculationsOfPackages?.totalAmount) <=
                                0 && "filterBlur3"
                                }`}
                            >
                              <b>
                                {" "}
                                {formatIndianCurrency(
                                  calculationsOfPackages?.basePrice
                                )}
                              </b>
                            </span>
                          </div>
                          {calculationsOfPackages?.discount > 0 && (
                            <>
                              <div className="flex justify_content_between mt-10">
                                <p>
                                  Discount (
                                  {calculationsOfPackages?.discountPercentage}
                                  %)
                                </p>
                                <span
                                  className={`room_text-right ${Number(
                                    calculationsOfPackages?.totalAmount
                                  ) <= 0 && "filterBlur3"
                                    }`}
                                >
                                  {formatIndianCurrency(
                                    calculationsOfPackages?.discountAmount
                                  )}
                                </span>
                              </div>
                            </>
                          )}
                          {calculationsOfPackages?.discount > 0 && (
                            <div className="flex justify_content_between mt-10 border-bottom-one p_b_10">
                              <p>
                                Package Cost <br />
                                After Discount
                              </p>
                              <span
                                className={`room_text-right ${Number(calculationsOfPackages?.totalAmount) <=
                                  0 && "filterBlur3"
                                  }`}
                              >
                                {formatIndianCurrency(
                                  calculationsOfPackages?.priceAfterDiscount
                                )}
                              </span>
                            </div>
                          )}
                          <div className="flex justify_content_between mt-10 border-bottom-one p_b_10">
                            <p>GST(5%) </p>
                            <span
                              className={`room_text-right  ${Number(calculationsOfPackages?.totalAmount) <=
                                0 && "filterBlur3"
                                }`}
                            >
                              {formatIndianCurrency(
                                calculationsOfPackages?.taxAmount
                              )}
                            </span>
                          </div>
                          <div className="flex justify_content_between mt-10">
                            <h6>
                              <b>Total Amount to be paid</b>
                            </h6>
                            <h6>
                              <span
                                className={` ${Number(calculationsOfPackages?.totalAmount) <=
                                  0 && "filterBlur3"
                                  }`}
                              >
                                <b>
                                  {formatIndianCurrency(
                                    calculationsOfPackages?.totalAmount
                                  )}
                                </b>
                              </span>
                            </h6>
                          </div>
                          {/* <div className="flex justify_content_between mt-10">
      <h6>
        <b>
          Per Pax Cost{" "}
          <small>({calculationsOfPackages?.perPaxCount})</small>
        </b>
      </h6>
      <h6>
        <b>
          {" "}
          {formatIndianCurrency(
            calculationsOfPackages?.perPaxCost
          )}
        </b>
      </h6>
    </div>{" "} */}
                        </>
                        {/* )} */}
                      </div>

                      <div className="buttons flex_direction_col">
                        {/* <Link to={"/checkout-package"}> */}
                        {Number(calculationsOfPackages?.totalAmount) > 0 ? (
                          <>
                            {/* {singlePackageData?.canCustomise && (
                              <button
                                onClick={navigateToCustomizePackage}
                                className="common_btn sm-btn"
                              >
                                Customize & Book
                              </button>
                            )} */}
                          </>
                        ) : (
                          <>
                            {singlePackageData?.canCustomise && (
                              <button
                                onClick={navigateToCustomizePackage}
                                className="common_btn_disabled"
                              >
                                Customize
                              </button>
                            )}
                          </>
                        )}
                        {/* </Link> */}
                        <button
                          onClick={(e) => handleshowRatePopup(e)}
                          className="secondary_btn mt-10 border-radius"
                        >
                          Get Quote
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                <aside>
                  <OurQuery />
                  <ChooseUs className="mt-20" />
                  <SendItinerary singlePackageData={singlePackageData} />
                </aside>
              </>
            )}
          </main>
        </div>
      </section>
      <Footer />

      {showRatePopup && (
        <SendEnquiryPopup
          package_id={singlePackageData?.packageId}
          setshowRatePopup={setshowRatePopup}
          typeDetail="package"
        />
      )}
    </>
  );
}

export default SinglePackage;
