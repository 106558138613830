import React, { useState } from "react";

const GetQuoteDownloadPopup = ({package_id, setshowRatePopup, handleDownload}) => {
  // const [showRatePopup, setShowRatePopup] = useState(false);

  const [formData, setFormData] = useState({
    company_name: "",
    name: "",
    phone: "",
    email: "",
    querry_detail: "",
    country: "",
    state: "",
    city: "",
    address: "",
    travel_date: "",
    leadSubject: "Download Itinerary",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleShowRatePopup = (e) => {
    e.stopPropagation();
    setshowRatePopup(false);
  };

  const postDataHandle = (e) => {

    e.preventDefault();
    const myHeaders = new Headers();

    myHeaders.append("Accept", "application/json");
 myHeaders.append("key", process.env.REACT_APP_API_KEY);

    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      leadName: formData?.company_name,

      leadPhone: formData?.phone,

      leadSubject: formData?.subject,

      leadEmail: formData?.email,

      package_id: package_id,

      leadDetail: formData?.querry_detail,

      leadSource: "Website",

      query_company: formData?.name,

      query_type: "agent",

      travel_date: formData?.travel_date,

      leadSubject: "Download Itinerary",
    });

    const requestOptions = {
      method: "POST",

      headers: myHeaders,

      body: raw,

      redirect: "follow",
    };

    fetch(`${process.env.REACT_APP_APP_URL}/api/v1/send-query`, requestOptions)
      .then((response) => response.json())

      .then((result) => {
        if(result.success){
          setshowRatePopup(false)
          handleDownload()
        }
      })

      .catch((error) => console.error(error));
  };
  return (
    <div className="bg-backdrop">
      <form onSubmit={postDataHandle}
        className="dashboard_checkin_modal_container"
        style={{
          width: "500px",
          padding: "20px",
          borderRadius: "8px",
          background: "#fff",
        }}
      >
        <h4 style={{ fontSize: "18px", fontWeight: "600", color: "#BB735D" }}>
          Download Itinerary
        </h4>
        <div
          className="dashboard_checkin_modal_close_btn"
          onClick={handleShowRatePopup}
          style={{
            cursor: "pointer",
            position: "absolute",
            top: "10px",
            right: "10px",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
          >
            <path
              d="M1 12.7464L13 0.851318"
              stroke="#AAAAAA"
              strokeWidth="1.2"
            />
            <path
              d="M13 12.7463L0.999999 0.851296"
              stroke="#AAAAAA"
              strokeWidth="1.2"
            />
          </svg>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            marginTop: "20px",
          }}
        >
          <input
          required
            className="w-full"
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            placeholder="Your Name"
            style={{
              padding: "10px",
              borderRadius: "4px",
              border: "1px solid #ccc",
            }}
          />
          <input
          required
            className="w-full"
            type="text"
            name="company_name"
            value={formData.company_name}
            onChange={handleInputChange}
            placeholder="Company Name"
            style={{
              padding: "10px",
              borderRadius: "4px",
              border: "1px solid #ccc",
            }}
          />
          <input
          required
            className="w-full"
            type="number"
            name="phone"
            value={formData.phone}
            onChange={handleInputChange}
            placeholder="Phone"
            style={{
              padding: "10px",
              borderRadius: "4px",
              border: "1px solid #ccc",
            }}
          />
          <input
          required
            className="w-full"
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            placeholder="Email"
            style={{
              padding: "10px",
              borderRadius: "4px",
              border: "1px solid #ccc",
            }}
          />

          <input
          required
            className="w-full"
            type="date"
            name="travel_date"
            value={formData.travel_date}
            onChange={handleInputChange}
            placeholder="Travel Date"
            style={{
              padding: "10px",
              borderRadius: "4px",
              border: "1px solid #ccc",
            }}
          />
          <textarea
            className="w-full"
            required
            type="text-area"
            name="querry_detail"
            value={formData.querry_detail}
            onChange={handleInputChange}
            placeholder="Querry Detail"
            style={{
              padding: "10px",
              borderRadius: "4px",
              border: "1px solid #ccc",
              height: "100px",
              overflow: "scroll",
            }}
          />
          {/* <div class="search-btn-width"> */}
            <button type="submit" class="common_btn">Download Itinerary</button>
          {/* </div> */}
        </div>
      </form>
    </div>
  );
};

export default GetQuoteDownloadPopup;
