import React from 'react';

function TabHeader({ activeTab, setActiveTab }) {
  
  return (
    <div className="package-update-content-tab-header">
      <div className="package-update-tab-header">
        <ul className="package-update-tab-list">
          <li className={`package-update-tab-item ${activeTab === "package-information" ? "active" : ""}`}>
            <div
              // href="#package-information"
              className="package-update-scrollAnchor cursor_pointer"
              onClick={() => setActiveTab("package-information")}
            >
              Overview
            </div>
          </li>
          <li className={`package-update-tab-item ${activeTab === "package-itinerary" ? "active" : ""}`}>
            <div
              // href="#package-itinerary"
              className="package-update-scrollAnchor cursor_pointer"
              onClick={() => setActiveTab("package-itinerary")}
            >
              Itinerary
            </div>
          </li>
          <li className={`package-update-tab-item ${activeTab === "package-hotels" ? "active" : ""}`}>
            <div
              // href="#package-hotels"
              className="package-update-scrollAnchor cursor_pointer"
              onClick={() => setActiveTab("package-hotels")}
            >
              Accommodation
            </div>
          </li>
          <li className={`package-update-tab-item ${activeTab === "package-destinations" ? "active" : ""}`}>
            <div
              // href="#package-destinations"
              className="package-update-scrollAnchor cursor_pointer"
              onClick={() => setActiveTab("package-destinations")}
            >
              Inclusion & Exclusion
            </div>
          </li>
          <li className={`package-update-tab-item ${activeTab === "package-cancellation" ? "active" : ""}`}>
            <div
              // href="#package-cancellation"
              className="package-update-scrollAnchor cursor_pointer"
              onClick={() => setActiveTab("package-cancellation")}
            >
            Policies
            </div>
          </li>
          <li className={`package-update-tab-item ${activeTab === "package-remember" ? "active" : ""}`}>
            <div
              // href="#package-remember"
              className="package-update-scrollAnchor cursor_pointer"
              onClick={() => setActiveTab("package-remember")}
            >
              Points to remember
            </div>
          </li>
          <li className={`package-update-tab-item ${activeTab === "package-gallery" ? "active" : ""}`}>
            <div
              // href="#package-gallery"
              className="package-update-scrollAnchor cursor_pointer"
              onClick={() => setActiveTab("package-gallery")}
            >
             Gallery
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default TabHeader;
