import React, { useState } from "react";
import { NavLink, Link, useParams, useLocation } from "react-router-dom";

import { useEffect } from "react";

const CustomerSideBar = () => {
  const [dropdown, setDropdown] = useState(false);
  const location = useLocation();

  const handleOpenDropdown = () => {
    setDropdown((prevState) => !prevState);
  };

  useEffect(() => {
    if (
      location.pathname.startsWith("/partner/rates/") ||
      location.pathname.startsWith("/partner/inventory/")
    ) {
      setDropdown(true);
    } else {
      setDropdown(false);
    }
  }, []);

  const { id } = useParams();
  // console.log(id);

  return (
    <>
      <section className="sidebar">
        <NavLink
          className="sidebar_btn flex align_items_center flex_gap_5"
          to={`/customer-dashboard`}
        >
          <svg
            width="15"
            height="15"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2 18C1.45 18 0.979167 17.8042 0.5875 17.4125C0.195833 17.0208 0 16.55 0 16V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H16C16.55 0 17.0208 0.195833 17.4125 0.5875C17.8042 0.979167 18 1.45 18 2V16C18 16.55 17.8042 17.0208 17.4125 17.4125C17.0208 17.8042 16.55 18 16 18H2ZM2 16H8V2H2V16ZM10 16H16V9H10V16ZM10 7H16V2H10V7Z"
              fill="#1C1B1F"
            />
          </svg>
          Dashboard
        </NavLink>
        <NavLink
          className="sidebar_btn flex align_items_center flex_gap_5"
          to={`/customer-profile`}
        >
          <svg
            className="m_0"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.04551 6.54502C6.14832 6.54502 5.38027 6.22557 4.74137 5.58666C4.10246 4.94775 3.78301 4.17971 3.78301 3.28252C3.78301 2.38533 4.10246 1.61729 4.74137 0.978379C5.38027 0.339473 6.14832 0.0200195 7.04551 0.0200195C7.94269 0.0200195 8.71074 0.339473 9.34965 0.978379C9.98855 1.61729 10.308 2.38533 10.308 3.28252C10.308 4.17971 9.98855 4.94775 9.34965 5.58666C8.71074 6.22557 7.94269 6.54502 7.04551 6.54502ZM0.520508 11.4388V10.7863C0.520508 10.3241 0.639453 9.89928 0.877344 9.51186C1.11523 9.12443 1.43129 8.82877 1.82551 8.62486C2.66832 8.20346 3.52473 7.8874 4.39473 7.6767C5.26473 7.466 6.14832 7.36064 7.04551 7.36064C7.94269 7.36064 8.82629 7.466 9.69629 7.6767C10.5663 7.8874 11.4227 8.20346 12.2655 8.62486C12.6597 8.82877 12.9758 9.12443 13.2137 9.51186C13.4516 9.89928 13.5705 10.3241 13.5705 10.7863V11.4388C13.5705 11.8874 13.4108 12.2714 13.0913 12.5908C12.7719 12.9103 12.3879 13.07 11.9393 13.07H2.15176C1.70316 13.07 1.31914 12.9103 0.999687 12.5908C0.680234 12.2714 0.520508 11.8874 0.520508 11.4388ZM2.15176 11.4388H11.9393V10.7863C11.9393 10.6367 11.9019 10.5008 11.8271 10.3785C11.7523 10.2561 11.6538 10.161 11.5314 10.093C10.7974 9.72596 10.0565 9.45068 9.30887 9.26717C8.56121 9.08365 7.80676 8.99189 7.04551 8.99189C6.28426 8.99189 5.5298 9.08365 4.78215 9.26717C4.03449 9.45068 3.29363 9.72596 2.55957 10.093C2.43723 10.161 2.33867 10.2561 2.26391 10.3785C2.18914 10.5008 2.15176 10.6367 2.15176 10.7863V11.4388ZM7.04551 4.91377C7.4941 4.91377 7.87812 4.75404 8.19758 4.43459C8.51703 4.11514 8.67676 3.73111 8.67676 3.28252C8.67676 2.83393 8.51703 2.4499 8.19758 2.13045C7.87812 1.811 7.4941 1.65127 7.04551 1.65127C6.59691 1.65127 6.21289 1.811 5.89344 2.13045C5.57398 2.4499 5.41426 2.83393 5.41426 3.28252C5.41426 3.73111 5.57398 4.11514 5.89344 4.43459C6.21289 4.75404 6.59691 4.91377 7.04551 4.91377Z"
              fill="#000"
            />
          </svg>
          My Profile
        </NavLink>
        <NavLink
          className="sidebar_btn flex align_items_center  flex_gap_5"
          to={`/customer-booking`}
        >
          <svg
            className="m_0"
            width="18"
            height="18"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <mask
              id="mask0_3887_4171"
              style={{ maskType: "alpha" }}
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="24"
              height="24"
            >
              <rect width="24" height="24" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_3887_4171)">
              <path
                d="M10.95 18.35L7.4 14.8L8.85 13.35L10.95 15.45L15.15 11.25L16.6 12.7L10.95 18.35ZM5 22C4.45 22 3.97917 21.8042 3.5875 21.4125C3.19583 21.0208 3 20.55 3 20V6C3 5.45 3.19583 4.97917 3.5875 4.5875C3.97917 4.19583 4.45 4 5 4H6V2H8V4H16V2H18V4H19C19.55 4 20.0208 4.19583 20.4125 4.5875C20.8042 4.97917 21 5.45 21 6V20C21 20.55 20.8042 21.0208 20.4125 21.4125C20.0208 21.8042 19.55 22 19 22H5ZM5 20H19V10H5V20ZM5 8H19V6H5V8Z"
                fill="#1C1B1F"
              />
            </g>
          </svg>
          My Booking
        </NavLink>
      </section>
    </>
  );
};

export default CustomerSideBar;
