import React, { useEffect, useState } from "react";
import "./SliderComponent.css";
 
const SliderComponent = (props) => {
  const [isToggled, setIsToggled] = useState(props.byDefaultEnable);
 
  const handleToggle = () => {
    setIsToggled((prevIsToggled) => !prevIsToggled);
    // console.log(isToggled);
    
    props.functionToRun(props.id, isToggled === false ? "enable": "disable")
  };
 
  useEffect(() => {
    setIsToggled(props.byDefaultEnable);
   
  }, [props.byDefaultEnable]);
 
  useEffect(() => {
    const beddingFinalValue = isToggled ? "enabled" : "disabled";
    props.func(beddingFinalValue, props.id);
  }, [isToggled, props.func, props.id]);
 
  return (
    <div className="bedding-wrapper">
      {/* {!props.notShowTitle && (
        <div>
          <p className="bedding_text">{props.title}</p>
        </div>
      )} */}
      <div className="flex align_items_center">
        {/* <span className="toggle_text">
          {isToggled ? props.positive : props.negative}
        </span> */}
        <label className="toggle-container">
          <input
            name={props.name}
            type="checkbox"
            checked={isToggled}
            onChange={handleToggle}
            className="toggle-checkbox-custom"
          />
          <span className="toggle-slider"></span>
        </label>
      </div>
    </div>
  );
};
 
export default SliderComponent;