import React, { useEffect } from "react";

const OgMetaTitles = ({ title, type, url, image }) => {
  useEffect(() => {
    // Function to update or create a meta tag
    const setMetaTag = (property, content) => {
      let tag = document.querySelector(`meta[property='${property}']`);
      if (tag) {
        tag.setAttribute("content", content);
      } else {
        tag = document.createElement("meta");
        tag.setAttribute("property", property);
        tag.setAttribute("content", content);
        document.head.appendChild(tag);
      }
    };

    // Set the Open Graph meta tags
    setMetaTag("og:title", title);
    setMetaTag("og:type", type);
    setMetaTag("og:url", url);
    setMetaTag(
      "og:image",
      image
        ? image
        : "https://www.mountainmysteries.com/static/media/Mountain-mystries-logo.54b65b51cce41f636b77.png"
    );

    // Optional cleanup function to remove tags (if necessary)
    return () => {
      document.querySelectorAll("meta[property^='og:']").forEach((tag) => {
        document.head.removeChild(tag);
      });
    };
  }, [title, type, url, image]);

  return null; // This component doesn't render anything visible
};

export default OgMetaTitles;
