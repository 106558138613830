import React, { useEffect, useState } from "react";
import "../BasicInformation/BasicInformation.css";
import AllAmenities from "../AllAmenities/AllAmenities";
import { showSuccessToast } from "../../../assets/toastUtils";
import { useParams } from "react-router-dom";

const Rooms = ({
  propertyData,
  handleInputPriceChange,
  handleInputChange,
  handleNextClick,
  activeTab,
  setPropertyData,
}) => {
  const { id } = useParams();
  // console.log(propertyData, "ppp");
  const [roomData, setRoomData] = useState({});

  const handleChange = (e, key, label) => {
    setPropertyData((prev) => {
      const updatedData = { ...prev };
      updatedData[key][label] = e.target.value;
      return updatedData;
    });
  };

  const addRoomDetails = (e) => {
    e.preventDefault();
    // console.log("form submit room");

    const raw = JSON.stringify({
      name: propertyData?.display_name,
      content: propertyData?.room_description,
      is_active: true,
      base_adults: propertyData?.base_adult,
      max_children: propertyData?.max_child,
      max_adults: propertyData?.max_adult,
      max_occupany: propertyData?.max_occupancy,
      area: propertyData?.room_size,
      unit: propertyData?.room_size_units,
      type: propertyData?.room_type,
      bed: propertyData?.bed_type,
      view: propertyData?.room_view,
      selected_amenities: "1,2,3",
    });
    const requestOptions = {
      method: "POST",
      body: raw,
      headers: {
        "Content-Type": "application/json",
        key: "D4X50uU40Vz4pweFLEmsoiJy107iPfTD4ompprop1",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_APP_URL}/api/v1/vendor/accommodation-room-create/170`,
      requestOptions
    )
      .then((response) => {
        return response.text().then((text) => {
          if (!response.ok) {
            throw new Error("Network response was not ok: " + text);
          }
          return text;
        });
      })
      .then((text) => {
        // console.log("Response text:", text);
        try {
          const data = JSON.parse(text);
          if (data.success) {
            setRoomData(data);
            showSuccessToast(data.success.message || "Rooms Added");
          } else {
            // console.log("Error fetching data", data);
          }
        } catch (error) {
          console.error("Error parsing JSON:", error, text);
        }
      })
      .catch((error) => {
        console.error("Error fetching details:", error);
      });
  };

  return (
    <section>
      <div className="basic-info-container">
        <div>
          <h3 className="text-left">Add New Room</h3>
          <p>Please fill the details of your property.</p>
        </div>

        <form onSubmit={addRoomDetails}>
          <div className="bg-white-div">
            <h4>Room Info</h4>
            <div className="flex flex_gap_10 w-full">
              <div className="w-half">
                <label htmlFor="">
                  Display Name <span className="required">*</span>
                </label>
                <input
                  type="text"
                  onChange={handleInputChange}
                  name="display_name"
                  value={propertyData?.display_name}
                  required
                />
              </div>
              <div className="w-half">
                <label htmlFor="">
                  Room Type <span className="required">*</span>
                </label>
                <select
                  name="room_type"
                  onChange={handleInputChange}
                  id=""
                  required
                >
                  <option value="">Select option</option>
                  <option value="Deluxe">Deluxe</option>
                  <option value="Standard">Standard</option>
                  <option value="Luxury">Luxury</option>
                  <option value="Master">Master</option>
                  <option value="Common">Common</option>
                  <option value="Tent">Tent</option>
                  <option value="Family Room">Family Room</option>
                  <option value="Water Villa">Water Villa</option>
                  <option value="Beach Villa">Beach Villa</option>
                  <option value="For Honeymooners">For Honeymooners</option>
                  <option value="Garden Villa">Garden Villa</option>
                  <option value="other">other</option>
                  <option value="Suite">Suite</option>
                </select>
              </div>
            </div>
            <div className="flex flex_gap_10 w-full">
              {/* <div className='w-half'>
                                <label htmlFor="">Total Rooms  <span className='required'>*</span></label>
                                <input type="number" name='total_rooms' value={propertyData.total_rooms} onChange={handleInputChange} />
                            </div> */}
              <div className="w-half">
                <label htmlFor="">
                  Room View <span className="required">*</span>
                </label>
                <select
                  name="room_view"
                  value={propertyData.room_view}
                  onChange={handleInputChange}
                  id=""
                  required
                >
                  <option value="">Select option</option>
                  <option value="Park View">Pool View</option>
                  <option value="Valley View">Valley View</option>
                  <option value="No View">No View</option>
                  <option value="Sea View">Sea View</option>
                  <option value="Hill View">Hill View</option>
                  <option value="Pool View">Pool View</option>
                  <option value="Garden View">Garden View</option>
                  <option value="River View">River View</option>
                  <option value="Lake View">Lake View</option>
                  <option value="Palace View">Palace View</option>
                  <option value="Bay View">Bay View</option>
                  <option value="Jungle View">Jungle View</option>
                  <option value="City View">City View</option>
                  <option value="Landmark View">Landmark View</option>
                  <option value="Terrace View">Terrace View</option>
                  <option value="Lake View">Courtyard View</option>
                  <option value="Desert View">Desert View</option>
                  <option value="Golf Course View">Golf Course View</option>
                  <option value="Mountain View">Mountain View</option>
                  <option value="Ocean View">Ocean View</option>
                  <option value="Backwater View">Backwater View</option>
                  <option value="Resort View">Resort View</option>
                  <option value="Desert View">Desert View</option>
                  <option value="Golf Course View">Golf Course View</option>
                  <option value="Mountain View">Mountain View</option>
                  <option value="Lagoon View">Lagoon View</option>
                  <option value="Forest View">Forest View</option>
                  <option value="Beach View">Beach View</option>
                  <option value="Airport View">Airport View</option>
                  <option value="Countryside View">Countryside View</option>
                  <option value="Harbor View">Harbor View</option>
                  <option value="Inter-coastal View">Inter-coastal View</option>
                  <option value="Marina View">Marina View</option>
                </select>
              </div>
              <div className="w-half">
                <label htmlFor="">
                  Bed Type <span className="required">*</span>
                </label>
                <select
                  name="bed_type"
                  onChange={handleInputChange}
                  id=""
                  required
                >
                  <option value="">Select option</option>
                  <option value="Twin Bed">Twin Bed</option>
                  <option value="King Bed">King Bed</option>
                  <option value="Queen Bed">Queen Bed</option>
                  <option value="Double Bed">Double Bed</option>
                  <option value="Single Bed">Single Bed</option>
                  <option value="Sofa Bed">Sofa Bed</option>
                  <option value="Standard Bed">Standard Bed</option>
                  <option value="1 King bed or 2 Twin Bed(s)">
                    1 King bed or 2 Twin Bed(s)
                  </option>
                  <option value="1 Queen bed or 2 Twin Bed(s)">
                    1 Queen bed or 2 Twin Bed(s)
                  </option>
                  <option value="1 Double bed or 2 Twin Bed(s)">
                    1 Double bed or 2 Twin Bed(s)
                  </option>
                  <option value="Bunk Bed">Bunk Bed</option>
                  <option value="Futon">Futon</option>
                  <option value="Murphy">Murphy</option>
                  <option value="Tatami Mats">Tatami Mats</option>
                </select>
              </div>
            </div>
            <div className="flex flex_gap_10 w-full">
              <div className="w-half">
                <label htmlFor="">
                  Room Size <span className="required">*</span>
                </label>
                <input
                  type="number"
                  name="room_size"
                  value={propertyData.room_size}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="w-half">
                <label htmlFor="">
                  Unit <span className="required">*</span>
                </label>
                <select
                  name="room_size_units"
                  onChange={handleInputChange}
                  id=""
                  required
                >
                  <option value="">Select option</option>
                  <option value="square meter">square meter</option>
                  <option value="square feet">square feet</option>
                </select>
              </div>
            </div>
            <div>
              <label htmlFor=""> Description</label>
              <textarea
                name="room_description"
                value={propertyData.room_description}
                onChange={handleInputChange}
                id=""
                className="w-full"
                placeholder="Type here..."
              ></textarea>
            </div>

            <div className="mt-10">
              <h4>OCCUPANCY</h4>
              <div className="flex flex_gap_10 w-full">
                <div className="w-half">
                  <label htmlFor="">
                    Base Adult <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    onChange={handleInputChange}
                    name="base_adult"
                    value={propertyData.base_adult}
                    required
                  />
                </div>
                {/* <div className='w-half'>
                                    <label htmlFor="">Min LOS</label>
                                    <input type="text" onChange={handleInputChange} name='min_los' value={propertyData.min_los} />
                                </div> */}
                {/* <div className='w-half'>
                                    <label htmlFor=""> Max LOS <span className='required'>*</span></label>
                                    <input type="text" onChange={handleInputChange} name='max_los' value={propertyData.max_los} required />
                                </div> */}
              </div>
              <div className="flex flex_gap_10 w-full">
                <div className="w-half">
                  <label htmlFor="">
                    {" "}
                    Max Adult <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    onChange={handleInputChange}
                    name="max_adult"
                    value={propertyData.max_adult}
                    required
                  />
                </div>
                <div className="w-half">
                  <label htmlFor=""> Max Child</label>
                  <input
                    type="text"
                    onChange={handleInputChange}
                    name="max_child"
                    value={propertyData.max_child}
                  />
                </div>
                <div className="w-half">
                  <label htmlFor="">Max Occupancy</label>
                  <input
                    type="text"
                    onChange={handleInputChange}
                    name="max_occupancy"
                    value={propertyData.max_occupancy}
                  />
                </div>
              </div>
              {/* <h6 className='mt-10'>Price Per Night</h6>
                            <div className='flex flex_gap_10 w-full'>
                                <div className='w-half'>
                                    <label htmlFor="">EP</label>
                                    <input type="text" onChange={(e) => handleChange(e, "price_per_night", "ep")} name='ep' value={propertyData['price_per_night']['ep']} />
                                </div>
                                <div className='w-half'>
                                    <label htmlFor="">CP</label>
                                    <input type="text" onChange={(e) => handleChange(e, "price_per_night", "cp")} name='ap' value={propertyData['price_per_night']['cp']} />
                                </div>
                                <div className='w-half'>
                                    <label htmlFor=""> MAP</label>
                                    <input type="text" onChange={(e) => handleChange(e, "price_per_night", "map")} name='ap' value={propertyData['price_per_night']['map']} />
                                </div>
                                <div className='w-half'>
                                    <label htmlFor=""> AP</label>
                                    <input type="text" onChange={(e) => handleChange(e, "price_per_night", "ap")} name='ap' value={propertyData['price_per_night']['ap']} />
                                </div>
                            </div> */}
              {/* <h6 className='mt-10'>Extra Adult Cost</h6>
                            <div className='flex flex_gap_10 w-full'>
                                <div className='w-half'>
                                    <label htmlFor="">EP</label>
                                    <input type="text" onChange={(e) => handleChange(e, "extra_adult_cost", "ep")} name='ep' value={propertyData['extra_adult_cost']['ep']} />
                                </div>
                                <div className='w-half'>
                                    <label htmlFor="">CP</label>
                                    <input type="text" onChange={(e) => handleChange(e, "extra_adult_cost", "cp")} name='cp' value={propertyData['extra_adult_cost']['cp']} />
                                </div>
                                <div className='w-half'>
                                    <label htmlFor=""> MAP</label>
                                    <input type="text" onChange={(e) => handleChange(e, "extra_adult_cost", "map")} name='map' value={propertyData['extra_adult_cost']['map']} />
                                </div>
                                <div className='w-half'>
                                    <label htmlFor=""> AP</label>
                                    <input type="text" onChange={(e) => handleChange(e, "extra_adult_cost", "ap")} name='ap' value={propertyData['extra_adult_cost']['ap']} />
                                </div>
                            </div> */}
              {/* <h6 className='mt-10'>Child With Bed Cost</h6>
                            <div className='flex flex_gap_10 w-full'>
                                <div className='w-half'>
                                    <label htmlFor="">EP</label>
                                    <input type="text" onChange={(e) => handleChange(e, "child_with_bed_cost", "ep")} name='ep' value={propertyData['child_with_bed_cost']['ep']} />
                                </div>
                                <div className='w-half'>
                                    <label htmlFor="">CP</label>
                                    <input type="text" onChange={(e) => handleChange(e, "child_with_bed_cost", "cp")} name='cp' value={propertyData['child_with_bed_cost']['cp']} />
                                </div>
                                <div className='w-half'>
                                    <label htmlFor=""> MAP</label>
                                    <input type="text" onChange={(e) => handleChange(e, "child_with_bed_cost", "map")} name='map' value={propertyData['child_with_bed_cost']['map']} />
                                </div>
                                <div className='w-half'>
                                    <label htmlFor=""> AP</label>
                                    <input type="text" onChange={(e) => handleChange(e, "child_with_bed_cost", "ap")} name='ap' value={propertyData['child_with_bed_cost']['ap']} />
                                </div>
                            </div> */}
              {/* <h6 className='mt-10'>Child Without Bed Cost</h6>
                            <div className='flex flex_gap_10 w-full'>
                                <div className='w-half'>
                                    <label htmlFor="">EP</label>
                                    <input type="text" onChange={(e) => handleChange(e, "child_without_bed_cost", "ep")} name='ep' value={propertyData['child_without_bed_cost']['ep']} />
                                </div>
                                <div className='w-half'>
                                    <label htmlFor="">CP</label>
                                    <input type="text" onChange={(e) => handleChange(e, "child_without_bed_cost", "cp")} name='cp' value={propertyData['child_without_bed_cost']['cp']} />
                                </div>
                                <div className='w-half'>
                                    <label htmlFor=""> MAP</label>
                                    <input type="text" onChange={(e) => handleChange(e, "child_without_bed_cost", "map")} name='map' value={propertyData['child_without_bed_cost']['map']} />
                                </div>
                                <div className='w-half'>
                                    <label htmlFor=""> AP</label>
                                    <input type="text" onChange={(e) => handleChange(e, "child_without_bed_cost", "ap")} name='ap' value={propertyData['child_without_bed_cost']['ap']} />
                                </div>
                            </div> */}
            </div>
          </div>

          <div className="flex justify_content_end mt-10">
            <button
              type="submit"
              className="primary_btn"
              style={{ width: "70px" }}
            >
              Save
            </button>
          </div>
        </form>
      </div>
      {/* <AllAmenities showTitle={false} /> */}
    </section>
  );
};

export default Rooms;
