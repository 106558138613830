import React from "react";
import "./PackageDetails.css";
import ButtonToDownloadTablePDF from "./ButtonToDownload";
import PrintButtonClickPackage from "./PrintButtonClickPackage";

const PrintButtonPackageDetails = ({ packageData }) => {

  return (
    <>
      <PrintButtonClickPackage
        itemToDownloadID={"package-details"}
        
        // longPdf={true}
      />

      {/* <hr></hr> */}

      <div id="package-details" className="ignore-print" style={{ display: "none" }}>
        <div style={{ textAlign: "center" }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            style={{ fill: "rgba(0, 0, 0, 1)" }}
          >
            <path d="M20.56 3.34a1 1 0 0 0-1-.08l-17 8a1 1 0 0 0-.57.92 1 1 0 0 0 .6.9L8 15.45v6.72L13.84 18l4.76 2.08a.93.93 0 0 0 .4.09 1 1 0 0 0 .52-.15 1 1 0 0 0 .48-.79l1-15a1 1 0 0 0-.44-.89zM18.1 17.68l-5.27-2.31L16 9.17l-7.65 4.25-2.93-1.29 13.47-6.34z"></path>
          </svg>
        </div>

        <div className="package-name-duration-top day-wise-details-container">
          <h5>{packageData?.packageName}</h5>

          <p>
            Validity : {new Date(packageData?.validFrom).toLocaleDateString()} -{" "}
            {new Date(packageData?.validTo).toLocaleDateString()}
          </p>

          <p>
            Duration : {packageData?.totalDays} D / {packageData?.totalNights} N{" "}
          </p>
        </div>

        {/* destination images */}

        <div
          className="day-wise-details-container"
          style={{ marginBottom: "20px" }}
        >
          <h3 className="day-wise-details-headings">Destinations</h3>
          <hr className="lineDownload" />

          <div style={{ display: "flex", gap: "15px", paddingBottom: "30px" }}>
            {packageData?.packageDestination?.map((destination, index) => (
              <div key={index}>
                <img
                  src={destination.image}
                  alt={destination.title}
                  height={100}
                  width={200}
                />
                <div style={{ textAlign: "center" }}>{destination?.title}</div>
                <div style={{ textAlign: "center" }}>
                  ({packageData?.totalNights} N)
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* DAY WISE DETAILS */}

        <div className="day-wise-details-container">
          <h3 className="day-wise-details-headings">DAY WISE DETAILS</h3>
          <hr className="line" />
          <div>
            {packageData?.packageItinerary?.map((day, index) => (
              <div key={index}>
                <h4>Day {day?.dayCount}</h4>
                <p>{day?.shortDescription}</p>
                <p>Start : {day?.startPoint} </p>
                <p>End : {day?.endPoint}</p>
              </div>
            ))}
          </div>
        </div>

        {/* Hotel Categories */}

        <div className="day-wise-details-container">
          <h3 className="day-wise-details-headings">Hotel Categories</h3>
          <hr className="line" />
          <ul className="hotel-categories_lists">
            {packageData?.hotelCategory?.map((category, index) => (
              <li key={index}>{category}</li>
            ))}
          </ul>
        </div>
        {/* Star Ratings */}
        <div className="day-wise-details-container">
          <h3 className="day-wise-details-headings">Star Ratings</h3>
          <hr className="line" />
          <ul className="hotel-categories_lists">
            {packageData?.hotelStarRating?.map((rating, index) => (
              <li key={index}>{rating} Star</li>
            ))}
          </ul>
        </div>

        {/* Policies */}

        <div className="day-wise-details-container">
          <h3 className="day-wise-details-headings">Policies</h3>
          <hr className="line" />
          {packageData?.packagePolicies?.map((policy, index) => (
            <div key={index}>
              <h4>{policy?.policyName}</h4>
              <p>{policy?.policyContent}</p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default PrintButtonPackageDetails;
