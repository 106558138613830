import React, { useEffect } from "react";
import car from "../../assets/images/car.png";
import "./CustomSliderTaxiCard.css";
import { formatIndianCurrency } from "../../utils/utils";
import { vehicleDetails as vehicleDetailsData } from "../../constants";

function CustomSliderTaxiCard({
  items,
  handleClose,
  allVehicles,
  changeSelectedVehicle,
  setIsVehicleCustomizedClick,
  fetchTransportCost,
  calculateDataDayByDay,
  fetchCostOfVehicle,
  vehicleDetails,
}) {
  // const isSelected =  ;
  // const cardStyle = isSelected
  // ? {
  //     boxShadow: '0 4px 8px 0 rgba(74, 74, 74, .1)',
  //     border: '1px solid #016937',
  //     position: 'relative', // Ensure position is relative for overlay additional styles
  // }
  // : {
  //     boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
  //     position: 'relative', // Ensure position is relative for overlay additional styles
  // };

  const vehicle = vehicleDetails && vehicleDetails[0];

  // console.log("vehicle", allVehicles);

  const handleChangeVehicle = (data) => {
    changeSelectedVehicle(data);
    setIsVehicleCustomizedClick(false);
    // calculateDataDayByDay();
    // fetchTransportCost()
  };

  // body overflow hidden

  useEffect(() => {
    const body = document.querySelector("body");

    body.style.overflow = "hidden";

    return () => {
      body.style.overflow = "auto";
    };
  }, []);
  return (
    <div
      style={{ padding: "10px 12px" }}
      className="border_0 rounded_0 hotel_card"
    >
      <h4 className="SliderTaxiCard_MainHeading">
        Showing {allVehicles.length} Vehicle
      </h4>

      {allVehicles?.length > 0 ? (
        <>
          {allVehicles
            ?.sort((a, b) => a.Price - b.Price)
            .map((data, index) => (
              <div
                className="SliderTaxiCard_Main hotel-content-flex hotel-main-card  "
                style={{
                  backgroundColor:
                    vehicle?.Vehicle === data?.Vehicle && "#01693724",
                }}
              >
                <div style={{height:"auto"}} className="hotel_view_img text-center Taxi_img">
                  {/* <img className="SliderTaxiCard_img" src={car} /> */}
                  {
                    vehicleDetailsData?.find(
                      (vehicle) => vehicle?.name === data?.Vehicle
                    ).image
                  }

                  {/* Selected */}
                  {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  fill="#016937"
                  height="12px"
                  width="12px"
                  version="1.1"
                  id="Capa_1"
                  viewBox="0 0 490 490"
                  xmlSpace="preserve"
                >
                  <polygon points="452.253,28.326 197.831,394.674 29.044,256.875 0,292.469 207.253,461.674 490,54.528" />
                </svg> */}
                </div>
                <div className="SliderTaxiCard_content hotel-content">
                  <div
                    style={{ justifyContent: "start", paddingRight: "10px" }}
                    className="hotel_main"
                  >
                    {/* {vehicle?.Vehicle}
                     {data?.Vehicle} */}

                    <h4>
                      <b>{data.Vehicle}</b>
                    </h4>

                    <small>Private Transfer - AC</small>

                    <div className="SliderTaxiCard_Provides">
                      <h4>Facilities :</h4>
                      <span className="">{data.MaxTraveller} seater</span>
                      <span>AC</span>
                      {/* <span>3 Luggage Bags</span> */}
                    </div>

                    {/* <h6
                    style={{
                      fontSize: "12px",
                      marginTop: "3px",
                      color: "#4a4a4a",
                    }}
                  >
                    Intercity Transfer; 10 Sightseeing Transfers Included
                  </h6> */}
                  </div>
                  <div className="room_rates" style={{ alignItems: "normal" }}>
                    <div className="SliderTaxiCard_rates room-flex">
                      <div>
                        {/* <p>Starting From</p> */}
                        <h5>
                          {formatIndianCurrency(data.Price)}{" "}
                          <small> + GST</small>{" "}
                        </h5>
                      </div>

                      {vehicle?.Vehicle === data?.Vehicle ? (
                        <div
                          className={`selected_banner`}
                          style={{ display: "flex" }}
                        >
                          Selected
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            fill="#016937"
                            height="12px"
                            width="12px"
                            version="1.1"
                            id="Capa_1"
                            viewBox="0 0 490 490"
                            xmlSpace="preserve"
                          >
                            <polygon points="452.253,28.326 197.831,394.674 29.044,256.875 0,292.469 207.253,461.674 490,54.528" />
                          </svg>
                        </div>
                      ) : (
                        <button
                          className="primary_btn"
                          onClick={() => handleChangeVehicle(data)}
                          type="button"
                        >
                          Change
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </>
      ) : (
        <div>No Vehicles </div>
      )}
    </div>
  );
}

export default CustomSliderTaxiCard;
