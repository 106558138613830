import React, { useEffect } from "react";
import car from "../../assets/images/car.png";
import "./CustomSliderTaxiCard.css";
import { formatIndianCurrency } from "../../utils/utils";
import { vehicleDetails as vehicleDetailsData } from "../../constants";
import { useNavigate } from "react-router-dom";

function CustomSliderActivityCard({
  items,
  handleClose,
  hotelDestinationActivities,
  changeSelectedVehicle,
  setIsVehicleCustomizedClick,
  fetchTransportCost,
  calculateDataDayByDay,
  fetchCostOfVehicle,
  vehicleDetails,
  changeSelectedActivity,
  hotelDayByDay,
}) {
  const navigate = useNavigate();
  // const isSelected =  ;
  // const cardStyle = isSelected
  // ? {
  //     boxShadow: '0 4px 8px 0 rgba(74, 74, 74, .1)',
  //     border: '1px solid #016937',
  //     position: 'relative', // Ensure position is relative for overlay additional styles
  // }
  // : {
  //     boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
  //     position: 'relative', // Ensure position is relative for overlay additional styles
  // };

  const checkIfActivityExists = (name) => {
    const activities = hotelDayByDay?.map((item) =>
      item.activities ? item.activity : null
    );
    // console.log("activities", activities);

    const activityExists = hotelDayByDay.find(
      (activity) => activity.name === name
    );
    return activityExists;
  };

  const doesActivityExist = (daysArray, activityName) => {
    return daysArray.some(
      (day) =>
        day.activity &&
        day.activity.some((activity) => activity.name === activityName)
    );
  };

  // console.log(
  //   doesActivityExist(hotelDayByDay, "Day Hikes with Lunch & Picnic")
  // );

  const vehicle = vehicleDetails && vehicleDetails[0];

  const handleChangeActivity = (data, index) => {
    changeSelectedActivity(data, index);
    setIsVehicleCustomizedClick(false);
    // calculateDataDayByDay();
    // fetchTransportCost()
  };

  // body overflow hidden

  useEffect(() => {
    const body = document.querySelector("body");

    body.style.overflow = "hidden";

    return () => {
      body.style.overflow = "auto";
    };
  }, []);
  // console.log("hotelDayByDay", hotelDayByDay);

  return (
    <div
      style={{ padding: "10px 12px" }}
      className="border_0 rounded_0 hotel_card"
    >
      <h4 className="SliderTaxiCard_MainHeading">
        Showing {hotelDestinationActivities.length} Activities
      </h4>

      {hotelDestinationActivities?.length > 0 ? (
        <>
          {hotelDestinationActivities
            ?.sort((a, b) => a.activity_price - b.activity_price)
            .map((data, index) => (
              <div
                className="SliderTaxiCard_Main hotel-content-flex hotel-main-card  "key={index}
                style={{
                  backgroundColor:
                    vehicle?.Vehicle === data?.Vehicle && "#01693724",
                }}
              >
                <div className="hotel_view_img text-center">
                  <img
                    className="SliderTaxiCard_img"
                    src={
                      data?.images && data.images.length > 0 && data?.images[0]
                        ? data?.images[0]
                        : "https://www.mountainmysteries.com/static/media/single-image.60cf4356b25f03068004.png"
                    }
                  />
                </div>
                <div className="SliderTaxiCard_content hotel-content">
                  <div
                    style={{ justifyContent: "start", paddingRight: "10px" }}
                    className="hotel_main"
                  >
                    <div className="flex justify_content_between">
                      <h6>
                        <b>{data?.name}</b>
                      </h6>
                      <button
                        onClick={() =>
                          navigate(
                            `/single-activitiy/${data?.id}?location=&date=null&month=null&year=null`
                          )
                        }
                        className="view_btn"
                        style={{width:"100px"}}
                      >
                        View Details
                      </button>
                    </div>

                    <div className="SliderTaxiCard_desc">
                      {data.content.trim().substring(0, 80) + "..."}
                    </div>
                  </div>
                  <div className="room_rates" style={{ alignItems: "normal" }}>
                    <div className="SliderTaxiCard_rates room-flex">
                      <div>
                        <h5>
                          {formatIndianCurrency(data?.activity_price)}{" "}
                          <small> + GST</small>{" "}
                        </h5>
                      </div>

                      {doesActivityExist(hotelDayByDay, data?.name) ? (
                        <div
                          className={`selected_banner`}
                          style={{ display: "flex" }}
                        >
                          Selected
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            fill="#016937"
                            height="12px"
                            width="12px"
                            version="1.1"
                            id="Capa_1"
                            viewBox="0 0 490 490"
                            xmlSpace="preserve"
                          >
                            <polygon points="452.253,28.326 197.831,394.674 29.044,256.875 0,292.469 207.253,461.674 490,54.528" />
                          </svg>
                        </div>
                      ) : (
                        <button
                          className="primary_btn"
                          onClick={() => handleChangeActivity(data, index)}
                          type="button"
                        >
                          Add
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </>
      ) : (
        <div>No Activities added in this location </div>
      )}
    </div>
  );
}

export default CustomSliderActivityCard;
