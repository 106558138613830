import React from "react";
import Header from "../../CustomComponents/Header/Header";
import ReviewReservationBanner from "../../CustomComponents/ReviewReservationBanner/ReviewReservationBanner";
import Footer from "../../CustomComponents/Footer/Footer";
import { Link } from "react-router-dom";
import PageMetaData from "../../CustomComponents/PageMetaData/PageMetaData";
import OgMetaTitles from "../../CustomComponents/PageMetaData/OgMetaTitles";

function HealthSafety() {
  return (
    <>
      <PageMetaData
        title="Health and Safety | Mountain Mysteries"
        description="Enhance your hotel capabilities with RevCatalyst's powerful system. Optimize performance, streamline operations, and unleash the full potential of property."
        keywords="hotel management, RevCatalyst, property optimization"
      />
      <OgMetaTitles
        title="Health and Safety | Mountain Mysteries"
        type="Enhance your hotel capabilities with RevCatalyst's powerful system. Optimize performance, streamline operations, and unleash the full potential of property."
        url={`https://www.mountainmysteries.com/health-safety`}
      />
      <Header />
      <ReviewReservationBanner heading="Health and Safety Guidelines" />
      {/* <PageLinks links={["Home", "Health & Safety"]} /> */}
      <section className="pb_30">
        <div className="container">
          <div className="overview-content mt-20">
            <p className="mb-10">
              At Mountain Mysteries, your safety and well-being are our top
              priorities. Whether you're exploring the serene landscapes of
              Himachal Pradesh or embarking on an adventure in the heart of the
              Himalayas, we are committed to ensuring that your journey is safe
              and enjoyable. These guidelines provide essential information and
              advice to help you stay safe while traveling with us.
            </p>

            <div>
              <h4 className="mt-10 mb-10">General Guidelines</h4>
              <b className="mt-00">Health and Hygiene</b>
              <ul className="p_10">
                <li>
                  <b>Stay Informed:</b> Keep yourself updated with the latest
                  health advisories and travel guidelines issued by the
                  Government of India and local health authorities.
                </li>
                <li>
                  <b>Vaccinations:</b> Ensure that you are up-to-date with all
                  recommended vaccinations. Carry proof of COVID-19 vaccination
                  and any other required health certificates.
                </li>
                <li>
                  <b>Personal Hygiene:</b> Regularly wash your hands with soap
                  and water for at least 20 seconds or use hand sanitizers.
                  Avoid touching your face, especially your eyes, nose, and
                  mouth.
                </li>
                <li>
                  <b>Face Masks:</b> Wear a face mask in crowded places and
                  follow local regulations regarding mask mandates.
                </li>
              </ul>
              <b className="mt-10 block"> Travel Insurance</b>
              <ul className="p_10">
                <li>
                  <b>Comprehensive Coverage: </b>Purchase comprehensive travel
                  insurance that covers health emergencies, accidents, and trip
                  cancellations. Ensure it includes coverage for activities such
                  as trekking, skiing, and other adventure sports.
                </li>
              </ul>
            </div>
            <div>
              <h4 className="mt-10 mb-10">Accommodation Safety</h4>
              <b className="mt-10"> Accommodation Standards</b>
              <ul className="p_10">
                <li>
                  <b>Verified Properties:</b> Mountain Mysteries only partners
                  with accommodations that meet our health and safety standards.
                  We conduct regular inspections to ensure cleanliness and
                  safety.
                </li>
                <li>
                  <b>Sanitization Protocols: </b> All properties are required to
                  adhere to stringent sanitization protocols, especially in
                  common areas and frequently touched surfaces.
                </li>
                <li>
                  <b>Emergency Contacts: </b> Familiarize yourself with
                  emergency exits, fire safety equipment, and local emergency
                  contact numbers provided by the accommodation.
                </li>
              </ul>
            </div>
            <div>
              <h4 className="mt-10 mb-10">Transportation Safety</h4>
              <b className="mt-10">Transport Arrangements</b>
              <ul className="p_10">
                <li>
                  <b>Licensed Operators: </b> We collaborate with licensed and
                  reliable transportation providers. Vehicles are regularly
                  maintained and inspected for safety.
                </li>
                <li>
                  <b>Driver Safety: </b> Our drivers are trained in safe driving
                  practices and are familiar with local routes and conditions.
                </li>
                <li>
                  <b>Passenger Protocols: </b> Passengers are encouraged to wear
                  seatbelts at all times and to follow any additional safety
                  instructions provided by the driver.
                </li>
              </ul>
            </div>
            <div>
              <h4 className="mt-10 mb-10">Adventure Activities</h4>
              <b className="mt-10"> Activity Guidelines</b>
              <ul className="p_10">
                <li>
                  <b>Certified Guides: </b> All adventure activities are led by
                  certified and experienced guides who prioritize safety.
                </li>
                <li>
                  <b>Safety Equipment: </b> Use appropriate safety gear and
                  equipment provided for activities like trekking, paragliding,
                  and river rafting.
                </li>
                <li>
                  <b> Physical Fitness: </b> Ensure you meet the necessary
                  physical fitness requirements for specific activities.
                  Disclose any medical conditions to our team in advance.
                </li>
              </ul>
            </div>
            <div>
              <h4 className="mt-10 mb-10">Emergency Procedures</h4>
              <b className="mt-10">Emergency Contact Information</b>
              <ul className="p_10">
                <li>
                  <b>24/7 Support: </b> Mountain Mysteries provides 24/7
                  customer support for emergencies. Contact us at
                  +91-98050-21966 or{" "}
                  <Link to="mailto:">info@mountainmysteries.com</Link>
                </li>
                <li>
                  <b> Local Authorities: </b> Keep a list of local emergency
                  contact numbers, including police, ambulance, and tourist
                  assistance.
                </li>
              </ul>
              <b className="mt-10 block">Incident Reporting</b>
              <ul className="p_10">
                <li>
                  <b>Immediate Action: </b> Report any accidents, injuries, or
                  safety concerns to your tour guide or the Mountain Mysteries
                  support team immediately.
                </li>
                <li>
                  <b>Documentation: </b> Document any incidents with photographs
                  and detailed descriptions for insurance and reporting
                  purposes.
                </li>
              </ul>
            </div>
            <div>
              <h4 className="mt-10 mb-10">Environmental Responsibility</h4>
              <b className="mt-10">Sustainable Practices</b>
              <ul className="p_10">
                <li>
                  <b>Leave No Trace: </b>Respect the environment by minimizing
                  waste and carrying back all non-biodegradable materials.
                </li>
                <li>
                  <b>Wildlife Safety: </b>Maintain a safe distance from wildlife
                  and follow guidelines for responsible wildlife tourism.
                </li>
                <li>
                  <b>Cultural Sensitivity: </b> Respect local customs,
                  traditions, and communities. Seek permission before
                  photographing individuals or cultural sites.
                </li>
              </ul>
            </div>
            <div>
              <h4 className="mt-10 mb-10">Conclusion</h4>
              <p>
                Your safety is our responsibility, and your cooperation is
                essential. By adhering to these guidelines, you contribute to a
                safe and enjoyable travel experience for yourself and others. We
                thank you for choosing Mountain Mysteries and look forward to
                creating unforgettable memories together.
              </p>
              <b className="mt-10 block">Safe Travels!</b>
              <i>Mountain Mysteries Team</i>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default HealthSafety;
