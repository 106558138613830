// CustomSliderTaxi.js
import React, { useRef } from 'react';

import "./CustomSliderTaxi.css"
import CustomSliderTaxiCard from '../CustomSliderTaxiCard/CustomSliderTaxiCard';

const NewCustomSliderTaxi = ({ onClose, setIsSliderOpen, selectedHotelId, allVehicles, changeSelectedVehicle, setIsVehicleCustomizedClick, fetchTransportCost, calculateDataDayByDay, fetchCostOfVehicle, vehicleDetails, changeSelectedActivity, setOldVehicleCost, setNewVehicleCost}) => {
    const taxiDetailsPopupRef = useRef(null);
    const headerRef = useRef(null);
    const handleScroll = () => {
        if (taxiDetailsPopupRef.current.scrollTop > 50) {
            headerRef.current.classList.add('is-fixed');
        } else {
            headerRef.current.classList.remove('is-fixed');
        }
    };
    const handleClose = () => {
        onClose();
        setNewVehicleCost(null);
        setOldVehicleCost(null);
    };

    const handleClickOutside = (event) => {
        if (
          taxiDetailsPopupRef.current &&
          !taxiDetailsPopupRef.current.contains(event.target)
        ) {
          onClose();
          setNewVehicleCost(null);
          setOldVehicleCost(null);
        }
      };
  
      document.addEventListener("mousedown", handleClickOutside);

    // const sortedData = [...data].sort((a, b) => (a.hotel_id === selectedHotelId ? -1 : 1));


    


    return (
        <div className="bg-backdrop">
            <div
                id="booking_details_slider_container_new"
                ref={taxiDetailsPopupRef}
            >
                <div className="CustomSliderTaxi_MainClose fixed_header" >
                    <div className="CustomSliderTaxi_Close" onClick={handleClose}>
                    <svg
                  width="14"
                  height="13"
                  viewBox="0 0 14 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M1 12.5L13 0.5" stroke="#AAAAAA" strokeWidth="1.2" />
                  <path d="M13 12.5L1 0.5" stroke="#AAAAAA" strokeWidth="1.2" />
                </svg>
                    </div>
                    <h4>Change Transfer</h4>
                </div>
                <CustomSliderTaxiCard
                    selectedHotelId={selectedHotelId}
                    allVehicles={allVehicles}
                    changeSelectedVehicle={changeSelectedVehicle}
                    setIsVehicleCustomizedClick={setIsVehicleCustomizedClick}
                    fetchTransportCost={fetchTransportCost}
                    calculateDataDayByDay={calculateDataDayByDay}
                    fetchCostOfVehicle={fetchCostOfVehicle}
                    vehicleDetails={vehicleDetails}
                />

            </div>

        </div>
    );
};

export default NewCustomSliderTaxi;
