import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { showSuccessToast } from "../../../assets/toastUtils";
import VendorHeader from "../VendorHeader/VendorHeader";

function EditVendorRoom() {
    const { id, room_id } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    // const { rooms } = location.state || {};
    const rooms ={}

    
    

    // console.log(rooms, "data rooms")

    // const [roomDetails, setRoomDetails] = useState(roomData || {})
    const [roomData, setRoomData] = useState({
        display_name: rooms?.name,
        room_view: rooms?.room_view,
        room_description: rooms?.content,
        base_adult: rooms?.base_adult,
        max_child: rooms?.max_child,
        max_adult: rooms?.max_adult,
        max_occupancy: rooms?.max_occupany,
        room_size: rooms?.room_area,
        room_size_units: rooms?.room_area_unit,
        room_type: rooms?.room_type,
        bed_type: rooms?.bed_type,
    });

    const [fetchedImages, setFetchedImages] = useState([]);

    
  // const { roomData, addressData } = location.state || {};
  const [propertyDetails, setPropertyDetails] = useState(roomData || {});
  // const [addressDetails, setAddressDetails] = useState(addressData || {});

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [propertyLogoLoader, setPropertyLogoLoader] = useState(false);
  const [uploadedLogoFile, setUploadedLogoFile] = useState(null);
  const [logoUrl, setLogoUrl] = useState([]);

  const token = localStorage.getItem("token");

  const handleFileUploadChange = (event) => {
    const files = event.target.files;
    setSelectedFiles([...selectedFiles, ...files]);
    const file = files[0];
    const fileUrl = URL.createObjectURL(file);
    setUploadedFile(file);
    setLogoUrl(fileUrl);
  };




    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setRoomData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };


    const getRooms = () => {
        const requestOptions = {
            headers: {
                "Content-Type": "application/json",
                key: "D4X50uU40Vz4pweFLEmsoiJy107iPfTD4ompprop1",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            method: "GET",
            redirect: "follow",
        };

        fetch(`${process.env.REACT_APP_APP_URL}/api/v1/vendor/get-single-room/${id}`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.success) {
                    setRoomData({
                        display_name: result?.room_detail?.name,
                        room_view: result?.room_detail?.room_view,
                        room_description: result?.room_detail?.content,
                        base_adult: result?.room_detail?.base_adult,
                        max_child: result?.room_detail?.max_child,
                        max_adult: result?.room_detail?.max_adult,
                        max_occupancy: result?.room_detail?.max_occupany,
                        room_size: result?.room_detail?.room_area,
                        room_size_units: result?.room_detail?.room_area_unit,
                        room_type: result?.room_detail?.room_type,
                        media: result?.room_detail?.media,
                    })
                    // setFetchedImages(result?.room_detail?.images);
                    // setRoomData(result?.room_detail);
                }
            })
            .catch((error) => console.error(error));
    };


    useEffect(() => {
     
        getRooms();
    }, [id, room_id]);
    // console.log(roomDetails,'data');

    // console.log(roomData, "datta")
    const editRoomDetails = (e) => {
        e.preventDefault();
        // console.log("form submit room");

        const raw = JSON.stringify({
            name: roomData?.display_name,
            content: roomData?.room_description,
            is_active: true,
            base_adults: Number(roomData?.base_adult),
            max_children: Number(roomData?.max_child),
            max_adults: Number(roomData?.max_adult),
            max_occupany: Number(roomData.max_occupancy),
            area: Number(roomData?.room_size),
            unit: roomData?.room_size_units,
            type: roomData?.room_type,
            bed: roomData?.bed_type,
            view: roomData?.room_view,
        });

        const requestOptions = {
            method: "POST",
            body: raw,
            headers: {
                "Content-Type": "application/json",
                key: "D4X50uU40Vz4pweFLEmsoiJy107iPfTD4ompprop1",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            redirect: "follow",
        };

        fetch(
            `${process.env.REACT_APP_APP_URL}/api/v1/vendor/accommodation-room-update/${id}/${room_id}`,
            requestOptions
        )
            .then((response) => {
                return response.text().then((text) => {
                    if (!response.ok) {
                        throw new Error("Network response was not ok: " + text);
                    }
                    return text;
                });
            })
            .then((text) => {
                try {
                    const data = JSON.parse(text);
                    if (data.success) {
                        navigate(`/partner/all-rooms/${id}`);
                        setRoomData(data);
                        showSuccessToast(data.success.message || "Room Details Updated");
                    } else {
                        // console.log("Error fetching data", data);////////////////////////////////////////////////////////////////////////////
                    }
                } catch (error) {
                    console.error("Error parsing JSON:", error, text);
                }
            })
            .catch((error) => {
                console.error("Error fetching details:", error);
            });
    };

    // const editRoomDetails = () => {
    //     const myHeaders = new Headers();
    //     myHeaders.append("Content-Type", "application/json");

    //     const raw = JSON.stringify({
    //         "name": "lom",
    //         "content": "A luxurious room with all modern amenities.",
    //         "is_active": true,
    //         "base_adults": 2,
    //         "max_children": 2,
    //         "max_adults": 3,
    //         "max_occupany": 4,
    //         "area": 35.5,
    //         "unit": "square meters",
    //         "type": "Deluxe",
    //         "bed": "King",
    //         "view": "Sea",
    //         "selected_amenities": "1,2,3"
    //     });

    //     const requestOptions = {
    //         method: "POST",
    //         headers: myHeaders,
    //         body: raw,
    //         redirect: "follow"
    //     };

    //     fetch("{{local}}v1/vendor/accommodation-room-update/140/184", requestOptions)
    //         .then((response) => response.text())
    //         .then((result) => console.log(result))
    //         .catch((error) => console.error(error));
    // }

    const deleteFetchedImages = async (id) => {
        const response = await fetch(
          `${process.env.REACT_APP_APP_URL}/api/v1/vendor/delete-image`,
    
          {
            method: "DELETE",
            body: JSON.stringify({
              mediaId: id,
            }),
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
    
        const data = await response
          .json()
          .then((data) => {
            if (data.success) {
              //   showSuccessToast(data.message);
              showSuccessToast("Image deleted successfully");
              getRooms();
            //   fetchPropertyData();
            } else {
              //   showErrorToast(data.message);
            }
          })
          .catch((error) => {
            console.error("Error:", error);
            // showErrorToast("An error occurred while processing your request.");
          });
      };
    
      const addPropertyImages = (event) => {
        event.preventDefault();
        // setLoader(true);
        const myHeaders = new Headers();
        const formData = new FormData();
        formData.append("media_type", "property");
     myHeaders.append("key", process.env.REACT_APP_API_KEY);
        formData.append("associated_id", id);
        for (let i = 0; i < selectedFiles.length; i++) {
          formData.append("image[]", selectedFiles[i]);
        }
        fetch(
          `${process.env.REACT_APP_APP_URL}/api/v1/vendor/add-room-image/${id}`,
          {
            method: "POST",
            body: formData,
            headers: {
              // "multipart/form-data": "multipart/form-data",
              Authorization: `Bearer ${token}`,
              key: "D4X50uU40Vz4pweFLEmsoiJy107iPfTD4ompprop1",
            },
          }
        )
          .then((res) => res.json())
          .then((data) => {
            if (data.success) {
              // fetchPropertyImages(id);
            //   fetchPropertyData();
              setSelectedFiles([]);
              getRooms()
              //   setLoader(false);
              //   showSuccessToast(data.message);
            } else if (data.error) {
              //   setLoader(false);
              const firstErrorKey = Object.keys(data.error)[0];
              const firstErrorMessage = data.error[firstErrorKey][0];
              //   showErrorToast(firstErrorMessage);
            } else {
              //   setLoader(false);
              //   showErrorToast(data.message);
            }
          });
      };
    
      // const [vendorId, setVendorId] = useState({});
      // useEffect(() => {
      //   const vendor_id = localStorage.getItem("userDetails");
      //   if (vendor_id) {
      //     const userDetails = JSON.parse(vendor_id);
      //     console.log(userDetails);
      //     setVendorId(userDetails.id);
      //   }
      // });
    return (
        <>
      <VendorHeader />

            <section>
                <div className='basic-info-container'>
                    <div className="flex_align_center flex_gap_20">
                        <span onClick={() => window.history.back()} className="back_btn">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.292893 7.29289C-0.0976311 7.68342 -0.0976311 8.31658 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292893 7.29289ZM1 9H16V7H1V9Z" fill="#333333" />
                            </svg>
                        </span>
                        <div>
                            <h3 className='text-left'>Edit Room</h3>
                            <p>Please fill the details of your property.</p>
                        </div>
                    </div>

                    <form onSubmit={editRoomDetails}>
                        <div className="bg-white-div">
                            <h4>Room Info</h4>
                            <div className='flex flex_gap_10 w-full'>
                                <div className='w-half'>
                                    <label htmlFor="">Display Name  <span className='required'>*</span></label>
                                    <input type="text" onChange={handleInputChange} name="display_name" value={roomData?.display_name} required />
                                </div>
                                <div className='w-half'>
                                    <label htmlFor="">Room Type  <span className='required'>*</span></label>
                                    <select name="room_type" onChange={handleInputChange} id="" value={roomData?.room_type} required>
                                        <option value="">Select option</option>
                                        <option value="Deluxe">Deluxe</option>
                                        <option value="Standard">Standard</option>
                                        <option value="Luxury">Luxury</option>
                                        <option value="Master">Master</option>
                                        <option value="Common">Common</option>
                                        <option value="Tent">Tent</option>
                                        <option value="Family Room">Family Room</option>
                                        <option value="Water Villa">Water Villa</option>
                                        <option value="Beach Villa">Beach Villa</option>
                                        <option value="For Honeymooners">For Honeymooners</option>
                                        <option value="Garden Villa">Garden Villa</option>
                                        <option value="other">other</option>
                                        <option value="Suite">Suite</option>
                                    </select>
                                </div>
                            </div>
                            <div className='flex flex_gap_10 w-full'>
                                {/* <div className='w-half'>
                                <label htmlFor="">Total Rooms  <span className='required'>*</span></label>
                                <input type="number" name='total_rooms' value={roomData.total_rooms} onChange={handleInputChange} />
                            </div> */}
                                <div className='w-half'>
                                    <label htmlFor="">Room View  <span className='required'>*</span></label>
                                    <select name="room_view" value={roomData?.room_view} onChange={handleInputChange} id="" required>
                                        <option value="">Select option</option>
                                        <option value="Park View">Pool View</option>
                                        <option value="Valley View">Valley View</option>
                                        <option value="No View">No View</option>
                                        <option value="Sea View">Sea View</option>
                                        <option value="Hill View">Hill View</option>
                                        <option value="Pool View">Pool View</option>
                                        <option value="Garden View">Garden View</option>
                                        <option value="River View">River View</option>
                                        <option value="Lake View">Lake View</option>
                                        <option value="Palace View">Palace View</option>
                                        <option value="Bay View">Bay View</option>
                                        <option value="Jungle View">Jungle View</option>
                                        <option value="City View">City View</option>
                                        <option value="Landmark View">Landmark View</option>
                                        <option value="Terrace View">Terrace View</option>
                                        <option value="Lake View">Courtyard View</option>
                                        <option value="Desert View">Desert View</option>
                                        <option value="Golf Course View">Golf Course View</option>
                                        <option value="Mountain View">Mountain View</option>
                                        <option value="Ocean View">Ocean View</option>
                                        <option value="Backwater View">Backwater View</option>
                                        <option value="Resort View">Resort View</option>
                                        <option value="Desert View">Desert View</option>
                                        <option value="Golf Course View">Golf Course View</option>
                                        <option value="Mountain View">Mountain View</option>
                                        <option value="Lagoon View">Lagoon View</option>
                                        <option value="Forest View">Forest View</option>
                                        <option value="Beach View">Beach View</option>
                                        <option value="Airport View">Airport View</option>
                                        <option value="Countryside View">Countryside View</option>
                                        <option value="Harbor View">Harbor View</option>
                                        <option value="Inter-coastal View">Inter-coastal View</option>
                                        <option value="Marina View">Marina View</option>
                                    </select>
                                </div>
                                <div className='w-half'>
                                    <label htmlFor="">Bed Type <span className='required'>*</span></label>
                                    <select name="bed_type" value={roomData?.bed_type} onChange={handleInputChange} id="" required>
                                        <option value="">Select option</option>
                                        <option value="Twin Bed">Twin Bed</option>
                                        <option value="King Bed">King Bed</option>
                                        <option value="Queen Bed">Queen Bed</option>
                                        <option value="Double Bed">Double Bed</option>
                                        <option value="Single Bed">Single Bed</option>
                                        <option value="Sofa Bed">Sofa Bed</option>
                                        <option value="Standard Bed">Standard Bed</option>
                                        <option value="1 King bed or 2 Twin Bed(s)">1 King bed or 2 Twin Bed(s)</option>
                                        <option value="1 Queen bed or 2 Twin Bed(s)">1 Queen bed or 2 Twin Bed(s)</option>
                                        <option value="1 Double bed or 2 Twin Bed(s)">1 Double bed or 2 Twin Bed(s)</option>
                                        <option value="Bunk Bed">Bunk Bed</option>
                                        <option value="Futon">Futon</option>
                                        <option value="Murphy">Murphy</option>
                                        <option value="Tatami Mats">Tatami Mats</option>
                                    </select>
                                </div>
                            </div>
                            <div className='flex flex_gap_10 w-full'>
                                <div className='w-half'>
                                    <label htmlFor="">Room Size  <span className='required'>*</span></label>
                                    <input type="number" name='room_size' value={roomData?.room_size} onChange={handleInputChange} required />
                                </div>
                                <div className='w-half'>
                                    <label htmlFor="">Unit  <span className='required'>*</span></label>
                                    <select name="room_size_units" value={roomData?.room_size_units} onChange={handleInputChange} id="" required>
                                        <option value="">Select option</option>
                                        <option value="square meter">square meter</option>
                                        <option value="square feet">square feet</option>
                                    </select>
                                </div>
                            </div>
                            <div>
                                <label htmlFor="">  Description</label>
                                <textarea name="room_description" value={roomData?.room_description} onChange={handleInputChange} id="" className='w-full' placeholder='Type here...'></textarea>
                            </div>

                            <div className='mt-10'>
                                <h4>OCCUPANCY</h4>
                                <div className='flex flex_gap_10 w-full'>
                                    <div className='w-half'>
                                        <label htmlFor="">Base Adult <span className='required'>*</span></label>
                                        <input type="text" onChange={handleInputChange} name='base_adult' value={roomData?.base_adult} required />
                                    </div>
                                    {/* <div className='w-half'>
                                    <label htmlFor="">Min LOS</label>
                                    <input type="text" onChange={handleInputChange} name='min_los' value={roomData.min_los} />
                                </div> */}
                                    {/* <div className='w-half'>
                                    <label htmlFor=""> Max LOS <span className='required'>*</span></label>
                                    <input type="text" onChange={handleInputChange} name='max_los' value={roomData.max_los} required />
                                </div> */}
                                </div>
                                <div className='flex flex_gap_10 w-full'>
                                    <div className='w-half'>
                                        <label htmlFor=""> Max Adult <span className='required'>*</span></label>
                                        <input type="text" onChange={handleInputChange} name='max_adult' value={roomData?.max_adult} required />
                                    </div>
                                    <div className='w-half'>
                                        <label htmlFor=""> Max Child</label>
                                        <input type="text" onChange={handleInputChange} name='max_child' value={roomData?.max_child} />
                                    </div>
                                    <div className='w-half'>
                                        <label htmlFor="">Max Occupancy</label>
                                        <input type="text" onChange={handleInputChange} name='max_occupancy' value={roomData?.max_occupancy} />
                                    </div>
                                </div>
                                {/* <h6 className='mt-10'>Price Per Night</h6>
                            <div className='flex flex_gap_10 w-full'>
                                <div className='w-half'>
                                    <label htmlFor="">EP</label>
                                    <input type="text" onChange={(e) => handleChange(e, "price_per_night", "ep")} name='ep' value={roomData['price_per_night']['ep']} />
                                </div>
                                <div className='w-half'>
                                    <label htmlFor="">CP</label>
                                    <input type="text" onChange={(e) => handleChange(e, "price_per_night", "cp")} name='ap' value={roomData['price_per_night']['cp']} />
                                </div>
                                <div className='w-half'>
                                    <label htmlFor=""> MAP</label>
                                    <input type="text" onChange={(e) => handleChange(e, "price_per_night", "map")} name='ap' value={roomData['price_per_night']['map']} />
                                </div>
                                <div className='w-half'>
                                    <label htmlFor=""> AP</label>
                                    <input type="text" onChange={(e) => handleChange(e, "price_per_night", "ap")} name='ap' value={roomData['price_per_night']['ap']} />
                                </div>
                            </div> */}
                                {/* <h6 className='mt-10'>Extra Adult Cost</h6>
                            <div className='flex flex_gap_10 w-full'>
                                <div className='w-half'>
                                    <label htmlFor="">EP</label>
                                    <input type="text" onChange={(e) => handleChange(e, "extra_adult_cost", "ep")} name='ep' value={roomData['extra_adult_cost']['ep']} />
                                </div>
                                <div className='w-half'>
                                    <label htmlFor="">CP</label>
                                    <input type="text" onChange={(e) => handleChange(e, "extra_adult_cost", "cp")} name='cp' value={roomData['extra_adult_cost']['cp']} />
                                </div>
                                <div className='w-half'>
                                    <label htmlFor=""> MAP</label>
                                    <input type="text" onChange={(e) => handleChange(e, "extra_adult_cost", "map")} name='map' value={roomData['extra_adult_cost']['map']} />
                                </div>
                                <div className='w-half'>
                                    <label htmlFor=""> AP</label>
                                    <input type="text" onChange={(e) => handleChange(e, "extra_adult_cost", "ap")} name='ap' value={roomData['extra_adult_cost']['ap']} />
                                </div>
                            </div> */}
                                {/* <h6 className='mt-10'>Child With Bed Cost</h6>
                            <div className='flex flex_gap_10 w-full'>
                                <div className='w-half'>
                                    <label htmlFor="">EP</label>
                                    <input type="text" onChange={(e) => handleChange(e, "child_with_bed_cost", "ep")} name='ep' value={roomData['child_with_bed_cost']['ep']} />
                                </div>
                                <div className='w-half'>
                                    <label htmlFor="">CP</label>
                                    <input type="text" onChange={(e) => handleChange(e, "child_with_bed_cost", "cp")} name='cp' value={roomData['child_with_bed_cost']['cp']} />
                                </div>
                                <div className='w-half'>
                                    <label htmlFor=""> MAP</label>
                                    <input type="text" onChange={(e) => handleChange(e, "child_with_bed_cost", "map")} name='map' value={roomData['child_with_bed_cost']['map']} />
                                </div>
                                <div className='w-half'>
                                    <label htmlFor=""> AP</label>
                                    <input type="text" onChange={(e) => handleChange(e, "child_with_bed_cost", "ap")} name='ap' value={roomData['child_with_bed_cost']['ap']} />
                                </div>
                            </div> */}
                                {/* <h6 className='mt-10'>Child Without Bed Cost</h6>
                            <div className='flex flex_gap_10 w-full'>
                                <div className='w-half'>
                                    <label htmlFor="">EP</label>
                                    <input type="text" onChange={(e) => handleChange(e, "child_without_bed_cost", "ep")} name='ep' value={roomData['child_without_bed_cost']['ep']} />
                                </div>
                                <div className='w-half'>
                                    <label htmlFor="">CP</label>
                                    <input type="text" onChange={(e) => handleChange(e, "child_without_bed_cost", "cp")} name='cp' value={roomData['child_without_bed_cost']['cp']} />
                                </div>
                                <div className='w-half'>
                                    <label htmlFor=""> MAP</label>
                                    <input type="text" onChange={(e) => handleChange(e, "child_without_bed_cost", "map")} name='map' value={roomData['child_without_bed_cost']['map']} />
                                </div>
                                <div className='w-half'>
                                    <label htmlFor=""> AP</label>
                                    <input type="text" onChange={(e) => handleChange(e, "child_without_bed_cost", "ap")} name='ap' value={roomData['child_without_bed_cost']['ap']} />
                                </div>
                            </div> */}
                            </div>
                        </div>

                        <div className="bg-white-div mt-10">
              <div className="edit-rooms-form-wrapper-container  mt-10">
                <h4 className="mb-10">Property Images</h4>

                <div className="propertyImagesContainer">
                  {roomData?.media?.map((item, index) => (
                    <div key={index} className="propertyImagesContainerDiv">
                      <img
                        src={item?.original_url}
                        alt={`Property Image ${index}`}
                      />
                      <button
                        type="button"
                        onClick={() => deleteFetchedImages(item.id)}
                        className="propertyImagesContainerDelete"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 8 8"
                          fill="none"
                        >
                          <path d="M1 6.94752L7 1" stroke="black" />
                          <path
                            d="M7 6.94775L1 1.00023"
                            stroke="black"
                            strokeLinecap="round"
                          />
                        </svg>
                      </button>
                    </div>
                  ))}
                </div>

                <div>
                  <div className="flex flex_gap_10 flex_wrap">
                    <>
                      {fetchedImages.map((item, index) => (
                        <div key={index}>
                          <div className="flex flex_gap_10 justify-content-between">
                            <div>
                              <img
                                src={item.src}
                                alt={`Property Image ${index}`}
                                height="80px"
                                width="100px"
                              />
                            </div>
                            <div className="featureImages_btnContainer">
                              <button
                                type="button"
                                onClick={() => deleteFetchedImages(item.id)}
                                className="featureImages_removeLogobtn"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="15"
                                  height="15"
                                  viewBox="0 0 8 8"
                                  fill="none"
                                >
                                  <path d="M1 6.94752L7 1" stroke="black" />
                                  <path
                                    d="M7 6.94775L1 1.00023"
                                    stroke="black"
                                  />
                                </svg>
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  </div>
                  <div style={{ position: "relative" }}>
                    <input
                      accept="image/*"
                      type="file"
                      id="select-image"
                      multiple="multiple"
                      onChange={handleFileUploadChange}
                      className={`${
                        selectedFiles.length === 0
                          ? "featureImages_upload_file_input"
                          : "featureImages_upload_file_input featureImages_addzindex"
                      }`}
                    />
                    <hr style={{ margin: "20px" }} />
                    {selectedFiles && selectedFiles.length > 0 ? (
                      <div className="propertyImagesContainer" style={{marginTop: "10px"}}>
                        {selectedFiles.map((file, index) => (
                          <div
                            key={index}
                            className="propertyImagesContainerDiv"
                          >
                            <div style={{height:"100%"}}>
                              <img
                                src={URL.createObjectURL(file)}
                                alt="error"
                                style={{ objectFit: "cover", width: "100%", height: "100%" }}
                              
                              />
                            </div>
                            <div className="featureImages_btnContainer">
                              <button
                                type="button"
                               style={{display: "flex"}}
                                onClick={() => setSelectedFiles((prev)=>prev.filter((item) => item !== file))}
                                className="propertyImagesContainerDelete"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="15"
                                  height="15"
                                  viewBox="0 0 8 8"
                                  fill="none"
                                >
                                  <path d="M1 6.94752L7 1" stroke="black" />
                                  <path
                                    d="M7 6.94775L1 1.00023"
                                    stroke="black"
                                  />
                                </svg>
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      // </div>
                      <div className="featureImages_uplaodFile_displaytext_container">
                        <p className="featured_image_text">Add Files</p>
                        <p className="featured_image_text_dragdrop">
                          or drop JPG, PNG images here
                        </p>
                      </div>
                    )}
                    {selectedFiles && selectedFiles.length ? (
                      <button
                        type="button"
                        className="primary_btn search-btn-width"
                        style={{marginTop:"20px"}}
                        onClick={(event) => addPropertyImages(event)}
                      >
                        Upload
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>

                        <div className="flex justify_content_end mt-10">
                            <button type='submit' className="primary_btn" style={{ width: "70px" }}>Save</button>
                        </div>
                    </form>
                </div>
                {/* <AllAmenities showTitle={false} /> */}
            </section>
        </>
    )
}

export default EditVendorRoom;