import React from 'react';


export const BgThemeButton = ({
  disabled,
  children,
  onClick,
  type,
  style,
  icon,
  className,
  loader,
  bgColor,
  textColor,
  width,
}) => {
  const getWidthClass = (width) => {
    switch (width) {
      case 'small':
        return 'bg_theme_button_small';
      case 'medium':
        return 'bg_theme_button_medium';
      case 'full':
        return 'bg_theme_button_full';
      default:
        return '';
    }
  };

  return (
    <button
      style={{
        cursor: loader ? 'not-allowed' : 'pointer',
        backgroundColor: bgColor,
        color: textColor,
        ...style,
      }}
      disabled={loader ? true : disabled}
      type={type}
      onClick={!loader ? onClick : () => {}}
      className={`BgThemeButton ${getWidthClass(width)} ${className}`}
    >
      {loader ? (
        <>
          <span className="bg_theme_button_spinner"></span>
          <span style={{color: "#ffffff", fontSize: "12px"}}>Please wait</span>
        </>
      ) : (
        <div style={{fontSize: "12px"}}>
          {icon}
          {children}
        </div>
      )}
    </button>
  );
};

export const DisabledTextButton = ({ disabled, children, onClick, loader, className, style,textColor }) => {
    return (
      <button
        style={{
            fontSize: "12px",
          cursor: loader ? 'not-allowed' : 'pointer',
          opacity: loader ? 0.5 : 1,
          color: textColor,
          ...style,

        }}
        disabled={loader ? true : disabled}
        onClick={!loader ? onClick : () => {}}
        className={`disabledTextButton ${className}`}
      >
         {loader ? 'Please wait...' : children}
      </button>
    );
  };
