import React, { useState, useEffect } from "react";
import Footer from "../../CustomComponents/Footer/Footer";
import CustomPagination from "../../CustomComponents/CustomPagination/CustomPagination";
import CustomListingCard from "../../CustomComponents/CustomListingCard/CustomListingCard";
import { useNavigate, useLocation } from "react-router-dom";
import Header from "../../CustomComponents/Header/Header";
import ListingBanner from "../../CustomComponents/ListingBanner/ListingBanner";
import FilterHoliday from "../../CustomComponents/FilterSection/FilterHoliday";
import Skeleton from "react-loading-skeleton";
import PageMetaData from "../../CustomComponents/PageMetaData/PageMetaData";
import PackageSearchForm from "../../CustomComponents/PackageSearchForm/PackageSearchForm";
import NoDataFound from "../../CustomComponents/NoDataFound/NoDataFound";
import AllPackagesSearchForm from "../../CustomComponents/AllPackagesSearchForm/AllPackagesSearchForm";
import OgMetaTitles from "../../CustomComponents/PageMetaData/OgMetaTitles";
import { scrollToTop } from "../../utils/utils";
import BreadcrumbSchema from "../../CustomComponents/Schema/BreadcrumbSchema";

function AllPackagesData() {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9;

  const navigate = useNavigate();
  const [bookingData, setBookingData] = useState({});
  const [loader, setLoader] = useState(true);
  const [filterLoader, setFilterLoader] = useState(false);
  const location = useLocation();
  const [packageData, setPackageData] = useState([]);
  const [sortedFilter, setSortedFilter] = useState({
    show: "Popular",
    value: "polpular",
  });

  useEffect(() => {
    // console.log(sortedFilter?.value);
    let dummyData = [...packageData];
    if (sortedFilter?.value === "l2hByp") {
      dummyData.sort((a, b) => a.startingPrice - b.startingPrice);
      setPackageData(dummyData);
    }

    if (sortedFilter?.value === "h2lByp") {
      dummyData.sort((a, b) => b.startingPrice - a.startingPrice);
      setPackageData(dummyData);
    }
    // duration
    if (sortedFilter?.value === "l2hByd") {
      dummyData.sort((a, b) => a.totalNights - b.totalNights);
      setPackageData(dummyData);
    }
    if (sortedFilter?.value === "h2lByd") {
      dummyData.sort((a, b) => b.totalNights - a.totalNights);
      setPackageData(dummyData);
    }
  }, [sortedFilter?.value]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const childAges = [];
    let i = 1;
    while (params.has(`childAge[${i}]`)) {
      childAges.push(params.get(`childAge[${i}]`));
      i++;
    }

    const data = {
      location: params.get("location"),
      room: params.get("room"),
      adult: params.get("adult"),
      children: params.get("children"),
      radio: params.get("radio"),
      childAge: childAges,
    };
    setBookingData(data);
    fetchPackageData(data);
  }, [location.search]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const clearSelection = () => {
    // console.log("Clearing selection...");
  };
  // console.log(packageData, "packageData");

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedData =
    packageData &&
    packageData &&
    packageData.length > 0 &&
    packageData.slice(startIndex, endIndex);

  const fetchPackageData = async (data, filters) => {
    // console.log("filters", filters);

    setFilterLoader(true);
    const myHeaders = new Headers();
    // myHeaders.append("Accept", "application/json");
    myHeaders.append("key", process.env.REACT_APP_API_KEY);
    const formdata = new FormData();
    {
      data.location && formdata.append("location", data.location);
    }
    // formdata.append("location", data.location ? data.location : "");
    // formdata.append("limit", 1);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_APP_URL}/api/v1/holidays/fetch?destination=${
          filters?.destinations ? filters?.destinations : ""
        }&price_range=${filters?.budget ? filters?.budget : ""}&duration=${
          filters?.duration ? filters?.duration : ""
        }&theme=${filters?.themes ? filters?.themes : ""}`,
        requestOptions
      );
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
      const result = await response.json();
      setLoader(false);
      setPackageData(result.data);
      scrollToTop();
    } catch (error) {
      setPackageData([]);
      setLoader(false);
      console.error(error);
    } finally {
      setFilterLoader(false);
    }
  };

  const sendDataToParent = (data) => {
    // console.log(data);
    fetchPackageData(bookingData, data);
  };

  return (
    <>
      <PageMetaData
        title="All Packages - Mountain Mysteries"
        description="Enhance your hotel capabilities with RevCatalyst's powerful system. Optimize performance, streamline operations, and unleash the full potential of property."
        keywords="hotel management, RevCatalyst, property optimization"
      />
      <OgMetaTitles
        title={`All Packages - Mountain Mysteries`}
        type="Enhance your hotel capabilities with RevCatalyst's powerful system. Optimize performance, streamline operations, and unleash the full potential of property."
        url={`https://www.mountainmysteries.com/all-packages`}
      />
      <BreadcrumbSchema />
      <Header />
      <AllPackagesSearchForm />
      {/* <ListingBanner heading={`${bookingData.location}`} /> */}
      <div className="container" id="hotelsData">
        <main>
          {loader ? (
            <div className="filters">
              <div className="agent border-bottom-one">
                <div>
                  <Skeleton width={100} />
                </div>
              </div>
              <div className="filter_checkbox border-bottom-one">
                <Skeleton width={150} />
                <Skeleton count={3} width={90} />
              </div>
              <div className="filter_checkbox border-bottom-one">
                <Skeleton width={150} />
                <Skeleton count={3} width={90} />
              </div>
              <div className="filter_checkbox border-bottom-one">
                <Skeleton width={150} />
                <Skeleton count={3} width={90} />
              </div>
              <div className="filter_checkbox ">
                <Skeleton width={150} />
                <Skeleton count={3} width={90} />
              </div>
            </div>
          ) : (
            <FilterHoliday
              sendDataToParent={sendDataToParent}
              showDestinations={true}
            />
          )}

          <div
            className="listing-right-container"
            style={{ paddingTop: "0px" }}
          >
            <div className="flex align_items_center flex_wrap justify_content_between">
              <h3 style={{ textTransform: "capitalize" }} className="text-left">
                {bookingData.location ? (
                  <span>{`Showing Packages In ${bookingData.location}`}</span>
                ) : (
                  <span> Showing All Packages</span>
                )}

                {/* {bookingData.location} */}
              </h3>
              <div className="sorted_div">
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <mask
                    id="mask0_5040_6776"
                    style={{ maskType: "alpha" }}
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="14"
                    height="14"
                  >
                    <rect width="14" height="14" fill="#D9D9D9" />
                  </mask>
                  <g mask="url(#mask0_5040_6776)">
                    <path
                      d="M5.83333 10.5V9.33333H8.16667V10.5H5.83333ZM3.5 7.58333V6.41667H10.5V7.58333H3.5ZM1.75 4.66667V3.5H12.25V4.66667H1.75Z"
                      fill="#1C1B1F"
                    />
                  </g>
                </svg>
                Sort by:{" "}
                <span className="cursor_pointer">
                  <b>{sortedFilter?.show}</b>
                </span>
                <div className="sorted_selected">
                  <div
                    onClick={() =>
                      setSortedFilter({
                        value: "l2hByp",
                        show: "Price - Low to High",
                      })
                    }
                    className="cursor_pointer p-5"
                  >
                    {" "}
                    <span>Price - Low to High</span>
                  </div>
                  <div
                    className="cursor_pointer p-5"
                    onClick={() =>
                      setSortedFilter({
                        value: "h2lByp",
                        show: "Price - high to Low",
                      })
                    }
                  >
                    {" "}
                    <span>Price - High to Low</span>
                  </div>
                  <div
                    className="cursor_pointer p-5"
                    onClick={() =>
                      setSortedFilter({
                        value: "l2hByd",
                        show: "Duration - Low to High",
                      })
                    }
                  >
                    {" "}
                    <span>Duration - Low to High</span>
                  </div>
                  <div
                    className="cursor_pointer p-5"
                    onClick={() =>
                      setSortedFilter({
                        value: "h2lByd",
                        show: "Duration - High to Low",
                      })
                    }
                  >
                    {" "}
                    <span>Duration - High to Low</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="cards-flex">
              {loader || filterLoader ? (
                Array.from({ length: 9 }).map((items, index) => (
                  <div
                    key={index}
                    className="card-listing"
                    style={{
                      background: "none",
                      borderRadius: "8px",
                      marginBottom: "0px",
                      boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                      padding: "0px",
                    }}
                  >
                    <div>
                      <Skeleton
                        style={{ borderRadius: "8px 8px 0px 0px" }}
                        height={150}
                      />
                    </div>
                    <div className="card_content" style={{ marginTop: "0px" }}>
                      <h6>
                        <Skeleton width="60%" />
                      </h6>
                      <h6>
                        <Skeleton width="40%" />
                      </h6>
                      <h6>
                        <Skeleton width="20%" />
                      </h6>
                      <h6>
                        <Skeleton width="24%" />
                      </h6>
                    </div>
                  </div>
                ))
              ) : (
                <>
                  {packageData?.length === 0 ? (
                    <NoDataFound />
                  ) : (
                    <>
                      {displayedData?.map((data, index) => (
                          <CustomListingCard
                            key={index}
                            imgSrc={
                              data.packageImages[0]
                                ? data.packageImages[0]
                                : data.packageDestination[0].images
                            }
                            title={data.packageName}
                            packageHub={data.packageHub}
                            duration={data.totalDays}
                            nightDuration={data.totalNights}
                            price={data.startingPrice}
                            bookingLink={data.bookingLink}
                            detailsLink={data.detailsLink}
                            packagehandle={data.packagehandle}
                          />
                      ))}
                    </>
                  )}
                </>
              )}
            </div>
            <div className="communication_pagination">
              <CustomPagination
                currentPage={currentPage}
                itemsPerPage={itemsPerPage}
                totalItems={packageData.length}
                onPageChange={handlePageChange}
                clearSelection={clearSelection}
              />
            </div>
          </div>
        </main>
      </div>
      {/* <DestinationLinks /> */}
      <Footer />
    </>
  );
}

export default AllPackagesData;
