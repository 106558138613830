import React, { useState } from "react";
import { NavLink, Link, useParams, useLocation } from "react-router-dom";
import "../AgentSideBar/AgentSideBar.css";
import { useEffect } from "react";

const AgentSideBar = () => {
  const [dropdown, setDropdown] = useState(false);
  const location = useLocation();

  const handleOpenDropdown = () => {
    setDropdown((prevState) => !prevState);
  };

  // useEffect(() => {
  //   const isActive =
  //     location.pathname.startsWith("/partner/rates/") ||
  //     location.pathname.startsWith("/partner/inventory/");
  //   setDropdown(isActive);
  // }, [location.pathname]);

  useEffect(() => {
    if (
      location.pathname.startsWith("/partner/rates/") ||
      location.pathname.startsWith("/partner/inventory/")
    ) {
      setDropdown(true);
    } else {
      setDropdown(false);
    }
  }, []);

  const { id } = useParams();
  // console.log(id);

  return (
    <>
      <section className="sidebar">
        <NavLink
          className="sidebar_btn flex align_items_center flex_gap_5"
          to={`/agent-dashboard`}
        >
          <svg
            width="15"
            height="15"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2 18C1.45 18 0.979167 17.8042 0.5875 17.4125C0.195833 17.0208 0 16.55 0 16V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H16C16.55 0 17.0208 0.195833 17.4125 0.5875C17.8042 0.979167 18 1.45 18 2V16C18 16.55 17.8042 17.0208 17.4125 17.4125C17.0208 17.8042 16.55 18 16 18H2ZM2 16H8V2H2V16ZM10 16H16V9H10V16ZM10 7H16V2H10V7Z"
              fill="#1C1B1F"
            />
          </svg>
          Dashboard
        </NavLink>
        {/* <NavLink
          className="sidebar_btn flex align_items_center flex_gap_5"
          to={`/agent-profile/${id}`}
        >
          <svg
            className="m_0"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.04551 6.54502C6.14832 6.54502 5.38027 6.22557 4.74137 5.58666C4.10246 4.94775 3.78301 4.17971 3.78301 3.28252C3.78301 2.38533 4.10246 1.61729 4.74137 0.978379C5.38027 0.339473 6.14832 0.0200195 7.04551 0.0200195C7.94269 0.0200195 8.71074 0.339473 9.34965 0.978379C9.98855 1.61729 10.308 2.38533 10.308 3.28252C10.308 4.17971 9.98855 4.94775 9.34965 5.58666C8.71074 6.22557 7.94269 6.54502 7.04551 6.54502ZM0.520508 11.4388V10.7863C0.520508 10.3241 0.639453 9.89928 0.877344 9.51186C1.11523 9.12443 1.43129 8.82877 1.82551 8.62486C2.66832 8.20346 3.52473 7.8874 4.39473 7.6767C5.26473 7.466 6.14832 7.36064 7.04551 7.36064C7.94269 7.36064 8.82629 7.466 9.69629 7.6767C10.5663 7.8874 11.4227 8.20346 12.2655 8.62486C12.6597 8.82877 12.9758 9.12443 13.2137 9.51186C13.4516 9.89928 13.5705 10.3241 13.5705 10.7863V11.4388C13.5705 11.8874 13.4108 12.2714 13.0913 12.5908C12.7719 12.9103 12.3879 13.07 11.9393 13.07H2.15176C1.70316 13.07 1.31914 12.9103 0.999687 12.5908C0.680234 12.2714 0.520508 11.8874 0.520508 11.4388ZM2.15176 11.4388H11.9393V10.7863C11.9393 10.6367 11.9019 10.5008 11.8271 10.3785C11.7523 10.2561 11.6538 10.161 11.5314 10.093C10.7974 9.72596 10.0565 9.45068 9.30887 9.26717C8.56121 9.08365 7.80676 8.99189 7.04551 8.99189C6.28426 8.99189 5.5298 9.08365 4.78215 9.26717C4.03449 9.45068 3.29363 9.72596 2.55957 10.093C2.43723 10.161 2.33867 10.2561 2.26391 10.3785C2.18914 10.5008 2.15176 10.6367 2.15176 10.7863V11.4388ZM7.04551 4.91377C7.4941 4.91377 7.87812 4.75404 8.19758 4.43459C8.51703 4.11514 8.67676 3.73111 8.67676 3.28252C8.67676 2.83393 8.51703 2.4499 8.19758 2.13045C7.87812 1.811 7.4941 1.65127 7.04551 1.65127C6.59691 1.65127 6.21289 1.811 5.89344 2.13045C5.57398 2.4499 5.41426 2.83393 5.41426 3.28252C5.41426 3.73111 5.57398 4.11514 5.89344 4.43459C6.21289 4.75404 6.59691 4.91377 7.04551 4.91377Z"
              fill="#000"
            />
          </svg>
          My Profile
        </NavLink> */}
        <NavLink
          className="sidebar_btn flex align_items_center flex_gap_5"
          to={`/agent-account`}
        >
          <svg
            className="m_0"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.04551 6.54502C6.14832 6.54502 5.38027 6.22557 4.74137 5.58666C4.10246 4.94775 3.78301 4.17971 3.78301 3.28252C3.78301 2.38533 4.10246 1.61729 4.74137 0.978379C5.38027 0.339473 6.14832 0.0200195 7.04551 0.0200195C7.94269 0.0200195 8.71074 0.339473 9.34965 0.978379C9.98855 1.61729 10.308 2.38533 10.308 3.28252C10.308 4.17971 9.98855 4.94775 9.34965 5.58666C8.71074 6.22557 7.94269 6.54502 7.04551 6.54502ZM0.520508 11.4388V10.7863C0.520508 10.3241 0.639453 9.89928 0.877344 9.51186C1.11523 9.12443 1.43129 8.82877 1.82551 8.62486C2.66832 8.20346 3.52473 7.8874 4.39473 7.6767C5.26473 7.466 6.14832 7.36064 7.04551 7.36064C7.94269 7.36064 8.82629 7.466 9.69629 7.6767C10.5663 7.8874 11.4227 8.20346 12.2655 8.62486C12.6597 8.82877 12.9758 9.12443 13.2137 9.51186C13.4516 9.89928 13.5705 10.3241 13.5705 10.7863V11.4388C13.5705 11.8874 13.4108 12.2714 13.0913 12.5908C12.7719 12.9103 12.3879 13.07 11.9393 13.07H2.15176C1.70316 13.07 1.31914 12.9103 0.999687 12.5908C0.680234 12.2714 0.520508 11.8874 0.520508 11.4388ZM2.15176 11.4388H11.9393V10.7863C11.9393 10.6367 11.9019 10.5008 11.8271 10.3785C11.7523 10.2561 11.6538 10.161 11.5314 10.093C10.7974 9.72596 10.0565 9.45068 9.30887 9.26717C8.56121 9.08365 7.80676 8.99189 7.04551 8.99189C6.28426 8.99189 5.5298 9.08365 4.78215 9.26717C4.03449 9.45068 3.29363 9.72596 2.55957 10.093C2.43723 10.161 2.33867 10.2561 2.26391 10.3785C2.18914 10.5008 2.15176 10.6367 2.15176 10.7863V11.4388ZM7.04551 4.91377C7.4941 4.91377 7.87812 4.75404 8.19758 4.43459C8.51703 4.11514 8.67676 3.73111 8.67676 3.28252C8.67676 2.83393 8.51703 2.4499 8.19758 2.13045C7.87812 1.811 7.4941 1.65127 7.04551 1.65127C6.59691 1.65127 6.21289 1.811 5.89344 2.13045C5.57398 2.4499 5.41426 2.83393 5.41426 3.28252C5.41426 3.73111 5.57398 4.11514 5.89344 4.43459C6.21289 4.75404 6.59691 4.91377 7.04551 4.91377Z"
              fill="#000"
            />
          </svg>
          My Account
        </NavLink>
        <NavLink
          className="sidebar_btn flex align_items_center  flex_gap_5"
          to={`/agent-booking`}
        >
          <svg
            className="m_0"
            width="18"
            height="18"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <mask
              id="mask0_3887_4171"
              style={{ maskType: "alpha" }}
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="24"
              height="24"
            >
              <rect width="24" height="24" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_3887_4171)">
              <path
                d="M10.95 18.35L7.4 14.8L8.85 13.35L10.95 15.45L15.15 11.25L16.6 12.7L10.95 18.35ZM5 22C4.45 22 3.97917 21.8042 3.5875 21.4125C3.19583 21.0208 3 20.55 3 20V6C3 5.45 3.19583 4.97917 3.5875 4.5875C3.97917 4.19583 4.45 4 5 4H6V2H8V4H16V2H18V4H19C19.55 4 20.0208 4.19583 20.4125 4.5875C20.8042 4.97917 21 5.45 21 6V20C21 20.55 20.8042 21.0208 20.4125 21.4125C20.0208 21.8042 19.55 22 19 22H5ZM5 20H19V10H5V20ZM5 8H19V6H5V8Z"
                fill="#1C1B1F"
              />
            </g>
          </svg>
          My Booking
        </NavLink>
        {/* <NavLink
          className="sidebar_btn flex align_items_center  flex_gap_5"
          to={`/agent-packages`}
        >
          <svg
            className="m_0"
            width="18"
            height="18"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <mask
              id="mask0_3887_4171"
              style={{ maskType: "alpha" }}
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="24"
              height="24"
            >
              <rect width="24" height="24" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_3887_4171)">
              <path
                d="M10.95 18.35L7.4 14.8L8.85 13.35L10.95 15.45L15.15 11.25L16.6 12.7L10.95 18.35ZM5 22C4.45 22 3.97917 21.8042 3.5875 21.4125C3.19583 21.0208 3 20.55 3 20V6C3 5.45 3.19583 4.97917 3.5875 4.5875C3.97917 4.19583 4.45 4 5 4H6V2H8V4H16V2H18V4H19C19.55 4 20.0208 4.19583 20.4125 4.5875C20.8042 4.97917 21 5.45 21 6V20C21 20.55 20.8042 21.0208 20.4125 21.4125C20.0208 21.8042 19.55 22 19 22H5ZM5 20H19V10H5V20ZM5 8H19V6H5V8Z"
                fill="#1C1B1F"
              />
            </g>
          </svg>
          My Packages
        </NavLink> */}
        <NavLink
          className="sidebar_btn flex align_items_center flex_gap_5"
          to={`/agent-credit-logs`}
        >
          <svg
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <mask
              id="mask0_5012_6616"
              style={{ maskType: "alpha" }}
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="21"
              height="20"
            >
              <rect x="0.5" width="20" height="20" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_5012_6616)">
              <path
                d="M3.83329 16.6666C3.37496 16.6666 2.9826 16.5034 2.65621 16.177C2.32982 15.8506 2.16663 15.4583 2.16663 14.9999V4.99992C2.16663 4.54159 2.32982 4.14922 2.65621 3.82284C2.9826 3.49645 3.37496 3.33325 3.83329 3.33325H7.99996V4.99992H3.83329V14.9999H17.1666V4.99992H13V3.33325H17.1666C17.625 3.33325 18.0173 3.49645 18.3437 3.82284C18.6701 4.14922 18.8333 4.54159 18.8333 4.99992V14.9999C18.8333 15.4583 18.6701 15.8506 18.3437 16.177C18.0173 16.5034 17.625 16.6666 17.1666 16.6666H3.83329ZM10.5 12.8333L6.33329 8.66659L7.49996 7.49992L9.66663 9.66658V3.33325H11.3333V9.66658L13.5 7.49992L14.6666 8.66659L10.5 12.8333Z"
                fill="#1C1B1F"
              />
            </g>
          </svg>
          Credit Logs
        </NavLink>
        <NavLink
          className="sidebar_btn flex align_items_center flex_gap_5"
          to={`/agent-advance-logs`}
        >
          <svg
          style={{rotate:"180deg"}}
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <mask
              id="mask0_5012_6616"
              style={{ maskType: "alpha" }}
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="21"
              height="20"
            >
              <rect x="0.5" width="20" height="20" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_5012_6616)">
              <path
                d="M3.83329 16.6666C3.37496 16.6666 2.9826 16.5034 2.65621 16.177C2.32982 15.8506 2.16663 15.4583 2.16663 14.9999V4.99992C2.16663 4.54159 2.32982 4.14922 2.65621 3.82284C2.9826 3.49645 3.37496 3.33325 3.83329 3.33325H7.99996V4.99992H3.83329V14.9999H17.1666V4.99992H13V3.33325H17.1666C17.625 3.33325 18.0173 3.49645 18.3437 3.82284C18.6701 4.14922 18.8333 4.54159 18.8333 4.99992V14.9999C18.8333 15.4583 18.6701 15.8506 18.3437 16.177C18.0173 16.5034 17.625 16.6666 17.1666 16.6666H3.83329ZM10.5 12.8333L6.33329 8.66659L7.49996 7.49992L9.66663 9.66658V3.33325H11.3333V9.66658L13.5 7.49992L14.6666 8.66659L10.5 12.8333Z"
                fill="#1C1B1F"
              />
            </g>
          </svg>
          Advance  Payments
        </NavLink>
        <NavLink
          className="sidebar_btn flex align_items_center flex_gap_5 "
          to={`/agent-promotion`}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <mask
              id="mask0_4518_3510"
              style={{ maskType: "alpha" }}
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="24"
              height="24"
            >
              <rect width="24" height="24" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_4518_3510)">
              <path
                d="M7.50006 11C6.53339 11 5.70839 10.6583 5.02506 9.975C4.34173 9.29167 4.00006 8.46667 4.00006 7.5C4.00006 6.53333 4.34173 5.70833 5.02506 5.025C5.70839 4.34167 6.53339 4 7.50006 4C8.46673 4 9.29173 4.34167 9.97506 5.025C10.6584 5.70833 11.0001 6.53333 11.0001 7.5C11.0001 8.46667 10.6584 9.29167 9.97506 9.975C9.29173 10.6583 8.46673 11 7.50006 11ZM7.50006 9C7.91673 9 8.27089 8.85417 8.56256 8.5625C8.85423 8.27083 9.00006 7.91667 9.00006 7.5C9.00006 7.08333 8.85423 6.72917 8.56256 6.4375C8.27089 6.14583 7.91673 6 7.50006 6C7.08339 6 6.72923 6.14583 6.43756 6.4375C6.14589 6.72917 6.00006 7.08333 6.00006 7.5C6.00006 7.91667 6.14589 8.27083 6.43756 8.5625C6.72923 8.85417 7.08339 9 7.50006 9ZM16.5001 20C15.5334 20 14.7084 19.6583 14.0251 18.975C13.3417 18.2917 13.0001 17.4667 13.0001 16.5C13.0001 15.5333 13.3417 14.7083 14.0251 14.025C14.7084 13.3417 15.5334 13 16.5001 13C17.4667 13 18.2917 13.3417 18.9751 14.025C19.6584 14.7083 20.0001 15.5333 20.0001 16.5C20.0001 17.4667 19.6584 18.2917 18.9751 18.975C18.2917 19.6583 17.4667 20 16.5001 20ZM16.5001 18C16.9167 18 17.2709 17.8542 17.5626 17.5625C17.8542 17.2708 18.0001 16.9167 18.0001 16.5C18.0001 16.0833 17.8542 15.7292 17.5626 15.4375C17.2709 15.1458 16.9167 15 16.5001 15C16.0834 15 15.7292 15.1458 15.4376 15.4375C15.1459 15.7292 15.0001 16.0833 15.0001 16.5C15.0001 16.9167 15.1459 17.2708 15.4376 17.5625C15.7292 17.8542 16.0834 18 16.5001 18ZM4.70006 19.3C4.51673 19.1167 4.42506 18.8833 4.42506 18.6C4.42506 18.3167 4.51673 18.0833 4.70006 17.9L17.9001 4.7C18.0834 4.51667 18.3167 4.425 18.6001 4.425C18.8834 4.425 19.1167 4.51667 19.3001 4.7C19.4834 4.88333 19.5751 5.11667 19.5751 5.4C19.5751 5.68333 19.4834 5.91667 19.3001 6.1L6.10006 19.3C5.91673 19.4833 5.68339 19.575 5.40006 19.575C5.11673 19.575 4.88339 19.4833 4.70006 19.3Z"
                fill="#1C1B1F"
              />
            </g>
          </svg>
          Offers
        </NavLink>
        {/* <NavLink
          className="sidebar_btn flex align_items_center flex_gap_5"
          to={`/partner/promotion/${id}`}
        >
          <svg
            className=""
            width="14"
            height="15"
            viewBox="0 0 14 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.75 14.25C1.3375 14.25 0.984375 14.1031 0.690625 13.8094C0.396875 13.5156 0.25 13.1625 0.25 12.75V2.25C0.25 1.8375 0.396875 1.48438 0.690625 1.19063C0.984375 0.896875 1.3375 0.75 1.75 0.75H7V2.25H1.75V12.75H7V14.25H1.75ZM10 11.25L8.96875 10.1625L10.8813 8.25H4.75V6.75H10.8813L8.96875 4.8375L10 3.75L13.75 7.5L10 11.25Z"
              fill="#000"
            />
          </svg>
          Settings
        </NavLink> */}
        {/* <NavLink
          className="sidebar_btn flex align_items_center flex_gap_5 "
          to={`/`}
        >
          <svg
            className=""
            width="14"
            height="15"
            viewBox="0 0 14 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.75 14.25C1.3375 14.25 0.984375 14.1031 0.690625 13.8094C0.396875 13.5156 0.25 13.1625 0.25 12.75V2.25C0.25 1.8375 0.396875 1.48438 0.690625 1.19063C0.984375 0.896875 1.3375 0.75 1.75 0.75H7V2.25H1.75V12.75H7V14.25H1.75ZM10 11.25L8.96875 10.1625L10.8813 8.25H4.75V6.75H10.8813L8.96875 4.8375L10 3.75L13.75 7.5L10 11.25Z"
              fill="#000"
            />
          </svg>
          Log Out
        </NavLink> */}
      </section>
    </>
  );
};

export default AgentSideBar;
