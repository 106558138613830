import React, { useEffect, useState } from "react";
import SideBar from "../../../CustomComponents/Property/SideBar/SideBar";
import TabBar from "../../../CustomComponents/Property/TabBar/TabBar";
import "../PropertyDetails/PropertyDetails.css";
import BasicInformation from "../../../CustomComponents/Property/BasicInformation/BasicInformation";
import Locations from "../../../CustomComponents/Property/Locations/Locations";
import AllAmenities from "../../../CustomComponents/Property/AllAmenities/AllAmenities";
import Rooms from "../../../CustomComponents/Property/Rooms/Rooms";
import PhotosVideos from "../../../CustomComponents/Property/PhotosVideos/PhotosVideos";
import Policies from "../../../CustomComponents/Property/Policies/Policies";
import MyProperties from "../../../CustomComponents/Property/MyProperties/MyProperties";
import Header from "../../../CustomComponents/Header/Header";
import VendorHeader from "../../../CustomComponents/Property/VendorHeader/VendorHeader";
import Dashboard from "../../../CustomComponents/Property/Dashboard/Dashboard";

function PropertyDetails() {
  const [activeTab, setActiveTab] = useState(1);
  const dataFromParent = (data) => {
    setActiveTab(data);
  };

  const [propertyData, setPropertyData] = useState({
    property_name: "",
    property_type: "",
    mobile_number: "",
    email: "",
    start_rating: "",
    property_built: "",
    built_year: "",
    no_of_rooms: "",
    resturant: "",
    no_of_floors: "",
    channel_manager: false,
    currency: "",
    vcc_currency: "",
    time_zone: "",
    check_in: "",
    check_out: "",
    description: "",
    display_name: "",
    room_type: "",
    total_rooms: "",
    room_view: "",
    bed_type: "",
    room_size: "",
    room_size_units: "",
    room_description: "",
    policy_description: "",
    terms_description: "",
    base_adult: "",
    min_los: "",
    max_los: "",
    max_adult: "",
    max_child: "",
    max_occupancy: "",
    price_per_night: {
      ep: "",
      map: "",
      cp: "",
      ap: "",
    },
    extra_adult_cost: {
      ep: "",
      map: "",
      cp: "",
      ap: "",
    },
    child_with_bed_cost: {
      ep: "",
      map: "",
      cp: "",
      ap: "",
    },
    child_without_bed_cost: {
      ep: "",
      map: "",
      cp: "",
      ap: "",
    },
  });

  // console.log(propertyData);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPropertyData({ ...propertyData, [name]: value });
  };

  const handleNextClick = (e) => {
    e.preventDefault();
    setActiveTab(activeTab + 1);
  };

  return (
    <>
      <VendorHeader showSelectProperty={false} />
      <div className="sidebar_main_div">
        {/* <SideBar allPropertiesPage={true} /> */}
        <MyProperties />
        {/* <div className="tabBar">
                    <TabBar dataFromParent={dataFromParent} activeTab={activeTab} />
                    {activeTab === 1 && <div><BasicInformation setActiveTab={setActiveTab} activeTab={activeTab} propertyData={propertyData} handleInputChange={handleInputChange} handleNextClick={handleNextClick} /></div>}
                    {activeTab === 2 && <div><Locations /></div>}
                    {activeTab === 3 && <div><AllAmenities showTitle={true} /></div>}
                    {activeTab === 4 && <div><Rooms activeTab={activeTab} propertyData={propertyData} handleInputChange={handleInputChange} handleNextClick={handleNextClick} /></div>}
                    {activeTab === 5 && <div><PhotosVideos /></div>}
                    {activeTab === 6 && <div><Policies activeTab={activeTab} propertyData={propertyData} handleInputChange={handleInputChange} handleNextClick={handleNextClick} /></div>}
                </div> */}
      </div>
    </>
  );
}

export default PropertyDetails;
