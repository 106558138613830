import React, { useEffect, useState } from "react";
import VendorHeader from "../../CustomComponents/Property/VendorHeader/VendorHeader";
import SideBar from "../../CustomComponents/Property/SideBar/SideBar";
import AllRooms from "../../CustomComponents/Property/AllRooms/AllRooms";
import { useRef } from "react";
import SeeBookingSlider from "../../CustomComponents/SeeBookingsSlider/SeeBookingsSlider";
import { useParams } from "react-router-dom";
import { formatIndianCurrency, textDateFormatter, textDateFormatterNoAddInIteration } from "../../utils/utils";
import Loader from "../../CustomComponents/Loader/Loader";
import NoDataFound from "../../CustomComponents/NoDataFound/NoDataFound";
import TourBookings from "../../CustomComponents/TourBookings/TourBookings";
import moment from "moment";

function CustomerPackages({ allPropertiesPage, selectedButton,  setSelectedButton }) {
  const [editPopup, setEditPopup] = useState(null);
  const [singleSliderBookingData, setSliderSingleBookingData] = useState(null);

  const [allBookings, setAllBookings] = useState([]);
  const [singleBookingData, setSingleBookingData] = useState([]);

  const [isSliderVisible, setIsSliderVisible] = useState(false);
  const [loader, setLoader] = useState(false);
  const { id: propertyId } = useParams();

  const handleOnClick = (index) => {
    if (editPopup === index) {
      setEditPopup(null); // Close if the same index is clicked again
    } else {
      setEditPopup(index); // Open for the clicked index
    }
  };

  const handleCloseSlider = () => {
    setIsSliderVisible(false);
  };
  const popupsRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (popupsRef.current && !popupsRef.current.contains(event.target)) {
        setEditPopup(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleChangeClick = async () => {
    setIsSliderVisible(true);
  };

  const fetchSidebarDetails = (bookingId) => {
    // console.log(bookingId);

    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
 myHeaders.append("key", process.env.REACT_APP_API_KEY);
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_APP_URL}/api/v1/vendor/get-single-booking/${bookingId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          // console.log(result);

          setSliderSingleBookingData(result?.data);
          setIsSliderVisible(true);
          // setEditPopup(null)
        }
      })
      .catch((error) => console.error(error));
  };

  const fetchBookingData = (id) => {
    setLoader(true);
    const requestOptions = {
      // myHeaders.append("key", "D4X50uU40Vz4pweFLEmsoiJy107iPfTD4ompprop1");
      headers: {
        "Content-Type": "application/json",
        key: "D4X50uU40Vz4pweFLEmsoiJy107iPfTD4ompprop1",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_APP_URL}/api/v1/customers/get-packages-booking`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log("result", result);

        if (result.success) {
          const groupByCategory = result?.data?.reduce((group, booking) => {
            const { created_at: tour_start } = booking;
            group[tour_start] = group[tour_start] ?? [];
            group[tour_start].push(booking);
            return group;
          }, {});
          setLoader(false);

          setAllBookings(groupByCategory);
          // setSingleBookingData([]);

          setLoader(false);
        } else {
          setLoader(false);
          setSingleBookingData([]);
        }
      })
      .catch((error) => setLoader(false));
  };

  useEffect(() => {
    // if (propertyId) {
    fetchBookingData(propertyId);
    // }
  }, []);


  const fetchSidebarTourDetails = (packageId) => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("key", process.env.REACT_APP_API_KEY);
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_APP_URL}/api/v1/single-package-booking/${packageId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          handleChangeClick();
          setSingleBookingData(result?.data);
        }
      })
      .catch((error) => console.error(error));
  };

  return (
    <div id="common_background_profile m_20">
      <div className="flex justify_content_between align_items_center flex_wrap">
        <h4 className="hide_heading">My Tours</h4>
        <div className="agent_dashboard_bookings">
          <div
            onClick={() => setSelectedButton("bookings")}
            className={`agent_button ${
              selectedButton === "bookings" ? "button-selected" : ""
            }`}
          >
            Hotel Bookings
          </div>
          <div
            onClick={() => setSelectedButton("tours")}
            className={`agent_button ${
              selectedButton === "tours" ? "button-selected" : ""
            }`}
          >
            Tour Bookings
          </div>
        </div>
      </div>
      <div className="common_background mt-10">
        <div className="profile_content Bookings">
          {loader ? (
            <Loader size="4em" color="#016932" />
          ) : Object.keys(allBookings).length > 0 ? (
            <div>
              {Object.keys(allBookings).map((item) => (
                <div key={item}>
                  <h6 className="mt-10">
                    {" "}
                    {moment(item).format("MMM Do YYYY")}
                    {/* {textDateFormatterNoAddInIteration(item)} */}
                  </h6>
                  {allBookings[item]?.length > 0 &&
                    allBookings[item].map((booking) => (
                      <div
                        key={booking?.unique_booking_id}
                        className="booking_details"
                      >
                         <div className="flex flex_gap_15">
                          <div className="vector_img">
                            <svg
                              width="20"
                              height="18"
                              viewBox="0 0 20 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M15 16H17V8H11V16H13V10H15V16ZM1 16V1C1 0.734784 1.10536 0.48043 1.29289 0.292893C1.48043 0.105357 1.73478 0 2 0H16C16.2652 0 16.5196 0.105357 16.7071 0.292893C16.8946 0.48043 17 0.734784 17 1V6H19V16H20V18H0V16H1ZM5 8V10H7V8H5ZM5 12V14H7V12H5ZM5 4V6H7V4H5Z"
                                fill="#888888"
                              />
                            </svg>
                          </div>
                          <div>
                            <p>{booking?.package_name}</p>
                            <button
                              className="view_btn"
                              onClick={() =>
                                fetchSidebarTourDetails(booking?.unique_package_id)
                              }
                            >
                              See Details
                            </button>
                            {/* <button
                              className="view_btn"
                              onClick={() =>
                                fetchSidebarDetails(booking?.unique_booking_id)
                              }
                            >
                              See Details
                            </button> */}
                          </div>
                        </div>
                        <div className="agent">
                          <div>
                            <small>Total Amount</small>
                            <p>{formatIndianCurrency(booking?.grand_total)}</p>
                          </div>
                          <div>
                            <small>Check-In</small>
                            <p>{textDateFormatter(booking?.tour_start)}</p>
                          </div>
                          <div>
                            <small>Check-Out</small>
                            <p>{textDateFormatter(booking?.tour_end)}</p>
                          </div>
                          <div>
                            <span className="change_btn text-capitalize">
                              {booking?.booking_status}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              ))}
              {isSliderVisible && singleBookingData && (
                <TourBookings
                  singleBookingData={singleBookingData}
                  onClose={handleCloseSlider}
                  setIsSliderVisible={setIsSliderVisible}
                />
              )}
            </div>
          ) : (
            <NoDataFound />
          )}
        </div>
      </div>
    </div>
  );
}

export default CustomerPackages;
