import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { destinationImages } from "../../constants";
import { Link } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
import { currentDateSingle, formatIndianCurrency } from "../../utils/utils";
import Skeleton from "react-loading-skeleton";

function HotelsList({ footerDestinations, heading }) {
  const [hotelData, setHotelData] = useState([]);
  const [loader, setLoader] = useState(true);

  // console.log(hotelData, "hotelData");

  const postBookingData = async (data, filters) => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("key", process.env.REACT_APP_API_KEY);

    const formdata = new FormData();
    formdata.append("checkIn", "2024-09-18");
    formdata.append("checkOut", "2024-09-20");
    formdata.append("adults", 2);
    formdata.append("child", 0);
    formdata.append("location", "Shimla");
    formdata.append("rooms", 1);
    formdata.append("PropertyType", "hotel");

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_APP_URL}/api/v1/hotel-engine/check-availability`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      setHotelData(result.data.properties);
    } catch (error) {
      console.error("Error fetching data:", error.message);
      // Display error message in a toast or alert
      // showErrorToast(error.message);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    postBookingData();
  }, []);

  var settings = {
    slidesToShow: 4,
    speed: 500,
    arrows: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          centerPadding: "40px",
          slidesToShow: 3,
          //   arrows: false,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1.5,
          arrows: false,
        },
      },
      {
        breakpoint: 424,
        settings: {
          slidesToShow: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <section id="topDestination">
      <div className="container mb-20">
        <div className="common_padding">
          <h3>{heading}</h3>
          <div className="images-flex">
            {loader ? (
              <Slider {...settings}>
                {Array.from({ length: 5 }).map((_, index) => {
                  return (
                    <Link
                      style={{ width: "33.33%" }}
                      className="destination_link"
                      key={index}
                    >
                      <div>
                        <Skeleton height={300} />
                      </div>
                    </Link>
                  );
                })}
              </Slider>
            ) : (
              <Slider {...settings} className="mt-20">
                {hotelData?.map((items, index) => {
                  // console.log(items);

                  return (
                    <Link
                      onClick={() =>
                        scroll.scrollToTop({
                          duration: 500,
                          smooth: "easeInOutQuad",
                        })
                      }
                      to={`/single-hotel/${items?.hotel_slug}?hotelId=${
                        items?.hotel_id
                      }&location=${
                        items?.hotel_city
                      }&room=1&adult=2&children=0&checkinDay=${new Date(
                        currentDateSingle
                      ).getDate()}&checkinMonth=${
                        new Date(currentDateSingle).getMonth() + 1
                      }&checkinYear=${new Date(
                        currentDateSingle
                      ).getFullYear()}&checkoutDay=${
                        new Date(currentDateSingle).getDate() + 2
                      }&checkoutMonth=${
                        new Date(currentDateSingle).getMonth() + 1
                      }&checkoutYear=${new Date(
                        currentDateSingle
                      ).getFullYear()}&hotel_name=${
                        items?.hotel_name
                      }&hotel_address=${items?.hotel_address}`}
                      className="destination_link"
                      key={index}
                    >
                      <img src={items?.hotel_image[0]} alt={items?.alt} />
                      <div className="destination_name">
                        <div className="flex justify_content_between align_items_end">
                          <div className="text-left w-70">
                            <span className="text-left text-white">
                              {items.hotel_name}
                            </span>
                            <span className="font_11 block text-left text-white">
                              <svg
                                width="10"
                                height="12"
                                viewBox="0 0 10 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M5.00004 6.00008C5.32087 6.00008 5.59553 5.88585 5.824 5.65737C6.05247 5.4289 6.16671 5.15425 6.16671 4.83341C6.16671 4.51258 6.05247 4.23793 5.824 4.00946C5.59553 3.78098 5.32087 3.66675 5.00004 3.66675C4.67921 3.66675 4.40455 3.78098 4.17608 4.00946C3.94761 4.23793 3.83337 4.51258 3.83337 4.83341C3.83337 5.15425 3.94761 5.4289 4.17608 5.65737C4.40455 5.88585 4.67921 6.00008 5.00004 6.00008ZM5.00004 10.2876C6.18615 9.19869 7.06601 8.20946 7.63963 7.31987C8.21324 6.43029 8.50004 5.64036 8.50004 4.95008C8.50004 3.89036 8.16219 3.02265 7.4865 2.34696C6.8108 1.67126 5.98199 1.33341 5.00004 1.33341C4.0181 1.33341 3.18928 1.67126 2.51358 2.34696C1.83789 3.02265 1.50004 3.89036 1.50004 4.95008C1.50004 5.64036 1.78685 6.43029 2.36046 7.31987C2.93407 8.20946 3.81393 9.19869 5.00004 10.2876ZM5.00004 11.8334C3.43476 10.5015 2.26567 9.26432 1.49275 8.12196C0.719832 6.97959 0.333374 5.9223 0.333374 4.95008C0.333374 3.49175 0.802471 2.32994 1.74067 1.46466C2.67886 0.599387 3.76532 0.166748 5.00004 0.166748C6.23476 0.166748 7.32122 0.599387 8.25942 1.46466C9.19761 2.32994 9.66671 3.49175 9.66671 4.95008C9.66671 5.9223 9.28025 6.97959 8.50733 8.12196C7.73442 9.26432 6.56532 10.5015 5.00004 11.8334Z"
                                  fill="white"
                                />
                              </svg>
                              {items.hotel_city}
                            </span>
                          </div>
                          <div className="w-30 text-end">
                            <span className="font_11">Starting From</span>
                            <h6>
                              {formatIndianCurrency(items.starting_price)}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </Link>
                  );
                })}
              </Slider>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default HotelsList;
