import React, { useEffect, useState } from "react";
import "./FeedBackForm.css";
import Flatpickr from "react-flatpickr";
import AddressAutocomplete from "../../CustomComponents/CustomGooglePlaceAPi/AddressAutocomplete";
import { calculateToDatePlus } from "../../utils/utils";
import { showErrorToast } from "../../assets/toastUtils";
import { Link, Navigate } from "react-router-dom";

const FeedBacKFormThankYou = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [addressData, setAddressData] = useState({});
  // console.log("addressData", addressData);

  //   const router = useRouter();
  const tours = [
    "Select a Tour",
    "Family Package",
    "Kinnour and Spiti",
    "Weekend Gateways",
    "Honeymoon Tour",
    "Offbeat Tours",
    "Volvo Package",
  ];
  const destinationsByTour = {
    "Family Package": [
      "Dalhousie",
      "Dharmshala",
      "Shimla",
      "Manali",
      "Chandigarh",
      "Kasol",
      "Amritsar",
    ],
    "Kinnour and Spiti": [
      "Sangla",
      "Kalpa",
      "Shimla",
      "Tabo",
      "Kaza",
      "Manali",
      "Chandratal",
    ],
    "Weekend Gateways": ["Kasol", "Jibhi", "Tirthan"],
    "Honeymoon Tour": ["Shimla", "Manali", "Chandigarh"],
    "Offbeat Tours": ["Kasol", "BirandPong", "Tirthan"],
    "Volvo Package": ["Dalhousie", "Dharmshala", "Shimla", "Manali"],
  };
  // console.log(destinationsByTour);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(calculateToDatePlus(new Date(), 1));
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const [date, setDate] = useState(new Date());

  // console.log(startDate);

  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    startDate: "",
    endDate: "",
    email: "",
    address: "",
    vehicle_no: "",
    vehicleCondition: "",
    transportation: "",
    vehicle: "",
    driver: "",
    tourTaken: "",
    ShimlaService: "",
    ManaliService: "",
    KasolService: "",
    DharmshalaService: "",
    DalhousieService: "",
    AmritsarService: "",
    ChandigarhService: "",
    SanglaService: "",
    KalpaService: "",
    TaboService: "",
    KazaService: "",
    ChandratalService: "",
    JibhiService: "",
    TirthanService: "",
    BirandPongService: "",
    suggestion: "",
  });

  const formatDate = (date) => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const handleDateChange = (dates) => {
    if (!dates) {
      // If dates is null or undefined, return early
      return;
    }

    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);

    // Update formData with selected start and end dates in dd/mm/yyyy format
    setFormData({
      ...formData,
      startDate: start ? formatDate(start) : "", // Check if start date is valid
      endDate: end ? formatDate(end) : "", // Check if end date is valid
    });
  };
  // console.log(startDate);
  // console.log(endDate);

  const [formErrors, setFormErrors] = useState({});

  const nextStep = () => {
    if (validate()) {
      setCurrentStep((prevStep) => prevStep + 1);
    }
  };

  const prevStep = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: newValue,
    }));
  };

  // console.log(formData);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validate = () => {
    const errors = {};
    // Add validation logic for each form field
    if (currentStep === 1) {
      if (!formData.name.trim()) {
        errors.name = "Name is required";
      }
      if (!formData.email.trim()) {
        errors.email = "Email is required";
      }
      if (!formData.phone.trim()) {
        errors.phone = "Phone No. is required";
      }
      //   if (!formData.address.trim()) {
      //     errors.address = "Address is required";
      //   }
      if (!formData.vehicle_no.trim()) {
        errors.vehicle_no = "Vehicle Number is required";
      }
    } else if (currentStep === 2) {
      if (!formData.tourTaken) {
        errors.tourTaken = "Tour Taken is required";
      }
      // if (!formData.guideService) {
      //   errors.guideService = "Guide Service rating is required";
      // }
      if (!formData.transportation) {
        errors.transportation = "Transportation is required";
      }
      if (!formData.driver) {
        errors.driver = "Driver is required";
      }

      // Add validation for other step 2 fields
    } else if (currentStep === 3) {
      // if (!formData.vehicleCondition) {
      //   errors.vehicleCondition = "Vehicle Condition rating is required";
      // }
      // Add validation for other step 3 fields
    } else if (currentStep === 4) {
      // if (!formData.ShimaService) {
      //   errors.ShimaService = "Shima Service rating is required";
      // }
      // Add validation for other step 4 fields
    }

    setFormErrors(errors);

    return Object.keys(errors).length === 0;
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   if (validate()) {
  //     console.log(formData);
  //     // Perform additional actions like submitting the form data
  //   }
  // };

  const handleFormSubmission = async (e) => {
    e.preventDefault();
    const formattedDate = startDate.toISOString().split("T")[0];
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");
 myHeaders.append("key", process.env.REACT_APP_API_KEY);
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    try {
      const response = await fetch(
        `${process.env.REACT_APP_APP_URL}/api/v1/feedback`,
        {
          method: "POST",
          headers: myHeaders,
          body: JSON.stringify({
            ...formData, // Ensure `formData` is an object containing the relevant form data
            date: formattedDate, // Ensure `formattedDate`, `startDate`, and `endDate` are correctly formatted
            startDate,
            endDate,
          }),
        }
      );
      // console.log(response);
      if (response.status === 201) {
        Navigate("/feedback-form/thankyou");
      } else {
        throw new Error("Failed to submit the form");
      }
      setFormData({
        name: "",
        email: "",
        phone: "",
        startDate: "",
        endDate: "",
        vehicle_no: "",
        address: "",
        guideService: "",
        transportation: "",
        driver: "",
        driverKnowledge: "",
        ShimaService: "",
        ManaliService: "",
        KasolService: "",
        DharmshalaService: "",
        DalhousieService: "",
        AmritsarService: "",
        ChandigarhService: "",
        suggestion: "",
      });
    } catch (error) {
      console.error(error);
      showErrorToast("Something Went Wrong! Please Try Again Later");
    }
  };
  // console.log(currentStep);

  const handleAddressData = (data) => {
    setAddressData(data);
  };

  return (
    <>
      <div className="container">
        <div className="form_container">
          <div className="">
          <h1 style={{marginTop:"90px"}}>Thank You For Your Feedback</h1>
          <Link to="/">Go To Home</Link>
          </div>
        </div>
      </div>
    </>
  );
};
export default FeedBacKFormThankYou;
