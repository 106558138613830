import React from "react";

const GetDirectionRedirect = ({ address, primaryColor, children }) => {
  // Construct Google Maps direction URL using the provided address
  const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
    address
  )}`;

  return (
    <a
      href={googleMapsUrl}
      target="_blank"
      rel="noopener noreferrer"
      style={{
        textDecoration: "none",
        fontWeight: "bold",
        textTransform: "uppercase",
        textAlign: "left",
        fontFamily: "Poppins, sans-serif",
        fontSize: "12px",
        color: primaryColor,
        lineHeight: "1.4em",
        display: "inline-block",
        verticalAlign: "middle",
      }}
    >
      {children}
    </a>
  );
};

export default GetDirectionRedirect;
