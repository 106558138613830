import React from "react";
import AgentHeader from "../AgentHeader/AgentHeader";
import AgentSideBar from "../AgentSideBar/AgentSideBar";
import Footer from "../../../CustomComponents/Footer/Footer";
import "./VendorPromotionAdd.css";
import { Link, useParams } from "react-router-dom";
import VendorHeader from "../../../CustomComponents/Property/VendorHeader/VendorHeader";
import SideBar from "../../../CustomComponents/Property/SideBar/SideBar";

const VendorPromotionAdd = () => {
  const { id } = useParams();
  return (
    <>
      <VendorHeader showSelectProperty={true} />
      <div className="sidebar_main_div">
        <SideBar />
        <section className="w-full table-data">
          <div>
            <div className="overflow_x_scroll_y_hidden">
              <div className="mt-5">
                <div>
                  <h4>Campaign deals</h4>
                  <small>
                    Get visibility in our emails, push notifications, on
                    affiliate websites, and other channels when you offer a
                    discount.
                  </small>
                </div>

                <div className="agentPromotionParent">
                  <div className="agentPromotionParentDiv">
                    <div
                      className="agentPromotionParentInnerDiv"
                      style={{ borderBottom: "1px solid #000" }}
                    >
                    <div className="agentPromotionParentInnerOneDiv">svg</div>
                    <div className="agentPromotionParentInnerTwoDiv">
                        <h4>Gateway Deals</h4>
                        <h5>Recommended 25% discount or more</h5>
                      </div>
                      <div className="agentPromotionParentInnerThirdDiv">
                        Target occupancy gaps and boost your visibility on our
                        platform
                      </div>
                      <Link to={`/vendor/promotion/add/next/getaway-deal/${id}`} className="agentPromotionParentInnerFourthDiv">
                        Add
                      </Link>
                    </div>
                    <div className="agentPromotionParentInnerDiv">
                    <div className="agentPromotionParentInnerOneDiv">svg</div>
                    <div className="agentPromotionParentInnerTwoDiv">
                        <h4>Late Escape Deal</h4>
                        <h5>20% discount or more</h5>
                      </div>
                      <div className="agentPromotionParentInnerThirdDiv">
                        Boost your visibility and increase end-of-year bookings
                        by 11% on average
                      </div>
                      <Link to={`/vendor/promotion/add/next/last-escape-deal/${id}`} className="agentPromotionParentInnerFourthDiv">
                        Add
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="mt-20">
                <div>
                  <h4>Targeting</h4>
                  <small>
                    Aim your discount at specific groups and become a favorite
                    property for valuable guest segments.
                  </small>
                </div>

                <div className="agentPromotionParent">
                  <div className="agentPromotionParentDiv">
                    <div
                      className="agentPromotionParentInnerDiv"
                      style={{ borderBottom: "1px solid #000" }}
                    >
                      <div className="agentPromotionParentInnerOneDiv">svg</div>
                      <div className="agentPromotionParentInnerTwoDiv">
                        <h4>Mobile rate</h4>
                        <h5>10% discount or more</h5>
                      </div>
                      <div className="agentPromotionParentInnerThirdDiv">
                        Become a top pick for customers on their mobile phones
                      </div>
                      <div className="agentPromotionParentInnerFourthDiv">
                        Add
                      </div>
                    </div>
                    <div className="agentPromotionParentInnerDiv">
                    <div className="agentPromotionParentInnerOneDiv">svg</div>
                    <div className="agentPromotionParentInnerTwoDiv">
                        <h4>Country rate</h4>
                        <h5>Recommended 10% discount or more</h5>
                      </div>
                      <div className="agentPromotionParentInnerThirdDiv">
                        Reach customers and increase revenue from a specific
                        region
                      </div>
                      <div className="agentPromotionParentInnerFourthDiv">Add</div>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="mt-20">
                <div>
                  <h4>Portfolio deals</h4>
                  <small>
                    Improve your occupancy with a range of customizable deal
                    types to suit your needs.
                  </small>
                </div>

                <div className="agentPromotionParent">
                  <div className="agentPromotionParentDiv">
                    <div
                      className="agentPromotionParentInnerDiv"
                      style={{ borderBottom: "1px solid #000" }}
                    >
                      <div className="agentPromotionParentInnerOneDiv">svg</div>
                      <div className="agentPromotionParentInnerTwoDiv">
                        <h4>Basic deal</h4>
                        <h5>Recommended 10% discount or more</h5>
                      </div>
                      <div className="agentPromotionParentInnerThirdDiv">
                        Customize a deal to suit your needs
                      </div>
                      <Link to={`/vendor/promotion/add/next/basic-deal/${id}`} className="agentPromotionParentInnerFourthDiv">
                        Add
                      </Link>
                    </div>
                    <div   className="agentPromotionParentInnerDiv"
                      style={{ borderBottom: "1px solid #000" }}>
                      <div className="agentPromotionParentInnerOneDiv">svg</div>
                      <div className="agentPromotionParentInnerTwoDiv">
                        <h4>Last-minute deal</h4>
                        <h5>Recommended 10% discount or more</h5>
                      </div>
                      <div className="agentPromotionParentInnerThirdDiv">
                        Fill any empty rooms you have left
                      </div>
                      <Link to={`/vendor/promotion/add/next/last-minute-deal/${id}`} className="agentPromotionParentInnerFourthDiv">
                        Add
                      </Link>
                    </div>
                    <div className="agentPromotionParentInnerDiv">
                      <div className="agentPromotionParentInnerOneDiv">svg</div>
                      <div className="agentPromotionParentInnerTwoDiv">
                        <h4>Early booker deal</h4>
                        <h5>Recommended 10% discount or more</h5>
                      </div>
                      <div className="agentPromotionParentInnerThirdDiv">
                        Any dates Be better prepared with more of your bookings
                        made earlier
                      </div>
                      <Link to={`/vendor/promotion/add/next/early-booker-deal/${id}`} className="agentPromotionParentInnerFourthDiv">
                        Add
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default VendorPromotionAdd;
