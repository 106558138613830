import React, { useEffect, useState } from "react";
import VendorHeader from "../../CustomComponents/Property/VendorHeader/VendorHeader";
import { currentDateSingle, textDateFormatter } from "../../utils/utils";
import { Link, useLocation, useParams } from "react-router-dom";
import SideBar from "../../CustomComponents/Property/SideBar/SideBar";
import Loader from "../../CustomComponents/Loader/Loader";
import NoDataFound from "../../CustomComponents/NoDataFound/NoDataFound";

const Rates = () => {
  const [loader, setLoader] = useState(false);

  const [ratesData, setRatesData] = useState([]);
  const location = useLocation();
  const [updatePopup, setUpdatePopup] = useState(false);
  const [startDate, setStartDate] = useState(currentDateSingle);

  const { id: propertyId } = useParams();

  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  // console.log(userDetails);

  useEffect(() => {
    if (propertyId) {
      ratesDetails(propertyId);
    }
  }, [propertyId, location, startDate]);

  // console.log(ratesData);

  const ratesDetails = (id) => {
    setLoader(true);
    const myHeaders = new Headers();
 myHeaders.append("key", process.env.REACT_APP_API_KEY);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_APP_URL}/api/v1/vendor/hotel-rates/${id}/list?filterDate=${startDate}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);

        if (result.success) {
          setRatesData(result?.rates);
          setLoader(false);
        } else {
          setLoader(false);
        }
        // console.log(result);
      })
      .catch((error) => setLoader(false));
  };

  // useEffect(() => {
  //   ratesDetails();
  // }, []);

  const handleOnUpdate = () => {
    setUpdatePopup(true);
  };

  return (
    <>
      <VendorHeader showSelectProperty={true} />
      <div className="sidebar_main_div">
        <SideBar allPropertiesPage={false} />

        <section className="w-full table-data">
          <div className="flex justify_content_between align_items_center">
            <h4>Rates</h4>
            <div className="flex flex_gap_10">
              {/* <button className="secondary_btn fit-width">
                {textDateFormatter(currentDateSingle)}
              </button> */}
              <input
                type="date"
                className="dateBtn_inventory m_0 fit-width"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                // ref={dateInputRef}
              />
              <Link
                className="primary_btn fit-width"
                to={`/rate/bulk-update/${propertyId}`}
              >
                BULK UPDATE
              </Link>
            </div>
          </div>
          {loader ? (
            <Loader size="4em" color="#016937" />
          ) : (
            <div className="overflow_x_scroll_y_hidden">
              {ratesData?.length > 0 ? (
                <>table
                  <table className="rates_table">
                    <tr>
                      <th>Room Category</th>
                      {Object.keys(ratesData[0]?.rate)
                        .slice(0, 1)
                        .map((planType,index) => (
                          <React.Fragment key={index}>
                            {ratesData[0]?.rate[planType].map((item,index) => (
                              <th key={index}>
                                {textDateFormatter(item.date)}
                              </th>
                            ))}
                          </React.Fragment>
                        ))}
                    </tr>
                    {ratesData?.map((item, index) => {
                      return (
                        <React.Fragment key={index}>
                          <tr>
                            <td>{item?.name}</td>
                          </tr>
                          {Object.keys(item.rate)?.map((data,index) => {
                            return (
                              <React.Fragment key={index}>
                                <tr key={data}>
                                  <td>
                                    <b>{data}</b>
                                  </td>
                                </tr>
                                {/* <tr>
                          <td className="font_12">Contracted</td>
                          {item.rate[data]?.slice(0, 7)?.map((rate) => {
                            return (
                              <>
                                <td>{rate.contracted_price}</td>
                              </>
                            );
                          })}
                        </tr> */}
                                <tr>
                                  <td className="font_12">Base Rate</td>
                                  {item.rate[data]?.slice(0, 7)?.map((rate,index) => {
                                    return (
                                      <React.Fragment key={index}>
                                        <td>{rate.base_rate}</td>
                                      </React.Fragment>
                                    );
                                  })}
                                </tr>
                                <tr>
                                  <td className="font_12">Extra Adult</td>
                                  {item.rate[data]?.slice(0, 7)?.map((rate,index) => {
                                    return (
                                      <React.Fragment key={index}>
                                        <td>{rate.extra_adult}</td>
                                      </React.Fragment>
                                    );
                                  })}
                                </tr>
                                <tr>
                                  <td className="font_12">Child with Bed</td>
                                  {item.rate[data]?.slice(0, 7)?.map((rate,index) => {
                                    return (
                                      <React.Fragment key={index}>
                                        <td>{rate.child_w_bed}</td>
                                      </React.Fragment>
                                    );
                                  })}
                                </tr>
                                <tr>
                                  <td className="font_12 border-bottom">
                                    Child W/O Bed
                                  </td>
                                  {item.rate[data]?.slice(0, 7)?.map((rate,index) => {
                                    return (
                                      <React.Fragment key={index}>
                                        <td className="border-bottom">
                                          {rate.child_wo_bed}
                                        </td>
                                      </React.Fragment>
                                    );
                                  })}
                                </tr>
                              </React.Fragment>
                            );
                          })}
                        </React.Fragment>
                      );
                    })}
                  </table>
                </>
              ) : (
                <>
                  <NoDataFound />
                </>
              )}
            </div>
          )}
        </section>
      </div>
    </>
  );
};

export default Rates;
