import React, { useEffect } from "react";
import PageMetaData from "../../../CustomComponents/PageMetaData/PageMetaData";
import CustomerHeader from "../CustomerHeader/CustomerHeader";
import CustomerSideBar from "../CustomerSideBar/CustomerSideBar";
import { useLocation, useNavigate } from "react-router-dom";
import { showErrorToast, showSuccessToast } from "../../../assets/toastUtils";
import { useState } from "react";
import Loader from "../../../CustomComponents/Loader/Loader";

function CustomerProfile() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const initialRole = queryParams.get("role") || "profile";
  const [activeTab, setActiveTab] = useState(initialRole);
  const [loader, setLoader] = useState(true);
  const [edit, setEdit] = useState(true);
  const [formErrors, setFormErrors] = useState({});

  const handleEditBtn = () => {
    setEdit(!edit);
  };

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "0",
  });

  const navigate = useNavigate();
  useEffect(() => {
    const userType = localStorage.getItem("userType");

    if (userType === "agent") {
      navigate("/agent-account");
    }
  }, [navigate]);
  const userToken = localStorage.getItem("token");

  const [customerDataa, setCustomerData] = useState(null);

  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    if (userDetails) {
      setFormData(userDetails);
    }
  }, []);

  useEffect(() => {
    const role = queryParams.get("role") || "profile";
    setActiveTab(role);
  }, [location.search]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const profileFilter = (tab) => {
    setActiveTab(tab);
  };

  const fetchCustomerDetails = () => {
    fetch(`${process.env.REACT_APP_APP_URL}/api/v1/customers/user-profile`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setCustomerData(data.data.customer);
          setLoader(false);
          setFormData({
            name: data.data.customer.Name || "",
            phone: data.data.customer.PhoneNumber || "0",
            email: data.data.customer.Email || "",
          });
          localStorage.setItem(
            "userDetails",
            JSON.stringify(data.data.customer)
          );
        }
      })
      .catch((error) => {
        console.error(
          "There has been a problem with your fetch operation:",
          error
        );
      });
  };

  useEffect(() => {
    fetchCustomerDetails();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const cust_id = customerDataa?.AgentId;
    const valid = validate();
    setTimeout(() => {
      setFormErrors({})
    }, 1500);
    if(!valid){
      showErrorToast("Please fill the above details.");
      return;
    }

    const raw = JSON.stringify(formData);

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_APP_URL}/api/customer/update/${cust_id}`,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      // .then((data) => {
      //   console.log(data, "datatta")
      //   setFormData({
      //     name: data.data.name || "",
      //     phone: data.data.phone || "0",
      //     email: data.data.email || "",
      //   });
      //   localStorage.setItem("userDetails", JSON.stringify(data.data));
      //   showSuccessToast("Profile Updated Successfully")
      //   navigate("/");
      // })
      // .catch((error) => {
      //   showErrorToast("Unable to update data");
      //   console.error(
      //     "There has been a problem with your fetch operation:",
      //     error
      //   );
      // });
      .then((data) => {
        setFormData({
          name: data.data.name || "",
          phone: data.data.phone || "0",
          email: data.data.email || "",
        });
        localStorage.setItem("userDetails", JSON.stringify(data.data));
        showSuccessToast("Profile Updated Successfully");
        // navigate("/");
        window.location.href = "/";
      })
      .catch((error) =>
        console.error(
          "There has been a problem with your fetch operation:",
          error
        )
      );
  };

  const validate = () => {
    const errors = {};
    if (!formData.name.trim()) {
      errors.name = "required";
    }
    if (!formData.email.trim()) {
      errors.email = "required";
    }
    if (!formData.phone.trim()) {
      errors.phone_number = "is required";
    }
    // }

    setFormErrors(errors);

    return Object.keys(errors).length === 0;
  };

  return (
    <div id="Listingholidays">
      <PageMetaData
        title="Mountain Mysteries"
        description="Enhance your hotel capabilities with RevCatalyst's powerful system. Optimize performance, streamline operations, and unleash the full potential of property."
        keywords="hotel management, RevCatalyst, property optimization"
      />
      <CustomerHeader />
      <div className="sidebar_main_div">
        <CustomerSideBar />
        <section className="w-full p-sm">
          <div id="common_background_profile m_20">
            <h4>My Profile</h4>{" "}
            <div className="common_background mt-10">
              {loader ? (
                <Loader size="4em" color="#016932" />
              ) : (
                <>
                  {edit ? (
                    <form onSubmit={handleSubmit}>
                      <div className="flex flex_gap_10">
                        <h6>Your Name : </h6>
                        <span>{formData?.name}</span>
                      </div>
                      <div className="flex flex_gap_10 mt-10">
                        <h6>Phone Number : </h6>
                        <span>{formData?.phone}</span>
                      </div>
                      <div className="flex flex_gap_10 mt-10">
                        <h6>Email : </h6>
                        <span>{formData?.email}</span>
                      </div>
                      <div className="button">
                        <button
                          className="primary_btn search-btn-width mt-10"
                          onClick={handleEditBtn}
                        >
                          Edit
                        </button>
                      </div>
                    </form>
                  ) : (
                    <form onSubmit={handleSubmit}>
                      <div>
                        <label htmlFor="name">Your Name</label>
                        <input
                          className="w-full"
                          type="text"
                          onChange={handleInputChange}
                          name="name"
                          value={formData.name || ""}
                          style={
                            formErrors.name && {
                              backgroundColor: "#ff000040",
                              border: "1px solid red",
                            }
                          }
                        />
                      </div>
                      <div className="input-flex">
                        <div>
                          <label htmlFor="phone">Phone</label>
                          <input
                            className="w-full"
                            type="text"
                            onChange={handleInputChange}
                            name="phone"
                            value={formData.phone || "0"}
                            style={
                              formErrors.phone && {
                                backgroundColor: "#ff000040",
                                border: "1px solid red",
                              }
                            }
                          />
                        </div>
                        <div>
                          <label htmlFor="email">Email Address</label>
                          <input
                            className="w-full"
                            type="email"
                            onChange={handleInputChange}
                            name="email"
                            value={formData.email || ""}
                            style={
                              formErrors.email && {
                                backgroundColor: "#ff000040",
                                border: "1px solid red",
                              }
                            }
                          />
                        </div>
                      </div>
                      <div className="button">
                        <button
                          className="primary_btn search-btn-width mt-10"
                          type="submit"
                        >
                          Save Changes
                        </button>
                      </div>
                    </form>
                  )}
                </>
              )}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default CustomerProfile;
