import React from "react";
import "../ChooseUs/ChooseUs.css"
import locationIcon from '../../assets/images/location-icon.png'
import destinationIcon from '../../assets/images/destination-icon.png'
import securityIcon from '../../assets/images/security-icon.png'
import customerIcon from '../../assets/images/customer-icon.png'

function ChooseUs() {
    return (
        <div className="choose_us">
            <h5 className="choose_heading">Why Choose <br />
                Mountain Mysteries ?</h5>
            <div className="highlightSection">
                <div className="highlight" style={{width:"100%", background:"#fff"}}>
                    <img src={locationIcon}
                        alt="location-icon" />
                    <span>All in one platform</span>
                </div>
                <div className="highlight" style={{width:"100%", background:"#fff"}}>
                    <img src={destinationIcon}
                        alt="destination-icon" />
                    <span>Expertise in Mountain Destinations</span>
                </div>
                <div className="highlight" style={{width:"100%", background:"#fff"}}>
                    <img src={securityIcon}
                        alt="security-icon" />
                    <span> Security</span>
                </div>
                <div className="highlight" style={{width:"100%", background:"#fff"}}>
                    <img src={customerIcon}
                        alt="customer-icon" />
                    <span>24x7 Customer
                        Support</span>
                </div>
            </div>
        </div>
    )
}

export default ChooseUs;