import React, { useState } from "react";
import { showErrorToast, showSuccessToast } from "../../../assets/toastUtils";
import { useNavigate, useParams } from "react-router-dom";
import VendorHeader from "../../../CustomComponents/Property/VendorHeader/VendorHeader";

function AddRoomVendor({ setPropertyData }) {
  const navigate = useNavigate();
  const [formErrors, setFormErrors] = useState({});

  const { id } = useParams();
  // console.log(propertyData, "ppp");
  const [roomData, setRoomData] = useState({
    display_name: "",
    room_view: "",
    display_name: "",
    room_description: "",
    base_adult: "",
    max_child: "",
    max_adult: "",
    max_occupancy: "",
    room_size: "",
    room_size_units: "",
    room_type: "",
    bed_type: "",
    room_view: "",
  });

  const validate = () => {
    const errors = {};
    if (!roomData?.display_name.trim()) {
      errors.display_name = "required";
    }
    if (!roomData?.room_type.trim()) {
      errors.room_type = "required";
    }
    if (!roomData?.room_view.trim()) {
      errors.room_view = "required";
    }
    if (!roomData?.bed_type.trim()) {
      errors.bed_type = "required";
    }
    if (!roomData?.room_size.trim()) {
      errors.room_size = "required";
    }
    if (!roomData?.room_size_units.trim()) {
      errors.room_size_units = "required";
    }
    if (!roomData?.base_adult.trim()) {
      errors.base_adult = "required";
    }
    if (!roomData?.max_adult.trim()) {
      errors.max_adult = "required";
    }

    if (!roomData?.room_description.trim()) {
      errors.room_description = "required";
    }
    if (!roomData?.max_child.trim()) {
      errors.max_child = "required";
    }
    if (!roomData?.max_occupancy.trim()) {
      errors.max_occupancy = "required";
    }

    setFormErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setRoomData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const addRoomDetails = (e) => {
    e.preventDefault();
    // console.log("form submit room");

    const valid = validate();
    setTimeout(() => {
      setFormErrors({});
    }, 1000);
    if (!valid) {
      showErrorToast("Please fill the required details");
      return;
    }

    const raw = JSON.stringify({
      name: roomData.display_name,
      content: roomData.room_description,
      is_active: true,
      base_adults: Number(roomData.base_adult),
      max_children: Number(roomData.max_child),
      max_adults: Number(roomData.max_adult),
      max_occupany: Number(roomData.max_occupancy),
      area: Number(roomData.room_size),
      unit: roomData.room_size_units,
      type: roomData.room_type,
      bed: roomData.bed_type,
      view: roomData.room_view,
      selected_amenities: "1,2,3",
    });
    const requestOptions = {
      method: "POST",
      body: raw,
      headers: {
        "Content-Type": "application/json",
        key: "D4X50uU40Vz4pweFLEmsoiJy107iPfTD4ompprop1",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      redirect: "follow",
    };
    // console.log("Payload:", raw);

    fetch(
      `${process.env.REACT_APP_APP_URL}/api/v1/vendor/accommodation-room-create/${id}`,
      requestOptions
    )
      .then((response) => {
        return response.text().then((text) => {
          if (!response.ok) {
            throw new Error("Network response was not ok: " + text);
          }
          return text;
        });
      })
      .then((text) => {
        // console.log("Response text:", text);
        try {
          const data = JSON.parse(text);
          if (data.success) {
            navigate(`/partner/all-rooms/${id}`);
            setRoomData(data);
            showSuccessToast(data.success.message || "Room Added");
          } else {
            // console.log("Error fetching data", data);
          }
        } catch (error) {
          console.error("Error parsing JSON:", error, text);
        }
      })
      .catch((error) => {
        console.error("Error fetching details:", error);
      });
  };

  return (
    <section>
      <VendorHeader />
      <div className="basic-info-container">
        <div className="flex_align_center flex_gap_20">
          <span onClick={() => window.history.back()} className="back_btn">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.292893 7.29289C-0.0976311 7.68342 -0.0976311 8.31658 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292893 7.29289ZM1 9H16V7H1V9Z"
                fill="#333333"
              />
            </svg>
          </span>
          <div>
            <h3 className="text-left">Add New Room</h3>
            <p>Please fill the details of your property.</p>
          </div>
        </div>

        <form onSubmit={addRoomDetails}>
          <div className="bg-white-div">
            <h4>Room Info</h4>
            <div className="flex flex_gap_10 w-full">
              <div className="w-half">
                <label htmlFor="">
                  Display Name <span className="required">*</span>
                </label>
                <input
                  type="text"
                  onChange={handleInputChange}
                  name="display_name"
                  value={roomData?.display_name}
                  style={
                    formErrors.display_name && {
                      backgroundColor: "#ff000040",
                      border: "1px solid red",
                    }
                  }
                />
              </div>
              <div className="w-half">
                <label htmlFor="">
                  Room Type <span className="required">*</span>
                </label>
                <select
                  name="room_type"
                  onChange={handleInputChange}
                  id=""
                  style={
                    formErrors.room_type && {
                      backgroundColor: "#ff000040",
                      border: "1px solid red",
                    }
                  }
                >
                  <option value="">Select option</option>
                  <option value="Deluxe">Deluxe</option>
                  <option value="Standard">Standard</option>
                  <option value="Luxury">Luxury</option>
                  <option value="Master">Master</option>
                  <option value="Common">Common</option>
                  <option value="Tent">Tent</option>
                  <option value="Family Room">Family Room</option>
                  <option value="Water Villa">Water Villa</option>
                  <option value="Beach Villa">Beach Villa</option>
                  <option value="For Honeymooners">For Honeymooners</option>
                  <option value="Garden Villa">Garden Villa</option>
                  <option value="other">other</option>
                  <option value="Suite">Suite</option>
                </select>
              </div>
            </div>
            <div className="flex flex_gap_10 w-full">
              {/* <div className='w-half'>
                                <label htmlFor="">Total Rooms  <span className='required'>*</span></label>
                                <input type="number" name='total_rooms' value={propertyData.total_rooms} onChange={handleInputChange} />
                            </div> */}
              <div className="w-half">
                <label htmlFor="">
                  Room View <span className="required">*</span>
                </label>
                <select
                  name="room_view"
                  value={roomData.room_view}
                  onChange={handleInputChange}
                  id=""
                  style={
                    formErrors.room_view && {
                      backgroundColor: "#ff000040",
                      border: "1px solid red",
                    }
                  }
                >
                  <option value="">Select option</option>
                  <option value="Park View">Pool View</option>
                  <option value="Valley View">Valley View</option>
                  <option value="No View">No View</option>
                  <option value="Sea View">Sea View</option>
                  <option value="Hill View">Hill View</option>
                  <option value="Pool View">Pool View</option>
                  <option value="Garden View">Garden View</option>
                  <option value="River View">River View</option>
                  <option value="Lake View">Lake View</option>
                  <option value="Palace View">Palace View</option>
                  <option value="Bay View">Bay View</option>
                  <option value="Jungle View">Jungle View</option>
                  <option value="City View">City View</option>
                  <option value="Landmark View">Landmark View</option>
                  <option value="Terrace View">Terrace View</option>
                  <option value="Lake View">Courtyard View</option>
                  <option value="Desert View">Desert View</option>
                  <option value="Golf Course View">Golf Course View</option>
                  <option value="Mountain View">Mountain View</option>
                  <option value="Ocean View">Ocean View</option>
                  <option value="Backwater View">Backwater View</option>
                  <option value="Resort View">Resort View</option>
                  <option value="Desert View">Desert View</option>
                  <option value="Golf Course View">Golf Course View</option>
                  <option value="Mountain View">Mountain View</option>
                  <option value="Lagoon View">Lagoon View</option>
                  <option value="Forest View">Forest View</option>
                  <option value="Beach View">Beach View</option>
                  <option value="Airport View">Airport View</option>
                  <option value="Countryside View">Countryside View</option>
                  <option value="Harbor View">Harbor View</option>
                  <option value="Inter-coastal View">Inter-coastal View</option>
                  <option value="Marina View">Marina View</option>
                </select>
              </div>
              <div className="w-half">
                <label htmlFor="">
                  Bed Type <span className="required">*</span>
                </label>
                <select
                  name="bed_type"
                  onChange={handleInputChange}
                  id=""
                  style={
                    formErrors.bed_type && {
                      backgroundColor: "#ff000040",
                      border: "1px solid red",
                    }
                  }
                >
                  <option value="">Select option</option>
                  <option value="Twin Bed">Twin Bed</option>
                  <option value="King Bed">King Bed</option>
                  <option value="Queen Bed">Queen Bed</option>
                  <option value="Double Bed">Double Bed</option>
                  <option value="Single Bed">Single Bed</option>
                  <option value="Sofa Bed">Sofa Bed</option>
                  <option value="Standard Bed">Standard Bed</option>
                  <option value="1 King bed or 2 Twin Bed(s)">
                    1 King bed or 2 Twin Bed(s)
                  </option>
                  <option value="1 Queen bed or 2 Twin Bed(s)">
                    1 Queen bed or 2 Twin Bed(s)
                  </option>
                  <option value="1 Double bed or 2 Twin Bed(s)">
                    1 Double bed or 2 Twin Bed(s)
                  </option>
                  <option value="Bunk Bed">Bunk Bed</option>
                  <option value="Futon">Futon</option>
                  <option value="Murphy">Murphy</option>
                  <option value="Tatami Mats">Tatami Mats</option>
                </select>
              </div>
            </div>
            <div className="flex flex_gap_10 w-full">
              <div className="w-half">
                <label htmlFor="">
                  Room Size <span className="required">*</span>
                </label>
                <input
                  type="number"
                  name="room_size"
                  value={roomData.room_size}
                  onChange={handleInputChange}
                  style={
                    formErrors.room_size && {
                      backgroundColor: "#ff000040",
                      border: "1px solid red",
                    }
                  }
                />
              </div>
              <div className="w-half">
                <label htmlFor="">
                  Unit <span className="required">*</span>
                </label>
                <select
                  name="room_size_units"
                  onChange={handleInputChange}
                  id=""
                  style={
                    formErrors.room_size_units && {
                      backgroundColor: "#ff000040",
                      border: "1px solid red",
                    }
                  }
                >
                  <option value="">Select option</option>
                  <option value="square meter">square meter</option>
                  <option value="square feet">square feet</option>
                </select>
              </div>
            </div>
            <div>
              <label htmlFor=""> Description</label>
              <textarea
                name="room_description"
                value={roomData.room_description}
                onChange={handleInputChange}
                id=""
                className="w-full"
                placeholder="Type here..."
                style={
                  formErrors.room_description && {
                    backgroundColor: "#ff000040",
                    border: "1px solid red",
                  }
                }
              ></textarea>
            </div>

            <div className="mt-10">
              <h4>OCCUPANCY</h4>
              <div className="flex flex_gap_10 w-full">
                <div className="w-half">
                  <label htmlFor="">
                    Base Adult <span className="required">*</span>
                  </label>
                  <input
                    type="number"
                    onChange={handleInputChange}
                    name="base_adult"
                    value={roomData.base_adult}
                    style={
                      formErrors.base_adult && {
                        backgroundColor: "#ff000040",
                        border: "1px solid red",
                      }
                    }
                  />
                </div>
                {/* <div className='w-half'>
                                    <label htmlFor="">Min LOS</label>
                                    <input type="text" onChange={handleInputChange} name='min_los' value={roomData.min_los} />
                                </div> */}
                {/* <div className='w-half'>
                                    <label htmlFor=""> Max LOS <span className='required'>*</span></label>
                                    <input type="text" onChange={handleInputChange} name='max_los' value={roomData.max_los} required />
                                </div> */}
              </div>
              <div className="flex flex_gap_10 w-full">
                <div className="w-half">
                  <label htmlFor="">
                    {" "}
                    Max Adult <span className="required">*</span>
                  </label>
                  <input
                    type="number"
                    onChange={handleInputChange}
                    name="max_adult"
                    value={roomData.max_adult}
                    style={
                      formErrors.max_adult && {
                        backgroundColor: "#ff000040",
                        border: "1px solid red",
                      }
                    }
                  />
                </div>
                <div className="w-half">
                  <label htmlFor=""> Max Child</label>
                  <input
                    type="number"
                    onChange={handleInputChange}
                    name="max_child"
                    value={roomData.max_child}
                    style={
                      formErrors.max_child && {
                        backgroundColor: "#ff000040",
                        border: "1px solid red",
                      }
                    }
                  />
                </div>
                <div className="w-half">
                  <label htmlFor="">Max Occupancy</label>
                  <input
                    type="number"
                    onChange={handleInputChange}
                    name="max_occupancy"
                    value={roomData.max_occupancy}
                    style={
                      formErrors.max_occupancy && {
                        backgroundColor: "#ff000040",
                        border: "1px solid red",
                      }
                    }
                  />
                </div>
              </div>
              {/* <h6 className='mt-10'>Price Per Night</h6>
                            <div className='flex flex_gap_10 w-full'>
                                <div className='w-half'>
                                    <label htmlFor="">EP</label>
                                    <input type="text" onChange={(e) => handleChange(e, "price_per_night", "ep")} name='ep' value={roomData['price_per_night']['ep']} />
                                </div>
                                <div className='w-half'>
                                    <label htmlFor="">CP</label>
                                    <input type="text" onChange={(e) => handleChange(e, "price_per_night", "cp")} name='ap' value={roomData['price_per_night']['cp']} />
                                </div>
                                <div className='w-half'>
                                    <label htmlFor=""> MAP</label>
                                    <input type="text" onChange={(e) => handleChange(e, "price_per_night", "map")} name='ap' value={roomData['price_per_night']['map']} />
                                </div>
                                <div className='w-half'>
                                    <label htmlFor=""> AP</label>
                                    <input type="text" onChange={(e) => handleChange(e, "price_per_night", "ap")} name='ap' value={roomData['price_per_night']['ap']} />
                                </div>
                            </div> */}
              {/* <h6 className='mt-10'>Extra Adult Cost</h6>
                            <div className='flex flex_gap_10 w-full'>
                                <div className='w-half'>
                                    <label htmlFor="">EP</label>
                                    <input type="text" onChange={(e) => handleChange(e, "extra_adult_cost", "ep")} name='ep' value={roomData['extra_adult_cost']['ep']} />
                                </div>
                                <div className='w-half'>
                                    <label htmlFor="">CP</label>
                                    <input type="text" onChange={(e) => handleChange(e, "extra_adult_cost", "cp")} name='cp' value={roomData['extra_adult_cost']['cp']} />
                                </div>
                                <div className='w-half'>
                                    <label htmlFor=""> MAP</label>
                                    <input type="text" onChange={(e) => handleChange(e, "extra_adult_cost", "map")} name='map' value={roomData['extra_adult_cost']['map']} />
                                </div>
                                <div className='w-half'>
                                    <label htmlFor=""> AP</label>
                                    <input type="text" onChange={(e) => handleChange(e, "extra_adult_cost", "ap")} name='ap' value={roomData['extra_adult_cost']['ap']} />
                                </div>
                            </div> */}
              {/* <h6 className='mt-10'>Child With Bed Cost</h6>
                            <div className='flex flex_gap_10 w-full'>
                                <div className='w-half'>
                                    <label htmlFor="">EP</label>
                                    <input type="text" onChange={(e) => handleChange(e, "child_with_bed_cost", "ep")} name='ep' value={roomData['child_with_bed_cost']['ep']} />
                                </div>
                                <div className='w-half'>
                                    <label htmlFor="">CP</label>
                                    <input type="text" onChange={(e) => handleChange(e, "child_with_bed_cost", "cp")} name='cp' value={roomData['child_with_bed_cost']['cp']} />
                                </div>
                                <div className='w-half'>
                                    <label htmlFor=""> MAP</label>
                                    <input type="text" onChange={(e) => handleChange(e, "child_with_bed_cost", "map")} name='map' value={roomData['child_with_bed_cost']['map']} />
                                </div>
                                <div className='w-half'>
                                    <label htmlFor=""> AP</label>
                                    <input type="text" onChange={(e) => handleChange(e, "child_with_bed_cost", "ap")} name='ap' value={roomData['child_with_bed_cost']['ap']} />
                                </div>
                            </div> */}
              {/* <h6 className='mt-10'>Child Without Bed Cost</h6>
                            <div className='flex flex_gap_10 w-full'>
                                <div className='w-half'>
                                    <label htmlFor="">EP</label>
                                    <input type="text" onChange={(e) => handleChange(e, "child_without_bed_cost", "ep")} name='ep' value={roomData['child_without_bed_cost']['ep']} />
                                </div>
                                <div className='w-half'>
                                    <label htmlFor="">CP</label>
                                    <input type="text" onChange={(e) => handleChange(e, "child_without_bed_cost", "cp")} name='cp' value={roomData['child_without_bed_cost']['cp']} />
                                </div>
                                <div className='w-half'>
                                    <label htmlFor=""> MAP</label>
                                    <input type="text" onChange={(e) => handleChange(e, "child_without_bed_cost", "map")} name='map' value={roomData['child_without_bed_cost']['map']} />
                                </div>
                                <div className='w-half'>
                                    <label htmlFor=""> AP</label>
                                    <input type="text" onChange={(e) => handleChange(e, "child_without_bed_cost", "ap")} name='ap' value={roomData['child_without_bed_cost']['ap']} />
                                </div>
                            </div> */}
            </div>
          </div>

          <div className="flex justify_content_end mt-10">
            <button
              type="submit"
              className="primary_btn"
              style={{ width: "70px" }}
            >
              Save
            </button>
          </div>
        </form>
      </div>
      {/* <AllAmenities showTitle={false} /> */}
    </section>
  );
}

export default AddRoomVendor;
