import React, { useEffect, useState } from "react";
import VendorHeader from "../../CustomComponents/Property/VendorHeader/VendorHeader";
import SideBar from "../../CustomComponents/Property/SideBar/SideBar";
import RatePlans from "../../CustomComponents/Property/RatePlans/RatePlans";

function RatePlansPage({ allPropertiesPage }) {
  return (
    <>
      <VendorHeader  showSelectProperty={true}/>
      <div className="sidebar_main_div">
        <SideBar allPropertiesPage={false} />
        <RatePlans />
      </div>
    </>
  );
}

export default RatePlansPage;
