import { Helmet } from "react-helmet";

const BreadcrumbSchema = () => {
  const breadcrumbSchema = {
    "@context": "https://schema.org/",
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "name": "All Hotels",
        "item": "https://www.mountainmysteries.com/all-hotels"
      },
      {
        "@type": "ListItem",
        "position": 2,
        "name": "Destinations",
        "item": "https://www.mountainmysteries.com/all-destinations"
      },
      {
        "@type": "ListItem",
        "position": 3,
        "name": "Activities in Himachal Pradesh",
        "item": "https://www.mountainmysteries.com/all-activities"
      },
      {
        "@type": "ListItem",
        "position": 4,
        "name": "Holidays Tour Packages",
        "item": "https://www.mountainmysteries.com/all-packages"
      }
    ]
  };

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(breadcrumbSchema)}
      </script>
    </Helmet>
  );
};

export default BreadcrumbSchema;
