import React, { useEffect, useRef, useState } from "react";

function loadScript(url, callback) {
  const script = document.createElement("script");
  script.src = url;
  script.async = true;
  script.onload = callback;
  document.body.appendChild(script);
}

function AddressAutocomplete({ formdata: parentFormData, onAddressChange }) {
  const [query, setQuery] = useState(parentFormData.address);
  const [formData, setFormData] = useState({
    country: parentFormData.country || "",
    state: parentFormData.state || "",
    city: parentFormData.city || "",
    address: parentFormData.address || "",
    latitude: "",
    longitude: "",
    placeId: "",
  });

  const autoCompleteRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=AIzaSyApd8SnCY_3LHinUXA84Pr4K0KxBWLKgj4&libraries=places`,
      () => handleScriptLoad(setQuery, autoCompleteRef, setFormData)
    );
  }, []);

  useEffect(() => {
    setFormData({
      country: parentFormData.country || "",
      state: parentFormData.state || "",
      city: parentFormData.city || "",
      address: parentFormData.address || "",
      latitude: "",
      longitude: "",
      placeId: "",
    });
    setQuery(parentFormData.address || "");
  }, [parentFormData]);

  const handleScriptLoad = (updateQuery, autoCompleteRef, setFormData) => {
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current,
      {
        types: ["geocode"],
        componentRestrictions: { country: "in" },
      }
    );

    autoCompleteRef.current.addListener("place_changed", () =>
      handlePlaceSelect(updateQuery, autoCompleteRef, setFormData)
    );
  };

  const handlePlaceSelect = (updateQuery, autoCompleteRef, setFormData) => {
    const place = autoCompleteRef.current.getPlace();
    // console.log(place);
    const addressComponents = place.address_components.reduce(
      (acc, component) => {
        const type = component.types[0];
        if (type === "country") acc.country = component.long_name;
        if (type === "administrative_area_level_1")
          acc.state = component.long_name;
        if (type === "locality") acc.city = component.long_name;
        return acc;
      },
      {}
    );

    const updatedFormData = {
      address: place.formatted_address,
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng(),
      placeId: place.place_id,
      country: addressComponents.country || "",
      state: addressComponents.state || "",
      city: addressComponents.city || "",
    };

    setFormData(updatedFormData);
    updateQuery(place.formatted_address);
    onAddressChange(updatedFormData);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  return (
    <div>
      <input
        className="w-full"
        ref={inputRef}
        value={query}
        onChange={(event) => setQuery(event.target.value)}
        placeholder="Search address..."
        type="text"
      />
      {formData.address && (
        <div style={{ marginTop: "10px" }}>
          <div className="input-flex">
            <div>
              <label htmlFor="country">Country</label>
              <input className="w-full"
                type="text"
                value={formData.country}
                readOnly
              />
            </div>
            <div>
              <label htmlFor="city">City</label>
              <input
                className="w-full"
                type="text"
                value={formData.city}
              // readOnly
              />
            </div>
            <div>
              <label htmlFor="state">State</label>
              <input
                className="w-full"
                type="text"
                value={formData.state}
              // readOnly
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AddressAutocomplete;
