import React from "react";
import "./SkeletonLoader.css"

function SkeletonLoader() {
    return (
        <div className="skeleton-loader">
            <div className="skeleton-header"></div>
            <div className="skeleton-content"></div>
            <div className="skeleton-footer"></div>
        </div>
    )
}

export default SkeletonLoader;