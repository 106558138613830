import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../CustomComponents/Footer/Footer";
import PageLinks from "../../CustomComponents/PageLinks/PageLinks";
import ReviewReservationBanner from "../../CustomComponents/ReviewReservationBanner/ReviewReservationBanner";
import Header from "../../CustomComponents/Header/Header";
import PageMetaData from "../../CustomComponents/PageMetaData/PageMetaData";
import NoIndexMeta from "../../utils/NoIndexMeta";

function PrivacyPolicy() {
  return (
    <>
      <PageMetaData
        title="Terms and Conditions | Mountain Mysteries"
        description="Enhance your hotel capabilities with RevCatalyst's powerful system. Optimize performance, streamline operations, and unleash the full potential of property."
        keywords="hotel management, RevCatalyst, property optimization"
      />
      <NoIndexMeta />
      <Header />
      <ReviewReservationBanner heading="Privacy Policy" />
      {/* <PageLinks links={["Home", "Term &  Conditions"]} /> */}
      <section className="pb_30">
        <div className="container">
          <div className="overview-content">
            <div>
              <p className="mt-20">
                We value your privacy and are committed to safeguarding your
                personal information in accordance with Indian data protection
                laws, including the Information Technology Act, 2000, and other
                applicable regulations. This Privacy Policy outlines our
                policies and procedures regarding the collection, use, and
                disclosure of your information when you use our services,
                including travel services, accommodations, holiday packages, and
                online and offline inventory sales. By using our services, you
                consent to the collection and use of your information in
                accordance with this Privacy Policy. Please take a moment to
                read this document to understand how we protect your privacy
                rights.
              </p>
            </div>
            <div>
              <h4 className="mt-20 mb-10">
                Collecting and Using Your Personal Data
              </h4>
              <b>Types of Data Collected</b>
              <p>
                While using our services, we may ask you to provide certain
                personally identifiable information (PII) that can be used to
                contact or identify you. This information may include, but is
                not limited to:
              </p>
              <ul className="ml_15">
                <li>Email address </li>
                <li>First name and last name </li>
                <li>Phone number </li>
                <li>Address, state, province, ZIP/postal code, city </li>
                <li>Usage data</li>
              </ul>
              <b className="mt-20 block">Usage Data</b>
              <p>
                Usage data is collected automatically when using our services.
                It may include information such as your device's Internet
                Protocol address (e.g., IP address), browser type, browser
                version, the pages of our service that you visit, the time and
                date of your visit, the time spent on those pages, unique device
                identifiers, and other diagnostic data.
              </p>
              <p>
                When you access the service through a mobile device, we may
                collect certain information automatically, including, but not
                limited to, the type of mobile device you use, your mobile
                device's unique ID, the IP address of your mobile device, your
                mobile operating system, the type of mobile Internet browser you
                use, unique device identifiers, and other diagnostic data.
              </p>
              <p>
                We may also collect information that your browser sends whenever
                you visit our service or access the service by or through a
                mobile device.
              </p>
              <b className="mt-20 block">Sensitive Personal Data</b>
              <p>
                In accordance with Indian data protection laws, we may collect
                sensitive personal data (SPD), which includes information such
                as:
              </p>
              <ul className="ml_15">
                <li>Passwords</li>
                <li>
                  Financial information (e.g., bank account, credit/debit card
                  details){" "}
                </li>
                <li>Physical or mental health condition </li>
                <li>Sexual orientation </li>
                <li>Medical records and history</li>
                <li>Biometric information </li>
              </ul>
              <p>
                We ensure that sensitive personal data is collected only when
                necessary and with your explicit consent.
              </p>
            </div>
            <div>
              <h4 className="mt-20 mb-10">Tracking Technologies and Cookies</h4>
              <p>
                We use cookies and similar tracking technologies to monitor
                activity on our services and store certain information. Tracking
                technologies include beacons, tags, and scripts to collect and
                track information and improve and analyze our services. The
                technologies we use include:
              </p>
              <ul className="ml_15">
                <li>
                  <b>Cookies or Browser Cookies:</b>A cookie is a small file
                  placed on your device. You can instruct your browser to refuse
                  all cookies or to indicate when a cookie is being sent.
                  However, if you do not accept cookies, you may not be able to
                  use some parts of our services. Unless you have adjusted your
                  browser setting to refuse cookies, our service may use
                  cookies.
                </li>
                <li>
                  <b>Flash Cookies:</b>Certain features of our services may use
                  local stored objects (or Flash cookies) to collect and store
                  information about your preferences or activity on our
                  services. Flash cookies are not managed by the same browser
                  settings as those used for browser cookies. For more
                  information on how you can delete Flash cookies, please read
                  Adobe's guide.
                </li>
                <li>
                  <b>Web Beacons:</b>Our emails and certain sections of our
                  services may contain small electronic files known as web
                  beacons (also referred to as clear gifs, pixel tags, and
                  single-pixel gifs). These beacons allow us to count users who
                  have visited those pages or opened an email and collect other
                  related website statistics (e.g., recording the popularity of
                  a certain section and verifying system and server integrity).
                </li>
              </ul>
              <b className="mt-20 mb-10 block">Types of Cookies We Use:</b>
              <b>1. Necessary/Essential Cookies</b>
              <ul className="ml_15">
                <li>
                  <b>Type:</b> Session Cookies
                </li>
                <li>
                  <b>Administered by:</b> Us
                </li>
                <li>
                  <b>Purpose: </b> These cookies are essential for providing you
                  with services available through the website and enable you to
                  use some of its features. They help authenticate users and
                  prevent fraudulent use of user accounts. Without these
                  cookies, the services you have asked for cannot be provided,
                  and we use these cookies only to provide you with those
                  services.
                </li>
              </ul>
              <b className="mt-10 block">
                2. Cookies Policy/Notice Acceptance Cookies{" "}
              </b>
              <ul className="ml_15">
                <li>
                  <b>Type:</b> Persistent Cookies{" "}
                </li>
                <li>
                  <b>Administered by:</b> Us
                </li>
                <li>
                  <b>Purpose: </b> These cookies identify if users have accepted
                  the use of cookies on the website.
                </li>
              </ul>
              <b className="mt-10 block">3. Functionality Cookies </b>
              <ul className="ml_15">
                <li>
                  <b>Type:</b> Persistent Cookies{" "}
                </li>
                <li>
                  <b>Administered by:</b> Us
                </li>
                <li>
                  <b>Purpose: </b> These cookies allow us to remember choices
                  you make when you use the website, such as remembering your
                  login details or language preference. The purpose of these
                  cookies is to provide you with a more personal experience and
                  to avoid you having to re-enter your preferences every time
                  you use the website.
                </li>
              </ul>
              <p>
                For more information about the cookies we use and your choices
                regarding cookies, please visit our Cookies Policy or the
                Cookies section of our Privacy Policy.
              </p>
              <b className="mt-20 mb-10 block">Use of Your Personal Data</b>
              <p>
                Mountain Mysteries may use your personal data for the following
                purposes:
              </p>
              <ul className="ml_15">
                <li>
                  <b>To Provide and Maintain Our Services: </b> We use your
                  personal data to deliver and maintain the services you have
                  requested, including monitoring usage to ensure quality and
                  improve performance.{" "}
                </li>
                <li>
                  <b>To Manage Your Account: </b> Your personal data allows us
                  to manage your registration as a user of our services,
                  granting you access to different functionalities available to
                  registered users.
                </li>
                <li>
                  <b>For the Performance of a Contract: </b> We process personal
                  data as necessary to fulfill contracts for products, services,
                  or any other agreements you have with us.
                </li>
                <li>
                  <b>To Contact You: </b> We may contact you via email, phone
                  calls, SMS, or other electronic communications (e.g., mobile
                  app push notifications) to provide updates, information about
                  our services, and security alerts.
                </li>
                <li>
                  <b>To Manage Your Requests: </b> We use your information to
                  address and manage any requests or inquiries you submit to us.
                </li>
                <li>
                  <b>For Business Transfers: </b>We may use your personal data
                  to evaluate or conduct a merger, divestiture, restructuring,
                  reorganization, dissolution, or other sale or transfer of some
                  or all of our assets, in which personal data about our service
                  users is part of the assets transferred.
                </li>
                <li>
                  <b>For Other Purposes: </b>We may use your personal data for
                  other purposes, such as data analysis, identifying usage
                  trends, assessing the effectiveness of our promotional
                  campaigns, and improving our services, products, marketing,
                  and user experience.
                </li>
              </ul>
              <b className="mt-20 mb-10 block">Sharing Your Personal Data</b>
              <p>
                We may share your personal information in the following
                situations:
              </p>
              <ul className="ml_15">
                <li>
                  <b>With Service Providers: </b> We may share your information
                  with service providers to monitor and analyze service usage
                  and to contact you. Service providers are required to adhere
                  to confidentiality and data protection obligations.
                </li>
                <li>
                  <b>For Business Transfers: </b> We may share or transfer your
                  information during negotiations of any merger, sale of company
                  assets, financing, or acquisition of all or a portion of our
                  business to another company.{" "}
                </li>
                <li>
                  <b>With Affiliates: </b> We may share your information with
                  our affiliates, requiring them to honor this Privacy Policy.
                  Affiliates include our parent company and any other
                  subsidiaries, joint venture partners, or companies we control
                  or are under common control with.
                </li>
                <li>
                  <b>With Business Partners: </b> We may share your information
                  with business partners to offer you certain products,
                  services, or Offers.
                </li>
                <li>
                  <b>With Other Users: </b> When you share personal information
                  or interact in public areas with other users, such information
                  may be viewed by all users and publicly distributed outside.
                </li>
                <li>
                  <b>With Your Consent</b> We may disclose your personal
                  information for any other purpose with your consent.
                </li>
              </ul>
            </div>
            <div>
              <h4 className="mt-20 mb-10">International Data Transfers </h4>
              <p>
                In accordance with Indian data protection laws, any transfer of
                your personal data outside India will be done in compliance with
                applicable legal requirements. We will ensure that adequate
                safeguards are in place to protect your personal data.
              </p>
            </div>
            <div>
              <h4 className="mt-20 mb-10">Retention of Your Personal Data</h4>
              <p>
                Mountain Mysteries will retain your personal data only as long
                as necessary for the purposes outlined in this Privacy Policy.
                We will retain and use your data to comply with legal
                obligations, resolve disputes, and enforce our agreements and
                policies.
              </p>
              <p>
                Usage data is generally retained for a shorter period unless
                used to enhance security or improve service functionality or if
                we are legally required to retain it longer.
              </p>
            </div>
            <div>
              <h4 className="mt-20 mb-10">Security of Your Personal Data</h4>
              <p>
                The security of your personal data is important to us. We strive
                to use commercially acceptable means to protect your data, but
                remember that no method of transmission over the Internet or
                electronic storage is 100% secure. While we strive to protect
                your personal data, we cannot guarantee its absolute security.
              </p>
              <p>
                We implement reasonable security practices and procedures as per
                Indian law to protect your personal data from unauthorized
                access, use, or disclosure.
              </p>
            </div>
            <div>
              <h4 className="mt-20 mb-10">Business Transactions</h4>
              <p>
                If Mountain Mysteries is involved in a merger, acquisition, or
                asset sale, your personal data may be transferred. We will
                notify you before your personal data is transferred and subject
                to a different Privacy Policy.
              </p>
            </div>
            <div>
              <h4 className="mt-20 mb-10">Other Legal Requirements</h4>
              <p>
                Mountain Mysteries may disclose your personal data in good faith
                if such action is necessary to:
              </p>
              <ul className="ml_15">
                <li>Comply with a legal obligation</li>
                <li>
                  Protect and defend the rights or property of Mountain
                  Mysteries
                </li>
                <li>
                  Prevent or investigate possible wrongdoing in connection with
                  the service
                </li>
                <li>
                  Protect the personal safety of users of the service or the
                  public
                </li>
                <li>Protect against legal liability</li>
              </ul>
            </div>
            <div>
              <h4 className="mt-20 mb-10">Links to Other Websites</h4>
              <p>
                Our services may contain links to websites not operated by us.
                If you click a third-party link, you will be directed to that
                third-party site. We strongly advise you to review the Privacy
                Policy of every site you visit. We have no control over and
                assume no responsibility for the content
              </p>
            </div>
            <div>
              <h4 className="mt-10 mb-10">Changes to This Privacy Policy</h4>
              <p>
                We may update our Privacy Policy occasionally. We will notify
                you of any changes by posting the new Privacy Policy on this
                page.
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default PrivacyPolicy;
