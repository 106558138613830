import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../CustomComponents/Footer/Footer";
import PageLinks from "../../CustomComponents/PageLinks/PageLinks";
import ReviewReservationBanner from "../../CustomComponents/ReviewReservationBanner/ReviewReservationBanner";
import Header from "../../CustomComponents/Header/Header";
import PageMetaData from "../../CustomComponents/PageMetaData/PageMetaData";
import NoIndexMeta from "../../utils/NoIndexMeta";

function CancellationPolicy() {
    return (
        <>
            <PageMetaData
                title="Cancellation Policy | Mountain Mysteries"
                description="Enhance your hotel capabilities with RevCatalyst's powerful system. Optimize performance, streamline operations, and unleash the full potential of property."
                keywords="hotel management, RevCatalyst, property optimization"
            />
            <NoIndexMeta />
            <Header />
            <ReviewReservationBanner heading="Cancellation Policy" />
            {/* <PageLinks links={["Home", "Cancellation Policy"]} /> */}
            <section className="pb_30">
                <div className="container">
                    <div className="overview-content mt-20">
                        <div>
                            <p>We understand that plans can change, and we strive to make our cancellation policy as straightforward and fair as possible, in compliance with Indian laws and regulations. This cancellation policy is designed to align with Indian legal requirements, including the Consumer Protection Act, 2019, and other relevant regulations, while ensuring transparency and fairness for customers. Please read this policy carefully to understand your rights and obligations when canceling travel and holiday packages, as well as accommodation bookings with us.
                            </p>
                        </div>
                        <div>
                            <h4 className='mt-10 mb-10'>General Cancellation Policy </h4>
                            <b>1. Applicability</b>
                            <p className="mt-10">This cancellation policy applies to all bookings made through Mountain Mysteries, whether online or offline. It covers travel packages, holiday packages, and accommodation inventory.
                            </p>
                            <b className='mt-10 block'>2. Notification of Cancellation        </b>
                            <ul className="ml_15">
                                <li>Cancellations must be submitted in writing via email or through our online cancellation form available on our website.
                                </li>
                                <li>Please include your booking reference number and the reason for cancellation in your request.
                                </li>
                            </ul>
                            <b className='mt-10 block'>3. Compliance with Indian Laws </b>
                            <ul className="ml_15">
                                <li>This policy is designed to comply with the Consumer Protection Act, 2019, and other relevant Indian laws. In case of any conflict between this policy and applicable laws, the latter shall prevail. </li>
                            </ul>
                        </div>
                        <div>
                            <h4 className='mt-10 mb-10'>Cancellation Terms for Travel and Holiday Packages
                            </h4>
                            <b>1. Cancellation by Customer                            </b>
                            <ul className="ml_15">
                                <li><b>More than 30 days before departure:</b> Full refund of the total booking amount, minus a processing fee of [Insert Amount or Percentage], in compliance with Indian laws.</li>
                                <li><b>15 to 30 days before departure:</b> 50% refund of the total booking amount.</li>
                                <li><b>Less than 15 days before departure:</b> No refund will be issued unless mandated by applicable Indian regulations.
                                </li>

                            </ul>
                            <b className='mt-10 block'>2. Changes to Booking                            </b>
                            <ul className="ml_15">
                                <li>Requests to modify travel or holiday packages (e.g., change of dates, destination) are subject to availability and may incur additional charges.
                                </li>
                                <li>Modifications must be requested at least 15 days before the scheduled departure date. </li>
                            </ul>
                            <b className='mt-10 block'>3. Non-Refundable Components                            </b>
                            <ul className="ml_15">
                                <li>Some components of travel packages, such as flights, special event tickets, or third-party services, may be non-refundable regardless of the cancellation date. These will be clearly indicated at the time of booking.
                                </li>
                                <li>In accordance with the Indian Contract Act, 1872, non-refundable components will be governed by the terms agreed upon at the time of booking.
                                </li>
                            </ul>
                            <b className='mt-10 block'>4.Force Majeure </b>
                            <ul className="ml_15">
                                <li>If cancellations occur due to events beyond our control, such as natural disasters, pandemics, or government restrictions, we will work with you to reschedule or provide a credit for future bookings. Refunds may not be possible for certain third-party services.</li>
                            </ul>
                        </div>
                        <div>
                            <h4 className='mt-10 mb-10'>Cancellation Terms for Accommodation Bookings </h4>
                            <b>1. Cancellation by Customer</b>
                            <ul className="ml_15">
                                <li><b> More than 15 days before check-in:</b> Full refund of the total booking amount, minus a processing fee of [Insert Amount or Percentage].
                                </li>
                                <li><b>7 to 15 days before check-in:</b>  50% refund of the total booking amount.                                </li>
                                <li><b>Less than 7 days before check-in: </b>No refund will be issued unless required by Indian consumer protection laws.
                                </li>

                            </ul>
                            <b className='mt-10 block'>2. No-Show Policy
                            </b>
                            <ul className="ml_15">
                                <li>If you fail to check in on the scheduled date without prior notification, the full booking amount will be charged, and no refund will be issued.
                                </li>
                            </ul>
                            <b className='mt-10 block'>3. Early Departure                            </b>
                            <ul className="ml_15">
                                <li>No refunds will be provided for early departures or unused nights unless specified under special circumstances governed by Indian laws.</li>
                            </ul>
                        </div>
                        <div>
                            <h4 className='mt-10 mb-10'>Special Circumstances</h4>
                            <b>1. Medical Emergencies</b>
                            <ul className="ml_15">
                                <li>In case of medical emergencies, we may offer a refund or rescheduling option upon submission of valid documentation, such as a medical certificate. Please contact our customer service team for assistance.
                                </li>
                            </ul>
                            <b className='mt-10 block'>2. Travel Insurance </b>
                            <ul className="ml_15">
                                <li>We strongly recommend purchasing travel insurance to cover unforeseen circumstances that may result in cancellations or trip interruptions.</li>

                            </ul>
                        </div>
                        <div>
                            <h4 className='mt-10 mb-10'>Refund Process</h4>
                            <b>1. Refund Timeline</b>
                            <ul className="ml_15">
                                <li>Refunds, if applicable, will be processed within 7-10 business days from the date of cancellation approval. </li>
                                <li>Refunds will be issued to the original payment method used at the time of booking.
                                </li>
                            </ul>
                            <b className='mt-10 block'>2. Processing Fees</b>
                            <ul className="ml_15">
                                <li>All cancellations may be subject to processing fees, which will be deducted from the refund amount. These fees cover administrative costs associated with the cancellation process.</li>

                            </ul>
                            <b className='mt-10 block'>3. Compliance with the Consumer Protection Act, 2019</b>
                            <ul className="ml_15">
                                <li>Refunds will be processed in accordance with the timelines and procedures set forth by the Consumer Protection Act, 2019, and other relevant Indian regulations.</li>
                            </ul>
                        </div>
                        <div>
                            <h4 className='mt-10 mb-10'>Cancellation by Mountain Mysteries</h4>
                            <b>1. Right to Cancel </b>
                            <ul className="ml_15">
                                <li>Mountain Mysteries reserves the right to cancel bookings due to unforeseen circumstances, such as lack of availability, changes in travel restrictions, or safety concerns.
                                </li>
                            </ul>
                            <b className='mt-10 block'>2. Refund or Rescheduling</b>
                            <ul className="ml_15">
                                <li>In such cases, we will offer you the option to either reschedule your booking or receive a full refund of the amount paid.
                                </li>
                                <li>Our actions will comply with the Indian Contract Act, 1872, ensuring fairness and transparency.</li>
                            </ul>
                        </div>
                        <div>
                            <h4 className='mt-10 mb-10'>Contact Us</h4>
                            <p>If you have any questions or need assistance with a cancellation, please don't hesitate to contact our customer support team.</p>
                        </div>
                        <div>
                            <h4 className='mt-20 mb-10'>Changes to This Cancellation Policy                            </h4>
                            <p>Mountain Mysteries reserves the right to update or modify this cancellation policy at any time. We will notify you of any changes by posting the updated policy on our website. It is your responsibility to review this policy periodically for any changes.
                            </p>
                            <p>Thank you for choosing Mountain Mysteries. We appreciate your understanding and cooperation.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />

        </>
    )
}

export default CancellationPolicy;