import React from "react";
import Skeleton from "react-loading-skeleton";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

const Gallery = ({ singlePackageData, loader }) => {
  return (
    <>
      {loader ? (
        <div className="package-update-content-containers">
          <div className="package-update-cards">
            <div className="package-card-body">
              <div className="package-update-card">
                <div className="p_10">
                  <Skeleton width={100} />
                </div>
                <div className="content-consider flex flex_gap_10 flex_wrap">
                  {Array.from({ length: 5 }).map((_, index) => (
                    <div
                      className="card-content"
                      style={{
                        borderRadius: "10px",
                        overflow: "hidden",
                        width: "32%",
                      }}
                    >
                      <Skeleton height={151} width={247} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="package-update-card">
            <div className="content-consider">
              <div>
                <div className="agent">
                  <div>
                    {" "}
                    <span style={{ fontWeight: "bold" }}>Photos</span>
                  </div>


             {singlePackageData?.packageImages?.length !== 0 &&     
                  <div
                    className="view_btn cursor_pointer"
                    onClick={() => {
                      Fancybox.show(
                        singlePackageData.packageImages.map((src) => ({
                          src,
                          type: "image",
                        }))
                      );
                    }}
                  >
                    View all Images
                  </div>}
                </div>
                <div className="flex flex_gap_10 flex_wrap mt-20">
                  {singlePackageData?.packagePolicies?.length > 0 ? (
                    <>
                      {singlePackageData?.packageImages?.length === 0 ? (
                        <>No image is uploaded</>
                      ) : (
                        singlePackageData?.packageImages?.map((item, index) => (
                          <div className="hotel_img cursor_pointer" key={index}>
                            <div
                              onClick={() => {
                                Fancybox.show(
                                  singlePackageData.packageImages.map(
                                    (src) => ({
                                      src,
                                      type: "image",
                                    })
                                  ),
                                  { startIndex: index } // This sets the clicked image as the first one shown
                                );
                              }}
                            >
                              <img src={item} alt={`hotel-image-${index}`} />{" "}
                              {/* Single image */}
                            </div>
                          </div>
                        ))
                      )}
                    </>
                  ) : (
                    <>No Data Found</>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Gallery;
