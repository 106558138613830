import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import VendorHeader from "../../CustomComponents/Property/VendorHeader/VendorHeader";
import SideBar from "../../CustomComponents/Property/SideBar/SideBar";
import TabBar from "../../CustomComponents/Property/TabBar/TabBar";
import BasicInformation from "../../CustomComponents/Property/BasicInformation/BasicInformation";

import { toast } from "react-toastify";
import "../../CustomComponents/Property/EditProperty/EditProperty.css";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import AddressAutocomplete from "../../CustomComponents/CustomGooglePlaceAPi/AddressAutocomplete";

function VendorUpdate() {
  const [fetchedImages, setFetchedImages] = useState([]);
  const [fetchedPropertyLogo, setFetchedPropertyLogo] = useState([]);
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [propertyLogoLoader, setPropertyLogoLoader] = useState(false);
  const [uploadedLogoFile, setUploadedLogoFile] = useState(null);
  const [logoUrl, setLogoUrl] = useState([]);

  const token = localStorage.getItem("token");

  const handleFileUploadChange = (event) => {
    const files = event.target.files;
    setSelectedFiles([...selectedFiles, ...files]);
    const file = files[0];
    const fileUrl = URL.createObjectURL(file);
    setUploadedFile(file);
    setLogoUrl(fileUrl);
  };

  // try
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { propertyData, addressData } = location.state || {};
  const [VendorDetails, setVendorDetails] = useState({
    company_name: "",
    name: "",
    phone: "",
    email: "",
    type: "",
    city: "",
    state: "",
    country: "",
    address: "",
    pan: "",
    gst: "",
    vendor_long: "",
    vendor_lat: "",
    vendor_state_code: "",
    vendor_country_code: "",
  });
  //   useEffect(() => {
  //     if (userDetails) {
  //       setVendorDetails(() => ({
  //         company_name: userDetails.vendor_company_name,
  //         name: userDetails.vendor_contact_name,
  //         phone: userDetails.vendor_contact_phone,
  //         email: userDetails.vendor_contact_email,
  //         type: userDetails.vendor_type,
  //         gst: userDetails.vendor_gst,
  //         pan: userDetails.vendor_pan,
  //       }));
  //       setAddressDetails({
  //         address: userDetails.vendor_address,
  //         latitude: "",
  //         longitude: "",
  //         country: userDetails.vendor_country,
  //         state: userDetails.vendor_state,
  //         city: userDetails.vendor_city,
  //       });
  //     }
  //   }, []);

  const [addressDetails, setAddressDetails] = useState({
    address: "",
    latitude: "",
    longitude: "",
    country: "",
    state: "",
    city: "",
  });

  const deleteFetchedImages = async (id) => {
    const response = await fetch(
      `${process.env.REACT_APP_APP_URL}/api/v1/vendor/delete-image`,

      {
        method: "DELETE",
        body: JSON.stringify({
          mediaId: id,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    const data = await response
      .json()
      .then((data) => {
        if (data.success) {
          //   showSuccessToast(data.message);
          showSuccessToast("Image deleted successfully");
          //   fetchPropertyData();
        } else {
          //   showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        // showErrorToast("An error occurred while processing your request.");
      });
  };

  const addPropertyImages = (event) => {
    event.preventDefault();
    // setLoader(true);
    const myHeaders = new Headers();
    const formData = new FormData();
    formData.append("media_type", "property");
 myHeaders.append("key", process.env.REACT_APP_API_KEY);
    formData.append("associated_id", id);
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append("image[]", selectedFiles[i]);
    }
    fetch(
      `${process.env.REACT_APP_APP_URL}/api/v1/vendor/add-hotel-image/${id}`,
      {
        method: "POST",
        body: formData,
        headers: {
          // "multipart/form-data": "multipart/form-data",
          Authorization: `Bearer ${token}`,
          key: "D4X50uU40Vz4pweFLEmsoiJy107iPfTD4ompprop1",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          // fetchPropertyImages(id);
          //   fetchPropertyData();
          setSelectedFiles([]);
          //   setLoader(false);
          //   showSuccessToast(data.message);
        } else if (data.error) {
          //   setLoader(false);
          const firstErrorKey = Object.keys(data.error)[0];
          const firstErrorMessage = data.error[firstErrorKey][0];
          //   showErrorToast(firstErrorMessage);
        } else {
          //   setLoader(false);
          //   showErrorToast(data.message);
        }
      });
  };

  const [vendorId, setVendorId] = useState({});
  useEffect(() => {
    const vendor_id = localStorage.getItem("userDetails");
    if (vendor_id) {
      const userDetails = JSON.parse(vendor_id);
      // console.log(userDetails);
      setVendorId(userDetails.id);
    }
  }, []);

  const UpdateVendorData = (e) => {
    e.preventDefault();
    const requestOptions = {
      headers: {
        "Content-Type": "application/json",
        key: "D4X50uU40Vz4pweFLEmsoiJy107iPfTD4ompprop1",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      method: "POST",
      redirect: "follow",
      body: JSON.stringify({
        ...VendorDetails,
        ...addressDetails,
      }),
    };

    fetch(
      `${process.env.REACT_APP_APP_URL}/api/v1/vendor/update/${vendorId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          showSuccessToast(result.message);
          navigate("/vendor/property/list");
          getUserProfile(token);
        } else {
          showErrorToast(result.message);
        }
      })
      .catch((error) => console.error("Error fetching property data:", error));
  };

  // console.log(VendorDetails, "filtered data");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setVendorDetails({ ...VendorDetails, [name]: value });
  };

  const handleAddressChange = (data) => {
    setAddressDetails(data);
  };

  // console.log(addressDetails, "addressDetails");

  const handleSave = (e) => {
    e.preventDefault();

    const requestOptions = {
      headers: {
        "Content-Type": "application/json",
        key: "D4X50uU40Vz4pweFLEmsoiJy107iPfTD4ompprop1",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      method: "POST",
      body: JSON.stringify({
        ...VendorDetails,
      }),
      redirect: "follow",
    };

    fetch(
      `https://extranet.travcatalyst.com/api1/vendor/accommodation-updatee/${id}/${vendorId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log("Property updated successfully:", result);
        navigate("/vendor/property/list");
      })
      .catch((error) => console.error("Error updating property data:", error));
  };

  const getUserProfile = (token) => {
    const requestOptions = {
      // myHeaders.append("key", "D4X50uU40Vz4pweFLEmsoiJy107iPfTD4ompprop1");
      headers: {
        "Content-Type": "application/json",
        key: "D4X50uU40Vz4pweFLEmsoiJy107iPfTD4ompprop1",
        Authorization: "Bearer " + token,
      },
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_APP_URL}/api/v1/vendor-details`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log("result", result);

        if (result.success) {
          setVendorDetails(() => ({
            company_name: result.vendor.vendor_company_name,
            name: result.vendor.vendor_contact_name,
            phone: result.vendor.vendor_contact_phone,
            email: result.vendor.vendor_contact_email,
            type: result.vendor.vendor_type,
            gst: result.vendor.vendor_gst,
            pan: result.vendor.vendor_pan,
          }));
          setAddressDetails({
            address: result.vendor.vendor_address,
            latitude: "",
            longitude: "",
            country: result.vendor.vendor_country,
            state: result.vendor.vendor_state,
            city: result.vendor.vendor_city,
          });
        } else {
        }
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    getUserProfile(token);
  }, []);

  return (
    <>
      <VendorHeader />
      <section>
        <div className="basic-info-container">
          <div className="flex_align_center flex_gap_20">
            <span onClick={() => window.history.back()} className="back_btn">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.292893 7.29289C-0.0976311 7.68342 -0.0976311 8.31658 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292893 7.29289ZM1 9H16V7H1V9Z"
                  fill="#333333"
                />
              </svg>
            </span>
            <div>
              <h3 className="text-left">Update Vendor Details</h3>
              <p>Please fill the details of your property.</p>
            </div>
          </div>
          <form onSubmit={UpdateVendorData}>
            <div className="bg-white-div">
              <div className="flex flex_gap_10 w-full">
                <div className="w-half">
                  <label htmlFor="property_name">
                    Company Name <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Company Name"
                    onChange={handleInputChange}
                    name="company_name"
                    value={VendorDetails?.company_name || ""}
                    required
                  />
                </div>
                <div className="w-half">
                  <label htmlFor="property_name">
                    Name <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Name"
                    onChange={handleInputChange}
                    name="name"
                    value={VendorDetails?.name || ""}
                    required
                  />
                </div>
              </div>

              <div className="flex flex_gap_10 w-full">
                <div className="w-half">
                  <label htmlFor="property_name">
                    Phone <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Phone No."
                    onChange={handleInputChange}
                    name="phone"
                    value={VendorDetails?.phone || ""}
                    required
                  />
                </div>
                <div className="w-half">
                  <label htmlFor="property_name">
                    Email <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Email"
                    onChange={handleInputChange}
                    name="email"
                    value={VendorDetails?.email || ""}
                    required
                  />
                </div>
              </div>
              <div className="flex flex_gap_10 w-full">
                <div className="w-half">
                  <label htmlFor="property_name">
                    Gst<span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Gst No."
                    onChange={handleInputChange}
                    name="gst"
                    value={VendorDetails?.gst || ""}
                    // required
                  />
                </div>
                <div className="w-half">
                  <label htmlFor="property_name">
                    Pan <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Pan No."
                    onChange={handleInputChange}
                    name="pan"
                    value={VendorDetails?.pan || ""}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="bg-white-div mt-10">
              <h4 className="mb-10">Address Details</h4>
              <div>
                <AddressAutocomplete
                  formdata={addressDetails}
                  onAddressChange={handleAddressChange}
                  addressDetails={addressDetails}
                />
              </div>
            </div>
            <div className="flex justify_content_end mt-10">
              <button
                type="submit"
                className="primary_btn"
                style={{ width: "70px" }}
              >
                Update
              </button>
            </div>
          </form>
        </div>
      </section>
    </>
  );
}

export default VendorUpdate;
