import React, { useEffect, useRef, useState } from "react";
import { formatIndianCurrency, textDateFormatter } from "../../utils/utils";
import "./PackageDetails.css";
function ItnerarySideBar({
  onClose,
  setIsSliderVisible,
  singleBookingData: singleSliderBookingData,
  singlePackageData: packageData,
  setIsItineraryDetailsVisible,
  isItineraryDetailsVisible,
}) {
  // console.log("singleSliderBookingData", singleSliderBookingData);
  const bookingDetailsPopupRef = useRef(null);
  const headerRef = useRef(null);

  useEffect(() => {
    setIsSliderVisible(true);
    document.body.style.overflow = "hidden";
    const handleClickOutside = (event) => {
      if (
        bookingDetailsPopupRef.current &&
        !bookingDetailsPopupRef.current.contains(event.target)
      ) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      setIsSliderVisible(false);
      document.body.style.overflow = "auto";
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose, setIsSliderVisible]);

  const handleScroll = () => {
    if (bookingDetailsPopupRef.current.scrollTop > 50) {
      headerRef.current.classList.add("is-fixed");
    } else {
      headerRef.current.classList.remove("is-fixed");
    }
  };

  //   useEffect(() => {
  //     const sliderContainer = bookingDetailsPopupRef.current;
  //     sliderContainer.addEventListener("scroll", handleScroll);

  //     return () => {
  //       sliderContainer.removeEventListener("scroll", handleScroll);
  //     };
  //   }, []);

  const [activeTab, setActiveTab] = useState("package-information");

  const noOfRooms = singleSliderBookingData?.items?.reduce(
    (previousValue, currentValue) => {
      return Number(previousValue) + Number(currentValue?.no_of_rooms);
    },
    0
  );

  const noOfChild = singleSliderBookingData?.items?.reduce(
    (previousValue, currentValue) => {
      return Number(previousValue) + Number(currentValue?.no_child);
    },
    0
  );

  const noOfAdults = singleSliderBookingData?.items?.reduce(
    (previousValue, currentValue) => {
      return Number(previousValue) + Number(currentValue?.no_adult);
    },
    0
  );

  const extraAdult = singleSliderBookingData?.items?.reduce(
    (previousValue, currentValue) => {
      return Number(previousValue) + Number(currentValue?.extra_adult);
    },
    0
  );

  const handleClose = () => {
    onClose();
  };
  //   console.log(singleSliderBookingData);

  return (
    <>
      <div className="bg-backdrop">
        <div
          id="booking_details_slider_container_new"
          ref={bookingDetailsPopupRef}
        >
          <div id="">
            <div className="close_svg border-bottom py-5">
              <svg
                style={{ marginLeft: "20px" }}
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                onClick={() => setIsItineraryDetailsVisible(false)}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M1 13L13 1" stroke="#000" strokeWidth="1.2" />
                <path d="M13 13L1 1" stroke="#000a" strokeWidth="1.2" />
              </svg>
            </div>

            <div className="package-name-duration-top day-wise-details-container">
              <h5>{packageData?.packageName}</h5>

              <p>
                Validity :{" "}
                {new Date(packageData?.validFrom).toLocaleDateString()} -{" "}
                {new Date(packageData?.validTo).toLocaleDateString()}
              </p>

              <p>
                Duration : {packageData?.totalDays} D /{" "}
                {packageData?.totalNights} N{" "}
              </p>
            </div>

            {/* destination images */}

            <div
              className="day-wise-details-container p_20"
              style={{ marginBottom: "20px" }}
            >
              <h5 className="day-wise-details-headings">Destinations</h5>
              <hr className="lineDownload" />

              <div
                style={{ display: "flex", gap: "15px", paddingBottom: "30px" }}
              >
                {packageData?.packageDestination?.map((destination, index) => (
                  <div key={index}>
                    <img
                      src={destination.image}
                      alt={destination.title}
                      height={100}
                      width={200}
                    />
                    <div style={{ textAlign: "center" }}>
                      {destination?.title}
                    </div>
                    <div style={{ textAlign: "center" }}>
                      ({packageData?.totalNights} N)
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* DAY WISE DETAILS */}

            <div className="day-wise-details-container p_20 p_top_0">
              <h5 className="day-wise-details-headings">DAY WISE DETAILS</h5>
              <hr className="line" />
              <div>
                {packageData?.packageItinerary?.map((day, index) => (
                  <div
                    key={index}
                    className="package-name-duration-top mt-10 text-left"
                    style={{ padding: "10px" }}
                  >
                    <h4 className="text-left">Day {day?.dayCount}</h4>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: day?.shortDescription,
                      }}
                      className="text-left py-5"
                    ></p>
                    <div className="flex justify_content_between align_items_center">
                      <p>
                        <b>Start :</b> {day?.startPoint}{" "}
                      </p>
                      <p>
                        <b>End :</b> {day?.endPoint}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Hotel Categories */}
            <div className="day-wise-details-container p_20 m_0 p_top_0">
              <h5 className="day-wise-details-headings">Hotel Categories</h5>
              <hr className="line" />
              <ul className="">
                {packageData?.hotelCategory?.map((category, index) => (
                  <li key={index} className="text-capitalize" >{category}</li>
                ))}
              </ul>
            </div>

            {/* Star Ratings */}
            <div className="day-wise-details-container p_20 m_0 p_top_0">
              <h5 className="day-wise-details-headings">Star Ratings</h5>
              <hr className="line" />
              <ul className="">
                {packageData?.hotelStarRating?.map((rating, index) => (
                  <li key={index}>{rating} Star</li>
                ))}
              </ul>
            </div>

            {/* Policies */}
            <div className="day-wise-details-container p_20 m_0 p_top_0">
              <h5 className="day-wise-details-headings">Policies</h5>
              <hr className="line" />
              {packageData?.packagePolicies?.map((policy, index) => (
                <div key={index}>
                  <h4 className="mt-10">{policy?.policyName}</h4>
                  <p
                    dangerouslySetInnerHTML={{ __html: policy?.policyContent }}
                  ></p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ItnerarySideBar;
