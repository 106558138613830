import React from "react";
import "./PointsRemember.css";
import Skeleton from "react-loading-skeleton";

const PointRemember = ({ singlePackageData, loader }) => {
  return (
    <>
      {loader ? (
        <div className="package-update-content-containers">
          <div className="package-update-cards">
            <div className="package-card-body">
              <div className="package-update-card">
                <div className="content-consider">
                  <div>
                    <Skeleton width={100} />
                    <div className="agent">
                      <div className="singlePackageTabShowBullets">
                        <ul>
                          <li>
                            <Skeleton width={720} />
                          </li>
                          <li>
                            <Skeleton width={400} />
                          </li>
                          <li>
                            <Skeleton width={300} />
                          </li>
                          <li>
                            <Skeleton width={200} />
                          </li>
                          <li>
                            <Skeleton width={720} />
                          </li>
                          <li>
                            <Skeleton width={400} />
                          </li>
                          <li>
                            <Skeleton width={300} />
                          </li>
                          <li>
                            <Skeleton width={200} />
                          </li>
                          <li>
                            <Skeleton width={720} />
                          </li>
                          <li>
                            <Skeleton width={400} />
                          </li>
                          <li>
                            <Skeleton width={300} />
                          </li>
                          <li>
                            <Skeleton width={200} />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  {Array.from({ length: 3 }).map((_, index) => (
                    <div key={index}>
                      <Skeleton width={100} />
                      <div className="agent">
                        <div className="singlePackageTabShowBullets">
                          <ul>
                            <li>
                              <Skeleton width={720} />
                              <Skeleton width={400} />
                              <Skeleton width={300} />
                              <Skeleton width={200} />
                            </li>
                            <li>
                              <Skeleton width={720} />
                              <Skeleton width={400} />
                              <Skeleton width={300} />
                              <Skeleton width={200} />
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="package-update-card">
            {/* Content for accommodation */}
            <div className="content-consider">
              <div>
                {singlePackageData?.packagePolicies?.length > 0 ? (
                  <>
                    {singlePackageData?.packagePolicies?.map((items,index) => {
                      const points_to_remember = items;
                      return (
                        <div key={index}>
                          {items.policyType === "points_to_remember" && (
                            <>
                         {items.policyContent ?   <div
                              className="singlePackageTabShowBullets"
                              dangerouslySetInnerHTML={{
                                __html: items.policyContent,
                              }}
                              ></div> : <>No Data Found.</>}
                              </>
                          )}
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <div className="no-data-found">No data found.</div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PointRemember;
