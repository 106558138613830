import React, { useEffect, useRef, useState } from "react";
import VendorHeader from "../../../CustomComponents/Property/VendorHeader/VendorHeader";
import AgentSideBar from "../AgentSideBar/AgentSideBar";
import AgentHeader from "../AgentHeader/AgentHeader";
import AgentOverview from "../AgentOverview/AgentOverview";
import FilterPackages from "../../../CustomComponents/FilterPackages/FilterPackages";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Slider from "react-slick/lib/slider";
import Flatpickr from "react-flatpickr";
import CustomListingCard from "../../../CustomComponents/CustomListingCard/CustomListingCard";
import Skeleton from "react-loading-skeleton";
import { animateScroll as scroll } from "react-scroll";
import {
  calculateToDatePlus,
  formatIndianCurrency,
} from "../../../utils/utils";
import CustomSearchFormHolidays from "../../../CustomComponents/SearchForm/CustomSearchFormHolidays/CustomSearchFormHolidays";
import BgBackdrop from "../../../CustomComponents/BgBackDrop/BgBackDrop";
import NewHotelsSearchForm from "../../../CustomComponents/SearchForm/NewHotelsSearchForm/NewHotelsSearchForm";
import NewHolidaysSearchForm from "../../../CustomComponents/SearchForm/NewHolidaysSearchForm/NewHolidaysSearchForm";
import { showErrorToast, showSuccessToast } from "../../../assets/toastUtils";
import SearchStateInput from "../../../CustomComponents/SearchSateInput/SearchStateInput";

function AgentDashboard({ allPropertiesPage, vendorProperty, text }) {
  var settings = {
    speed: 500,
    centerMode: false,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,

    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          infinite: true,
          arrows: false,
        },
      },
      {
        breakpoint: 630,
        settings: {
          infinite: true,
          slidesToShow: 2,
          arrows: false,
        },
      },
      {
        breakpoint: 450,
        settings: {
          infinite: true,
          slidesToShow: 1.5,
          arrows: false,
        },
      },
      {
        breakpoint: 374,
        settings: {
          infinite: true,
          arrows: false,
          slidesToShow: 1.5,
        },
      },
    ],
  };
  var hotelSettings = {
    speed: 500,
    centerMode: false,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,

    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          infinite: true,
          arrows: false,
        },
      },
      {
        breakpoint: 630,
        settings: {
          infinite: true,
          slidesToShow: 2,
          arrows: false,
        },
      },
      {
        breakpoint: 450,
        settings: {
          infinite: true,
          slidesToShow: 1.5,
          arrows: false,
        },
      },
      {
        breakpoint: 374,
        settings: {
          infinite: true,
          arrows: false,
          slidesToShow: 1.5,
        },
      },
    ],
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [hotelData, setHotelData] = useState([]);
  const [activeButton, setActiveButton] = useState("holiday");
  // console.log("hotelData", hotelData);

  const navigate = useNavigate();
  const itemsPerPage = 9;
  const [bookingData, setBookingData] = useState({});
  const [loader, setLoader] = useState(true);
  const location = useLocation();
  const [adult, setAdults] = useState(2);
  const [children, setChildren] = useState(0);
  const [rooms, setRooms] = useState(1);
  const [childAge, setChildAge] = useState([]);

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [dataFromChild, setDataFromChild] = useState("");
  // const [stateResultArray, setStateResultArray] = useState("");
  // console.log(stateResultArray);
  const [filter, setFilter] = useState("Holidays"); // To store the selected filter

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter); // Update the filter based on the button clicked
  };

  const getDataFromChild = (data, resultArray) => {
    setDataFromChild(data);
    // setStateResultArray(resultArray)
    // console.log(resultArray);
  };
  // console.log(dataFromChild, "getDataFromChild");

  useEffect(() => {
    // Check if token is present in local storage
    const token = localStorage.getItem("token");
    if (token) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  // console.log("isLoggedIn", isLoggedIn);
  const [searchFilterPopup, setSearchFilterPopup] = useState(false);

  const [guestDetails, setIsguestDetails] = useState(false);

  const [selectedLocation, setSelectedLocation] = useState(null);
  const dropdownRef = useRef(null);
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      // Click is outside the dropdown, so close it
      // setIsguestDetails(false);
    }
  };
  // functions for increase decrease
  const onChildDecrease = (e) => {
    e.preventDefault();
    const updatedChildren = children - 1;

    setChildren(updatedChildren ? updatedChildren : 0);
    const updatedAges = childAge.slice(0, childAge.length - 1);
    setChildAge(updatedAges);
  };

  const onChildIncrease = (e) => {
    e.preventDefault();
    const updatedChildren = children + 1;
    setChildren(updatedChildren);
    setChildAge([...childAge, ""]);
  };

  const handleChildrenAgeChange = (index, event) => {
    const updatedAges = [...childAge];
    const ageValue = event.target.value.replace(/[^0-9]/g, "");

    if (ageValue > 12) {
      showErrorToast("Age should be less than or equal to 12");
      return;
    }

    updatedAges[index] = ageValue !== "" ? parseInt(ageValue, 10) : "";
    setChildAge(updatedAges);
  };

  const [destinationToggle, setdestinationToggle] = useState(false);
  const [selectDates, setselectDates] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(calculateToDatePlus(new Date(), 1));

  const onAdultIncrease = () => {
    const newAdult = adult + 1;
    setAdults(newAdult);
    if (newAdult > 1 && newAdult % 2 === 0 && rooms < newAdult / 2) {
      setRooms(rooms + 1);
    }
  };

  const onAdultDecrease = () => {
    if (adult > 1) {
      const newAdult = adult - 1;
      setAdults(newAdult);
      if (newAdult % 2 !== 0 && rooms > 1) {
        setRooms(rooms - 1);
      }
    }
  };

  const dropdownGuestDetails = () => {
    setIsguestDetails(!guestDetails);
  };

  // STATES
  const [date, setDate] = useState(new Date());
  const [selectedRadio, setSelectedRadio] = useState("");

  const [data, setData] = useState({
    fromCity: "",
    toCity: "",
  });
  //
  const [guest, setGuest] = useState(2);

  // functions for increase decrease

  const handleSubmitButtonClickHandler = async (e) => {
    if (e) {
      e.preventDefault();
    }
    let hasChildAgeError = false;

    const sanitizedChildAges = childAge
      .map((age) => (age !== "" ? age : null))
      .filter((age) => age !== null);

    const childAgeParams = sanitizedChildAges
      .map((age, index) => {
        if (age > 12) {
          alert("Child age must be between 0 and 12");
          hasChildAgeError = true;
          return;
        }
        return `&childAge[${index + 1}]=${age}`;
      })
      .join("");

    if (hasChildAgeError) {
      return;
    }

    setLoader(true);

    // console.log(children, "children");

    try {
      await navigate(
        `/agent/all-packages?location=${dataFromChild}&guest=${guest}&children=0&${childAgeParams}&radio=${selectedRadio}`,
        // `/searchresults?hotelID=&location=${property_city}&checkinDay=${checkin.day}&checkinMonth=${checkin.month}&checkinYear=${checkin.year}&checkoutDay=${checkout.day}&checkoutMonth=${checkout.month}&checkoutYear=${checkout.year}&room=${rooms}&adult=${adult}&children=${children}${childAgeParams}&checkIn=${formated_start_date}&checkOut=${formated_end_date}&noOfNights=${noOfNights}#hotels`,
        undefined,
        { shallow: false }
      );
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoader(false);
    } finally {
      setLoader(false);
    }
  };

  const handleSubmitButtonClickHandlerOnHotels = async (e) => {
    if (!startDate || !endDate) {
      showErrorToast("Fill required detail first");
      return;
    }

    if (e) {
      e.preventDefault();
    }
    let hasChildAgeError = false;

    const sanitizedChildAges = childAge
      .map((age) => (age !== "" ? age : null))
      .filter((age) => age !== null);

    const childAgeParams = sanitizedChildAges
      .map((age, index) => {
        if (age > 12) {
          alert("Child age must be between 0 and 12");
          hasChildAgeError = true;
          return;
        }
        return `&childAge[${index + 1}]=${age}`;
      })
      .join("");

    if (hasChildAgeError) {
      return;
    }

    setLoader(true);

    try {
      await navigate(
        `/agent/all-Hotels?hotelID=1&location=${dataFromChild}&room=${rooms}&adult=${adult}&children=0&checkinDay=${new Date(
          startDate
        ).getDate()}&checkinMonth=${
          new Date(startDate).getMonth() + 1
        }&checkinYear=${new Date(
          startDate
        ).getFullYear()}&checkoutDay=${new Date(
          endDate
        ).getDate()}&checkoutMonth=${
          new Date(endDate).getMonth() + 1
        }&checkoutYear=${new Date(
          endDate
        ).getFullYear()}&room=${rooms}&adult=${adult}&radio=${selectedRadio}`,
        undefined,
        { shallow: false }
      );
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoader(false);
    } finally {
      setLoader(false);
    }
  };

  const handleLogout = async () => {
    const token = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_APP_URL}/api/v1/customers/logout`,
        requestOptions
      );
      const result = await response.text();
      // console.log(result);
      showSuccessToast("Logged Out Successfull");
      localStorage.clear();
      setIsLoggedIn(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (destinationToggle) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [destinationToggle]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const childAges = [];
    let i = 1;
    while (params.has(`childAge[${i}]`)) {
      childAges.push(params.get(`childAge[${i}]`));
      i++;
    }

    const data = {
      location: params.get("location"),
      room: params.get("room"),
      adult: params.get("adult"),
      children: params.get("children"),
      radio: params.get("radio"),
      childAge: childAges,
    };
    setBookingData(data);
    fetchPackageData(data);
    postBookingData();
    setChildren(data.children);
    setChildAge(childAges);
  }, [location.search]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const clearSelection = () => {
    // console.log("Clearing selection...");
  };

  // Calculate start and end index of items to display
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const [packageData, setPackageData] = useState([]);
  // console.log(packageData, "packageData");

  const fetchPackageData = async (data) => {
    // setLoader(true);
    const myHeaders = new Headers();
    // myHeaders.append("Accept", "application/json");
    myHeaders.append("key", process.env.REACT_APP_API_KEY);
    const formdata = new FormData();
    // formdata.append("location", data.location);
    formdata.append("limit", 8);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_APP_URL}/api/v1/holidays/fetch`,
        requestOptions
      );
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
      const result = await response.json();

      // console.log(result);
      setLoader(false);

      const filteredData = result?.data?.filter(
        (item) => item.canBookable === 1
      );
      setPackageData(filteredData);
    } catch (error) {
      setPackageData([]);
      setLoader(false);
      console.error(error);
    }
  };

  // console.log(packageData);

  const postBookingData = async (data, filters) => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("key", process.env.REACT_APP_API_KEY);

    const formdata = new FormData();
    formdata.append("checkIn", "2024-09-18");
    formdata.append("checkOut", "2024-09-20");
    formdata.append("adults", 2);
    formdata.append("child", 0);
    formdata.append("location", "Shimla");
    formdata.append("rooms", 1);
    formdata.append("PropertyType", "hotel");

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_APP_URL}/api/v1/hotel-engine/check-availability`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      setHotelData(result.data.properties);
    } catch (error) {
      console.error("Error fetching data:", error.message);
      // Display error message in a toast or alert
      // showErrorToast(error.message);
    } finally {
      setLoader(false);
    }
  };

  // useEffect(() => {
  //   postBookingData();
  // }, []);

  var hotelListing = {
    slidesToShow: 4,
    speed: 500,
    arrows: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          centerPadding: "40px",
          slidesToShow: 3,
          //   arrows: false,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1.5,
          arrows: false,
        },
      },
      {
        breakpoint: 424,
        settings: {
          slidesToShow: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <>
      <AgentHeader showSelectProperty={true} vendorProperty={false} />
      <div className="sidebar_main_div">
        <AgentSideBar />
        <div className="agent-info-container">
          <div className="flex justify_content_between align_items_center">
            <h4>Overview</h4>
            <Link
              className="view-all-btn fit-width"
              onClick={() => setSearchFilterPopup(true)}
            >
              Search More..
            </Link>
          </div>
          <AgentOverview />
          {/* Recommended packages */}
          <section className="mt-20">
            <div className="flex justify_content_between align_items_center">
              <div>
                {" "}
                <h4 className="text-left">Recommended Packages</h4>
              </div>

              {searchFilterPopup && (
                <div className="bg_back_drop_overlay">
                  <div className="holdays-search-popup">
                    <div className="flex justify_content_between align_items_center">
                      <div></div>
                      <div className="flex flex_gap_10 justify_content_center">
                        <button
                          className="secondary_btn fit-width"
                          style={{
                            backgroundColor:
                              filter === "Holidays" ? "#016937" : null,
                            color: filter === "Holidays" ? "#fff" : "#016937",
                          }}
                          onClick={() => handleFilterChange("Holidays")}
                        >
                          Holidays
                        </button>
                        <button
                          style={{
                            backgroundColor:
                              filter === "Hotels" ? "#016937" : null,
                            color: filter === "Hotels" ? "#fff" : "#016937",
                          }}
                          className="secondary_btn fit-width"
                          onClick={() => handleFilterChange("Hotels")}
                        >
                          Hotels
                        </button>
                      </div>
                      <div className="p_10 p_top_0">
                        <svg
                          onClick={() => setSearchFilterPopup(false)}
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 13L13 1"
                            stroke="#AAAAAA"
                            strokeWidth="1.2"
                          />
                          <path
                            d="M13 13L1 1"
                            stroke="#AAAAAA"
                            strokeWidth="1.2"
                          />
                        </svg>
                      </div>
                    </div>
                    {filter === "Holidays" && (
                      <div
                        className="NewSearch-Main search_form"
                        id="NewSearch-Main"
                        style={
                          guestDetails || destinationToggle
                            ? { zIndex: "999" }
                            : { position: "" }
                        }
                      >
                        <form
                          className="search_form_main "
                          onSubmit={handleSubmitButtonClickHandler}
                        >
                          <button className="hide_radio_btn">
                            <span>All</span>
                            <svg
                              fill="#BB735D"
                              height="10px"
                              width="10px"
                              version="1.1"
                              id="Layer_1"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              viewBox="0 0 330 330"
                              xmlSpace="preserve"
                            >
                              <path
                                id="XMLID_225_"
                                d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393
          c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393
          s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z"
                              />
                            </svg>
                          </button>
                          <div className="search_data_home">
                            <div className="search_form_div NewSearch-tags relative">
                              <label htmlFor="">From City/Destination</label>
                              <SearchStateInput
                                onClick={() => setdestinationToggle(true)}
                                onDataChange={getDataFromChild}
                                dropdownGuestDetails={dropdownGuestDetails}
                                setIsguestDetails={setIsguestDetails}
                              />
                            </div>
                            <div className="search_form_div NewSearch-tags icon_button_only">
                              <label
                                onClick={dropdownGuestDetails}
                                className="search_label"
                              >
                                Guests{" "}
                                <svg
                                  width="9"
                                  height="5"
                                  viewBox="0 0 9 5"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M8.25 0.75L4.75 4.25L1.25 0.75"
                                    stroke="#BB735D"
                                  />
                                </svg>
                              </label>
                              <p
                                style={{ position: "relative" }}
                                className="dropdown-toggle search_form_input"
                              >
                                <div
                                  onClick={dropdownGuestDetails}
                                  ref={dropdownRef}
                                >
                                  <div className="optionCounter"></div>
                                  <span id="adult_count" className="font_16">
                                    {" "}
                                    <b>{guest}</b>{" "}
                                  </span>
                                  <span className="font_12"> Guests, </span>
                                  <span id="child_count" className="font_16">
                                    <b>{children}</b>
                                  </span>
                                  <span className="font_12"> Children</span>
                                </div>
                                {guestDetails && (
                                  <div
                                    className="guest_details_dropdown"
                                    id="guestDetailsPopup"
                                  >
                                    <div className="agent">
                                      <span>Guests</span>
                                      <div>
                                        <button
                                          type="button"
                                          disabled={guest <= 1}
                                          className="optionCounterButton optionCounterButtonWithBorder"
                                          onClick={() => {
                                            if (guest <= 2) return;
                                            setGuest(Number(guest) - 1);
                                          }}
                                        >
                                          <span
                                            className="onclick_btn"
                                            id="adult_minus"
                                          >
                                            -
                                          </span>
                                        </button>

                                        <input
                                          className="optionCounterNumber"
                                          value={guest}
                                          min={1}
                                          type="number"
                                          onChange={(e) =>
                                            setGuest(e.target.value)
                                          }
                                        />

                                        <button
                                          type="button"
                                          disabled={guest >= 11}
                                          className="optionCounterButton optionCounterButtonWithBorder"
                                          onClick={() => {
                                            if (guest >= 11) return;
                                            setGuest(Number(guest) + 1);
                                          }}
                                        >
                                          <span
                                            className="onclick_btn"
                                            id="adult_plus"
                                          >
                                            +
                                          </span>
                                        </button>
                                      </div>
                                    </div>
                                    <div className="agent">
                                      <span>Children</span>
                                      <div>
                                        <button
                                          type="button"
                                          disabled={children <= 0}
                                          className="optionCounterButton optionCounterButtonWithBorder"
                                          onClick={onChildDecrease}
                                        >
                                          <span
                                            className="onclick_btn"
                                            id="adult_minus"
                                          >
                                            -
                                          </span>
                                        </button>

                                        <input
                                          className="optionCounterNumber"
                                          value={children}
                                          min={1}
                                        />

                                        <button
                                          type="button"
                                          // disabled={children <= 1}
                                          className="optionCounterButton optionCounterButtonWithBorder"
                                          onClick={onChildIncrease}
                                        >
                                          <span
                                            className="onclick_btn"
                                            id="adult_plus"
                                          >
                                            +
                                          </span>
                                        </button>
                                      </div>
                                    </div>

                                    {childAge.map((age, index) => (
                                      <div className="navbar agent" key={index}>
                                        <div>
                                          <span>Child Age</span>
                                        </div>
                                        <div>
                                          <input
                                            className="childAge_input"
                                            type="number"
                                            name="childAge[]"
                                            min="1"
                                            value={age}
                                            onChange={(event) =>
                                              handleChildrenAgeChange(
                                                index,
                                                event
                                              )
                                            }
                                            required
                                          />
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </p>
                            </div>

                            <div className="">
                              <button type="submit" className="search-btn m_0">
                                SEARCH
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    )}
                    {filter === "Hotels" && (
                      <div
                        className="NewSearch-Main search_form"
                        id="NewSearch-Main"
                        style={
                          guestDetails || destinationToggle
                            ? { zIndex: "999" }
                            : { position: "" }
                        }
                      >
                        <form
                          className="search_form_main"
                          onSubmit={handleSubmitButtonClickHandlerOnHotels}
                        >
                          <button className="hide_radio_btn">
                            <span>All</span>
                            <svg
                              fill="#BB735D"
                              height="10px"
                              width="10px"
                              version="1.1"
                              id="Layer_1"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              viewBox="0 0 330 330"
                              xmlSpace="preserve"
                            >
                              <path
                                id="XMLID_225_"
                                d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393
c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393
s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z"
                              />
                            </svg>
                          </button>

                          <div className="input_search_top search_data_home">
                            <div className="search_form_div NewSearch-tags">
                              <label htmlFor="">
                                <small>City/Destination</small>{" "}
                              </label>
                              <SearchStateInput
                                onClick={() => setdestinationToggle(true)}
                                onDataChange={getDataFromChild}
                                setIsguestDetails={setIsguestDetails}
                              />
                            </div>

                            <div
                              className="search_form_div w-100"
                              onClick={() => [
                                setStartDate(null),
                                setdestinationToggle(true),
                              ]}
                              setStartDate={setStartDate}
                            >
                              <label>Check In</label>
                              <Flatpickr
                                className="search_form_input"
                                value={startDate}
                                onChange={([date1, date2]) => {
                                  setStartDate(date1);
                                  setEndDate(date2);
                                }}
                                options={{ mode: "range", minDate: "today" }}
                                required
                              />
                            </div>
                            <div className="search_form_div w-100">
                              <label>Check Out</label>
                              <Flatpickr
                                className="search_form_input"
                                value={endDate}
                                onChange={([date1, date2]) => {
                                  setStartDate(date1);
                                  setEndDate(date2);
                                }}
                                options={{ mode: "range", minDate: "today" }}
                                required
                              />
                              {/* <Flatpickr
                        className="search_form_input"
                        value={endDate}
                        onChange={([date1, date2]) => {
                          setStartDate(date1);
                          setEndDate(date2);
                        }}
                        options={{ mode: "range", minDate: "today" }}
                        required
                      /> */}
                            </div>
                            <div className="search_form_div NewSearch-tags icon_button_only">
                              <label
                                onClick={dropdownGuestDetails}
                                className="search_label"
                              >
                                Room & Guests{" "}
                                <svg
                                  width="9"
                                  height="5"
                                  viewBox="0 0 9 5"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M8.25 0.75L4.75 4.25L1.25 0.75"
                                    stroke="#BB735D"
                                  />
                                </svg>
                              </label>
                              <p
                                style={{ position: "relative" }}
                                className="dropdown-toggle search_form_input"
                              >
                                <div
                                  onClick={dropdownGuestDetails}
                                  ref={dropdownRef}
                                >
                                  <d iv className="optionCounter"></d>
                                  <span className="font_16" id="adult_count">
                                    <b> {adult}</b>
                                  </span>
                                  <span className="font_12"> Adults,</span>{" "}
                                  <span className="font_16" id="room_count">
                                    <b> {rooms}</b>
                                  </span>
                                  <span className="font_12"> Rooms, </span>{" "}
                                  <span className="font_16" id="child_count">
                                    <b> {children}</b>
                                  </span>
                                  <span className="font_12"> Children</span>
                                </div>
                                {guestDetails && (
                                  <div
                                    className="guest_details_dropdown"
                                    id="guestDetailsPopup"
                                  >
                                    <div className="agent">
                                      <span>Adult</span>
                                      <div>
                                        <button
                                          type="button"
                                          disabled={adult <= 1}
                                          className="optionCounterButton optionCounterButtonWithBorder"
                                          onClick={onAdultDecrease}
                                        >
                                          <span
                                            className="onclick_btn"
                                            id="adult_minus"
                                          >
                                            -
                                          </span>
                                        </button>

                                        <input
                                          className="optionCounterNumber"
                                          value={adult}
                                          min={1}
                                        />

                                        <button
                                          type="button"
                                          // disabled={rooms <= 1}
                                          className="optionCounterButton optionCounterButtonWithBorder"
                                          onClick={onAdultIncrease}
                                        >
                                          <span
                                            className="onclick_btn"
                                            id="adult_plus"
                                          >
                                            +
                                          </span>
                                        </button>
                                      </div>
                                    </div>
                                    <div className="agent">
                                      <span>Rooms</span>
                                      <div>
                                        <button
                                          type="button"
                                          disabled={rooms <= 1}
                                          className="optionCounterButton optionCounterButtonWithBorder"
                                          onClick={() =>
                                            setRooms(Number(rooms) - 1)
                                          }
                                        >
                                          <span
                                            className="onclick_btn"
                                            id="adult_minus"
                                          >
                                            -
                                          </span>
                                        </button>

                                        <input
                                          className="optionCounterNumber"
                                          value={rooms}
                                          min={1}
                                        />

                                        <button
                                          type="button"
                                          // disabled={rooms <= 1}
                                          className="optionCounterButton optionCounterButtonWithBorder"
                                          onClick={() =>
                                            setRooms(Number(rooms) + 1)
                                          }
                                        >
                                          <span
                                            className="onclick_btn"
                                            id="adult_plus"
                                          >
                                            +
                                          </span>
                                        </button>
                                      </div>
                                    </div>
                                    <div className="agent">
                                      <span>Children</span>
                                      <div>
                                        <button
                                          type="button"
                                          disabled={children <= 0}
                                          className="optionCounterButton optionCounterButtonWithBorder"
                                          onClick={onChildDecrease}
                                        >
                                          <span
                                            className="onclick_btn"
                                            id="adult_minus"
                                          >
                                            -
                                          </span>
                                        </button>

                                        <input
                                          className="optionCounterNumber"
                                          value={children}
                                          min={1}
                                        />

                                        <button
                                          type="button"
                                          // disabled={children <= 1}
                                          className="optionCounterButton optionCounterButtonWithBorder"
                                          onClick={onChildIncrease}
                                        >
                                          <span
                                            className="onclick_btn"
                                            id="adult_plus"
                                          >
                                            +
                                          </span>
                                        </button>
                                      </div>
                                    </div>

                                    {childAge.map((age, index) => (
                                      // <div className="optionItem" key={index}>
                                      //   <span className="optionText">Child {index + 1} Age</span>
                                      //   <div className="optionCounter">
                                      //     <input
                                      //       className="childAge_input"
                                      //       type="number"
                                      //       name="childAge[]"
                                      //       min="1"
                                      //       value={age}
                                      //       onChange={(event) =>
                                      //         handleChildrenAgeChange(index, event)
                                      //       }
                                      //       required
                                      //     />
                                      //   </div>
                                      // </div>
                                      <div className="navbar agent" key={index}>
                                        <div>
                                          <span>Child Age</span>
                                        </div>
                                        <div>
                                          <input
                                            className="childAge_input"
                                            type="number"
                                            name="childAge[]"
                                            min="1"
                                            value={age}
                                            onChange={(event) =>
                                              handleChildrenAgeChange(
                                                index,
                                                event
                                              )
                                            }
                                            required
                                          />
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </p>
                            </div>

                            <div className="">
                              <button type="submit" className="search-btn m_0">
                                SEARCH
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className="packages_container mt-20">
              {/* {!loader ? ( */}
              <Slider {...settings}>
                {packageData?.map((data, index) => (
                  <CustomListingCard
                    className="w-full"
                    key={index}
                    imgSrc={
                      data.packageImages[0]
                        ? data.packageImages[0]
                        : data.packageDestination[0].images
                    }
                    title={data.packageName}
                    packageHub={data.packageHub}
                    duration={data.totalDays}
                    nightDuration={data.totalNights}
                    price={data.startingPrice}
                    bookingLink={data.bookingLink}
                    detailsLink={data.detailsLink}
                    packagehandle={data.packagehandle}
                    canBookable={data?.canBookable}
                  />
                ))}
              </Slider>
              {/* ) : (
                <>
                  <Slider {...settings}>
                    {Array.from({ length: 4 }).map((_, index) => (
                      <div className="card-listing">
                        <div className="hotelCardImg">
                          <Skeleton height={170} />
                        </div>
                        <div className="card_content">
                          <h6>
                            <Skeleton width={300} />
                          </h6>
                          <p>
                            <Skeleton width={100} />
                          </p>
                          <div className=" flex justify_content_between align_items_end">
                            <div>
                              <h4>
                                <Skeleton width={100} />
                              </h4>
                            </div>
                            <div>
                              {" "}
                              <h5>
                                <Skeleton width={100} />
                              </h5>
                            </div>
                          </div>
                          <div className="buttons justify_content_between">
                            <button>
                              {" "}
                              <Skeleton width={100} />
                            </button>
                            <button>
                              {" "}
                              <Skeleton width={100} />
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>
                </>
              )} */}
            </div>
          </section>
          {/* Recommended hotels  */}
          <section id="topDestination">
            <div>
              {" "}
              <h4 className="text-left mt-20">Recommended Hotels</h4>
            </div>

            <div className="images-flex mt-20">
              {/* {!loader ? ( */}
              <Slider {...hotelSettings}>
                {hotelData?.map((items, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => {
                        scroll.scrollToTop({
                          duration: 500,
                          smooth: "easeInOutQuad",
                        });
                        navigate(
                          `/single-hotel/${items?.hotel_slug}?hotelId=${items?.hotel_id}&location=${items?.hotel_city}&room=1&adult=2&children=0&&checkinDay=8&checkinMonth=10&checkinYear=2024&checkoutDay=9&checkoutMonth=10&checkoutYear=2024`
                        );
                      }}
                      className="destination_link"
                    >
                      <img src={items?.hotel_image[0]} alt={items?.alt} />
                      <div className="destination_name">
                        <div className="flex justify_content_between">
                          <div className="text-left">
                            <span className="text-left text-white">
                              {items.hotel_name}
                            </span>
                            <span className="font_11 block text-left text-white">
                              <svg
                                width="10"
                                height="12"
                                viewBox="0 0 10 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M5.00004 6.00008C5.32087 6.00008 5.59553 5.88585 5.824 5.65737C6.05247 5.4289 6.16671 5.15425 6.16671 4.83341C6.16671 4.51258 6.05247 4.23793 5.824 4.00946C5.59553 3.78098 5.32087 3.66675 5.00004 3.66675C4.67921 3.66675 4.40455 3.78098 4.17608 4.00946C3.94761 4.23793 3.83337 4.51258 3.83337 4.83341C3.83337 5.15425 3.94761 5.4289 4.17608 5.65737C4.40455 5.88585 4.67921 6.00008 5.00004 6.00008ZM5.00004 10.2876C6.18615 9.19869 7.06601 8.20946 7.63963 7.31987C8.21324 6.43029 8.50004 5.64036 8.50004 4.95008C8.50004 3.89036 8.16219 3.02265 7.4865 2.34696C6.8108 1.67126 5.98199 1.33341 5.00004 1.33341C4.0181 1.33341 3.18928 1.67126 2.51358 2.34696C1.83789 3.02265 1.50004 3.89036 1.50004 4.95008C1.50004 5.64036 1.78685 6.43029 2.36046 7.31987C2.93407 8.20946 3.81393 9.19869 5.00004 10.2876ZM5.00004 11.8334C3.43476 10.5015 2.26567 9.26432 1.49275 8.12196C0.719832 6.97959 0.333374 5.9223 0.333374 4.95008C0.333374 3.49175 0.802471 2.32994 1.74067 1.46466C2.67886 0.599387 3.76532 0.166748 5.00004 0.166748C6.23476 0.166748 7.32122 0.599387 8.25942 1.46466C9.19761 2.32994 9.66671 3.49175 9.66671 4.95008C9.66671 5.9223 9.28025 6.97959 8.50733 8.12196C7.73442 9.26432 6.56532 10.5015 5.00004 11.8334Z"
                                  fill="white"
                                />
                              </svg>
                              {items.hotel_city}
                            </span>
                          </div>
                          <div>
                            <span className="font_11">Starting From</span>
                            <h6>
                              {formatIndianCurrency(items.starting_price)}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Slider>
              {/* ) : (
                <Slider {...hotelListing}>
                  {Array.from({ length: 5 }).map((_, index) => {
                    return (
                      <Link
                        style={{ width: "33.33%" }}
                        className="destination_link"
                      >
                        <div>
                          <Skeleton height={300} />
                        </div>
                      </Link>
                    );
                  })}
                </Slider>
              )} */}
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default AgentDashboard;
