import React, { useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { useNavigate, useLocation } from "react-router-dom";
import NoDataFound from "../NoDataFound/NoDataFound";
import Skeleton from "react-loading-skeleton";
import CustomListingCard from "../CustomListingCard/CustomListingCard";
import { useEffect } from "react";
import { animateScroll as scroll } from "react-scroll";
import CustomListingCardActivities from "../CustomListingCardActivities/CustomListingCardActivities";

function FilterActivitiesComponent(Props) {
  var settings = {
    speed: 500,
    centerMode: false,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,

    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          infinite: true,
          arrows: true,
        },
      },
      {
        breakpoint: 630,
        settings: {
          infinite: true,
          slidesToShow: 2,
          arrows: false,
        },
      },
      {
        breakpoint: 450,
        settings: {
          infinite: true,
          slidesToShow: 1.5,
          arrows: false,
        },
      },
      {
        breakpoint: 374,
        settings: {
          infinite: true,
          slidesToShow: 1,
          arrows: false,
        },
      },
    ],
  };
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const itemsPerPage = 9;
  const [bookingData, setBookingData] = useState({});
  const [loader, setLoader] = useState(true);
  const location = useLocation();
  const [children, setChildren] = useState(0);
  const [childAge, setChildAge] = useState([]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const childAges = [];
    let i = 1;
    while (params.has(`childAge[${i}]`)) {
      childAges.push(params.get(`childAge[${i}]`));
      i++;
    }

    const data = {
      location: params.get("location"),
      room: params.get("room"),
      adult: params.get("adult"),
      children: params.get("children"),
      radio: params.get("radio"),
      childAge: childAges,
    };
    setBookingData(data);
    fetchActivitiesData(data);
    setChildren(data.children);
    setChildAge(childAges);
  }, [location.search]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const clearSelection = () => {
    // console.log("Clearing selection...");
  };

  // Calculate start and end index of items to display
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const [packageData, setPackageData] = useState([]);
  // console.log(packageData, "packageData");

  const fetchActivitiesData = async (data) => {
    setLoader(true);
    const myHeaders = new Headers();
    // myHeaders.append("Accept", "application/json");
    myHeaders.append("key", process.env.REACT_APP_API_KEY);
    const formdata = new FormData();
    // formdata.append("location", data.location);
    formdata.append("limit", 8);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_APP_URL}/api/v1/activities/fetch`,
        requestOptions
      );
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
      const result = await response.json();

      // console.log(result);
      setLoader(false);

      // const filteredData = result?.data?.filter(
      //   (item) => item.canBookable === 1
      // );
      setPackageData(result?.data);
    } catch (error) {
      setPackageData([]);
      setLoader(false);
      console.error(error);
    }
  };

  useEffect(() => {
    fetchActivitiesData();
  }, []);

  return (
    <section className="packages_section">
      <div className="common_padding">
        <h3> Popular Activities in Himachal Pradesh </h3>
      </div>
      <div className="container">
        <div className="packages_container">
          {loader ? (
              <Slider {...settings}>
                {Array.from({ length: 4 }).map((_, index) => (
                  <div className="card-listing" key={index}>
                    <div className="hotelCardImg">
                      <Skeleton height={170} />
                    </div>
                    <div className="card_content">
                      <h6>
                        <Skeleton width={300} />
                      </h6>
                      <p>
                        <Skeleton width={100} />
                      </p>
                      <div className=" flex justify_content_between align_items_end">
                        <div>
                          <h4>
                            <Skeleton width={100} />
                          </h4>
                        </div>
                        <div>
                          {" "}
                          <h5>
                            <Skeleton width={100} />
                          </h5>
                        </div>
                      </div>
                      <div className="buttons justify_content_between">
                        <button>
                          {" "}
                          <Skeleton width={100} />
                        </button>
                        <button>
                          {" "}
                          <Skeleton width={100} />
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
          ) : (
            <Slider {...settings}>
              {packageData?.map((data, index) => (
                <CustomListingCardActivities
                  key={index}
                  imgSrc={
                    data.images[0]
                      ? data.images[0]
                      : data.packageDestination[0].images
                  }
                  title={data.name}
                  packageHub={data.packageHub}
                  duration={1}
                  nightDuration={data.totalNights}
                  price={data.activity_price}
                  bookingLink={data.bookingLink}
                  detailsLink={data.detailsLink}
                  packagehandle={data.packagehandle}
                  canBookable={data?.canBookable}
                  description={data?.content}
                  id={data.id}
                />
              ))}
            </Slider>
          )}

          <Link
            className="view-all-btn"
            onClick={() =>
              scroll.scrollToTop({
                duration: 500,
                smooth: "easeInOutQuad",
              })
            }
            to="/all-activities"
          >
            View All
          </Link>
        </div>
      </div>
    </section>
  );
}

export default FilterActivitiesComponent;
