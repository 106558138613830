import React, { useEffect, useState } from "react";
import sightseeingImg from "../../assets/images/Sightseeing.jpg";
import { Link } from "react-router-dom";
import StarRating from "../StarRating/StarRating";
import NoDataFound from "../NoDataFound/NoDataFound";
import imgNotFound from "../../assets/images/single-image.png";
import Skeleton from "react-loading-skeleton";

const AccommodationContent = ({
  singlePackageData,
  setSelectedCategory,
  selectedCategory,
  setSelectedMealPlan,
  selectedMealPlan,
  loader,
}) => {
  const [expandedCard, setExpandedCard] = useState(0); // Initially expand the first card
  const [selectedCard, setSelectedCard] = useState(0); // Initially select the first card

  const toggleCard = (index, e) => {
    e.stopPropagation();
    if (expandedCard === index) {
      setExpandedCard(null); // Collapse the card if it's already open
    } else {
      setExpandedCard(index); // Expand the clicked card
    }
  };

  const toggleSelect = (index) => {
    setSelectedCard(index); // Toggle the selected card
  };

  // console.log(singlePackageData);

  const groupByCategory = (accommodations) => {
    return (
      accommodations &&
      accommodations?.reduce((acc, accommodation) => {
        const category = accommodation.accommodationCategory;
        if (!acc[category]) {
          acc[category] = [];
        }
        acc[category].push(accommodation);
        return acc;
      }, {})
    );
  };

  // useEffect(() => {
  //   // setSelectedCategory("Accommodation");

  //   const selectedArray = groupByCategory(singlePackageData?.packageHotels)[selectedCategory];
  //   if(selectedArray?.length > 0){
  //     const findMeaPlan = selectedArray[0]?.accommodationMealPlan;
  //     setSelectedMealPlan(findMeaPlan);
  //     // console.log(findMeaPlan, "findMeaPlan");
  //   }

  // }, [selectedCategory]);

  useEffect(() => {
    if (!singlePackageData || !selectedCategory) {
      console.error(
        "Missing data: singlePackageData or selectedCategory is undefined."
      );
      return;
    }

    const groupedData = groupByCategory(singlePackageData.packageHotels);

    if (!groupedData || !groupedData[selectedCategory]) {
      console.error(
        `Error: groupByCategory returned undefined or no data for category ${selectedCategory}.`
      );
      return;
    }

    const selectedArray = groupedData[selectedCategory];

    if (selectedArray.length === 0) {
      console.warn(
        `No data found for the selected category: ${selectedCategory}`
      );
      return;
    }

    const findMealPlan = selectedArray[0]?.accommodationMealPlan;

    if (findMealPlan) {
      setSelectedMealPlan(findMealPlan);
    } else {
      console.warn("No meal plan found in the selected category.");
    }
  }, [selectedCategory, singlePackageData]);

  // console.log(selectedMealPlan, "selectedMealPlan");
  const data = groupByCategory(singlePackageData?.packageHotels);
  // console.log(data);

  return (
    <>
      {loader ? (
        <div className="package-update-content-containers">
          <div>
            {Array.from({ length: 3 }).map((_, index) => (
              <div className="package-update-card">
                <div className="p_10">
                  <Skeleton width={100} />
                </div>
                <div
                  className="content-consider"
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "10px",
                    maxHeight: "1000px",
                    overflow: "hidden",
                  }}
                >
                  {Array.from({ length: 5 }).map((_, index) => (
                    <div
                      className="card-content"
                      style={{
                        width: "calc(33.33% - 10px)",
                        boxSizing: "border-box",
                        border: "1px solid rgb(221, 221, 221)",
                        padding: "10px",
                      }}
                    >
                      <Skeleton height={150} width={225} />
                      <Skeleton width={80} />
                      <div className="flex justify_content_between">
                        <Skeleton width={50} />
                        <Skeleton width={30} />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <>
          {singlePackageData?.packageHotels ? (
            <>
              {Object.keys(
                groupByCategory(singlePackageData?.packageHotels)
              ).map((category, index) => (
                <div
                  key={index}
                  className="package-update-card"
                  style={{ transition: "height 0.3s ease-out" }}
                >
                  <div
                    className="flex p_10"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    onClick={(e) => toggleCard(index, e)}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <svg
                        width="15"
                        height="15"
                        viewBox="0 0 9 5"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                          cursor: "pointer",
                          transform:
                            expandedCard === index
                              ? "rotate(180deg)"
                              : "rotate(0deg)",
                          transition: "transform 0.3s",
                        }}
                      >
                        <path
                          d="M8.25 0.75L4.75 4.25L1.25 0.75"
                          stroke="#000"
                        />
                      </svg>{" "}
                      <div
                        className="flex text-capitalize"
                        style={{ alignItems: "center", gap: "4px" }}
                      >
                        {" "}
                        <b> {category} :</b>
                        {/* <StarRating value={3} />{" "} */}
                      </div>
                    </div>
                    <button
                      onClick={() => [
                        toggleSelect(index, category),
                        setSelectedCategory(category),
                      ]}
                      style={
                        selectedCard !== index
                          ? {
                              cursor: "pointer",
                              border: "1px solid #BB735D",
                              color: "#BB735D",
                              textTransform: "capitalize",
                              background: "#fff",
                              borderRadius: "4px",
                              display: "inline-block",
                              textAlign: "center",
                              fontSize: "14px",
                              fontWeight: "500",
                              padding: "5px 10px",
                            }
                          : { cursor: "pointer", color: "#016932" }
                      }
                    >
                      {selectedCard === index ? "Selected" : "Select"}
                    </button>
                  </div>
                  <div
                    className="accomodation_card"
                    style={{
                      display: "flex",
                      gap: "10px",
                      maxHeight: expandedCard === index ? "1000px" : "0",
                      overflow: "hidden",
                      transition: "max-height 0.3s ease-out",
                    }}
                  >
                    {groupByCategory(singlePackageData?.packageHotels)[
                      category
                    ]?.map((itemData, itemIndex) => {
                      // console.log(itemData, "itemdata");
                      return (
                        <div
                          key={itemIndex}
                          className="card_content accomodation_img"
                          style={{
                            // flexBasis: "calc(33.33% - 10px)",
                            boxSizing: "border-box",
                            border: "1px solid #ddd",
                            padding: "10px",
                          }}
                        >
                          <img
                            src={
                              itemData.accommodationImage
                                ? itemData.accommodationImage
                                : imgNotFound
                            }
                            style={{ width: "100%", height: "120px" }}
                            alt={`Image ${itemIndex + 1}`}
                          />
                          <h6 className="py-5">{itemData.accommodationName}</h6>
                          <div className="flex justify_content_between flex_wrap">
                            <p className="font_12">
                              {itemData.accommodationCity}
                            </p>
                            <p className="font_12">
                              {itemData.accommodationRating} Stars
                            </p>
                          </div>
                          <div className="flex py-5">
                            <b className="font_12">Meal Plan: </b>
                            <p className="text-uppercase font_12">
                              {" "}
                              {itemData.accommodationMealPlan}
                            </p>
                          </div>
                          {itemData?.accommodationRoom?.map((item, index) => {
                            return (
                              <div className="flex">
                                <b className="font_11">Room Category:</b>{" "}
                                <p className="font_11 text_right" key={index}>
                                  {item?.RoomCategory}
                                </p>
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                  </div>
                </div>
              ))}
            </>
          ) : (
            <NoDataFound />
          )}
        </>
      )}
    </>
  );
};

export default AccommodationContent;
